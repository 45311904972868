import { Button, Modal } from "antd";
import React, { useState } from "react";

const AlertConfirm = ({
                          actionBtnText,
                          actionBtnProps,
                          modalProps,
                          validate,
                          btnType
                      }) => {
    const {okText, onOk, alertMsg, alertTitle} = modalProps;
    const [visible, setVisible] = useState(false);

    const handleOk = () => {
        setVisible(false);
        onOk();
    };
    return (
        <>
            <Button
                type={btnType}
                {...actionBtnProps}
                onClick={() => {
                    if (!validate) {
                        setVisible(true);
                    } else if (validate()) setVisible(true);
                }}
            >
                {actionBtnText}
            </Button>
            <Modal
                title={alertTitle}
                visible={visible}
                centered
                onOk={handleOk}
                onCancel={() => setVisible(false)}
                okText={okText}
            >
                <p>{alertMsg}</p>
            </Modal>
        </>
    );
};
export default AlertConfirm;
