import useAxios from "axios-hooks";
import React from "react";
import { TestLineChart } from "../../screens/dashboard/charts";
import URLS from "../../constants/index";
import { formatData, formatDates } from "./chartUtils";

function TimeChart(props) {
  let [date1, date2] = [];
  // Sets default time. handled here because date1 and date2 don't need to be formatted if its default
  // and this is the only way that it can know
  if (props.firstDate === "" && props.secondDate === "") {
    date1 = new Date().getTime() - 86400000 * 7;
    date2 = new Date().getTime();
  } else {
    [date1, date2] = [props.firstDate, props.secondDate];
  }

  if (!props.input.hasOwnProperty("after")) {
    props.input.after = date1;
    props.input.before = date2;
  }
  const [{ data, loading, error }, refetch] = useAxios({
    url: `${URLS.API_ANALYTICS}/api/events`,
    params: props.input,
  });

  let formattedData = [];
  let widgetNumbers = {};
  let hourly = false;
  let [xAxis, yAxis] = "";
  let blankData = data && data.data && data.data.length === 0;
  if (data && data.data && !blankData) {
    [formattedData, widgetNumbers, hourly] = formatData(
      data.data,
      props.multiple,
      props.donation,
      date1,
      date2
    );
    xAxis = hourly ? "Hour" : "Date";
    yAxis = "Count";
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;
  return (
    <TestLineChart
      data={formattedData}
      name={props.name}
      xAxis={xAxis}
      yAxis={yAxis}
      multiple={props.multiple}
      widgetTracking={widgetNumbers} // this is for if splitting lines by widget, we need this data
      donation={props.donation}
      hourly={hourly}
    />
  );
}

export { TimeChart };
