import React, { useEffect, useState, useCallback } from "react";
import { Menu, Spin, Input, Button, message, Dropdown } from "antd";
import {
  UserRole,
  SEARCH_DELAY,
  customFieldsTypes,
  PAGINATION_COUNTS,
} from "../../../utils/contants";
import styled from "styled-components";
import { Mutations } from "../../../api";
import Queries from "../../../api/Queries";
import DeletePopUp from "../../../components/DeletePopUp";
import { useQuery, useMutation } from "@apollo/client";
import { debounce, trimErrorMessage } from "../../../utils";
import { getSession } from "../../../helpers/SessionManagement";
import PaginatedTableview from "../../../components/paginatedTableView";
import { initialColumns, otherColumns, initialVisibleFields } from "./columns";
import { DownOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import ExportCsv from "../exportCsv";

const PAGINATION_COUNT = PAGINATION_COUNTS.USERS;

const TableHeader = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;

  .TextInput {
    width: 256px;
    height: 32px;
    min-width: 100px;
  }
  .Actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export default function ProducersTable(props) {
  const { settingsData } = props;
  const [searchString, setSearchString] = useState("");
  const [selectedUserKeys, setSelectedUserKeys] = useState([]);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const onChangeSearch = (str) => {
    setSearchString(str);
  };

  const debounceOnChange = useCallback(
    debounce(onChangeSearch, SEARCH_DELAY),
    []
  );
  const localClient = useApolloClient();
  const cachedData = localClient.cache.readQuery({
    query: Queries.GET_PAGE_NUMBER,
  });
  const {
    data: producersData,
    error: producersError,
    loading: producersLoading,
    refetch: refetchProducers,
    fetchMore: fetchMoreProducers,
  } = useQuery(Queries.PRODUCERS_PAGINATION, {
    variables: {
      page: cachedData?.pageNumber || 1,
      query: searchString,
      per: PAGINATION_COUNT,
    },
    fetchPolicy: "network-only",
  });

  const [deleteProducers, { loading: deleteLoading, data: deleteData }] =
    useMutation(Mutations.DELETE_USERS);

  useEffect(() => {
    if (deleteData) {
      refetchProducers();
      message.success("User deleted successfully.");
    }
    // eslint-disable-next-line
  }, [deleteData]);

  useEffect(() => {
    if (producersError) {
      message.error(trimErrorMessage(producersError.message));
    }
  }, [producersError]);

  const onDeleteSelected = () => {
    if (producersData) {
      deleteProducers({
        variables: {
          ids: selectedUserKeys,
        },
      }).catch((err) => message.error(trimErrorMessage(err.message)));
    }
  };

  const customColumns = new Map(
    settingsData && settingsData.producerSetting
      ? settingsData.producerSetting.customFields.map((field) => {
          return [
            field.label,
            { name: field.label, id: field.id, type: field.type },
          ];
        })
      : []
  );

  const actionsMenu = () => (
    <Menu>
      <Menu.Item>
        <DeletePopUp
          action={onDeleteSelected}
          title="Confirm delete"
          message="Are you sure you want to delete these records?"
        >
          Delete
        </DeletePopUp>
      </Menu.Item>
    </Menu>
  );

  const handleAddRecordClick = () => {
    props.history.push("/users/producer/create");
  };

  const areRowsSelected = selectedUserKeys.length > 0;

  const rowSelection = {
    onChange: (selectedUserKeys, selectedRows) => {
      setSelectedUserKeys(selectedUserKeys);
    },
    selectedUserKeys: selectedUserKeys,
  };

  const onTableChange = (page, filter) => {
    setFetchMoreLoading(true);
    localClient.writeQuery({
      query: Queries.GET_PAGE_NUMBER,
      data: {
        pageNumber: page.current,
      },
    });
    fetchMoreProducers({
      variables: {
        page: page.current,
        query: searchString,
        per: PAGINATION_COUNT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    }).finally(setFetchMoreLoading(false));
  };
  return (
    <div style={{ background: "#fff" }}>
      <Spin spinning={deleteLoading}>
        <TableHeader>
          <Input
            allowClear
            prefix={<SearchOutlined />}
            placeholder={"Search producer"}
            style={{ width: 256, height: 40 }}
            onChange={(e) => {
              if (e.target.value.trim() === "" && searchString.length === 0)
                return;
              debounceOnChange(e.target.value);
            }}
          />
          {getSession()?.userType === UserRole.ADMIN && (
            <div className="Actions">
              <Button
                shape={"round"}
                size={"medium"}
                type={"primary"}
                icon={<PlusOutlined />}
                style={{ marginRight: 12 }}
                onClick={handleAddRecordClick}
                disabled={getSession()?.userType !== UserRole.ADMIN}
              >
                Add Producer
              </Button>
              <ExportCsv />
              <Dropdown
                trigger="click"
                overlay={actionsMenu}
                disabled={!areRowsSelected}
              >
                <Button style={{ width: 135, height: 32 }}>
                  Action <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}
        </TableHeader>

        <PaginatedTableview
          withPopover={true}
          onTableChange={onTableChange}
          customColumns={customColumns}
          selectedRows={selectedUserKeys}
          onResetColumns={initialVisibleFields}
          customFieldsTypes={customFieldsTypes}
          defaultVisibleFields={initialVisibleFields}
          data={producersData?.producers?.nodes || []}
          current={producersData?.producers?.metadata?.currentPage || 1}
          loading={producersLoading || fetchMoreLoading}
          pageSize={
            producersData?.producers?.metadata?.limitValue ||
            PAGINATION_COUNTS.default
          }
          totalCount={producersData?.producers?.metadata?.totalCount}
          columns={[...initialColumns(props.history), ...otherColumns()]}
          onRowSelection={props.isShowRowSelection ? rowSelection : false}
        />
      </Spin>
    </div>
  );
}
