import React, { useState, useEffect } from "react";
import { Upload, Modal } from "antd";
import ImgCrop from "antd-img-crop";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { trimS3FilePathFromURL } from "./../../utils";
import {
    createImageUploadProps,
    deleteFile,
} from "../../helpers/ImageUploadHelpers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: ${(props) => (props.squareCrop ? "10rem" : "16rem")};
    height: ${(props) => (props.squareCrop ? "10rem" : "9rem")};
    background-color: #fafafa;
    border-radius: ${(props) => props.radius};
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    width: ${(props) => (props.squareCrop ? "9rem" : "15rem")};
    height: ${(props) => (props.squareCrop ? "9rem" : "8rem")};
    border-radius: ${(props) => props.radius};
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: ${(props) => (props.squareCrop ? "10rem" : "16rem")};
    height: ${(props) => (props.squareCrop ? "10rem" : "9rem")};
    border-radius: ${(props) => props.radius};
  }
  .ant-upload-list-picture-card-container {
    width: ${(props) => (props.squareCrop ? "10rem" : "16rem")};
    height: ${(props) => (props.squareCrop ? "10rem" : "9rem")};
  }
`;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const validateArray = (arr) => {
    if (Array.isArray(arr)) {
        return arr;
    } else if (arr) {
        return [arr];
    } else {
        return [];
    }
};

export default function UploadMultiple(props) {
    const [images, setImages] = useState(validateArray(props.images));
    const [image, setImage] = useState("");
    const [imagesURL, setImagesURL] = useState([]);
    const [preview, setPreview] = useState({
        previewImage: "",
        previewVisible: false,
        previewTitle: "",
    });

    const [uploadLoading, setUploadLoading] = useState(false);

    const imageUploadProps = createImageUploadProps(
        props.dir,
        setImage,
        uploadLoading,
        setUploadLoading
    );

    const uploadButton = (
        <div
            style={{
                width: props.squareCrop ? "9rem" : "16rem",
                height: props.squareCrop ? "9rem" : "9rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <PlusOutlined/>
            Upload
        </div>
    );

    const getFilenameWithExtension = (name) => {
        if (name) {
            const arr = name.split("-");
            return arr[arr.length - 1];
        }
        return "";
    };

    const getFileId = (name) => {
        if (name) {
            return name.replace(
                "https://localeyz-web-platform.s3.amazonaws.com/",
                ""
            );
        }
        return "";
    };

    useEffect(() => {
        if (props.images) {
            if (Array.isArray(props.images)) {
                setImages(
                    props.images.map((item) => ({
                        name: getFilenameWithExtension(item),
                        url: item,
                        uid: getFileId(item),
                    }))
                );
                setImagesURL(props.images);
            } else {
                let arr = [];
                arr.push(props.images);
                setImages([
                    {
                        name: getFilenameWithExtension(props.images),
                        url: props.images,
                        uid: getFileId(props.images),
                    },
                ]);
                setImagesURL(arr);
            }
        }
    }, [props.images]);

    useEffect(() => {
        if (image) {
            props.setImages([
                ...imagesURL,
                image
            ]);
            setImagesURL([
                ...imagesURL,
                image
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    useEffect(() => {
        props.onLoadingChanges && props.onLoadingChanges(uploadLoading);
        // eslint-disable-next-line
    }, [uploadLoading]);

    const handlePreview = async (file) => {
        if (!file.url && !preview.previewVisible) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreview({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        });
    };

    const handleCancelPreview = () => {
        if (preview.previewVisible) {
            setPreview({
                ...preview,
                previewVisible: false,
            });
        }
    };

    const handleOnRemove = (file) => {
        if (file) {
            const filteredImages = imagesURL.filter(
                (item) => !item.includes(file.name)
            );
            deleteFile(trimS3FilePathFromURL(file.url));
            props.setImages(filteredImages);
            setImagesURL(filteredImages);
        }
    };

    const handleChangeThumbnail = ({fileList}) => {
        setImages(fileList);
    };

    const isAvatar = props.type === "avatar";

    return (
        <Container squareCrop={props.squareCrop} radius={isAvatar ? "50%" : "0"}>
            <ImgCrop
                aspect={props.squareCrop ? 1 / 1 : 16 / 9}
                shape={isAvatar ? "round" : null}
            >
                <Upload
                    accept={"image/*"}
                    {...imageUploadProps}
                    listType="picture-card"
                    fileList={images}
                    disabled={uploadLoading}
                    onPreview={handlePreview}
                    onRemove={handleOnRemove}
                    onChange={handleChangeThumbnail}
                >
                    {images.length > props.allowImages - 1 ? null : uploadButton}
                </Upload>
            </ImgCrop>
            <Modal
                visible={preview.previewVisible}
                footer={null}
                onCancel={handleCancelPreview}
            >
                <img
                    alt="thumbnail"
                    style={{width: "100%", borderRadius: isAvatar ? "50%" : "0"}}
                    src={preview.previewImage}
                />
            </Modal>
        </Container>
    );
}
