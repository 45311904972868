import { Button, Modal, Select } from "antd";
import React, { useState } from "react";
import AlertConfirm from "../../../components/AlertConfirm";

// const { Option } = Select;

const AddTags = ({addTagsVisible, setAddTagsVisible, handleAddTags}) => {
  const [newTags, setNewTags] = useState([]);

  return (
    <Modal
      title={
        "Add tags"
      }
      visible={addTagsVisible}
      onCancel={() => setAddTagsVisible(false)}
      footer={[
        <Button
          key="delete"
          onClick={() => setAddTagsVisible(false)}
          style={{width: "75px"}}
        >
          Cancel
        </Button>,
        <AlertConfirm
          actionBtnText={'Add tags'}
          btnType={"primary"}
          modalProps={{
            okText: 'Confirm',
            alertMsg: 'Are you sure you want to add tags to all selected episodes?',
            onOk: () => {
              handleAddTags(newTags);
              setAddTagsVisible(false);
            },
            alertTitle: 'Confirm add tags'
          }}
        />,
      ]}
    >
      <p>You can add new tags by typing the tag and pressing enter. For example, if you want to add “Fall Highschool Sports” as tag, type it out (without the quotes) and then hit enter. You can apply and add as many as you like.</p>
      <Select
        allowClear
        filterOption={true}
        placeholder={"Select tags"}
        style={{
          width: '100%',
          height: 32,
          marginBottom: 12,
          marginRight: 12,
        }}
        value={newTags}
        onClear={() => {
          setNewTags([]);
        }}
        onChange={(values, option) => {
          setNewTags(values);
        }}
        tokenSeparators={[","]} 
        mode="tags"
      >
      </Select>
    </Modal>
  )
}

export default AddTags;