import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  Input,
  Switch,
  message,
  Spin,
  Radio,
  Form,
} from "antd";
import styled from "styled-components";
import { Mutations, Queries } from "../../../api";
import { useMutation, useQuery } from "@apollo/client";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import UploadMultiple from "../../../components/UploadMultiple";
import { LIVEVIDEO_FORM_FIELDS, PROTOCOLS } from "../../../utils/contants";
import { getChannelIdFromUrl } from "../../../utils";
import HelpText from "../../../components/HelpText";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const { TextArea } = Input;

const { Option } = Select;

const StyledForm = styled.div`
  .save {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .switch {
    margin-top: 1.5rem;
    margin-right: 0.5rem;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const HorizontalContainer = styled.div`
  display: flex;
`;

const style = { fontWeight: 600, margin: "18px 0px" };
const style2 = { fontWeight: 600, width: "100%" };
const protocolStyle = { fontWeight: 600, width: 100 };
const urlStyle = { fontWeight: 600, width: "100%" };
const rules = [{ required: true, message: "This field is required." }];

export default function LiveVideoForm(props) {
  const [image, setImage] = useState("");
  const [temp, setTemp] = useState(false);
  const [isCableCast, setIsCableCast] = useState(false);
  const [form] = Form.useForm();
  const [
    createLiveVideo,
    { loading: createLoading, error: createError, data: createData },
  ] = useMutation(Mutations.CREATE_LIVE_VIDEO, {
    // @todo move to resolvers ?
    update(cache, { data: { createLiveVideo } }) {
      const { liveVideos } = cache.readQuery({
        query: Queries.ALL_LIVE_VIDEOS,
      });
      cache.writeQuery({
        query: Queries.ALL_LIVE_VIDEOS,
        data: {
          liveVideos: [createLiveVideo, ...liveVideos],
        },
      });
    },
  });
  const [
    updateLiveVideo,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(Mutations.UPDATE_LIVE_VIDEO, {
    // @todo move to resolvers ?
    update(cache, { data: { updateLiveVideo } }) {
      let { liveVideos } = cache.readQuery({ query: Queries.ALL_LIVE_VIDEOS });
      liveVideos.forEach((liveVideo, i) => {
        if (liveVideo.id === updateLiveVideo.id) {
          liveVideos[i] = updateLiveVideo;
        }
      });
      cache.writeQuery({
        query: Queries.ALL_LIVE_VIDEOS,
        data: { liveVideos: liveVideos },
      });
    },
  });

  const id = props.match.params.id;
  const {
    loading: liveVideoLoading,
    error: liveVideoError,
    data: liveVideoData,
  } = useQuery(Queries.LIVE_VIDEO_BY_ID, {
    skip: id === "new",
    variables: { id: id },
  });

  useEffect(() => {
    // Handle load video

    if (liveVideoError) message.error(liveVideoError.message);

    // Handle create
    if (createData) {
      props.history.push("/live-video");
      message.success(createData.createLiveVideo.name + " created");
    }
    if (createError) message.error(createError.message);
    // Handle update
    if (updateData) {
      message.success(updateData.updateLiveVideo.name + " updated");
      props.history.push("/live-video");
    }
    if (updateError) message.error(updateError.message);
  }, [
    id,
    createError,
    createData,
    liveVideoError,
    liveVideoData,
    updateError,
    updateData,
    props.history,
  ]);

  useEffect(() => {
    if (liveVideoData) {
      initForm(liveVideoData);
    }
    if (id === "new") initForm();
    // eslint-disable-next-line
  }, [liveVideoData]);

  useEffect(() => {
    const formFields = form.getFieldsValue();
    if (formFields.provider === "cablecast" || formFields.provider === "telvue")
      form.setFieldsValue({ playerType: "web" });
    if (formFields.provider === "cablecast" && formFields.streamUrl !== "") {
      let url = new URL(formFields.streamProtocol + formFields.streamUrl);
      let channelID = getChannelIdFromUrl("channel_id", url);
      if (channelID) {
        form.setFieldsValue({
          channelId: channelID,
        });
      }
    } else {
      form.setFieldsValue({
        channelId: undefined,
      });
    }
    // eslint-disable-next-line
  }, [isCableCast, temp]);

  const initForm = (data = {}) => {
    setImage(data?.liveVideoById?.image || "");
    const { name, description = "", published = true } =
      data?.liveVideoById || "";
    const streamPro = data?.liveVideoById?.streamUrl?.includes(PROTOCOLS.http)
      ? PROTOCOLS.http
      : PROTOCOLS.https || PROTOCOLS.https;
    const streamUrl =
      data?.liveVideoById?.streamUrl
        .replace(PROTOCOLS.https, "")
        .replace(PROTOCOLS.http, "") || "";
    const rssPro = data?.liveVideoById?.scheduleRss?.includes(PROTOCOLS.http)
      ? PROTOCOLS.http
      : PROTOCOLS.https || PROTOCOLS.https;
    const scheduleRss =
      data?.liveVideoById?.scheduleRss
        .replace(PROTOCOLS.https, "")
        .replace(PROTOCOLS.http, "") || "";
    const stations = data?.liveVideoById?.stations.split(",") || [];
    const provider = data?.liveVideoById?.provider || "Other";
    const playerType = data?.liveVideoById?.playerType || "native";
    const channelId = data?.liveVideoById?.channelId || null;
    provider === "cablecast" && setIsCableCast(true);
    form.setFieldsValue({
      name: name,
      stations: stations,
      playerType: playerType,
      provider: provider,
      description: description,
      streamUrl: streamUrl,
      streamProtocol: streamPro,
      scheduleRss: scheduleRss,
      rssProtocol: rssPro,
      channelId: channelId,
      published: published,
    });
  };

  const handleSubmit = async () => {
    const formFields = form.getFieldsValue();
    const variables = {
      name: formFields.name,
      streamUrl: formFields.streamUrl
        ? formFields.streamProtocol + formFields.streamUrl
        : "",
      description: formFields.description,
      scheduleRss: formFields.scheduleRss
        ? formFields.rssProtocol + formFields.scheduleRss
        : "",
      image: image,
      stations: formFields.stations.toString(),
      playerType: formFields.playerType,
      provider: formFields.provider ? formFields.provider : null,
      channelId: formFields.channelId ? formFields.channelId : null,
      published: formFields.published ? formFields.published : false,
    };
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    if (id !== "new") {
      updateLiveVideo({ variables: { id: id, ...variables } }).catch(() => {});
    } else {
      createLiveVideo({ variables }).catch(() => {});
    }
  };

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Spin
        spinning={id && (liveVideoLoading || createLoading || updateLoading)}
      >
        <StyledForm>
          <Form.Provider onFormFinish={() => handleSubmit()} scrollToFirstError>
            <Form form={form} layout={"vertical"}>
              <Form.Item
                label={<HelpText title="Name" />}
                name={LIVEVIDEO_FORM_FIELDS.name.key}
                style={style}
                rules={rules}
              >
                <Input
                  type={"text"}
                  placeholder={LIVEVIDEO_FORM_FIELDS.name.placeHolder}
                  size={"large"}
                />
              </Form.Item>
              <Form.Item
                label={<HelpText title="Stations" />}
                name={LIVEVIDEO_FORM_FIELDS.stations.key}
                style={style}
                rules={rules}
              >
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder={LIVEVIDEO_FORM_FIELDS.stations.placeHolder}
                  size={"large"}
                />
              </Form.Item>
              <Form.Item
                label={<HelpText title={"Live video provider"} />}
                name={LIVEVIDEO_FORM_FIELDS.provider.key}
                style={style}
                rules={rules}
              >
                <Select
                  style={{ width: 200 }}
                  size={"large"}
                  onChange={(e) => {
                    e === "cablecast"
                      ? setIsCableCast(true)
                      : setIsCableCast(false);
                    setTemp(!temp);
                  }}
                >
                  <Option value="cablecast">Cablecast</Option>
                  <Option value="telvue">Telvue</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              <HelpText title={"Stream URL"} />
              <HorizontalContainer>
                <Form.Item
                  name={LIVEVIDEO_FORM_FIELDS.streamProtocol.key}
                  style={protocolStyle}
                >
                  <Select className="select-before" size={"large"}>
                    <Option value={PROTOCOLS.https}>{PROTOCOLS.https}</Option>
                    <Option value={PROTOCOLS.http}>{PROTOCOLS.http}</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={LIVEVIDEO_FORM_FIELDS.streamUrl.key}
                  style={urlStyle}
                >
                  <Input
                    onChange={(e) => {
                      setTemp(!temp);
                    }}
                    type={"text"}
                    placeholder={LIVEVIDEO_FORM_FIELDS.streamUrl.placeHolder}
                    size={"large"}
                  />
                </Form.Item>
              </HorizontalContainer>
              <Form.Item
                name={LIVEVIDEO_FORM_FIELDS.playerType.key}
                label={<HelpText title={"Stream type"} />}
                style={style2}
                rules={rules}
              >
                <Radio.Group>
                  <Radio size={"large"} value={"native"}>
                    Video stream source
                  </Radio>
                  <Radio size={"large"} value={"web"}>
                    Video web embed
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {!isCableCast && (
                <>
                  <HelpText title={"Schedule RSS"} />
                  <HorizontalContainer>
                    <Form.Item
                      name={LIVEVIDEO_FORM_FIELDS.rssProtocol.key}
                      style={protocolStyle}
                    >
                      <Select className="select-before" size={"large"}>
                        <Option value={PROTOCOLS.https}>
                          {PROTOCOLS.https}
                        </Option>
                        <Option value={PROTOCOLS.http}>{PROTOCOLS.http}</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={LIVEVIDEO_FORM_FIELDS.scheduleRss.key}
                      style={urlStyle}
                    >
                      <Input
                        type={"text"}
                        placeholder={
                          LIVEVIDEO_FORM_FIELDS.scheduleRss.placeHolder
                        }
                        size={"large"}
                      />
                    </Form.Item>
                  </HorizontalContainer>
                </>
              )}
              {isCableCast && (
                <Form.Item
                  label={<HelpText title="Channel ID" />}
                  name={LIVEVIDEO_FORM_FIELDS.channelId.key}
                  style={style}
                  rules={rules}
                >
                  <Input
                    type={"text"}
                    style={{ width: "250px" }}
                    placeholder={LIVEVIDEO_FORM_FIELDS.channelId.placeHolder}
                    size={"large"}
                    disabled={true}
                  />
                </Form.Item>
              )}
              <Form.Item label={<HelpText title="Image" />}>
                <UploadMultiple
                  dir="radio"
                  allowImages={1}
                  squareCrop={true}
                  images={image}
                  setImages={(img) => setImage(img?.toString() || "")}
                />
              </Form.Item>
              <Form.Item
                label={<HelpText title="Description" />}
                name={LIVEVIDEO_FORM_FIELDS.description.key}
                style={style2}
              >
                <TextArea
                  size={"large"}
                  placeholder={LIVEVIDEO_FORM_FIELDS.description.placeHolder}
                  rows={4}
                />
              </Form.Item>
              <SwitchWrapper>
                <Form.Item
                  name={LIVEVIDEO_FORM_FIELDS.published.key}
                  valuePropName="checked"
                >
                  <Switch className="switch" />
                </Form.Item>
                <HelpText title="Published" />
              </SwitchWrapper>
            </Form>
            <div className={"save"}>
              <Button
                shape="round"
                size={"large"}
                onClick={() => {
                  props.history.push("/live-video");
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                shape="round"
                icon={false ? <LoadingOutlined /> : <SaveOutlined />}
                size={"large"}
                onClick={form.submit}
              >
                {id !== "new" ? "Save live video" : "Create live video"}
              </Button>
            </div>
          </Form.Provider>
        </StyledForm>
      </Spin>
    </div>
  );
}
