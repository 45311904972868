import React from "react";
import { Form } from "antd";
import { ChromePicker } from "react-color";
import { Col, Row } from "react-styled-flexboxgrid";

const ColorPicker = (props) => {
    const {
        colors,
        setColors,
        isTextColor,
        isPrimaryColor,
        isSecondaryColor,
        isBackgroundColor,
    } = props;
    const getCol = () => {
        let col = 12;
        if (isTextColor) col = col - 3;
        if (isPrimaryColor) col = col - 3;
        if (isSecondaryColor) col = col - 3;
        if (isBackgroundColor) col = col - 3;
        return col === 0 ? 3 : col;
    };
    return (
        <Form layout={"vertical"}>
            <Row>
                {isPrimaryColor && (
                    <Col lg={getCol()} md={getCol() + 1} sm={getCol() + 2} xs={12}>
                        <Form.Item label="Primary color" style={{fontWeight: 600}}>
                            <ChromePicker
                                color={colors.primaryColor}
                                onChangeComplete={(e) =>
                                    setColors({...colors, primaryColor: e.hex})
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
                {isSecondaryColor && (
                    <Col lg={getCol()} md={getCol() + 1} sm={getCol() + 2} xs={12}>
                        <Form.Item label="Seconday color" style={{fontWeight: 600}}>
                            <ChromePicker
                                color={colors.secondaryColor}
                                onChangeComplete={(e) =>
                                    setColors({...colors, secondaryColor: e.hex})
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
                {isTextColor && (
                    <Col lg={getCol()} md={getCol() + 1} sm={getCol() + 2} xs={12}>
                        <Form.Item label="Text color" style={{fontWeight: 600}}>
                            <ChromePicker
                                color={colors.textColor}
                                onChangeComplete={(e) =>
                                    setColors({...colors, textColor: e.hex})
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
                {isBackgroundColor && (
                    <Col lg={getCol()} md={getCol() + 1} sm={getCol() + 2} xs={12}>
                        <Form.Item label="Background color" style={{fontWeight: 600}}>
                            <ChromePicker
                                color={colors.bgColor}
                                onChangeComplete={(e) =>
                                    setColors({...colors, bgColor: e.hex})
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </Form>
    );
};

export default ColorPicker;
