import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Queries, Mutations } from "../../api";
import HelpText from "../../components/HelpText";
import { useQuery, useMutation } from "@apollo/client";
import { Collapse, Select, Button, message, Spin, Tag, Tooltip } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CustomFieldsComponent from "../../components/CustomFieldsComponent";
import {
  DASH,
  getValidCustomFields,
  getValidCustomFieldsDirectus,
  getValidCustomFieldsRuby,
  isValidCustomFields
} from "../../utils";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { useEntities, useEntityTable } from "../../hooks/entity";
import { useDatabase } from "../../hooks/database";
import { getSession } from "../../helpers/SessionManagement";
import TableHeader from "../../components/Table/TableHeader";
import { defaultStatus } from "../../utils/contants";
import { v4 as uuidv4 } from "uuid";
import TableView from "./TableView";

const SaveWrapper = styled.div`
  padding: 1em 0;
  display: flex;
  justify-content: center;
`;

const { Panel } = Collapse;
const { Option } = Select;

const Programs = (props) => {
  const { database } = useDatabase();
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const session = getSession();

  const staticFilters = {
    organization_id: {
      _eq: session?.organization?.id,
    },
  };

  const { fetchData, setQuickSearch } = useEntities({
    baseEntity: "programs",
    staticFilters,
    fields: [
      "id",
      "title",
      "topics.topic_id",
      "full_description",
      "status",
      "sponsored",
      "producer_id.name",
      "custom_field_value.*",
      "created_at",
      "updated_at",
      "media",
      "image",
      "published",
      "organization_id",
      "program_type_id.text",
      "episodes",
      "episode_origin",
      "assistant_producers.user_id.name",
    ],
  });

  const { getColumnAttributes, loading: loadingDatabase } = useEntityTable({
    baseEntity: "programs",
    staticFilters,
  });

  const [savingSettings, setSavingSettings] = useState(false);
  const [topicsOptions, setTopicsOptions] = useState([]);
  const [typesOptions, settypesOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProgramTopics, setSelectedProgramTopics] = useState([]);
  const [selectedProgramTypes, setSelectedProgramTypes] = useState([]);
  const [progarmCustomFielsData, setProgramCustomFieldData] = useState([]);

  const {
    loading: settingsLoading,
    data: settingsData,
    error: settingsError,
  } = useQuery(Queries.PROGRAM_SETTINGS, {fetchPolicy: 'network-only'});

  useEffect(() => {
    if (settingsData && settingsData.programSetting) {
      const { programSetting } = settingsData;

      setTopicsOptions(
        programSetting.topics.map((topic) => {
          return <Option key={topic.id}>{topic.title}</Option>;
        })
      );

      setSelectedProgramTopics(programSetting.topics.map((topic) => topic.id));
      settypesOptions(
        programSetting.programTypes.map((programType) => {
          return <Option key={programType.id}>{programType.text}</Option>;
        })
      );

      setSelectedProgramTypes(
        programSetting.programTypes.map((programType) => programType.id)
      );

      setProgramCustomFieldData(
        getValidCustomFieldsRuby(programSetting.programCustomFields)
      );
    }
  }, [settingsData]);

  useEffect(() => {
    if (settingsError) {
      message.success(settingsError.message);
    }
  }, [settingsError]);

  const deleteCustomField = (id) => {
    setProgramCustomFieldData(
      progarmCustomFielsData.filter((item, index) => index !== id)
    );
  };

  const handleUpdateAndCreate = async () => {
    // Handle topics.
    setSavingSettings(true);
    const newTopics = (selectedProgramTopics || []).filter(isNaN);
    const topicVals = (selectedProgramTopics || []).filter((t) => !isNaN(t));

    if (newTopics?.[0]) {
      const newTopicIDs = await database.items("topics").createMany(
        newTopics.map((pt) => ({
          title: pt,
        }))
      );

      if (newTopicIDs?.data?.[0]) {
        newTopicIDs.data.forEach((pt) => {
          topicVals.push(pt?.id);
        });
      }
    }

    // Handle program types.
    const newProgramTypes = (selectedProgramTypes || []).filter(isNaN);
    const programTypeVals = (selectedProgramTypes || []).filter(
      (pt) => !isNaN(pt)
    );

    if (newProgramTypes?.[0]) {
      const newProgramTypeIDs = await database
        .items("program_types")
        .createMany(
          newProgramTypes.map((pt) => ({
            text: pt,
          }))
        );

      if (newProgramTypeIDs?.data?.[0]) {
        newProgramTypeIDs.data.forEach((pt) => {
          programTypeVals.push(pt?.id);
        });
      }
    }

    if (isValidCustomFields(progarmCustomFielsData)) {
      const customFields = getValidCustomFieldsDirectus(progarmCustomFielsData);

      if (settingsData?.programSetting?.id) {
        await database
          .items("program_settings")
          .updateOne(settingsData?.programSetting?.id, {
            types: (programTypeVals || []).map((pt) => ({
              program_type_id: pt,
            })),
            topics: (topicVals || []).map((t) => ({
              topic_id: t,
            })),
            program_custom_fields: (customFields || []).map((cf) => ({
              ...cf,
              id: cf?.id || uuidv4(),
            })),
          });

        message.success("Settings for program updated successfully");
      } else {
        await database.items("program_settings").createOne({
          types: (programTypeVals || []).map((pt) => ({
            program_type_id: pt,
          })),
          topics: (topicVals || []).map((t) => ({
            topic_id: t,
          })),
          ...(customFields?.[0]
            ? {
                program_custom_fields: customFields.map((cf) => ({
                  ...cf,
                  id: cf?.id || uuidv4(),
                })),
              }
            : {}),
        });

        message.success("Settings for program created successfully");
      }
    } else {
      message.error("Label field requierd!");
    }

    setSavingSettings(false);
  };

  const getStatusItemColor = (status) =>
    status === defaultStatus.accept
      ? "green"
      : status === defaultStatus.declined
      ? "red"
      : status === defaultStatus.draft
      ? "#e9e9e9"
      : "orange";

  useEffect(() => {
    async function initCols() {
      const fields = await database.fields.readMany("programs");
      const orgInfo = await database
        .items("organizations")
        .readOne(session?.organization?.id, {
          fields: ["program_settings.types.program_type_id.text"],
        });

      const statusFieldOptions =
        fields.find((f) => f?.field === "status")?.meta?.options?.choices || [];

      const programSettings = await database
        .items("program_settings")
        .readMany({
          fields: "*",
          filter: {
            organization_id: session?.organization?.id,
          },
        });

      let customFieldCols = [];
      if (programSettings?.data?.[0]?.program_custom_fields?.[0]) {
        customFieldCols = programSettings.data[0].program_custom_fields.map(
          (field) => ({
            field: field?.id,
            colId: field?.id,
            headerName: field?.label,
            flex: 1,
            filter: false,
            sortable: false,
            initialHide: true,
            valueGetter: (params) => {
              if (params?.data?.custom_field_value?.[0]) {
                const val = params.data.custom_field_value.find(
                  (f) => f?.id === field?.id
                );

                return val?.value || " - ";
              }

              return " - ";
            },
          })
        );
      }

      const columns = [
        {
          field: "id",
          colId: "id",
          headerName: "ID",
          sort: "desc",
          checkboxSelection: (params) => !params?.node?.footer,
          flex: 1,
          ...(await getColumnAttributes({
            field: "id",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/programs/edit/${params?.data?.id}`}>
                {params?.value}
              </Link>
            );
          },
        },
        {
          field: "title",
          colId: "title",
          headerName: "Program",
          flex: 1,
          ...(await getColumnAttributes({
            field: "title",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/programs/edit/${params?.data?.id}`}>
                {params?.value}
              </Link>
            );
          },
        },
        {
          field: "episodes",
          colId: "episodes",
          headerName: "Episodes",
          flex: 1,
          filter: false,
          sortable: false,
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return params?.node?.data?.episodes?.length || 0;
          },
        },
        {
          field: "producer_id.name",
          colId: "producer_id.name",
          headerName: "Producer",
          flex: 1,
          ...(await getColumnAttributes({
            field: "name",
            entity: "users",
          })),
          sortable: false,
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return params?.value || " - ";
          },
        },
        {
          field: "updated_at",
          colId: "updated_at",
          headerName: "Updated date",
          flex: 1,
          ...(await getColumnAttributes({
            field: "updated_at",
          })),
        },
        {
          field: "status",
          colId: "status",
          headerName: "Status",
          flex: 1,
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params) => {
              const val = parseInt(params?.value);
              switch (val) {
                case 0:
                  return "DRAFT";

                case 1:
                  return "IN_REVIEW";

                case 2:
                  return "ACCEPTED";

                case 3:
                  return "DECLINED";

                default:
                  return " - ";
              }
            },
            values: [0, 1, 2, 3],
          },
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            const status =
              statusFieldOptions.find((s) => s?.value === params?.value)
                ?.text || " - ";

            return (
              <span>
                <Tag
                  color={getStatusItemColor(status)}
                  style={{
                    maxWidth: "150px",
                    overflowX: "scroll",
                    display: "inline-flex",
                  }}
                >
                  {status}
                </Tag>
              </span>
            );
          },
        },
        {
          field: "published",
          colId: "published",
          headerName: "Published",
          flex: 1,
          ...(await getColumnAttributes({
            field: "published",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span>
                {!params?.value && (
                  <Tag color="red" size="small">
                    Not published
                  </Tag>
                )}

                {params?.value && <Tag color="green">Published</Tag>}
              </span>
            );
          },
        },
        {
          field: "program_type_id.text",
          colId: "program_type_id.text",
          headerName: "Type",
          flex: 1,
          filterParams: {
            values: async (params) => {
              let items = [];
              if (orgInfo?.program_settings?.[0]?.types) {
                items = orgInfo.program_settings[0].types.map(
                  (t) => t?.program_type_id?.text
                );
              }

              params.success(items);
            },
          },
          valueFormatter: (params) => {
            return params?.value || " - ";
          },
        },
        {
          field: "created_at",
          colId: "created_at",
          headerName: "Created At",
          flex: 1,
          minWidth: 100,
          ...(await getColumnAttributes({
            field: "created_at",
          })),
        },
        {
          field: "thumbnail",
          colId: "thumbnail",
          headerName: "Thumbnail",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          cellRendererFramework: (params) => {
            if (params?.data?.image) {
              return (
                <Tooltip
                  title={
                    <img style={{ width: "100%" }} src={params.data.image} />
                  }
                >
                  <a>View Image</a>
                </Tooltip>
              );
            }

            return " - ";
          },
        },
        {
          field: "assistant_producers",
          colId: "assistant_producers",
          headerName: "Assistant Producers",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          cellRendererFramework: (params) => {
            if (params?.data?.assistant_producers?.[0]) {
              return params.data.assistant_producers.map((ap) => (
                <Tag
                  key={ap?.user_id?.name}
                  color={"green"}
                  style={{
                    maxWidth: "150px",
                    overflowX: "scroll",
                    display: "inline-flex",
                  }}
                >
                  {ap?.user_id?.name}
                </Tag>
              ));
            }

            return " - ";
          },
        },
        ...customFieldCols,
      ];

      setColumns(columns);
    }

    if (!loadingDatabase) {
      initCols();
    }
    // eslint-disable-next-line
  }, [database, getColumnAttributes, loadingDatabase]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <div style={{ padding: 24, background: "#fff" }}>
        <Spin spinning={savingSettings}>
          <Collapse onChange={() => {}} style={{ width: "100%" }}>
            <Panel header="Settings" key="2" style={{ width: "100%" }}>
              <HelpText title={"Program topics"} />
              <Select
                mode="tags"
                style={{ width: "100%" }}
                size={"large"}
                placeholder="Program topics"
                value={selectedProgramTopics}
                onChange={setSelectedProgramTopics}
              >
                {topicsOptions}
              </Select>
              <HelpText title={"Program types"} />
              <Select
                mode="tags"
                style={{ width: "100%" }}
                size={"large"}
                placeholder="Program types"
                value={selectedProgramTypes}
                onChange={setSelectedProgramTypes}
              >
                {typesOptions}
              </Select>

              <HelpText title={"Program custom fields"} />
              <CustomFieldsComponent
                data={progarmCustomFielsData}
                setData={setProgramCustomFieldData}
                deleteCustomField={deleteCustomField}
              />
              <SaveWrapper>
                {settingsLoading ? (
                  <Spin size="large" />
                ) : (
                  <Button
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      handleUpdateAndCreate();
                    }}
                  >
                    {settingsData?.programSetting
                      ? "Update program settings"
                      : "Create program stings"}
                  </Button>
                )}
              </SaveWrapper>
            </Panel>
          </Collapse>
        </Spin>
      </div>
      <div
        style={{
          padding: 24,
          background: "#fff",
          marginTop: 20,
          flex: 1,
        }}
      >
        <TableHeader
          collection="programs"
          label="program"
          link="/programs/new"
          history={props.history}
          tableRef={tableRef}
          selectedRows={selectedRows}
          setQuickSearch={setQuickSearch}
        />

        <Table
          hideGroupBy
          tableRef={tableRef}
          fetchData={fetchData}
          wrapperHeight={false}
          columns={columns}
          gridOptions={{
            onSelectionChanged: (event) => {
              setSelectedRows(event.api.getSelectedNodes());
            },
            groupIncludeTotalFooter: false,
            domLayout: "autoHeight",
            paginationAutoPageSize: false,
          }}
        />
      </div>
    </div>
  );
};

export default Programs;
