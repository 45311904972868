import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import HelpText from "../../../../components/HelpText";
import Password from "antd/lib/input/Password";
import { RestOutlined } from "@ant-design/icons";
import PasswordAlert from "../../../../components/PasswordAlert";
import {
    hasDigit,
    hasLowerCase,
    hasUpperCase,
    hasSpecialCharacter,
} from "../../../../utils";

export default function ResetUserPassword(props) {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleResetPassword = async () => {
        if (
            newPassword.length > 7 &&
            hasDigit(newPassword) &&
            hasLowerCase(newPassword) &&
            hasUpperCase(newPassword) &&
            hasSpecialCharacter(newPassword)
        ) {
            if (!currentPassword) {
                message.error("Current password is required.", 5);
            } else if (newPassword !== confirmPassword) {
                message.error("Confirm password should be same as new password.", 5);
            } else {
                props.handleChangePassword(
                    currentPassword,
                    newPassword,
                    confirmPassword
                );
            }
        } else {
            message.error("Your password does not satisfy the requirements.");
        }
    };
    return (
        <div>
            <Modal
                visible={props.passwordModelVisible}
                title="Change password"
                onCancel={() => {
                    props.setPasswordModelVisible(false);
                }}
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            props.setPasswordModelVisible(false);
                        }}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        icon={<RestOutlined/>}
                        onClick={() => {
                            handleResetPassword();
                        }}
                    >
                        Reset
                    </Button>,
                ]}
            >
                <HelpText title={"Current password"} required={true}/>
                <Password
                    value={currentPassword}
                    size={"large"}
                    required
                    type={Password}
                    placeholder={"Current password"}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />

                <HelpText title={"New password"} required={true}/>
                <PasswordAlert isShow={true} password={newPassword}/>
                <Password
                    style={{marginTop: "1rem"}}
                    value={newPassword}
                    size={"large"}
                    required
                    placeholder={"New password"}
                    type={Password}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <Password
                    value={confirmPassword}
                    style={{marginTop: "1rem"}}
                    size={"large"}
                    required
                    type={Password}
                    placeholder={"Confirm  password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </Modal>
        </div>
    );
}
