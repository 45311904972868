import React from "react";
import dayjs from "dayjs";
import { Queries } from "../api";

const isValidEmail = (mail) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(mail);
};

const isValidPassword = (expression) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  return regex.test(expression);
};

const trimErrorMessage = (str) => {
  if (str) {
    str = str.replace("GraphQL error: ", "");
  }
  return str;
};

const trimS3FilenameFromURL = (url) => {
  if (url) {
    const arr = url.split("/");
    return arr[arr.length - 1];
  }
  return [];
};

const trimS3FilePathFromURL = (url) => {
  if (url) {
    return url.replace("https://localeyz-web-platform.s3.amazonaws.com/", "");
  }
  return "";
};

const getFilenameWithExtension = (name) => {
  if (name) {
    const arr = name.split("-");
    return arr[arr.length - 1];
  }
  return "";
};

const getFileId = (name) => {
  if (name) {
    const arr = name.split("-");
    return `${arr[0]}-${arr[1]}-${arr[2]}-${arr[3]}`;
  }
  return "";
};

const getValidCustomFields = (fields) =>
  fields?.map((item) => ({
    id: item.id,
    type: item.type,
    label: item.label,
    staff: item.staff,
    required: item.required,
    options: item.options,
    allowMultiple: item.allow_multiple,
    charLimit: item.char_limit || 250,
    time: item.time,
  }));

const getValidCustomFieldsDirectus = (fields) =>
  fields?.map((item) => ({
    id: item.id,
    type: item.type,
    label: item.label,
    staff: item.staff,
    required: item.required,
    options: item.options,
    allow_multiple: item.allowMultiple,
    char_limit: item.charLimit || 250,
    time: item.time,
  }));


const getValidCustomFieldsRuby = (fields) =>
  fields.map((item) => ({
    id: item.id,
    type: item.type,
    label: item.label,
    staff: item.staff,
    required: item.required,
    options: item.options,
    allowMultiple: item.allowMultiple,
    charLimit: item.charLimit || 250,
    time: item.time,
  }));


const isValidCustomFields = (fields) => {
  let isVaild = true;
  const f = fields || [];
  f.forEach((item) => {
    if (item.label.trim() === "") {
      isVaild = false;
    }
  });
  return isVaild;
};

const isEmptyOrNull = (data) => !data?.length;

const initialSocialLink = [
  {
    id: 0,
    url: "",
    mediaType: "FACEBOOK",
  },
];

const initialExternalLink = [
  {
    id: 0,
    url: "",
    title: "",
  },
];

const hasLowerCase = (str) => {
  return /[a-z]/.test(str);
};

const hasUpperCase = (str) => {
  return /[A-Z]/.test(str);
};

const hasDigit = (str) => {
  return /[0-9]/.test(str);
};

const hasSpecialCharacter = (str) => {
  // eslint-disable-next-line
  return /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(str);
};

const checkForDeleteProducers = (list, data) => {
  let missingObjs = [];
  if (list?.length > 0 && data?.length > 0) {
    data.forEach((item) => {
      let m = list.some((ele) => ele?.id === item?.id);
      if (!m) {
        missingObjs.push(item);
      }
    });
  }
  return missingObjs.filter((ob) => !!ob);
};

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};
const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string.slice(1);
};

const DASH = (
  <span style={{ justifyContent: "center", display: "flex" }}>&#8212;</span>
);
const getFormatedDate = (date) =>
  dayjs(date).isValid()
    ? dayjs(date, "YYYY-MM-DDThh:mm:ssZ").format("MM-DD-YYYY")
    : DASH;

const getFormatedDateAndTime = (date) =>
  dayjs(date).isValid()
    ? dayjs(date, "YYYY-MM-DDThh:mm:ssZ").format("MM-DD-YYYY H:mm")
    : DASH;

const undefOrNull = (variable) => variable === null || variable === undefined;

const emptyIfNull = (str) => str || "";

function formatNumber(value) {
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
}

const fixAutocomplete = () => {
  const selects = document.querySelectorAll(".ant-select-selector input");
  selects.forEach((e) => {
    e.setAttribute("autocomplete", "stopDamnAutocomplete");
  });
};

const widgetsUpdateCacheData = (type, cache, newData) => {
  if (type === "add") {
    const { allWidgets } = cache.readQuery({
      query: Queries.ALL_WIDGETS,
    });
    cache.writeQuery({
      query: Queries.ALL_WIDGETS,
      data: {
        allWidgets: [newData, ...allWidgets],
      },
    });
    return;
  } else if (type === "update") {
    const { allWidgets } = cache.readQuery({
      query: Queries.ALL_WIDGETS,
    });

    cache.writeQuery({
      query: Queries.ALL_WIDGETS,
      data: {
        allWidgets: allWidgets.map((widget) => {
          if (
            widget.sourceId === newData.sourceId &&
            widget.widgetType === newData.widgetType
          )
            return {
              id: widget.id,
              name: newData.name,
              sourceId: newData.sourceId,
              updatedAt: widget.updatedAt,
              createdAt: widget.createdAt,
              widgetType: newData.widgetType,
              __typename: "GeneralWidget",
            };
          else return widget;
        }),
      },
    });
    return;
  }
};

function getChannelIdFromUrl(name, url) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]"); // eslint-disable-line no-useless-escape
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(url.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// sort utils
const orderMap = {
  descend: "desc",
  ascend: "asc",
  default: "desc",
};

// sort utils
const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export {
  DASH,
  getFormatedDate,
  getFormatedDateAndTime,
  debounce,
  hasDigit,
  hasLowerCase,
  hasUpperCase,
  hasSpecialCharacter,
  getFileId,
  isValidEmail,
  isValidPassword,
  trimErrorMessage,
  capitalizeFirstLetter,
  trimS3FilenameFromURL,
  trimS3FilePathFromURL,
  getValidCustomFields,
  isValidCustomFields,
  getFilenameWithExtension,
  isEmptyOrNull,
  initialSocialLink,
  initialExternalLink,
  checkForDeleteProducers,
  undefOrNull,
  emptyIfNull,
  formatNumber,
  fixAutocomplete,
  widgetsUpdateCacheData,
  getChannelIdFromUrl,
  orderMap,
  camelToSnakeCase,
  getValidCustomFieldsRuby,
  getValidCustomFieldsDirectus
};
