import { Button, Modal, Select } from "antd";
import React, { useState } from "react";
import AlertConfirm from "../../../components/AlertConfirm";

const { Option } = Select;

const AddTopics = ({
  addTopicsVisible,
  setAddTopicsVisible,
  episodeSettings,
  handleAddTopics,
  topics,
  collection = "episodes",
}) => {
  const [newTopics, setNewTopics] = useState([]);

  const topicOptions = topics || episodeSettings?.episodeSetting?.topics || [];
  return (
    <Modal
      title={"Add topics"}
      visible={addTopicsVisible}
      onCancel={() => setAddTopicsVisible(false)}
      footer={[
        <Button
          key="delete"
          onClick={() => setAddTopicsVisible(false)}
          style={{ width: "75px" }}
        >
          Cancel
        </Button>,
        <AlertConfirm
          actionBtnText={"Add topics"}
          btnType={"primary"}
          modalProps={{
            okText: "Confirm",
            alertMsg: `Are you sure you want to add topics to all selected ${collection}?`,
            onOk: () => {
              handleAddTopics(newTopics);
              setAddTopicsVisible(false);
            },
            alertTitle: "Confirm add topics",
          }}
        />,
      ]}
    >
      <p>Choose the topics you want to apply to the selected {collection}.</p>
      <Select
        allowClear
        filterOption={(txt, topic) => topic.children.includes(txt)}
        placeholder={"Select topics"}
        style={{
          width: "100%",
          height: 32,
          marginBottom: 12,
          marginRight: 12,
        }}
        value={newTopics}
        onClear={() => {
          setNewTopics([]);
        }}
        onChange={(values, option) => {
          setNewTopics(values);
        }}
        mode="multiple"
      >
        {topicOptions.map((item) => (
          <Option key={item.id}>{item.title}</Option>
        ))}
      </Select>
    </Modal>
  );
};

export default AddTopics;
