import React from "react";
import { InputNumber } from "antd";

export default function TimeComponent(props) {

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <span style={{fontSize: "12px"}}>Hours</span>
                <InputNumber
                    min={0}
                    max={24}
                    type={"number"}
                    value={props.duration?.hours}
                    placeholder="00"
                    style={{width: "71px"}}
                    onChange={(hours) =>
                        props.setDuration({
                            ...props.duration,
                            hours,
                        })
                    }
                />
            </div>
            <div
                style={{display: "flex", flexDirection: "column", marginLeft: "10px"}}
            >
                <span style={{fontSize: "12px"}}>Minutes</span>
                <InputNumber
                    min={0}
                    max={60}
                    type={"number"}
                    value={props.duration?.minutes}
                    placeholder="00"
                    style={{width: "71px"}}
                    onChange={(minutes) =>
                        props.setDuration({
                            ...props.duration,
                            minutes,
                        })
                    }
                />
            </div>
            <div
                style={{display: "flex", flexDirection: "column", marginLeft: "10px"}}
            >
                <span style={{fontSize: "12px"}}>seconds</span>
                <InputNumber
                    min={0}
                    max={60}
                    type={"number"}
                    value={props.duration?.seconds}
                    placeholder="00"
                    style={{width: "71px"}}
                    onChange={(seconds) =>
                        props.setDuration({
                            ...props.duration,
                            seconds,
                        })
                    }
                />
            </div>
        </div>
    );
}