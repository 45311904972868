import React, { useEffect, useRef } from "react";
import URLS from "../../constants";

const checkIframeTypeWidget = (type) => {
    const types = [
        "video-on-demand",
        "producer-portal",
        "footer",
        "playlist",
        "live-video",
        "podcasts",
        "radio",
        "schedule",
        "donation",
        "subscription",
        "search",
    ];
    return types.includes(type);
};

const Preview = (props) => {
    const pathArr = props.history.location.pathname.split("/");
    const type = pathArr[2];
    const id = props.match.params.id;

    const iframeRef = useRef(null);

    useEffect(() => {
        const ref = iframeRef.current;
        if (!ref) return;
        let interval = null;
        if (id && checkIframeTypeWidget(type)) {
            const html = `<localeyz-widget data-type="${type}" data-id="${id}" data-version="0"></localeyz-widget><script id="script" rel="preload" src="${process.env.REACT_APP_FRONT_END_BASE_URL}${type === "producer-portal" ? "producer-portal/v1/js/main.js" : "widgets/v1/js/main.js"}"></script>`;
            ref.contentWindow.document.write(html);
            interval = setInterval(() => {
                if (ref.contentWindow.init) {
                    ref.contentWindow.init();
                    clearInterval(interval);
                }
            }, 500);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }

            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        };
    }, [
        id,
        type,
        iframeRef
    ]);

    return (
        <div style={{padding: 24, background: "#fff"}}>
            <div>
                {checkIframeTypeWidget(type) && (
                    <iframe
                        ref={iframeRef}
                        title={"iframe-embed"}
                        style={{width: "100%", height: "1000px", border: "none"}}
                    />
                )}
            </div>
        </div>
    );
};

export default Preview;
