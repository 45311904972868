import React, { useState, useEffect } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    PieChart,
    Pie,
    Sector,
    Label,
    ResponsiveContainer,
    Bar,
    BarChart
} from "recharts";
import { Typography } from "antd";
import styled from "styled-components";

export const ChartContainer = styled.div`
  background-color: #f8f8f8;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  svg {
    overflow: visible;
  }
`;

const LineChartContainer = styled.div`
  background-color: #f8f8f8;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;

// generated from https://coolors.co/fff275-ff8c42-ff3c38-a23e48-6c8ead-69626d-e8c7de-691e06-504746-27187e
const colorArray = [
    "#6a0dad",
    "#ff8c42",
    "#ff3c38",
    "#a23e48",
    "#6c8ead",
    "#69626d",
    "#B85199",
    "#691e06",
    "#504746",
    "#27187e"
]

const {Title} = Typography;

export const TestLineChart = (props) => {
    let formattedLines = [];
    useEffect(() => {
        if (props.multiple) {
            let counter = 0;
            let widgetCounts = props.widgetTracking;
            // render a number of lines on the chart depending on if there is data to show
            // for each widget or not
            for (let widget in widgetCounts) {
                if (widgetCounts[widget] > 0) {
                    formattedLines[counter] = (
                        <Line
                            type="monotone"
                            dataKey={widget}
                            stroke={colorArray[counter]}
                        />
                    );
                }
                counter++;
            }
        }
    }, [
        props.widgetTracking,
        props.multiple,
        formattedLines
    ]);

    return (
        <LineChartContainer>
            <Title style={{textAlign: "left", margin: 0}} level={4}>
                {props.name}
            </Title>
            {props.data.length !== 0 ? (
                <ResponsiveContainer>
                    <LineChart
                        width={500}
                        height={300}
                        data={props.data}
                        margin={{
                            top: 15,
                            right: 40,
                            left: 20,
                            bottom: 15,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis
                            tickFormatter={(date) => {
                                if (props.hourly) {
                                    if (date > 12) {
                                        date -= 12;
                                    }
                                    if (date === 0) {
                                        date += 12;
                                    }
                                    return date + ":00";
                                } else {
                                    return date;
                                }
                            }}
                            dataKey={props.xAxis}
                            name={props.hourly ? props.xAxis + ":00" : props.xAxis}
                            interval={"preserveEnd"}
                        >
                            <Label
                                value={props.xAxis}
                                position={"insideBottom"}
                                offset={-10}
                            />
                        </XAxis>
                        <YAxis
                            domain={[
                                "auto",
                                "auto"
                            ]}
                            name={props.donation ? "Amount" : props.yAxis}
                            allowDecimals={false}
                            tickFormatter={(data) => (props.donation ? "$" + data : data)}
                        >
                        </YAxis>
                        <Tooltip/>
                        {props.multiple ? (
                            formattedLines
                        ) : (
                            <Line
                                type="monotone"
                                dataKey={props.yAxis}
                                stroke="#8884d8"
                                activeDot={{r: 8}}
                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <p>No data available for specified range</p>
            )}
        </LineChartContainer>
    );
};

export const PieChartComponent = (info) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = (data, index) => {
        setActiveIndex(index);
    };

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            payload,
            percent,
            value,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path
                    d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                    stroke={fill}
                    fill="none"
                />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    textAnchor={textAnchor}
                    fill="#333"
                >{`${info.label}: ${value}`}</text>
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    dy={18}
                    textAnchor={textAnchor}
                    fill="#999"
                >
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    return (
        <ChartContainer>
            <Title style={{textAlign: 'left', margin: 0}} level={4}>
                {info.title}
            </Title>
            <ResponsiveContainer>
                <PieChart width={500} height={300}>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={info.data}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={onPieEnter}
                    />
                </PieChart>
            </ResponsiveContainer>
        </ChartContainer>
    );
};

export const BarChartComponent = (props) => {
    return (
        <ChartContainer>
            <Title style={{ textAlign: "left", margin: 0 }} level={4}>
                {props.name}
            </Title>
            {props.data.length !== 0 ?
                <ResponsiveContainer>
                    <BarChart data={props.data}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey={props.xDataKey}>
                            <Label
                                value={props.xAxis}
                                position={"bottom"}
                            />
                        </XAxis>
                        <YAxis
                            name={"Count"}/>
                        <Tooltip/>
                        <Bar dataKey="count" fill="#8884d8" barGap={3}/>
                    </BarChart>
                </ResponsiveContainer>
                : (
                    <p>No data currently available</p>
                )}

        </ChartContainer>
    )
}
