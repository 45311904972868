import React, { useState, useEffect } from "react";
import "./App.css";
import "antd/dist/antd.css";
import Routes from "./routes";
import LogIn from "./screens/logIn";
import PrimaryLayout from "./layouts";
import { ApolloDataClient } from "./api";
import { clearClientCache } from "./api";
import SignUpForm from "./screens/signUp";
import PrivateRoute from "./PrivateRoute";
import { ApolloProvider } from "@apollo/client";
import { createBrowserHistory } from "history";
import { Route, Switch, Router } from "react-router";
import { ResetPassword } from "./screens/resetPassword";
import {
  isSession,
  getSession,
  deleteSession,
} from "./helpers/SessionManagement";
import EmailChangeConfirmation from "./screens/emailChangeConfirmation";

const history = createBrowserHistory();

function App() {
  const isValidSession = () => {
    if (isSession()) {
      const sessionExpiry = getSession()?.expiry;
      const currentTime = (Date.now() / 1000) | 0;
      if (currentTime < sessionExpiry) {
        return true;
      }
      deleteSession();
      clearClientCache();
    }
    return false;
  };

  const [auth, setAuth] = useState(isValidSession());

  useEffect(() => {
    if (
      window.location.pathname.toLowerCase().includes("email-change-request") ||
      window.location.pathname.toLowerCase().includes("signup")
    ) {
      setAuth(false);
      deleteSession();
      clearClientCache();
    }
  }, []);

  return (
    <ApolloProvider client={ApolloDataClient.client}>
      <div className="App">
        {auth ? (
          <Router history={history}>
            <PrimaryLayout setAuth={setAuth}>
              <Switch>
                {Routes.map((route) => {
                  return <PrivateRoute {...route} key={route} />;
                })}
              </Switch>
            </PrimaryLayout>
          </Router>
        ) : (
          <Router history={history}>
            <Switch>
              <Route
                path="/email-change-request"
                component={EmailChangeConfirmation}
              />
              <Route path="/signup" component={SignUpForm} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route
                path="/"
                component={() => <LogIn setAuth={setAuth} history={history} />}
              />
            </Switch>
          </Router>
        )}
      </div>
    </ApolloProvider>
  );
}

export default App;
