import React, { useEffect, useRef, useState } from "react";
import { Queries } from "../../api";
import LiveVideoColumns from "./columns";
import Mutations from "../../api/Mutations";
import { Avatar, Button, Divider, message, Tag } from "antd";
import { trimErrorMessage } from "../../utils";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import SimpleTableView from "../../components/simpleTableView";
import { useApolloClient } from "@apollo/client";
import Table from "../../components/Table";
import { useDatabase } from "../../hooks/database";
import { getSession } from "../../helpers/SessionManagement";
import { useEntities, useEntityTable } from "../../hooks/entity";
import { Link } from "react-router-dom";
import DeleteConfirm from "../../components/DeleteConfirm/DeleteConfirm";
import _ from "lodash";

const LiveVideo = (props) => {
  const { database } = useDatabase();
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const session = getSession();

  const staticFilters = {
    organization_id: {
      _eq: session?.organization?.id,
    },
  };

  const { fetchData } = useEntities({
    baseEntity: "live_videos",
    staticFilters,
    fields: ["*"],
  });

  const { getColumnAttributes, loading: loadingDatabase } = useEntityTable({
    baseEntity: "live_videos",
    staticFilters,
  });

  const { loading: dataLoading, data } = useQuery(Queries.ALL_LIVE_VIDEOS, {
    onError: (err) => message.error(trimErrorMessage(err.message)),
  });

  const localClient = useApolloClient();
  const [current, setCurrent] = useState(
    localClient.cache.readQuery({ query: Queries.GET_PAGE_NUMBER })
      ?.pageNumber || 1
  );

  const [deleteLiveVideo, { loading: deleteLoading }] = useMutation(
    Mutations.DELETE_LIVE_VIDEO,
    {
      onCompleted: (deleteData) =>
        message.success(deleteData.deleteLiveVideo.name + " deleted"),
      onError: () =>
        message.error(
          "This video is used by a widget! You most remove dependencies first."
        ),
      update(cache, { data: { deleteLiveVideo } }) {
        let { liveVideos } = cache.readQuery({
          query: Queries.ALL_LIVE_VIDEOS,
        });
        liveVideos = liveVideos.filter((video) => {
          return video.id !== deleteLiveVideo.id;
        });
        cache.writeQuery({
          query: Queries.ALL_LIVE_VIDEOS,
          data: { liveVideos: liveVideos },
        });
      },
    }
  );

  const [refreshingVideo, setRefreshingVideo] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [refreshLiveVideo, { loading: refreshLoading }] = useMutation(
    Mutations.REFRESH_LIVE_VIDEO,
    {
      onCompleted: () =>
        message.success(refreshingVideo.name + " successfully refreshed."),
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const onDelete = (id) =>
    deleteLiveVideo({
      variables: {
        id,
      },
    });

  const onRefresh = (id, name) => {
    setRefreshingVideo({
      id: id,
      name: name,
    });
    refreshLiveVideo({ variables: { id: id } });
  };

  const onChange = (page) => {
    localClient.writeQuery({
      query: Queries.GET_PAGE_NUMBER,
      data: {
        pageNumber: page.current,
      },
    });
    setCurrent(page.current);
  };

  const onEdit = (id) => props.history.push(`/live-video/${id}`);
  useEffect(() => {
    async function initCols() {
      const columns = [
        {
          field: "image",
          colId: "image",
          headerName: "Image",
          flex: 1,
          maxWidth: 100,
          ...(await getColumnAttributes({
            field: "image",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return <Avatar src={params?.value}>{params?.value}</Avatar>;
          },
        },
        {
          field: "name",
          colId: "name",
          headerName: "Name",
          flex: 1,
          ...(await getColumnAttributes({
            field: "name",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/live-video/${params?.data?.id}`}>
                {params?.value}
              </Link>
            );
          },
        },
        {
          field: "stations",
          colId: "stations",
          headerName: "Stations",
          flex: 1,
          ...(await getColumnAttributes({
            field: "stations",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return params?.value || "";
          },
        },
        {
          field: "stream_url",
          colId: "stream_url",
          headerName: "Stream URL",
          flex: 1,
          ...(await getColumnAttributes({
            field: "stream_url",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Tag
                color="orange"
                style={{
                  maxWidth: "200px",
                  overflowX: "scroll",
                  display: "inline-flex",
                }}
              >
                {params?.value || "Not Provided"}
              </Tag>
            );
          },
        },
        {
          field: "provider",
          colId: "provider",
          headerName: "Provider",
          flex: 1,
          ...(await getColumnAttributes({
            field: "provider",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Tag
                color={"orange"}
                style={{
                  maxWidth: "100px",
                  overflowX: "scroll",
                  display: "inline-flex",
                }}
              >
                {params?.value || ""}
              </Tag>
            );
          },
        },
        {
          field: "published",
          colId: "published",
          headerName: "Published",
          flex: 1,
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params) => {
              if (parseInt(params?.value) === 1) {
                return "Published";
              }

              return "Unpublished";
            },
            values: [1, 0],
          },
          valueFormatter: (params) => {
            if (params?.value) {
              return "Published";
            }

            return "Unpublished";
          },
          valueGetter: (params) => {
            const colId = params.column.getId();
            const val = _.get(params?.data || {}, colId);
            return !!val;
          },
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span style={{ display: "inline-flex" }}>
                {!params?.value && (
                  <Tag
                    color="red"
                    size="small"
                    style={{ maxWidth: "70px", overflowX: "scroll" }}
                  >
                    Not published
                  </Tag>
                )}

                {params?.value && (
                  <Tag
                    color="green"
                    style={{ maxWidth: "150px", overflowX: "scroll" }}
                  >
                    Published
                  </Tag>
                )}
              </span>
            );
          },
        },
        {
          field: "actions",
          colId: "actions",
          headerName: "Actions",
          flex: 1,
          minWidth: 250, // @todo figure out how to override width
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span style={{ display: "inline-flex" }}>
                {/*<Button*/}
                {/*  type={"primary"}*/}
                {/*  icon={<EditOutlined />}*/}
                {/*  onClick={() => onEdit(params?.data?.id)}*/}
                {/*>*/}
                {/*  Edit*/}
                {/*</Button>*/}
                {/*<Divider type="vertical" style={{ alignSelf: "center" }} />*/}
                <Button
                  icon={
                    refreshLoading &&
                    refreshingVideo.id === params?.data?.id ? (
                      <LoadingOutlined />
                    ) : (
                      <PlusOutlined />
                    )
                  }
                  type={"secondary"}
                  disabled={refreshLoading}
                  onClick={() =>
                    onRefresh(params?.data?.id, params?.data?.name)
                  }
                >
                  {refreshLoading && refreshingVideo.id === params?.data?.id
                    ? "Refresh"
                    : "Refresh"}
                </Button>
                <Divider type="vertical" style={{ alignSelf: "center" }} />
                <DeleteConfirm
                  name={params?.data?.name}
                  action={() => onDelete(params?.data?.id)}
                />
              </span>
            );
          },
        },
      ];

      setColumns(columns);
    }

    if (!loadingDatabase) {
      initCols();
    }
    // eslint-disable-next-line
  }, [database, getColumnAttributes, loadingDatabase]);

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        size={"large"}
        onClick={() => props.history.push("/live-video/new")}
      >
        Add a live video
      </Button>

      <div style={{ marginTop: "2rem" }} />

      <Table
        hideGroupBy
        tableRef={tableRef}
        fetchData={fetchData}
        wrapperHeight={false}
        columns={columns}
        gridOptions={{
          groupIncludeTotalFooter: false,
          domLayout: "autoHeight",
          paginationAutoPageSize: false,
        }}
      />
    </div>
  );
};

export default LiveVideo;
