import React from "react";
import styled from "styled-components";
import { Button, Switch } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import DeleteConfirm from "../DeleteConfirm/DeleteConfirm"

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 38px;
`;
const SwitchContainer = styled.div`
  font-size: 16px;
  margin-right: 12px;
  align-self: center;
`;

const FormTitle = (props) => (
    <h1
        style={{
            fontSize: props.size,
            fontWeight: 600,
            marginTop: "2rem",
            marginBottom: "1rem",
            textAlign: "start",
        }}
    >
        {props.title}
    </h1>
);

const FormFooter = (props) => (
    <Container>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <Button style={{marginRight: 6}} onClick={() => props.onCancel()}>{props.negativeBtnTitle}</Button>
            {
                props.showDelete && (
                    <DeleteConfirm name={props.name} action={props.handleDelete}></DeleteConfirm>
                )
            }
        </div>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            {props.showSwitch && (
                <SwitchContainer>
                    {props.switchLabel}
                    <Switch
                        style={{marginLeft: 6}}
                        checked={props.switchValue}
                        onChange={() => props.onChange()}
                    />
                </SwitchContainer>
            )}
            <Button
                type="primary"
                icon={<SaveOutlined/>}
                onClick={() => props.onSubmit()}
                disabled={
                    props.disablePositive || props.imageLoading || props.docLoading
                }
            >
                {props.positiveBtnTitle}
            </Button>
        </div>
    </Container>
);

export { FormTitle, FormFooter };
