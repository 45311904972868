import { Button, Modal, Select } from "antd";
import React, { useState } from "react";
import AlertConfirm from "../../../components/AlertConfirm";

const { Option } = Select;

const ChangeProgram = ({changeProgramVisible, setChangeProgramVisible, handleChangeProgram, programsForDropdownLoading, debounceOnProgramQuery, programs}) => {
  const [newProgram, setNewProgram] = useState(null);

  return (
    <Modal
      title={
        "Change program"
      }
      visible={changeProgramVisible}
      onCancel={() => setChangeProgramVisible(false)}
      footer={[
        <Button
          key="delete"
          onClick={() => setChangeProgramVisible(false)}
          style={{width: "75px"}}
        >
          Cancel
        </Button>,
        <AlertConfirm
          actionBtnText={'Change'}
          btnType={"primary"}
          modalProps={{
            okText: 'Confirm',
            alertMsg: 'Are you sure you want to change the program for all selected episodes?',
            onOk: () => {
              handleChangeProgram(newProgram);
              setChangeProgramVisible(false);
            },
            alertTitle: 'Confirm change program'
          }}
        />,
      ]}
    >
      <Select
        showSearch
        allowClear
        filterOption={true}
        optionFilterProp={"filter"}
        placeholder={"Select new program"}
        defaultActiveFirstOption={false}
        style={{
          width: '100%',
          height: 32,
          marginBottom: 12,
          marginRight: 12,
        }}
        value={newProgram}
        loading={programsForDropdownLoading}
        onClear={() => {
          debounceOnProgramQuery("");
          setNewProgram(null);
        }}
        onSearch={(query) => {
          if (query.trim() === "" && query.length === 0) return;
          debounceOnProgramQuery(query);
        }}
        onSelect={(labeledValue, option) => {
          setNewProgram(labeledValue);
        }}
      >
        {
          programs?.map((item) => (
            <Option key={item.id} filter={item.title}>
              {item.title}
            </Option>
          ))
        }
      </Select>
    </Modal>
  )
}

export default ChangeProgram;