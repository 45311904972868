import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SaveOutlined } from "@ant-design/icons";
import { Queries, Mutations } from "../../../api";
import { Col, Row } from "react-styled-flexboxgrid";
import { Button, Input, message, Spin, Form } from "antd";
import LinkColumns from "../../../components/LinkColumns";
import ColorsPicker from "../../../components/ColorsPicker";
import { useMutation, useLazyQuery } from "@apollo/client";
import UploadMultiple from "../../../components/UploadMultiple";
import MailchimpFields from "../../../components/MailchimpFields";
import { SocialLinksComponent } from "../../../components/LinksComponent";
import { initialSocialLink, trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const RootContainer = styled.div`
  padding: 24px;
  background: #ffffff;
  label {
    font-weight: 600;
    font-size: 16px;
  }
  .ant-form-item-label {
    text-align: start;
  }
`;
const DisplayHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const { TextArea } = Input;

export default function FooterWidget(props) {
  const [form] = Form.useForm();
  const [colors, setColors] = useState({
    primaryColor: "#DB18FF",
    bgColor: "#DB18FF",
  });
  const [images, setImages] = useState([]);
  const [columns, setColumns] = useState([]);
  const [socialMediaLinks, setSocialMediaLinks] = useState(initialSocialLink);
  const id = props.match.params.id === "new" ? false : props.match.params.id;
  const [createFooterWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_FOOTER_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createFooterWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updateFooterWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_FOOTER_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updateFooterWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [
    getFooterWidget,
    {
      loading: footerWidgetLoading,
      error: footerWidgetError,
      data: footerWidgetData,
    },
  ] = useLazyQuery(Queries.FOOTER_WIDGET_BY_ID, {
    variables: { id: id },
  });

  const initForm = (data = {}) => {
    form.setFieldsValue({
      name: data?.name || "",
      additionalInfo: data?.additionalInfo || "",
      mailchimpListId: data?.mailchimpListId || "",
      mailchimpSegmentId: data?.mailchimpSegmentId || "",
      mailchimpSub: data?.hasMailchimpSubscription || false,
      mailchimpSuccessMessage: data?.mailchimpSuccessMessage || "",
    });
  };

  useEffect(() => {
    if (id) {
      getFooterWidget();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id && footerWidgetData) {
      const footerWidget = footerWidgetData.footerWidgetById;
      initForm(footerWidget);
      setColors({
        bgColor: footerWidget.bgColor,
        primaryColor: footerWidget.primaryColor,
      });
      setImages(
        footerWidget.images.map((item) => {
          return item.url;
        })
      );
      setSocialMediaLinks(
        footerWidget?.socialMediaLinks?.map((item, id) => ({
          id,
          url: item.url,
          mediaType: item.mediaType,
        }))
      );
      setColumns(footerWidget.footerColumns);
    }

    if (footerWidgetError) return message.error(footerWidgetError.message);

    // eslint-disable-next-line
  }, [id, footerWidgetData, footerWidgetError, props.history]);

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=footer");
  };

  const removeIdsFromColumns = (allColumns) => {
    const myColumns = allColumns?.map((item) => {
      return {
        header: item.header,
        footerColumnsLinks: item.footerColumnsLinks.map((item) => {
          return {
            url: item.url,
            title: item.title,
          };
        }),
      };
    });
    return myColumns;
  };

  const handleSubmit = () => {
    const fields = form.getFieldsValue();
    const variables = {
      images: images,
      name: fields.name,
      additionalInfo: fields.additionalInfo,
      bgColor: colors.bgColor,
      primaryColor: colors.primaryColor,
      mailchimpSegmentId: fields.mailchimpSub ? fields.mailchimpSegmentId : "",
      hasMailchimpSubscription: fields.mailchimpSub,
      mailchimpSuccessMessage: fields.mailchimpSub
        ? fields.mailchimpSuccessMessage
        : "",
      mailchimpListId: fields.mailchimpSub ? fields.mailchimpListId : "",
      socialMediaLinks: socialMediaLinks.filter(
        (item) => item.url && delete item.id
      ),
      columns: removeIdsFromColumns(columns).filter((item) => item.header),
    };
    if (id) {
      updateFooterWidget({ variables: { id: id, ...variables } });
    } else {
      createFooterWidget({ variables: variables });
    }
  };

  return (
    <RootContainer>
      <Spin
        spinning={
          (id !== "new" && (footerWidgetLoading || updateLoading)) ||
          (id === "new" && createLoading)
        }
      >
        <Form.Provider onFormFinish={() => handleSubmit()}>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Form form={form} layout={"vertical"}>
                <Form.Item
                  label={"Name"}
                  name="name"
                  style={{
                    width: "70%",
                    fontWeight: 600,
                    marginBottom: 18,
                    fontSize: 18,
                  }}
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Input type={"text"} placeholder="Name of widget" />
                </Form.Item>

                <Form.Item
                  label={"Social links"}
                  style={{ width: "80%", fontWeight: 600, fontSize: 18 }}
                >
                  <SocialLinksComponent
                    data={socialMediaLinks}
                    links={(list) => setSocialMediaLinks(list)}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <ColorsPicker
                colors={colors}
                isTextColor={false}
                setColors={setColors}
                isPrimaryColor={true}
                isSecondaryColor={false}
                isBackgroundColor={true}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Form form={form} layout={"vertical"}>
                <Form.Item
                  label={"Additional Info"}
                  name="additionalInfo"
                  style={{
                    width: "70%",
                    fontWeight: 600,
                    marginBottom: 18,
                    fontSize: 18,
                  }}
                  rules={[{ required: false }]}
                >
                  <TextArea
                    size={"large"}
                    placeholder={"Additional information"}
                    rows={6}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <Form.Item
            label={"Link columns"}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LinkColumns
              title={"Column links"}
              data={columns}
              setData={(list) => {
                if (list.length > 0) {
                  setColumns(list);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label={"Additional images"}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UploadMultiple
              dir={"footer-widget/images"}
              images={images}
              setImages={setImages}
              allowImages={3}
              squareCrop={true}
            />
          </Form.Item>
          <MailchimpFields form={form} successMessage={true} />
        </Form.Provider>
        <DisplayHorizontal>
          <Button shape="round" size={"large"} onClick={goToListWidgets}>
            Cancel
          </Button>

          <Button
            type="primary"
            shape="round"
            size={"large"}
            onClick={form.submit}
            icon={<SaveOutlined />}
          >
            {id ? "Save widget" : "Create widget"}
          </Button>
        </DisplayHorizontal>
      </Spin>
    </RootContainer>
  );
}
