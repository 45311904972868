import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import { Mutations, Queries } from "../../../api";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Select, Input, message, Spin, Form } from "antd";
import { SaveOutlined, LoadingOutlined } from "@ant-design/icons";
import { trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const { Option } = Select;

const Container = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  height: 100%;
`;

const HorizontalContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

const DEFAULT_COLOR = "#5229F4";

const CardSize = [
  { id: "BIG", value: "Big" },
  { id: "SMALL", value: "Small" },
];
const Layout = [
  { id: "HORIZONTAL", value: "Horizontal" },
  { id: "GRID", value: "Grid" },
];
const PlaylistWidget = (props) => {
  const [form] = Form.useForm();
  const [colors, setColors] = useState({
    bgColor: DEFAULT_COLOR,
    textColor: DEFAULT_COLOR,
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });
  const id = props.match.params.id;

  const [createPlaylistWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_PLAYLIST_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createPlaylistWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updatePlaylistWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_PLAYLIST_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updatePlaylistWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const {
    loading: playlistLoading,
    error: playlistError,
    data: playlistData,
  } = useQuery(Queries.PLAYLIST_WIDGET_BY_ID, {
    skip: id === "new",
    variables: { id: id },
  });

  const {
    loading: playlistsLoading,
    error: playlistsError,
    data: playlistsData,
  } = useQuery(Queries.PLAYLISTS);

  useEffect(() => {
    setFormFields();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (playlistData) {
      setFormFields(playlistData?.playlistWidgetById);
      setColors({
        bgColor: playlistData?.playlistWidgetById?.bgColor,
        textColor: playlistData?.playlistWidgetById?.textColor,
        primaryColor: playlistData?.playlistWidgetById?.primaryColor,
        secondaryColor: playlistData?.playlistWidgetById?.secondaryColor,
      });
    }
    if (playlistError) {
      message.error(playlistError.message);
    }
    // eslint-disable-next-line
  }, [playlistData, playlistError]);

  useEffect(() => {
    if (playlistError) {
      message.error(playlistsError.message);
    }
    // eslint-disable-next-line
  }, [playlistsData, playlistsError]);

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=playlist");
  };

  const setFormFields = (data = {}) => {
    form.setFieldsValue({
      name: data?.name || "",
      cardSize: data?.cardSize || undefined,
      layout: data?.layout || undefined,
      playlists: data?.playlists?.map((item) => item.id) || [],
    });
  };

  const handleSubmit = () => {
    const fields = form.getFieldsValue();

    if (id === "new") {
      createPlaylistWidget({ variables: { ...fields, ...colors } });
    } else {
      updatePlaylistWidget({
        variables: { id: id, ...fields, ...colors },
      });
    }
  };

  return (
    <Container>
      <Spin
        spinning={
          playlistLoading || playlistsLoading || createLoading || updateLoading
        }
        style={{ width: "100%", alignSelf: "center" }}
      >
        <Form
          form={form}
          layout={"vertical"}
          scrollToFirstError
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Name"
            name={"name"}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input type={"text"} placeholder="Name of widget" />
          </Form.Item>

          <Form.Item
            label="Playlist"
            name={"playlists"}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Select
              mode="multiple"
              placeholder="Playlist"
              optionFilterProp={"filter"}
              onChange={(ids) => {}}
            >
              {playlistsData?.playlists?.map((item) => (
                <Option key={item.id} value={item.id} filter={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <HorizontalContainer>
            <Form.Item
              label="Card size"
              name={"cardSize"}
              style={{ width: "20%" }}
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Select placeholder="Select">
                {CardSize.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Layout"
              name={"layout"}
              style={{ width: "20%" }}
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Select placeholder="Select">
                {Layout.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </HorizontalContainer>
          <HorizontalContainer>
            <Form.Item label="Primary color">
              <ChromePicker
                color={colors.primaryColor}
                onChangeComplete={(e) =>
                  setColors({ ...colors, primaryColor: e.hex })
                }
              />
            </Form.Item>
            <Form.Item label="Seconday color">
              <ChromePicker
                color={colors.secondaryColor}
                onChangeComplete={(e) =>
                  setColors({ ...colors, secondaryColor: e.hex })
                }
              />
            </Form.Item>
            <Form.Item label="Text color">
              <ChromePicker
                color={colors.textColor}
                onChangeComplete={(e) =>
                  setColors({ ...colors, textColor: e.hex })
                }
              />
            </Form.Item>
            <Form.Item label="Background color">
              <ChromePicker
                color={colors.bgColor}
                onChangeComplete={(e) =>
                  setColors({ ...colors, bgColor: e.hex })
                }
              />
            </Form.Item>
          </HorizontalContainer>
          <HorizontalContainer>
            <Button shape="round" size={"large"} onClick={goToListWidgets}>
              Cancel
            </Button>

            <Button
              type="primary"
              shape="round"
              icon={createLoading ? <LoadingOutlined /> : <SaveOutlined />}
              size={"large"}
              onClick={form.submit}
            >
              {id === "new" ? "Create widget" : "Update widget"}
            </Button>
          </HorizontalContainer>
        </Form>
      </Spin>
    </Container>
  );
};

export default PlaylistWidget;
