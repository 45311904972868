import React, { useState, useEffect } from 'react';
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { useMutation } from "@apollo/client";
import { Mutations } from "../../../api";

const ExportCsv = () => {
    const [isDisableExport, setIsDisableExport] = useState(false);
    const [
        exportCSVCall,
        {loading: exportLoading, error: exportError, data: exportData},
    ] = useMutation(Mutations.USERS_DATA_TO_CSV, {});

    useEffect(() => {
        if (exportData) {
            message.success('Users export in progress, CSV will be emailed')
        }

        if (exportError) {
            message.error("Error exporting data to CSV.");
        }
    }, [
        exportData,
        exportError
    ]);

    const onClickExportCSV = () => {
        exportCSVCall().then(() => null);
        setIsDisableExport(true)
    }

    return (
        <Button
            disabled={isDisableExport}
            icon={exportLoading ? <LoadingOutlined/> : <SaveOutlined/>}
            style={{marginRight: 12}}
            onClick={() => onClickExportCSV()}
        >
            Export CSV
        </Button>
    )
};

export default ExportCsv;
