import React, { useCallback, useEffect, useState } from "react";
import MediaTableHeader from "../MediaTableHeader";
import { useMutation } from "@apollo/client";
import { Mutations } from "../../api";
import { SEARCH_DELAY } from "../../utils/contants";
import { debounce } from "../../utils";
import { message } from "antd";
import { useDatabase } from "../../hooks/database";
import { getSession } from "../../helpers/SessionManagement";
import { clearDirectusCache } from "../../helpers/ClearDirectusCache";

const TableHeader = ({
  collection,
  label,
  link,
  history,
  selectedRows,
  setQuickSearch,
  isCableCast = false,
  tableRef = null,
  showPush = false,
}) => {
  const { database } = useDatabase();
  const [searchString, setSearchString] = useState("");
  const [isDisableExport, setIsDisableExport] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [topics, setTopics] = useState([]);
  const session = getSession();

  const onChangeSearch = (str) => {
    setSearchString(str);
    setQuickSearch(str);
    tableRef.current.api.refreshServerSideStore();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce(onChangeSearch, SEARCH_DELAY),
    []
  );

  // Episodes.
  const [
    deleteEpisodes,
    { loading: deleteEpisodeLoading, data: deleteEpisodeData },
  ] = useMutation(Mutations.DELETE_EPISODES);

  const [
    updateEpisodesPublishedStatus,
    {
      loading: updateEpisodeStausPublishLoading,
      data: updateEpisodePublishStausData,
    },
  ] = useMutation(Mutations.UPDATE_EPISODE_PUBLISHED_STATUS, {});

  const [
    updateEpisodesStatus,
    { loading: updateEpisodeStausLoading, data: updateEpisodeStausData },
  ] = useMutation(Mutations.UPDATE_EPISODE_STATUS, {});

  const [
    changeEpisodesProgram,
    { loading: changeEpisodesProgramLoading, data: changeEpisodesProgramData },
  ] = useMutation(Mutations.CHANGE_EPISODES_PROGRAM, {});

  const [
    addEpisodesTopics,
    { loading: addEpisodesTopicsLoading, data: addEpisodesTopicsData },
  ] = useMutation(Mutations.ADD_EPISODES_TOPICS, {});

  const [
    addEpisodesTags,
    { loading: addEpisodesTagsLoading, data: addEpisodesTagsData },
  ] = useMutation(Mutations.ADD_EPISODES_TAGS, {});

  // Programs.
  const [
    updateProgramsStatus,
    { loading: programStatusLoading, data: programStatusData },
  ] = useMutation(Mutations.UPDATE_PROGRAM_STATUS, {});

  const [
    updateProgramsPublishStatus,
    { loading: programStatusPublishLoading, data: programPublishStatusData },
  ] = useMutation(Mutations.UPDATE_PROGRAM_PUBLISH_STATUS, {});

  const [
    deletePrograms,
    { loading: deleteLoading, data: deleteData },
  ] = useMutation(Mutations.DELETE_PROGRAMS);

  const [exportCSVCall, { error: exportError, data: exportData }] = useMutation(
    Mutations[`${collection.toUpperCase()}_DATA_TO_CSV`],
    {}
  );

  useEffect(() => {
    if (exportData) {
      message.success(collection + " export in progress, CSV will be emailed");
    }

    if (exportError) {
      message.error("Error exporting data to CSV.");
    }
  }, [collection, exportData, exportError]);

  useEffect(() => {
    if (programPublishStatusData || updateEpisodePublishStausData) {
      message.success("Publish status updated successfully");
    }
  }, [programPublishStatusData, updateEpisodePublishStausData]);

  useEffect(() => {
    if (programStatusData || updateEpisodeStausData) {
      message.success("Status updated successfully");
    }
  }, [programStatusData, updateEpisodeStausData]);

  useEffect(() => {
    if (deleteData || deleteEpisodeData) {
      message.success(`${collection} deleted successfully`);
    }
    // eslint-disable-next-line
  }, [deleteData, deleteEpisodeData]);

  useEffect(() => {
    async function init() {
      const collectionInfo = await database.collections.readOne(collection);
      console.log(collectionInfo);
    }

    if (database) {
      init();
    }
  }, [collection, database]);

  useEffect(() => {
    if (changeEpisodesProgramData) {
      message.success("Episodes updated successfully");
    }
  }, [changeEpisodesProgramData]);

  useEffect(() => {
    if (addEpisodesTopicsData) {
      message.success("Topics updated successfully");
    }
  }, [addEpisodesTopicsData]);

  useEffect(() => {
    if (addEpisodesTagsData) {
      message.success("Tags updated successfully");
    }
  }, [addEpisodesTagsData]);

  const handleAddTopics = async (newTopics) => {
    const selectedRows = tableRef.current.api.getSelectedRows() || [];
    const ids = selectedRows.map((r) => r?.id);

    if (selectedRows.length > 0 && newTopics?.[0]) {
      if (collection === "programs") {
        selectedRows.forEach(async (row) => {
          const combinedTopics = [
            ...newTopics.map((t) => ({
              topic_id: { id: t },
            })),
            ...(row?.topics || [])
              .filter((t) => !newTopics.includes(t?.topic_id))
              .map((t) => ({
                topic_id: { id: t?.topic_id },
              })),
          ];

          await database.items("programs").updateOne(row?.id, {
            topics: combinedTopics,
          });
        });
      } else {
        addEpisodesTopics({
          variables: {
            topics: newTopics,
            ids,
          },
        })
          .then(async () => {
            await clearDirectusCache();
            tableRef.current.api.refreshServerSideStore();
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    }
  };

  const handleAddTags = (newTags) => {
    const selectedRows = tableRef.current.api.getSelectedRows() || [];
    const ids = selectedRows.map((r) => r?.id);

    if (ids.length > 0) {
      addEpisodesTags({
        variables: {
          tags: newTags,
          ids,
        },
      })
        .then(async () => {
          await clearDirectusCache();
          tableRef.current.api.refreshServerSideStore();
        })
        .catch((e) => {
          message.error(e.message);
        });
    }
  };

  const handleChangeProgram = (newProgramId) => {
    const selectedRows = tableRef.current.api.getSelectedRows() || [];
    const ids = selectedRows.map((r) => r?.id);

    if (ids.length > 0) {
      changeEpisodesProgram({
        variables: {
          programId: newProgramId,
          ids,
        },
      })
        .then(async () => {
          await clearDirectusCache();
          tableRef.current.api.refreshServerSideStore();
        })
        .catch((e) => {
          message.error(e.message);
        });
    }
  };

  const handleUpdateStatus = (status) => {
    const selectedRows = tableRef.current.api.getSelectedRows() || [];
    const ids = selectedRows.map((r) => r?.id);

    if (ids.length > 0) {
      if (collection === "programs") {
        updateProgramsStatus({
          variables: {
            status: status,
            ids,
          },
        })
          .then(async () => {
            await clearDirectusCache();
            tableRef.current.api.refreshServerSideStore();
          })
          .catch((e) => {
            message.error(e.message);
          });
      } else {
        updateEpisodesStatus({
          variables: {
            status: status,
            ids,
          },
        })
          .then(async () => {
            await clearDirectusCache();
            tableRef.current.api.refreshServerSideStore();
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    }
  };

  const handleDelete = () => {
    const selectedRows = tableRef.current.api.getSelectedRows() || [];
    const ids = selectedRows.map((r) => r?.id);

    if (collection === "programs") {
      deletePrograms({
        variables: {
          ids,
        },
      })
        .then(async () => {
          await clearDirectusCache();
          tableRef.current.api.refreshServerSideStore();
        })
        .catch(() => message.error("server error"));
    } else {
      deleteEpisodes({
        variables: {
          ids,
        },
      })
        .then(async () => {
          await clearDirectusCache();
          tableRef.current.api.refreshServerSideStore();
        })
        .catch(() => message.error("server error"));
    }
  };

  const handlePublishStatus = (status) => {
    const selectedRows = tableRef.current.api.getSelectedRows() || [];
    const ids = selectedRows.map((r) => r?.id);

    if (ids.length > 0) {
      if (collection === "programs") {
        updateProgramsPublishStatus({
          variables: {
            published: status,
            ids,
          },
        })
          .then(async () => {
            await clearDirectusCache();
            tableRef.current.api.refreshServerSideStore();
          })
          .catch((error) => {
            message.error("Error:", error.message);
          });
      } else {
        updateEpisodesPublishedStatus({
          variables: {
            published: status,
            ids,
          },
        })
          .then(async () => {
            await clearDirectusCache();
            tableRef.current.api.refreshServerSideStore();
          })
          .catch((error) => {
            message.error("Error:", error.message);
          });
      }
    }
  };

  const isLoading = () => {
    return (
      programStatusLoading ||
      programStatusPublishLoading ||
      deleteLoading ||
      deleteEpisodeLoading ||
      updateEpisodeStausPublishLoading ||
      updateEpisodeStausLoading ||
      changeEpisodesProgramLoading ||
      addEpisodesTopicsLoading ||
      addEpisodesTagsLoading
    );
  };

  const [
    syncEpisodeWithCableCast,
    { loading: cableCastLoading, data: cableCastData },
  ] = useMutation(Mutations.PUSH_EPISODES_TO_CABLE_CAST, {
    errorPolicy: "all",
    onError: (e) => message.error(e.message),
  });

  const [
    syncEpisodeWithTelvue,
    { loading: telvueLoading, data: telvueData },
  ] = useMutation(Mutations.PUSH_EPISODES_TO_TELVUE_HYPERCAST, {
    errorPolicy: "all",
    onError: (e) => message.error(e.message),
  });

  const onClickAddNew = () => {
    history.push(link);
  };

  const onClickExportCSV = () => {
    setIsDisableExport(true);
    exportCSVCall().then(() => null);
  };

  const handlePushVideo = () => {
    const selectedRows = tableRef.current.api.getSelectedRows() || [];
    const ids = selectedRows.map((r) => r?.id);

    if (isCableCast) {
      if (ids.length > 0) {
        syncEpisodeWithCableCast({
          variables: { episodeIds: ids },
        });
      }
    } else {
      if (ids.length > 0) {
        syncEpisodeWithTelvue({
          variables: { episodeIds: ids },
        });
      }
    }

    message.success("Episodes pushed successfully");
  };

  useEffect(() => {
    async function init() {
      if (collection === "episodes") {
        const programs = await database.items("programs").readMany({
          fields: ["id", "title"],
          limit: -1,
          filter: {
            organization_id: {
              _eq: session?.organization?.id,
            },
          },
        });

        const episodeSettings = await database
          .items("episode_settings")
          .readMany({
            fields: ["topics.topic_id.id", "topics.topic_id.title"],
            filter: {
              organization_id: session?.organization?.id,
            },
          });

        let topics = [];
        if (episodeSettings?.data?.[0]?.topics?.[0]) {
          topics = episodeSettings.data[0].topics.map((t) => ({
            ...(t?.topic_id || {}),
          }));
        }

        setTopics(topics);
        setPrograms(programs?.data);
      } else if (collection === "programs") {
        const programSettings = await database
          .items("program_settings")
          .readMany({
            fields: ["topics.topic_id.id", "topics.topic_id.title"],
            filter: {
              organization_id: session?.organization?.id,
            },
          });

        let topics = [];
        if (programSettings?.data?.[0]?.topics?.[0]) {
          topics = programSettings.data[0].topics.map((t) => ({
            ...(t?.topic_id || {}),
          }));
        }

        setTopics(topics);
      }
    }

    if (database) {
      init();
    }
  }, [collection, database, session.organization.id]);

  return (
    <MediaTableHeader
      tableRef={tableRef}
      isPrograms={collection === "programs"}
      isDisableExport={isDisableExport}
      exportLoading={false}
      isShowPushAction={showPush}
      onClickAddNew={onClickAddNew}
      setStatus={handleUpdateStatus}
      onChangeSearch={(value) => {
        if (value.trim() === "" && searchString.length === 0) return;
        debounceOnChange(value);
      }}
      handlePushVideo={handlePushVideo}
      onDelete={handleDelete}
      onClickExportCSV={onClickExportCSV}
      handlePublishStatus={handlePublishStatus}
      handleChangeProgram={handleChangeProgram}
      handleAddTopics={handleAddTopics}
      handleAddTags={handleAddTags}
      addNewBtnTitle={`Add new ${label}`}
      searchPlaceholder={`Search ${label}`}
      areRowsSelected={selectedRows.length > 0}
      isLoading={isLoading()}
      programs={programs}
      topics={topics}
    />
  );
};

export default TableHeader;
