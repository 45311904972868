import React, { useState } from "react";
import { Modal, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

function DeletePopUp(props) {
    const [visible, setVisible] = useState(false);

    const handleDelete = (toDelete) => {
        props.action(toDelete);
        setVisible(false);
    };

    return (
        <>
            <Button
                disabled={props.disabled ? props.disabled : false}
                style={{width: 174, height: 32, fontSize: 14, textAlign: "center"}}
                icon={<DeleteOutlined/>}
                type="danger"
                onClick={() => {
                    if (props.isDeleteEpisode) setVisible(true);
                    else {
                        handleDelete(true);
                    }
                }}
            >
                Delete
            </Button>
            <Modal
                title={
                    props.title ? props.title : "Delete from Master Control Integration?"
                }
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button
                        key="delete"
                        onClick={() => handleDelete(false)}
                        style={{width: "75px"}}
                    >
                        No
                    </Button>,
                    <Button
                        key="masterControl"
                        type="primary"
                        style={{background: "red", borderColor: "red"}}
                        onClick={() => {
                            handleDelete(true);
                        }}
                    >
                        Delete
                    </Button>,
                ]}
            >
                <p>
                    {props.message ? (
                        props.message
                    ) : (
                        <>
                            Do you want to delete this episode from <b>{props.integrationType}</b>{" "} as well? <br/>
                            This episode will only be deleted from <b>{props.integrationType}</b>{" "} if it exists
                            there.
                        </>
                    )}
                </p>
            </Modal>
        </>
    );
}

export default DeletePopUp;
