import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Select, Input, Switch, message, Spin, Form } from "antd";
import UploadMultiple from "../../../components/UploadMultiple";
import { RADIO_FORM_FIELDS, PROTOCOLS } from "../../../utils/contants";
import HelpText from "../../../components/HelpText";
import { useDatabase } from "../../../hooks/database";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const { TextArea } = Input;

const { Option } = Select;

const StyledForm = styled.div`
  .save {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .switch {
    margin-top: 1.5rem;
    margin-right: 0.5rem;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const HorizontalContainer = styled.div`
  display: flex;
`;

const rules = [{ required: true, message: "This field is required." }];
const style = { fontWeight: 600, margin: "18px 0px" };

export default function RadioForm(props) {
  const { database } = useDatabase();

  const [form] = Form.useForm();
  const [image, setImage] = useState("");

  const id = props.match.params.id;
  const initForm = (data = {}) => {
    setImage(data?.image || "");
    const {
      name,
      station = "",
      description = "",
      published = true,
    } = data || "";

    const pro = data?.stream_url?.includes(PROTOCOLS.http)
      ? PROTOCOLS.http
      : PROTOCOLS.https || PROTOCOLS.https;

    const streamUrl =
      data?.stream_url
        .replace(PROTOCOLS.https, "")
        .replace(PROTOCOLS.http, "") || "";

    form.setFieldsValue({
      name: name,
      station: station,
      description: description,
      streamUrl: streamUrl,
      protocol: pro,
      published: published,
    });
  };

  const handleSubmit = async () => {
    const formFields = form.getFieldsValue();
    const variables = {
      name: formFields.name,
      station: formFields.station,
      image: image,
      description: formFields.description,
      stream_url: formFields.protocol + formFields.streamUrl,
      published: formFields.published,
    };
    
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();

    if (id !== "new") {
      await database.items("radios").updateOne(id, variables);
      props.history.push("/radio");
      message.success(`${formFields.name} updated.`);
    } else {
      await database.items("radios").createOne(variables);
      props.history.push("/radio");
      message.success(`${formFields.name} created.`);
    }
  };

  useEffect(() => {
    async function init(id) {
      const res = await database.items("radios").readOne(id, {
        fields: "*",
      });

      initForm(res);
    }

    if (database) {
      if (id !== "new") {
        init(id);
      } else {
        initForm();
      }
    }
    // eslint-disable-next-line
  }, [id, database]);

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Spin spinning={!id}>
        <StyledForm>
          <Form.Provider onFormFinish={() => handleSubmit()} scrollToFirstError>
            <Form form={form} layout={"vertical"}>
              <Form.Item
                label={<HelpText title="Name" />}
                name={RADIO_FORM_FIELDS.name.key}
                style={style}
                rules={rules}
              >
                <Input
                  type={"text"}
                  placeholder={RADIO_FORM_FIELDS.name.placeHolder}
                  size={"large"}
                />
              </Form.Item>
              <Form.Item
                label={<HelpText title="Station" />}
                name={RADIO_FORM_FIELDS.station.key}
                style={style}
                rules={rules}
              >
                <Input
                  type={"text"}
                  placeholder={RADIO_FORM_FIELDS.station.placeHolder}
                  size={"large"}
                />
              </Form.Item>
              <HelpText title="Stream URL" required={true} />
              <HorizontalContainer>
                <Form.Item
                  name={RADIO_FORM_FIELDS.protocol.key}
                  style={{ fontWeight: 600, width: 100 }}
                  rules={[{ required: true }]}
                >
                  <Select className="select-before" size={"large"}>
                    <Option value={PROTOCOLS.https}>{PROTOCOLS.https}</Option>
                    <Option value={PROTOCOLS.http}>{PROTOCOLS.http}</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={RADIO_FORM_FIELDS.streamUrl.key}
                  style={{ fontWeight: 600, width: "100%" }}
                  rules={rules}
                >
                  <Input
                    type={"text"}
                    placeholder={RADIO_FORM_FIELDS.streamUrl.placeHolder}
                    size={"large"}
                  />
                </Form.Item>
              </HorizontalContainer>
              <Form.Item label={<HelpText title="Image" />}>
                <UploadMultiple
                  dir="radio"
                  allowImages={1}
                  squareCrop={true}
                  images={image}
                  setImages={(img) => setImage(img?.toString() || "")}
                />
              </Form.Item>
              <Form.Item
                label={<HelpText title="Description" />}
                name={RADIO_FORM_FIELDS.description.key}
                style={{ fontWeight: 600, width: "100%" }}
              >
                <TextArea
                  size={"large"}
                  placeholder={RADIO_FORM_FIELDS.description.placeHolder}
                  rows={4}
                />
              </Form.Item>
              <SwitchWrapper>
                <Form.Item
                  name={RADIO_FORM_FIELDS.published.key}
                  valuePropName="checked"
                >
                  <Switch className="switch" />
                </Form.Item>
                <HelpText title="Published" />
              </SwitchWrapper>
            </Form>
            <div className={"save"}>
              <Button
                shape="round"
                size={"large"}
                onClick={() => {
                  props.history.push("/radio");
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                shape="round"
                icon={<SaveOutlined />}
                size={"large"}
                onClick={form.submit}
              >
                {id !== "new" ? "Save radio" : "Create radio"}
              </Button>
            </div>
          </Form.Provider>
        </StyledForm>
      </Spin>
    </div>
  );
}
