import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Queries, Mutations } from "../../../api";
import { Button, message, Spin, Form } from "antd";
import WidgetForm from "../../../components/WidgetForm";
import { useMutation, useQuery } from "@apollo/client";
import { getSession } from "../../../helpers/SessionManagement";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const HorizontalContainerSB = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const DEFAULT_COLOR = "#5229F4";
const FORM_FIELDS = {
  name: {
    key: "name",
    label: "Name",
    placeHolder: "Radio widget name",
  },
  select: {
    key: "radios",
    label: "Radio Stations",
    placeHolder: "Select radio stations to include",
  },
};

const CreateRadiosList = (p) => {
  let radios = [];
  if (p) {
    p.forEach((p) => {
      radios.push(p.id);
    });
  }
  return radios;
};

const RadioWidget = (props) => {
  const id = props.match.params.id !== "new" ? props.match.params.id : "new";
  const [form] = Form.useForm();
  const [colors, setColors] = useState({
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });

  const [createRadioWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_RADIO_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createRadioWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updateRadioWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_RADIO_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updateRadioWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const { loading: radioWidgetLoading, data: radioWidgetData } = useQuery(
    Queries.RADIO_WIDGET_BY_ID,
    {
      skip: id === "new",
      variables: { id: id },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const { data: radiosData, loading: radiosLoading } = useQuery(
    Queries.ALL_RADIO,
    {
      onError: () => message.error("server error"),
    }
  );

  useEffect(() => {
    if (id === "new") {
      loadColors(
        getSession().organization?.primaryColor || DEFAULT_COLOR,
        getSession().organization?.secondaryColor || DEFAULT_COLOR
      );
    }

    if (id !== "new" && radioWidgetData) {
      initForm(radioWidgetData);
      loadColors(
        radioWidgetData.radioWidgetById.primaryColor,
        radioWidgetData.radioWidgetById.secondaryColor
      );
    }
    // eslint-disable-next-line
  }, [radioWidgetData, id]);

  const loadColors = (primaryColor, secondaryColor) => {
    setColors({
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
    });
  };

  const initForm = (data = {}) => {
    form.setFieldsValue({
      name: data?.radioWidgetById?.name || "",
      radios: CreateRadiosList(data?.radioWidgetById?.radio),
    });
  };

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=radio");
  };

  const handleSubmit = () => {
    const formFields = form.getFieldsValue();
    const variables = {
      name: formFields.name,
      radios: formFields.radios,
      primaryColor: colors.primaryColor,
      secondaryColor: colors.secondaryColor,
    };
    if (id !== "new") {
      updateRadioWidget({ variables: { id: id, ...variables } });
    } else {
      createRadioWidget({ variables });
    }
  };

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Spin
        spinning={
          (id !== "new" && radioWidgetLoading) || createLoading || updateLoading
        }
      >
        <WidgetForm
          form={form}
          colors={colors}
          setColors={setColors}
          isPrimaryColor={true}
          isSecondaryColor={true}
          listImageType={"image"}
          FORM_FIELDS={FORM_FIELDS}
          handleSubmit={handleSubmit}
          selectLoading={radiosLoading}
          selectOptions={radiosData?.radios}
        />

        <HorizontalContainerSB>
          <Button shape="round" size={"large"} onClick={goToListWidgets}>
            Cancel
          </Button>

          <Button
            type="primary"
            shape="round"
            size={"large"}
            onClick={form.submit}
            style={{ marginTop: 16 }}
            icon={false ? <LoadingOutlined /> : <SaveOutlined />}
          >
            {id !== "new" ? "Update widget" : "Create widget"}
          </Button>
        </HorizontalContainerSB>
      </Spin>
    </div>
  );
};
export default RadioWidget;
