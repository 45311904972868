import React, { useState } from "react";
import styled from "styled-components";
import { Modal, Form, Input } from "antd";
import { isValidEmail } from "../../utils";

const ThemeColors = {
    colors: {
        brand: "#1890FF",
        background: "#FFFFFF",
        white: "#FFFFFF",
        lightWhite: "#F0F2F5",
        green: "#008000",
        lightGreen: "#f0fff0",
        red: " #ff0000",
        lightRed: "#ffe4e1",
        black: "#343434",
        grey: "#A0A9BA",
        lightGrey: "#8C8C8C",
        gray: "#BFBFBF",
        gray77: "#C4C4C4",
        gray98: "#FAFAFA",
        grayBorder: "#DADADA",
        gray85: "#D9D9D9",
        polarGreen: "#F6FFED",
        gray9: "#262626",
        lightBlue: "#E6F7FF",
    },
};

const LabelWithLink = styled.div`
  justify-content: flex-start;
  display: flex;
  margin-bottom: 0.3rem;
  line-height: 1.5;
  p {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    .link-text {
      color: ${ThemeColors.colors.brand};
      cursor: pointer;
    }
  }
`;

const ResetPasswordModal = (props) => {
    const [email, setEmail] = useState("");
    const validateMessages = {
        types: {
            email: "The input is not valid E-mail!",
        },
    };
    return (
        <Modal
            destroyOnClose
            title="Forgot Password"
            visible={props.isShow}
            okText={"Reset"}
            onOk={() => {
                props.handleOkClick(email);
            }}
            onCancel={() => {
                props.handleCancelClick();
            }}
        >
            <Form layout={"vertical"} validateMessages={validateMessages}>
                <Form.Item
                    label="Email"
                    name={["email"]}
                    rules={[{type: "email"}]}
                    style={{marginBottom: "1rem"}}
                >
                    <Input
                        type={"email"}
                        style={{height: "40px"}}
                        placeholder="Your email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Item>
            </Form>
            <LabelWithLink>
                <p>
                    We will email a link to your account email to allow you to reset your
                    password. Please enter the email address associated with your account.
                </p>
            </LabelWithLink>
        </Modal>
    );
};

const ResetPasswordSuccessModel = (data) => {
    return Modal.success({
        title: "Password reset email sent",
        content: (
            <LabelWithLink>
                <p>
                    Please check your <span className="link-text">{data}</span> email for
                    a link to reset your password.
                </p>
            </LabelWithLink>
        ),
        okText: "Close",
        onOk() {
        },
    });
};

const InviteProducer = (props) => {
    const [producer, setProducer] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });

    const isDisable = () => {
        if (producer.firstName && producer.lastName && isValidEmail(producer.email))
            return false;
        return true;
    };

    const resetState = () => {
        setProducer({
            firstName: "",
            lastName: "",
            email: "",
        });
    };

    return (
        <Modal
            visible={props.visible}
            title="Invite Producer"
            okText="Invite"
            cancelText="Cancel"
            onCancel={props.onCancel}
            destroyOnClose={true}
            okButtonProps={{disabled: isDisable()}}
            onOk={() => {
                resetState();
                props.onOk(producer.firstName, producer.lastName, producer.email);
            }}
        >
            <Form layout="vertical" name="form_in_modal">
                <Form.Item style={{width: "48%", float: "left"}} label="First Name">
                    <Input
                        title="First Name"
                        placeholder="Producer's First Name"
                        onChange={(e) =>
                            setProducer({...producer, firstName: e.target.value})
                        }
                    />
                </Form.Item>
                <Form.Item style={{width: "48%", float: "right"}} label="Last Name">
                    <Input
                        title="Last Name"
                        placeholder="Producer's Last Name"
                        onChange={(e) =>
                            setProducer({...producer, lastName: e.target.value})
                        }
                    />
                </Form.Item>
                <Form.Item
                    style={{width: "100%"}}
                    name="email"
                    label="Email"
                    rules={[
                        () => ({
                            validator(rule, value) {
                                if (value) {
                                    if (isValidEmail(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject("The input is not valid Email!");
                                } else {
                                    return Promise.reject();
                                }
                            },
                        }),
                    ]}
                >
                    <Input
                        title="Email"
                        placeholder="Producer's Email Address"
                        onChange={(e) =>
                            setProducer({...producer, email: e.target.value})
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export { ResetPasswordModal, ResetPasswordSuccessModel, InviteProducer };
