import React, { useEffect } from "react";
import { Spin, message } from "antd";
import Logo from "../../logo.svg";
import styled from "styled-components";
import { trimErrorMessage } from "./../../utils";
import { getParams } from "../../helpers/URLParams";
import { Queries } from "../../api";
import { useLazyQuery } from '@apollo/client'

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  background: #001529;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  p {
    font-weight: 600;
    font-size: 18px;
    margin-top: 3rem;
    color: #ffffff;
  }
`;

export default function EmailChangeConfirmation(props) {
    const [
        verifyEmailReq,
        {data: verifyEmailChangeData, error: verifyEmailChangeError},
    ] = useLazyQuery(Queries.VERIFY_EMAIL_CHANGE, {
        variables: {token: getParams().token},
        onError: (e) => message.error(trimErrorMessage(e.message)),
    });

    useEffect(() => {
        if (getParams()?.token) verifyEmailReq();
        // eslint-disable-next-line
    }, [getParams()?.token]);

    useEffect(() => {
        if (verifyEmailChangeData) {
            message.success(
                "Email changed successfully. Please login with new email."
            );
            props.history.push("/");
        }
        if (verifyEmailChangeError) {
            props.history.push("/");
        }
        // eslint-disable-next-line
    }, [
        verifyEmailChangeData,
        verifyEmailChangeError
    ]);

    return (
        <Wrapper>
            <img alt="localeyz-logo" src={Logo} height={128}/>
            <Spin style={{marginTop: "3rem"}} size={"large"}/>
        </Wrapper>
    );
}
