import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://aa085c84b9544308ad8d81119c855d2a@o423686.ingest.sentry.io/5354391",
});

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
