import React from "react";
import { Tooltip, Typography } from "antd";
import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";

const Title = styled.p`
  margin: 0px 0.5rem 0px 0px;
`;

const CustomFieldToolTip = ({
                                title,
                                toolTipText,
                                required,
                                preText,
                                tooltips,
                            }) => {
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            {required && (
                <span style={{color: "red", fontSize: 12, marginRight: 6}}>*</span>
            )}
            <Title style={{display: toolTipText ? "inline-flex" : "flex"}}>
                {title}
            </Title>

            {!!toolTipText && (
                <Tooltip
                    title={
                        <Typography.Text
                            copyable={{
                                text: toolTipText,
                                tooltips: tooltips,
                            }}
                            style={{color: "#FFFFFF"}}
                        >
                            {preText || "" + toolTipText}
                        </Typography.Text>
                    }
                >
                    <InfoCircleOutlined/>
                </Tooltip>
            )}
        </div>
    );
};

export default CustomFieldToolTip;
