import React from "react";
import { BarChartComponent, ChartContainer } from "./charts";
import { Typography } from "antd";
import useAxios from "axios-hooks";
import URLS from "../../constants";
import { formatVODdata } from "./chartUtils";

const { Title } = Typography;

export const VODStaffChart = (props) => {
  const [{ data, loading, error }, refetch] = useAxios({
    url: `${URLS.API_ANALYTICS}/api/events`,
    params: props.input,
  });
  const formattedData = data && formatVODdata(data.data, props.formatId);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;
  return (
    <ChartContainer>
      <BarChartComponent
        data={formattedData}
        xAxis={props.xLabel}
        xDataKey={props.xDataKey}
        name={props.title}
      />
    </ChartContainer>
  );
};
