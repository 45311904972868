import React from 'react';
import { Form, Typography, Radio, Select, Spin, Empty, Checkbox } from 'antd';
import styled from 'styled-components';

const {Option} = Select;

const CheckSelectWrapper = styled.div`
  margin-bottom: 27px;
  .playlist-check-text-select.radio {
    margin: 0 0 0 30px;
  }
  .playlist-check-text-select{
    width: fit-content;
    min-width: 400px;
    max-width: 100%;

    @media(max-width: 750px){
      width: 100%;
      min-width: unset;
    }

    @media(max-width: 550px){
      margin: 0;
    }
  }
  .playlist-check-select{
    margin-top: 1rem;
  }
  .playlist-check{
    white-space: initial;
  }
`;

const ConditionalSelect = ({
   selectTitle,
   selectPlaceholder,
   selectName,
   checkValue,
   selectedValue,
   options,
   style,
   onSearchChange,
   isLoading,
   valueOpts = [],
   radio= true
}) => {
    const newOptions = [...options]
    if (valueOpts.length > 0) {
      valueOpts.forEach(valOpt => {
        if (!options.find(opt => opt.id === valOpt.id)) {
          newOptions.push(valOpt)
        }
      })
    }

    return (
        <CheckSelectWrapper style={style}>
          {
            radio &&
              <Radio
                value={checkValue}
                className="playlist-check"
              >
                <Typography.Text>{selectTitle}</Typography.Text>
              </Radio>
          }
          {
            !radio &&
            <Typography.Text>{selectTitle}</Typography.Text>
          }
            <div className={`playlist-check-text-select ${radio ? 'radio' : ''}`}>
                {!!selectName &&
                <Form.Item name={selectName}
                           rules={ radio ? [
                               {
                                   required: selectedValue && selectedValue === checkValue,
                                   message: "This field is required.",
                               }
                           ] : []}
                >
                    <Select
                        className="playlist-check-select"
                        mode="multiple"
                        placeholder={selectPlaceholder}
                        disabled={ radio ? checkValue !== selectedValue : false}
                        onSearch={((query) => onSearchChange(query))}
                        filterOption={() => true}s
                        notFoundContent={isLoading ? <Spin size="small"/> : <Empty/>}
                        loading={isLoading}
                    >
                        {newOptions.map((item) => (
                            <Option key={item.id}>
                                {item.title}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                }
            </div>

        </CheckSelectWrapper>
    );
};
export default ConditionalSelect;