import React from "react";
import { Form, Input } from "antd";
import { Col, Row } from "react-styled-flexboxgrid";

const {TextArea} = Input;
const FormIntroWidgets = (props) => {
    const {form, widgetType} = props;
    return (
        <Form layout={"vertical"} form={form}>
            <Row style={{justifyContent: "space-between"}}>
                <Col lg={5} md={6} sm={12} xs={12}>
                    <Form.Item
                        label={"Title"}
                        name={"title"}
                        rules={[{required: true, message: "This field is required."}]}
                    >
                        <Input
                            type={"text"}
                            placeholder={`Your ${widgetType} form intro title`}
                        />
                    </Form.Item>
                </Col>
                <Col lg={5} md={6} sm={12} xs={12}>
                    <Form.Item
                        label={"Message"}
                        name={"message"}
                        rules={[{required: true, message: "This field is required."}]}
                    >
                        <TextArea
                            placeholder={`Your ${widgetType} form intro message`}
                            autoSize={{minRows: 2, maxRows: 4}}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Form.Item
                        label={"Success message"}
                        name={"successMessage"}
                        rules={[{required: true, message: "This field is required."}]}
                    >
                        <TextArea
                            placeholder={`Your ${widgetType} success message`}
                            autoSize={{minRows: 3, maxRows: 5}}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Form.Item
                        label={"Success email message"}
                        name={"successEmailMessage"}
                        rules={[{required: true, message: "This field is required."}]}
                    >
                        <TextArea
                            placeholder={`Your ${widgetType} success email message`}
                            autoSize={{minRows: 3, maxRows: 5}}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default FormIntroWidgets;
