import React, { useState, useEffect } from "react";
import { Spin, message, Form } from "antd";
import {
  ApolloAuthClient,
  ApolloDataClient,
  Queries,
  Mutations,
} from "../../api";
import Logo from "../../logo.svg";
import { getParams } from "../../helpers/URLParams";
import { ApolloProvider, useMutation } from "@apollo/client";
import {
  trimErrorMessage,
  initialSocialLink,
  hasDigit,
  hasLowerCase,
  hasUpperCase,
  hasSpecialCharacter,
} from "../../utils";
import { UserRole } from "../../utils/contants";
import UserInfoForm from "../../components/UserInfoForm";

const SignUpScreen = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [squareImage, setSquareImage] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [userType, setUserType] = useState(UserRole.STAFF);
  const [redirectUrl, setRedirectUrl] = useState("https://app.localeyz.io/");
  const [socialLinksList, setSocialLinksList] = useState(initialSocialLink);
  const [inviteToken, setInviteToken] = useState(null);

  const [createUser, { data, loading: createLoading }] = useMutation(
    Mutations.USER_SIGN_UP,
    {
      update(cache, { data: { userSignUp: addNewUser } }) {
        let { users } = cache.readQuery({
          query: Queries.ALL_USERS_QUERY,
        });
        users.unshift(addNewUser);
        cache.writeQuery({
          query: Queries.ALL_USERS_QUERY,
          data: { users: users },
        });
      },
    }
  );

  useEffect(() => {
    const callInviteByToken = async () => {
      try {
        setLoading(true);
        const result = await ApolloDataClient.client.query({
          query: Queries.INVITE_BY_TOKEN,
          variables: {
            token: getParams().token,
          },
        });
        setInviteToken(getParams().token);

        if (result.data) {
          message.success(
            `Welcome ${
              result.data.inviteByToken.firstname +
              " " +
              result.data.inviteByToken.lastname +
              ". Please fill the form to join"
            }`
          );
          props.history.push("signup");

          if (result?.data?.inviteByToken?.userType === UserRole.PRODUCER) {
            callProduceSettings(result?.data?.inviteByToken?.organization?.id);
          } else {
            setLoading(false);
          }
          setFormFields(result.data.inviteByToken);
        } else {
          message.error(
            "Your invite has expired, please contact your community media center for a new invite."
          );
          handleFinish();
        }
      } catch (e) {
        setLoading(false);
        if (e.message.includes("Network error")) {
          message.error("Please check your network connection!");
        } else message.error(trimErrorMessage(e.message));
        handleFinish();
      }
    };
    callInviteByToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      message.success("Account Created Successfully");
      if (userType === UserRole.PRODUCER) {
        window.open(checkLink(redirectUrl), "_self");
      } else {
        props.history.push("/");
      }
    }
    // eslint-disable-next-line
  }, [data, props.history]);

  const checkLink = (link) => {
    if (link.includes("http")) return link;
    return `https://${link}`;
  };

  const callProduceSettings = async (id) => {
    try {
      const result = await ApolloDataClient.client.query({
        query: Queries.PRODUCER_SETTING_BY_ORG,
        variables: {
          organizationId: id,
        },
      });
      if (result.data) {
        setRedirectUrl(result.data.producerSettingByOrganization?.redirectUrl);
        setCustomFields(
          result.data.producerSettingByOrganization?.customFields.filter(
            (customField) => !customField.staff
          )
        );

        setLoading(false);
      } else {
        setLoading(false);
        message.error("Unable to get producer settings!");
      }
    } catch (e) {
      setLoading(false);
      if (e.message.includes("Network error")) {
        message.error("Please check your network connection!");
      } else message.error(trimErrorMessage(e.message));
      handleFinish();
    }
  };

  const setFormFields = (data = {}) => {
    setUserType(data?.userType || UserRole.STAFF);
    form.setFieldsValue({
      email: data?.email || "",
      firstname: data?.firstname || "",
      lastname: data?.lastname || "",
      bio: "",
      state: "New York",
      city: "",
      phone: "",
      zipcode: "02130",
      address: "",
      password: "",
      username: "",
      socialMediaLinks: [],
      customFieldValue: [],
      passwordConfirmation: "",
      avatar: "",
      userType: data?.userType || UserRole.STAFF,
    });
  };

  const handleFinish = () => {
    props.history.push("/");
  };

  const onSubmit = async () => {
    const fields = form.getFieldsValue();

    if (
      fields["password"].length > 7 &&
      hasDigit(fields["password"]) &&
      hasLowerCase(fields["password"]) &&
      hasUpperCase(fields["password"]) &&
      hasSpecialCharacter(fields["password"])
    ) {
      let customFieldValue = [];
      if (customFields?.length > 0) {
        customFields.forEach((customField) => {
          customFieldValue.push({
            id: customField.id,
            value:
              fields[customField.id] === undefined
                ? ""
                : JSON.stringify(fields[customField.id]),
          });
        });
      }
      createUser({
        variables: {
          address: fields["address"],
          bio: fields["bio"],
          city: fields["city"],
          customFieldValue,
          email: fields["email"],
          phone: fields["phone"],
          state: fields["state"],
          zipcode: fields["zipcode"],
          userType: userType,
          username: fields["username"],
          token: inviteToken,
          socialMediaLinks: socialLinksList.filter(
            (item) => item.url && delete item.id
          ),
          password: fields["password"],
          passwordConfirmation: fields["password"],
          name: `${fields.firstname} ${fields.lastname}`,
          lastname: fields.lastname,
          firstname: fields.firstname,
          avatar: squareImage,
        },
      }).catch((e) => {
        message.error(trimErrorMessage(e.message));
      });
    } else {
      message.error("Your password does not satisfy the requirements.");
    }
  };
  return (
    <Spin spinning={loading || createLoading}>
      <div style={{ background: "#001529", padding: "2.5rem" }}>
        <img src={Logo} alt={"localeyz-logo"} />
        <UserInfoForm
          form={form}
          onSubmit={onSubmit}
          loading={loading}
          disableUsername={false}
          squareImage={squareImage}
          setSquareImage={setSquareImage}
          socialLinksList={socialLinksList}
          setSocialLinksList={setSocialLinksList}
          disabledFields={{
            firstname: true,
            lastname: true,
            email: true,
            userType: true,
          }}
          customFields={customFields}
          onCancel={handleFinish}
        />
      </div>
    </Spin>
  );
};

export default function SignUpProvider(props) {
  return (
    <ApolloProvider client={ApolloAuthClient.client}>
      <SignUpScreen {...props} />
    </ApolloProvider>
  );
}
