import React from 'react';
import CreateUser from '../../components/CreateUser';
import { ApolloProvider } from '@apollo/client';
import { ApolloAuthClient } from '../../api';

const CreateUserThroughAdmin = (props) => {
    return (
        <ApolloProvider client={ApolloAuthClient.client}>
            <CreateUser
                {...props}
                isModal={true}
            />
        </ApolloProvider>
    )
}
export default CreateUserThroughAdmin;
