import { useCallback, useEffect, useRef, useReducer, useState } from "react";
import { useDatabase } from "./database";
import numeral from "numeral";
import _ from "lodash";
import { format, parseISO } from "date-fns";

export const useEntities = ({
  baseEntity,
  filters = {},
  fields = null,
  staticFilters = {},
}) => {
  const fetchData = useRef(null);
  const { database } = useDatabase();

  const [baseEntityState, setBaseEntity] = useState(baseEntity);
  const [entityResult, setEntityResult] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [quickSearch, setQuickSearch] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const filterStateRef = useRef(null);

  const [filterState, setFilterState] = useReducer(
    (state, action) => {
      if (action?.type === "clear") {
        return Object.entries(filters).reduce((agg, [entityKey]) => {
          agg[entityKey] = [];
          return agg;
        }, {});
      }

      if (action?.type === "replace") {
        return action?.state || {};
      }

      return {
        ...state,
        ...action,
      };
    },
    Object.entries(filters).reduce((agg, [entityKey]) => {
      agg[entityKey] = [];
      return agg;
    }, {})
  );

  filterStateRef.current = filterState;
  const filtersRef = useRef(
    Object.entries(filters).reduce((agg, [entityKey]) => {
      agg[entityKey] = null;
      return agg;
    }, {})
  );

  const fetchEntityResult = useCallback(
    async ({
      skipMainFilters = false,
      additionalFilters = {},
      rawFilters = null,
      offset = null,
      limit = null,
      sort = null,
    } = {}) => {
      try {
        setFetchingData(true);
        const newFilters = { ...filters, ...additionalFilters };
        const filterObj = Object.entries(newFilters).reduce(
          (agg, [filterKey, filter]) => {
            if (typeof filter?.filter === "object") {
              agg._and.push({ ...filter.filter });
            } else if (filterState?.[filterKey] && !skipMainFilters) {
              const val = filterState[filterKey] || [];
              if (filter?.filter && val?.[0]) {
                agg._and.push({ ...filter.filter(val) });
              }
            }

            return agg;
          },
          {
            _and: [],
          }
        );

        const allFilters = {
          ...filterObj,
          ...(staticFilters ? staticFilters : {}),
          ...(rawFilters ? rawFilters : {}),
        };

        console.time("SERVER SIDE LOAD");
        console.log("SERVER SIDE LOAD START");
        console.log({
          meta: "*",
          fields: fields || "*.*",
          ...(Object.keys(allFilters || {}).length > 0
            ? {
                filter: allFilters,
              }
            : {}),
          ...(quickSearch ? { search: quickSearch } : {}),
          ...(offset ? { offset } : {}),
          ...(limit ? { limit } : {}),
          ...(sort ? { sort } : {}),
        });

        const entities = await database.items(baseEntityState).readMany({
          meta: "*",
          fields: fields || "*.*",
          ...(Object.keys(allFilters || {}).length > 0
            ? {
                filter: allFilters,
              }
            : {}),
          ...(quickSearch ? { search: quickSearch } : {}),
          ...(offset ? { offset } : {}),
          ...(limit ? { limit } : {}),
          ...(sort ? { sort } : {}),
        });

        console.timeEnd("SERVER SIDE LOAD");
        setEntityResult({ rows: entities?.data || [], meta: entities?.meta });
        setFetchingData(false);
        return {
          rows: entities?.data || [],
          meta: entities?.meta,
          setEntityResult,
        };
      } catch (e) {
        setFetchingData(false);

        // eslint-disable-next-line no-console
        console.log(e);
        return { rows: [], meta: null, error: e.message, setEntityResult };
      }
    },
    // eslint-disable-next-line
    [baseEntityState, database, filterState, quickSearch, staticFilters]
  );

  useEffect(() => {
    fetchData.current = fetchEntityResult;
  }, [fetchEntityResult]);

  return {
    setBaseEntity,
    entityResult,
    filtersRef,
    filterState,
    filterStateRef,
    setFilterState,
    fetchEntityResult,
    fetchingData,
    fetchData,
    setQuickSearch,
    quickSearch,
    setInitialized,
    initialized,
  };
};

export const useEntityTable = ({ baseEntity, staticFilters }) => {
  const { database, loading } = useDatabase();
  const [baseEntityState, setBaseEntity] = useState(baseEntity);
  const cache = useRef({});
  const optionsCache = useRef({});

  const getColumnAttributes = useCallback(
    async ({
      field,
      entity = null,
      isCurrency = false,
      aggFunc = null,
      filter = null,
      dateFormat = "MM/dd/yyyy",
      noFilters = false,
    }) => {
      let fields;
      if (entity) {
        if (cache.current?.[entity]) {
          fields = cache.current?.[entity];
        } else {
          const res = await database.fields.readMany(entity);
          cache.current[entity] = res;
          fields = res;
        }
      } else if (cache.current?.[baseEntityState]) {
        fields = cache.current?.[baseEntityState];
      } else {
        const res = await database.fields.readMany(baseEntityState);
        cache.current[baseEntityState] = res;
        fields = res;
      }

      const fieldInfo = (fields || []).find((f) => f?.field === field);
      if (!fieldInfo) return {};

      if (filter === "agSetColumnFilter") {
        return {
          filter: "agSetColumnFilter",
          filterParams: {
            values: async (params) => {
              let items = [];
              const res = await database
                .items(entity || baseEntityState)
                .readMany({
                  fields: [fieldInfo?.field],
                });

              if (res?.data?.[0]) {
                items = res.data.map((i) => i?.[fieldInfo?.field]);
              }

              params.success(items);
            },
          },
        };
      }

      const optionsCacheKey = `${entity || baseEntityState}:${
        fieldInfo?.field
      }`;
      switch (fieldInfo?.type) {
        case "boolean":
          return {
            filter: "agSetColumnFilter",
            filterParams: {
              valueFormatter: (params) => {
                if (parseInt(params?.value) === 1) {
                  return "Yes";
                }

                return "No";
              },
              values: [1, 0],
            },
            valueFormatter: (params) => {
              if (params?.value) {
                return "Yes";
              }

              return "No";
            },
            valueGetter: (params) => {
              const colId = params.column.getId();
              const val = _.get(params?.data || {}, colId);
              return !!val;
            },
          };

        case "integer":
        case "float":
          return {
            filter: "agNumberColumnFilter",
            ...(aggFunc
              ? {
                  aggFunc,
                }
              : {}),
            ...(isCurrency
              ? {
                  valueFormatter: (params) =>
                    numeral(params?.value).format("$0,0[.]00"),
                }
              : {}),
          };

        case "timestamp":
        case "dateTime":
          return {
            filter: "agDateColumnFilter",
            valueGetter: (params) => {
              const colId = params.column.getId();
              const val = _.get(params?.data || {}, colId);
              if (!val) {
                return "";
              }

              return parseISO(val);
            },
            valueFormatter: (params) => {
              if (!params?.value) {
                return "";
              }

              return format(params?.value, dateFormat);
            },
          };

        default:
          return {
            filterParams: {
              comparator: (a, b) => {
                const aVal = isNaN(a) ? a : parseInt(a);
                const bVal = isNaN(b) ? b : parseInt(b);

                let foundOptionA = (
                  optionsCache.current?.[optionsCacheKey] || []
                ).find((o) => o?.[fieldInfo?.field] === aVal);

                let foundOptionB = (
                  optionsCache.current?.[optionsCacheKey] || []
                ).find((o) => o?.[fieldInfo?.field] === bVal);

                if (foundOptionA) {
                  foundOptionA = foundOptionA?.[fieldInfo?.field];
                } else {
                  foundOptionA = "zzzzz";
                }

                if (foundOptionB) {
                  foundOptionB = foundOptionB?.[fieldInfo?.field];
                } else {
                  foundOptionB = "zzzzz";
                }

                if (
                  !isNaN(foundOptionA) ||
                  !isNaN(foundOptionB) ||
                  !foundOptionA
                ) {
                  return foundOptionA - foundOptionB;
                }

                return foundOptionA.localeCompare(foundOptionB, "en", {
                  numeric: true,
                  ignorePunctuation: true,
                });
              },
              valueFormatter: (params) => {
                const val = isNaN(params?.value)
                  ? params?.value
                  : parseInt(params?.value);

                const foundOption = (
                  optionsCache.current?.[optionsCacheKey] || []
                ).find((o) => {
                  if (!isNaN(val)) {
                    return parseInt(o?.[fieldInfo?.field]) === val;
                  }

                  return o?.[fieldInfo?.field] === val;
                });

                if (foundOption) {
                  return foundOption?.[fieldInfo?.field];
                }

                return "-";
              },
              values: async (params) => {
                let items = [];
                const res = await database
                  .items(entity || baseEntityState)
                  .readMany({
                    limit: -1,
                    fields: ["id", fieldInfo?.field],
                    ...(!noFilters && staticFilters
                      ? {
                          filter: { ...staticFilters },
                        }
                      : {}),
                  });

                if (res?.data?.[0]) {
                  optionsCache.current[optionsCacheKey] = res.data;
                  items = res.data.map((i) => i?.[fieldInfo?.field]);
                }

                params.success(items);
              },
            },
          };
      }
    },
    // eslint-disable-next-line
    [baseEntityState, database]
  );

  return { getColumnAttributes, setBaseEntity, loading };
};
