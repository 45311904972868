import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { debounce } from "../../../utils";
import Queries from "../../../api/Queries";
import Mutations from "../../../api/Mutations";
import { useMutation, useQuery } from "@apollo/client";
import { defaultVisibleFields, defaultGroupColumns } from "./columns";
import { Button, Spin, message, Input, Menu, Dropdown } from "antd";
import { PAGINATION_COUNTS, SEARCH_DELAY } from "./../../../utils/contants";
import DeleteConfirm from "../../../components/DeleteConfirm/DeleteConfirm";
import { PlusOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import PaginatedTableview from "../../../components/paginatedTableView";
import { useApolloClient } from "@apollo/client";

const PAGINATION_COUNT = PAGINATION_COUNTS.GROUPS;

const Container = styled.div`
  display: block;
  background: #ffffff;
  padding: 24px;
  .ant-table-wrapper {
    @media screen and (max-width: 700px) {
      max-width: 87.5%;
      padding-top: 5rem;
    }
  }
  .ant-input-affix-wrapper {
    margin-bottom: 20px;
    width: 16rem;
    color: lightgray;
  }
  .ant-input-search ant-input-affix-wrapper ant-input {
    width: 20rem;
  }
  .new-group {
    position: absolute;
    right: 130px;

    @media screen and (max-width: 700px) {
      left: 7rem;
      margin-top: 6rem;
    }

    @media screen and (max-width: 360px) {
      left: 7rem;
      margin-top: 3rem;
    }
  }
  .ant-table-content {
    overflow-x: auto;
  }
`;

const ActionsMenuStyled = styled.div`
  display: inline-block;
  width: 100%;
  .ant-btn.ant-dropdown-trigger {
    float: right;
    margin-top: -3rem;
    @media screen and (max-width: 411px) {
      margin-top: 0rem;
      float: none;
    }
  }
`;

export default function Groups(props) {
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [pageSize, setPageSize] = useState(PAGINATION_COUNTS.default);
  const onChangeSearch = (str) => {
    setSearchString(str);
  };
  const debounceOnChange = useCallback(
    debounce(onChangeSearch, SEARCH_DELAY),
    []
  );
  const localClient = useApolloClient();
  const cachedData = localClient.cache.readQuery({
    query: Queries.GET_PAGE_NUMBER,
  });
  const {
    loading: groupsLoading,
    error: groupsError,
    data: groupsData,
    refetch: refetchGroups,
    fetchMore: fetchMoreGroups,
  } = useQuery(Queries.GROUPS_PAGINATION, {
    variables: {
      page: cachedData?.pageNumber || 1,
      query: searchString,
      per: pageSize,
    },
    fetchPolicy: "network-only",
  });

  const [deleteGroups, { error: deleteError, data: deleteData }] = useMutation(
    Mutations.DELETE_GROUPS
  );

  useEffect(() => {
    if (deleteData) {
      refetchGroups();
      message.success("Groups deleted successfully");
    }
    // eslint-disable-next-line
  }, [deleteData]);

  useEffect(() => {
    if (deleteError || groupsError) {
      message.error("server error");
    }
  }, [deleteError, groupsError]);

  const onClickAddNew = () => {
    props.history.push("/users/groups/new");
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedRowKeys,
  };

  const handleDelete = () => {
    deleteGroups({
      variables: {
        ids: selectedRowKeys,
      },
    });
    setSelectedRowKeys([]);
  };

  const onPageChange = (page) => {
    if (groupsData?.groups?.metadata?.currentPage !== page) {
      setFetchMoreLoading(true);
      localClient.writeQuery({
        query: Queries.GET_PAGE_NUMBER,
        data: {
          pageNumber: page.current,
        },
      });
      fetchMoreGroups({
        variables: {
          page: page.current,
          query: searchString,
          per: PAGINATION_COUNT,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      }).finally(() => setFetchMoreLoading(false));
    }
  };

  return (
    <Container>
      <ActionsMenuStyled>
        <Input
          allowClear
          placeholder="Search groups"
          prefix={<SearchOutlined />}
          onChange={(e) => {
            if (e.target.value.trim() === "" && searchString.length === 0)
              return;
            debounceOnChange(e.target.value);
          }}
        />
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size={"medium"}
          onClick={onClickAddNew}
          className={"new-group"}
          style={{ marginRight: "1rem" }}
        >
          Create a new group
        </Button>
        <ActionsMenu
          selectedRowKeys={selectedRowKeys}
          onDelete={() => handleDelete()}
          groupsData={
            groupsData?.groupsConnection?.nodes.length > 0
              ? groupsData?.groupsConnection?.nodes
              : []
          }
        />
      </ActionsMenuStyled>

      <PaginatedTableview
        loading={groupsLoading || fetchMoreLoading}
        withPopover={false}
        columns={defaultGroupColumns()}
        selectedRows={selectedRowKeys}
        onTableChange={onPageChange}
        onRowSelection={rowSelection}
        onResetColumns={defaultVisibleFields}
        defaultVisibleFields={defaultVisibleFields}
        data={groupsData?.groups?.nodes || []}
        pageSize={pageSize}
        totalCount={groupsData?.groups?.metadata?.totalCount}
        current={groupsData?.groups?.metadata?.currentPage || 1}
        onShowSizeChange={(current, size) => setPageSize(size)}
        pageSizeOptions={["5", "10", "20", "50"]}
        showSizeChanger={true}
      />
    </Container>
  );
}

const formatGroupNames = (selectedRowKeys, groupsData) => {
  const l = [];
  groupsData.forEach((g) => {
    if (selectedRowKeys.includes(g.id)) {
      l.push(g.name);
    }
  });
  return l.reduce(
    (acc, curr, i) =>
      i !== 0 ? acc + `${l.length - 1 === i ? ` and  ` : ", "}` + curr : curr,
    ""
  );
};

const ActionsMenu = (props) => {
  const { onDelete, selectedRowKeys, groupsData } = props;

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <DeleteConfirm
          action={() => {
            onDelete();
          }}
          bypass={true}
          message={`Are you sure you want to groups ${formatGroupNames(
            selectedRowKeys,
            groupsData
          )}?`}
        >
          Delete
        </DeleteConfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <Spin spinning={false}>
      <Dropdown overlay={menu} disabled={!selectedRowKeys.length}>
        <Button>
          Action <DownOutlined />
        </Button>
      </Dropdown>
    </Spin>
  );
};
