import { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { Directus } from "@directus/sdk";
import { deleteSession } from "../helpers/SessionManagement";
import { Transport } from "../utils/transport";
import { clearClientCache } from "../api";

let databaseInstance = null;

export const useDatabase = () => {
  const [loading, setLoading] = useState(!databaseInstance);
  const handleSignOut = useCallback(async () => {
    message.success(`See you next time`);

    deleteSession();
    clearClientCache();
    if (localStorage) {
      localStorage.removeItem("auth_token");
    }

    // session && setAuth(false);
  }, []);

  const query = useCallback(async (queryReq) => {
    setLoading(true);
    const res = await axios.post("/api/knex", {
      query: queryReq,
    });

    setLoading(false);
    return {
      rows: res?.data || [],
      meta: {
        total_count: res?.data?.length,
        filter_count: res?.data?.length,
      },
    };
  }, []);

  useEffect(() => {
    async function init() {
      try {
        if (!databaseInstance) {
          setLoading(true);
          const transport = new Transport({
            url: process?.env?.REACT_APP_DIRECTUS_ENDPOINT,
            handleLogout: handleSignOut,
          });

          databaseInstance = new Directus(
            process?.env?.REACT_APP_DIRECTUS_ENDPOINT,
            {
              transport,
            }
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        if ([403, 401].includes(e?.response?.status)) {
          // console.log(router)
          // await signOut({ redirect: false })
          // await router.push(
          //   `/login${router?.asPath ? `?redirect=${router?.asPath}` : ''}`
          // )
        }
      }
    }

    init();
  }, [handleSignOut]);

  return { database: databaseInstance, query, loading };
};
