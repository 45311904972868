import React, { useState } from "react";
import styled from "styled-components";
import { Col, Row } from "react-styled-flexboxgrid";
import { Form, Select, Typography, Radio, Input } from "antd";

const {Option} = Select;

const PopUpLogicRadioGroup = styled(Radio.Group)`
  width: fit-content;
  flex-direction: column;
  display: flex;
  border-left: 1px solid #d9d9d9;
  padding: 5px 0 5px 2rem;

  @media (max-width: 550px) {
    padding-left: 10px !important;
  }
  .ant-input {
    padding-right: 0px;
  }
  .popup-logic-check {
    white-space: initial;
    align-items: center;
  }
  .popup-logic-interval {
    display: flex;
  }
  .ant-input-group-addon {
    border: none;
    background: transparent;
    padding: 0 11px 0 0;
  }
  #popupDelayHours {
    margin-right: 11px;
    width: 72px;
  }
`;

const WidgetTypeSelect = (props) => {
    const {form, TYPES, POPUP_TYPES} = props;
    const [, setFormChanged] = useState(false);
    return (
        <Form layout={"vertical"} form={form}>
            <Row>
                <Col lg={3} md={3} sm={4} xs={12}>
                    <Form.Item
                        name={"type"}
                        label={"Type"}
                        rules={[{required: true, message: "This field is required."}]}
                        style={{
                            marginTop: 0,
                            marginBottom:
                                form.getFieldValue("type") === TYPES.POP_UP.value ? 0 : 24,
                        }}
                    >
                        <Select
                            onChange={() => setFormChanged((prev) => !prev)}
                            placeholder="Type of subscription widget"
                        >
                            {Object.values(TYPES).map((item, index) => (
                                <Option key={index} value={item.value}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {form.getFieldValue("type") === TYPES.POP_UP.value && (
                        <Form.Item
                            name="popUpLogic"
                            label={"Pop-up logic"}
                            style={{marginTop: 18}}
                        >
                            <PopUpLogicRadioGroup
                                onChange={() => setFormChanged((prev) => !prev)}
                            >
                                <Radio
                                    value={POPUP_TYPES.ONE_TIME.value}
                                    className="popup-logic-check"
                                >
                                    <Typography.Text>
                                        {POPUP_TYPES.ONE_TIME.label}
                                    </Typography.Text>
                                </Radio>
                                <Radio
                                    value={POPUP_TYPES.EVERY_VISIT.value}
                                    className="popup-logic-check"
                                >
                                    <Typography.Text>
                                        {POPUP_TYPES.EVERY_VISIT.label}
                                    </Typography.Text>
                                </Radio>
                                <Radio
                                    value={POPUP_TYPES.HOURLY_BASIS.value}
                                    className="popup-logic-check popup-logic-interval"
                                >
                                    <Form.Item
                                        rules={[
                                            {
                                                required:
                                                    form.getFieldValue("popUpLogic") ===
                                                    POPUP_TYPES.HOURLY_BASIS.value
                                                        ? true
                                                        : false,
                                                message: "This field is required.",
                                            },
                                            () => ({
                                                validator(rule, value) {
                                                    if (value >= 1) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject("Please enter a valid number.");
                                                },
                                            }),
                                        ]}
                                        style={{margin: "0"}}
                                        name="popupDelayHours"
                                    >
                                        <Input
                                            addonBefore={POPUP_TYPES.HOURLY_BASIS.label}
                                            addonAfter="Hours"
                                            type="number"
                                            min={1}
                                        />
                                    </Form.Item>
                                </Radio>
                            </PopUpLogicRadioGroup>
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default WidgetTypeSelect;
