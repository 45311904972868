import React from "react";
import ColorsPicker from "../ColorsPicker/index";
import { Input, Form, Select, Avatar } from "antd";

const {Option} = Select;

const WidgetForm = (props) => {
    const {
        form,
        colors,
        setColors,
        FORM_FIELDS,
        handleSubmit,
        selectOptions,
        selectLoading,
        isPrimaryColor,
        isSecondaryColor,
        isBackgroundColor,
        isTextColor,
    } = props;
    return (
        <Form.Provider onFormFinish={handleSubmit} scrollToFirstError>
            <Form form={form} layout={"vertical"}>
                <Form.Item
                    label={FORM_FIELDS.name.label}
                    name={FORM_FIELDS.name.key}
                    style={{fontWeight: 600, marginBottom: 18}}
                    rules={[{required: true, message: "This field is required."}]}
                >
                    <Input type={"text"} placeholder={FORM_FIELDS.name.placeHolder}/>
                </Form.Item>
                <Form.Item
                    label={FORM_FIELDS.select.label}
                    name={FORM_FIELDS.select.key}
                    style={{fontWeight: 600, marginBottom: 18}}
                    rules={[{required: true, message: "This field is required."}]}
                >
                    <Select
                        mode="multiple"
                        loading={selectLoading}
                        placeholder={FORM_FIELDS.select.placeHolder}
                    >
                        {selectOptions?.map((item) => (
                            <Option key={item.id} value={item.id}>
                                <Avatar
                                    src={item.image}
                                    size={16}
                                    style={{marginBottom: "4px", marginRight: "4px"}}
                                />
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {FORM_FIELDS.dropdown ?
                    <Form.Item
                        label={FORM_FIELDS.dropdown.label}
                        name={FORM_FIELDS.dropdown.key}
                        style={{fontWeight: 600, marginBottom: 18}}
                        rules={[{required: true, message: "This field is required."}]}
                    >
                        <Select style={{fontWeight: 400}} placeholder={FORM_FIELDS.dropdown.placeHolder}>
                            {FORM_FIELDS.dropdown.options?.map((item) => (
                                <Option style={{fontWeight: 400}} key={item.id} value={item.id}>
                                    {item.value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item> : <></>
                }
            </Form>
            <ColorsPicker
                colors={colors}
                setColors={setColors}
                isPrimaryColor={isPrimaryColor}
                isSecondaryColor={isSecondaryColor}
                isBackgroundColor={isBackgroundColor}
                isTextColor={isTextColor}
            />
        </Form.Provider>
    );
};

export default WidgetForm;
