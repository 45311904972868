import * as React from 'react';
import URLS from './../../constants';
import { Voyager } from 'graphql-voyager';
import "graphql-voyager/dist/voyager.css";

function introspectionProvider(query) {
    return fetch(URLS.API_BASE_URL + 'graphql', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({query: query}),
    }).then(response => response.json());
}

export default function ApiDocs(props) {
    return (
        <div style={{padding: 24, background: '#fff'}}>
            <Voyager
                introspection={introspectionProvider}
                workerURI={process.env.PUBLIC_URL + '/voyager.worker.js'}
            />
        </div>

    )
}
