import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SaveOutlined } from "@ant-design/icons";
import { Mutations, Queries } from "../../../api";
import { Col, Row } from "react-styled-flexboxgrid";
import { useMutation, useQuery } from "@apollo/client";
import ColorsPicker from "../../../components/ColorsPicker";
import { Button, Select, Input, message, Spin, Form } from "antd";
import { trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const { Option } = Select;

const RootContainer = styled.div`
  padding: 24px;
  background: #ffffff;
  .ant-select-selection-placeholder {
    font-weight: 400;
    font-size: 14px;
  }
  .ant-select-selection-item {
    font-weight: 300;
  }
  .ant-form-item-label {
    text-align: start;
  }
`;

const DisplayHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DEFAULT_COLOR = "#5229F4";
const VideoOnDemandWidget = (props) => {
  const [form] = Form.useForm();
  const [colors, setColors] = useState({
    bgColor: DEFAULT_COLOR,
    textColor: DEFAULT_COLOR,
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });
  const [widgetType, setWidgetType] = useState();
  const id = props.match.params.id === "new" ? false : props.match.params.id;

  const [createVodWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_VOD_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createVodWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updateVodWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_VOD_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updateVodWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const {
    loading: vodWidgetLoading,
    error: vodWidgetError,
    data: vodWidgetData,
  } = useQuery(Queries.VOD_WIDGET_BY_ID, {
    skip: !id,
    variables: { id: id },
  });

  const initForm = (data = {}) => {
    form.setFieldsValue({
      name: data?.name || "",
      type: data?.widgetType || "",
      sidebarStream: data?.sidebarStream || "",
    });
  };

  useEffect(() => {
    if (id && vodWidgetData) {
      const vodWidget = vodWidgetData.vodWidgetById;
      initForm(vodWidget);
      setWidgetType(vodWidget.widgetType);
      setColors({
        bgColor: vodWidget.bgColor,
        textColor: vodWidget.textColor,
        primaryColor: vodWidget.primaryColor,
        secondaryColor: vodWidget.secondaryColor,
      });
    }
    if (vodWidgetError) return message.error(vodWidgetError.message);
    // eslint-disable-next-line
  }, [vodWidgetData, vodWidgetError]);

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=video-on-demand");
  };

  const handleSubmit = () => {
    const fields = form.getFieldsValue();
    const variables = {
      name: fields.name,
      bgColor: colors.bgColor,
      widgetType: fields.type,
      textColor: colors.textColor,
      primaryColor: colors.primaryColor,
      secondaryColor: colors.secondaryColor,
      sidebarStream: fields.sidebarStream || "related_episodes",
    };
    if (id) {
      updateVodWidget({ variables: { id: id, ...variables } });
    } else {
      createVodWidget({ variables: variables });
    }
  };

  return (
    <RootContainer>
      <Spin
        spinning={
          (id && (vodWidgetLoading || updateLoading)) || (!id && createLoading)
        }
      >
        <Form.Provider onFormFinish={() => handleSubmit()}>
          <Form form={form} layout={"vertical"}>
            <Row>
              <Col lg={4} md={6} sm={8} xs={12}>
                <Form.Item
                  label={"Name"}
                  name="name"
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 18,
                  }}
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Input type={"text"} placeholder="Name of widget" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={6} sm={8} xs={12}>
                <Form.Item
                  name="type"
                  label={"Type"}
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 18,
                  }}
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    size={"large"}
                    style={{ width: "100%" }}
                    onChange={(e) => setWidgetType(e)}
                    placeholder={"Select type of widget"}
                  >
                    <Option key={"program"}>Programs</Option>
                    <Option key={"episode"}>Episodes</Option>
                    <Option key={"program_episode"}>
                      Programs and Episodes
                    </Option>
                    <Option key={"producer"}>Producers</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <ColorsPicker
            colors={colors}
            isTextColor={true}
            setColors={setColors}
            isPrimaryColor={true}
            isSecondaryColor={true}
            isBackgroundColor={true}
          />

          {(widgetType === "episode" || widgetType === "program_episode") && (
            <Row>
              <Col lg={4} md={6} sm={8} xs={12}>
                <Form form={form} layout={"vertical"}>
                  <Form.Item
                    name="sidebarStream"
                    label={"Sidebar stream"}
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      marginBottom: 18,
                    }}
                    rules={[
                      { required: true, message: "This field is required." },
                    ]}
                  >
                    <Select
                      size={"large"}
                      placeholder={"Select sidebar stream"}
                    >
                      <Option
                        key={"related_episodes"}
                        value={"related_episodes"}
                      >
                        Related episodes
                      </Option>
                      <Option key={"recent_episodes"} value={"recent_episodes"}>
                        Most recent episodes
                      </Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )}
          <DisplayHorizontal>
            <Button shape="round" size={"large"} onClick={goToListWidgets}>
              Cancel
            </Button>

            <Button
              shape="round"
              type="primary"
              size={"large"}
              icon={<SaveOutlined />}
              onClick={form.submit}
            >
              {id ? "Save widget" : "Create widget"}
            </Button>
          </DisplayHorizontal>
        </Form.Provider>
      </Spin>
    </RootContainer>
  );
};

export default VideoOnDemandWidget;
