import React, { useState, useCallback } from "react";
import HelpText from "./../../../components/HelpText";
import {
  Button,
  Input,
  Form,
  Switch,
  Layout,
  message,
  Spin,
  Radio,
  Space,
} from "antd";

import styled from "styled-components";
import { ThemeColors } from "../../../theme";
import { SaveOutlined } from "@ant-design/icons";
import { Queries, Mutations } from "../../../api";
import { useQuery, useMutation } from "@apollo/client";
import { PLAYLIST_TYPE, SEARCH_DELAY } from "../../../utils/contants";
import ConditionalSelect from "../../../components/ConditionalSelect";
import { debounce, trimErrorMessage, undefOrNull } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const { TextArea } = Input;
const { Footer } = Layout;

const Wrapper = styled.div`
  padding: 60px;
  background: ${ThemeColors.colors.white};

  @media (max-width: 550px) {
    padding: 15px;
  }
`;

const TopBoxContainer = styled.div`
  width: 50vw;
`;

const PublishContainer = styled.div`
  margin: 0 0 3rem 0;
  display: flex;
  align-items: center;

  .playlist-publish-switch {
    margin: 2rem 1rem 10px 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  .playlist-submit-btn {
    @media (max-width: 450px) {
      margin-top: 20px;
    }
  }
`;

const BoxContainer = styled.div`
  display: block;
  padding-left: 2rem;
  margin-top: 2rem;
  border-left: 1px solid #d9d9d9;

  @media (max-width: 550px) {
    padding-left: 10px;
  }
`;

const defaultPlaylistState = {
  title: "",
  description: "",
  playlistType: PLAYLIST_TYPE.FILTERS,
  publishOnApp: true,
  filterType: "OR",
  episodes: [],
  programs: [],
  tags: [],
  topics: [],
};

const PlaylistForm = (props) => {
  const passedPlaylistId = props.match.params.id;

  const [form] = Form.useForm();
  const [queryProgram, setQueryProgram] = useState();
  const [queryEpisode, setQueryEpisode] = useState();
  const [playlist, setPlaylist] = useState(null);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [selectedType, setSelectedType] = useState(PLAYLIST_TYPE.FILTERS);

  const goToPlaylists = () => props.history.push("/playlist");

  const [createPlaylist, { loading: creatingPlaylist }] = useMutation(
    Mutations.CREATE_PLAYLIST,
    {
      update(cache, { data: { createPlaylist } }) {
        let currentPlayLists = [];
        try {
          const { playlists } = cache.readQuery({
            query: Queries.GET_ALL_PLAYLISTS,
          });
          currentPlayLists = playlists || [];
          cache.writeQuery({
            query: Queries.GET_ALL_PLAYLISTS,
            data: {
              playlists: [createPlaylist, ...currentPlayLists],
            },
          });
        } catch (err) {
          //ignore
        }
        cache.writeQuery({
          query: Queries.GET_PLAYLIST_BY_ID,
          data: { playlistById: createPlaylist },
          variables: {
            id: createPlaylist,
          },
        });
        message.success(
          "Playlist '" + createPlaylist.title + "' created successfully."
        );
        goToPlaylists();
      },
    }
  );

  const [updatePlaylist, { loading: updatingPlaylist }] = useMutation(
    Mutations.UPDATE_PLAYLIST,
    {
      update(cache, { data: { updatePlaylist } }) {
        let currentPlayLists = [];
        try {
          const { playlists } = cache.readQuery({
            query: Queries.GET_ALL_PLAYLISTS,
          });
          currentPlayLists = playlists || [];
          currentPlayLists.forEach((playlist, i) => {
            if (playlist?.id && playlist.id === updatePlaylist.id) {
              currentPlayLists[i] = updatePlaylist;
            }
          });
          cache.writeQuery({
            query: Queries.GET_ALL_PLAYLISTS,
            data: { playlists: currentPlayLists },
          });
        } catch (err) {
          //ignore
          console.log(err);
        }
        cache.writeQuery({
          query: Queries.GET_PLAYLIST_BY_ID,
          data: { playlistById: updatePlaylist },
          variables: {
            id: updatePlaylist.id,
          },
        });
        message.success(
          "Playlist '" + updatePlaylist.title + "' updated successfully."
        );
        goToPlaylists();
      },
    }
  );

  const { loading: fetchingPlaylistDetail } = useQuery(
    Queries.GET_PLAYLIST_BY_ID,
    {
      variables: {
        id: passedPlaylistId,
      },
      skip: !!!passedPlaylistId,
      onCompleted: (data) => {
        const playlistToUpdate = data?.playlistById;
        form.setFieldsValue({
          title: playlistToUpdate?.title || defaultPlaylistState.title,
          filterType:
            playlistToUpdate?.filterType || defaultPlaylistState.filterType,
          description:
            playlistToUpdate?.description || defaultPlaylistState.description,
          publishOnApp: undefOrNull(playlistToUpdate?.publishOnApp)
            ? defaultPlaylistState.publishOnApp
            : playlistToUpdate?.publishOnApp,
          episodes:
            playlistToUpdate?.episodes?.map((item) => item.id) ||
            defaultPlaylistState.episodes,
          programs:
            playlistToUpdate?.programs?.map((item) => item.id) ||
            defaultPlaylistState.programs,
          tags:
            playlistToUpdate?.tags?.map((item) => item.id) ||
            defaultPlaylistState.tags,
          topics:
            playlistToUpdate?.topics?.map((item) => item.id) ||
            defaultPlaylistState.topics,
        });

        setSelectedType(
          playlistToUpdate?.playlistType || defaultPlaylistState.playlistType
        );

        setPlaylist(playlistToUpdate);
      },
      onError: (err) => {
        message.error(trimErrorMessage(err.message));
        goToPlaylists();
      },
    }
  );

  const { data: episodesData, loading: episodesLoading } = useQuery(
    Queries.EPISODES_FOR_DROPDOWN,
    {
      variables: {
        episodeQuery: queryEpisode,
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const { loading: programsLoading, data: programsData } = useQuery(
    Queries.PROGRAMS_FOR_DROPDOWN,
    {
      variables: {
        programQuery: queryProgram,
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const { loading: tagsLoading, data: tagsData } = useQuery(
    Queries.GET_ALL_USER_TAGS,
    {
      onError: (err) => message.error(trimErrorMessage(err.message)),
      onCompleted: (data) => setFilteredTags(data?.tags || []),
    }
  );

  const { loading: topicsLoading, data: topicsData } = useQuery(
    Queries.GET_ALL_TOPICS,
    {
      onError: (err) => message.error(trimErrorMessage(err.message)),
      onCompleted: (data) => setFilteredTopics(data?.topics || []),
    }
  );

  const filterTags = (query) =>
    setFilteredTags(
      tagsData?.tags?.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      ) || []
    );

  const filterTopics = (query) =>
    setFilteredTopics(
      topicsData?.topics?.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      ) || []
    );

  const onProgramSearch = (str) => setQueryProgram(str);

  const debounceOnProgramQuery = useCallback(
    debounce(onProgramSearch, SEARCH_DELAY),
    []
  );

  const onEpisodeSearch = (str) => setQueryEpisode(str);

  const debounceOnEpisodeQuery = useCallback(
    debounce(onEpisodeSearch, SEARCH_DELAY),
    []
  );

  const TopBoxesContainer = () => {
    return (
      <TopBoxContainer>
        <HelpText required title={"Title"} />
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: "This field is required.",
            },
          ]}
        >
          <Input size={"large"} placeholder={"Title of playlist"} />
        </Form.Item>
        <HelpText title={"Description"} />
        <Form.Item name="description">
          <TextArea
            size={"large"}
            placeholder={"Description of playlist"}
            rows={6}
          />
        </Form.Item>
        <HelpText title={"Filters"} />

        <Form.Item name="filterType">
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={"OR"}>
                Episodes must meet at least one filter requirement
              </Radio>
              <Radio value={"AND"}>
                Episodes must meet all filter requirements
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </TopBoxContainer>
    );
  };

  const BoxesContainer = () => {
    return (
      <>
        <BoxContainer>
          <Form.Item
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <ConditionalSelect
              selectTitle="Specific Episodes"
              selectPlaceholder="Search by specific episodes"
              selectName="episodes"
              selectedValue={selectedType}
              checkValue={PLAYLIST_TYPE.SPECIFIC_EPISODES}
              options={episodesData?.episodes?.nodes || []}
              valueOpts={playlist?.episodes || []}
              onSearchChange={debounceOnEpisodeQuery}
              isLoading={episodesLoading}
              radio={false}
            />
            <ConditionalSelect
              selectTitle="By programs"
              selectPlaceholder="Search by programs"
              selectName="programs"
              selectedValue={selectedType}
              checkValue={PLAYLIST_TYPE.BY_PROGRAMS}
              options={programsData?.programs?.nodes || []}
              valueOpts={playlist?.programs || []}
              onSearchChange={debounceOnProgramQuery}
              isLoading={programsLoading}
              radio={false}
            />
            <ConditionalSelect
              selectTitle="By Topics"
              selectPlaceholder="Search by topics"
              selectName="topics"
              checkValue={PLAYLIST_TYPE.BY_TOPICS}
              options={filteredTopics}
              selectedValue={selectedType}
              onSearchChange={filterTopics}
              isLoading={topicsLoading}
              radio={false}
            />
            <ConditionalSelect
              selectTitle="By tags"
              selectPlaceholder="Search by tags"
              selectName="tags"
              checkValue={PLAYLIST_TYPE.BY_TAGS}
              options={filteredTags}
              isLoading={tagsLoading}
              style={{ marginBottom: 0 }}
              selectedValue={selectedType}
              onSearchChange={filterTags}
              radio={false}
            />
          </Form.Item>
        </BoxContainer>
      </>
    );
  };

  const FooterContainer = () => {
    return (
      <Footer
        style={{
          padding: 0,
          height: "30%",
          display: "flex",
          flexDirection: "column",
          background: "white",
        }}
      >
        <PublishContainer>
          <Form.Item
            valuePropName="checked"
            name="publishOnApp"
            className="playlist-publish-switch"
          >
            <Switch />
          </Form.Item>
          <HelpText title={"Publish on mobile app"} />
        </PublishContainer>
        <ButtonContainer>
          <Form.Item>
            <Button
              onClick={() => props.history.goBack()}
              htmlType="reset"
              shape="round"
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="playlist-submit-btn"
              htmlType="submit"
              shape="round"
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleSubmit}
            >
              {!!passedPlaylistId ? "Update playlist" : "Create playlist"}
            </Button>
          </Form.Item>
        </ButtonContainer>
      </Footer>
    );
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();

      // Clearing directus cache for showing table view with updated records
      await clearDirectusCache();

      if (!!!passedPlaylistId)
        createPlaylist({
          variables: {
            playlistType: PLAYLIST_TYPE.FILTERS,
            ...fields,
          },
        }).catch((err) => message.error(trimErrorMessage(err.message)));
      else {
        updatePlaylist({
          variables: {
            ...fields,
            playlistType: PLAYLIST_TYPE.FILTERS,
            id: passedPlaylistId,
          },
        }).catch((err) => message.error(trimErrorMessage(err.message)));
      }
    } catch (_) {}
  };

  return (
    <Wrapper>
      <Spin
        spinning={
          creatingPlaylist || updatingPlaylist || fetchingPlaylistDetail
        }
        size="large"
      >
        <Form.Provider>
          <Form
            layout={"vertical"}
            form={form}
            scrollToFirstError
            initialValues={defaultPlaylistState}
            onValuesChange={(values) => {
              values.playlistType && setSelectedType(values.playlistType);
            }}
          >
            {TopBoxesContainer()}
            {BoxesContainer()}
            {FooterContainer()}
          </Form>
        </Form.Provider>
      </Spin>
    </Wrapper>
  );
};

export default PlaylistForm;
