import { gql } from '@apollo/client';

const ALL_USERS_QUERY = gql`
  query($page: Int, $per: Int, $query: String, $staffOnly: Boolean) {
    users(per: $per, page: $page, query: $query, staffOnly: $staffOnly) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      nodes {
        id
        email
        name
        bio
        state
        city
        phone
        avatar
        zipcode
        userType
        customFieldValue {
          id
          value
        }
        address
        username
        socialMediaLinks {
          id
          url
          mediaType
        }
        organization {
          title
          id
        }
      }
    }
  }
`;

const PRODUCERS_PAGINATION = gql`
  query PRODUCERS_PAGINATION($query: String, $page: Int, $per: Int) {
    producers(page: $page, per: $per, query: $query) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      nodes {
        id
        email
        name
        userType
        avatar
        city
        state
        address
        zipcode
        updatedAt
        groups {
          id
          name
          description
        }
        organization {
          id
          title
        }
        customFieldValue {
          id
          value
        }
      }
    }
  }
`;

const ME_QUERY = gql`
  query {
    me {
      id
      email
      name
      bio
      state
      city
      phone
      avatar
      zipcode
      userType
      neonCrmId
      customFieldValue {
        id
        value
      }
      address
      username
      socialMediaLinks {
        id
        url
        mediaType
      }
      organization {
        title
        id
      }
    }
  }
`;

const ALL_PODCASTS = gql`
  query {
    podcasts {
      id
      name
      title
      rssFeed
      published
      image
      podcastEpisode {
        description
        id
        audioUri
        publishedAt
        title
      }
    }
  }
`;

const ALL_PODCASTS_PUBLISH_STATUS = gql`
  query {
    podcasts {
      published
    }
  }
`;

const PODCAST_BY_ID = gql`
  query podcastById($id: String!) {
    podcastById(id: $id) {
      id
      name
      title
      rssFeed
      image
      published
      podcastEpisode {
        description
        id
        audioUri
        publishedAt
        title
      }
    }
  }
`;

const ORGANIZATION = gql`
  query {
    episodeSetting {
      masterControlIntegration
    }
    organization {
      address
      title
      id
      city
      description
      image
      squareImage
      latitude
      primaryColor
      secondaryColor
      latitude
      longitude
      state
      title
      website
      phone
      timezone
      zip
      youtubeAccessToken
      youtubeRefreshToken
      youtubeMetadata {
        id
        value
      }
    }
  }
`;

const PODCAST_WIDGET_BY_ID = gql`
  query podcastWidgetById($id: String!, $published: Boolean) {
    podcastWidgetById(id: $id, published: $published) {
      name
      id
      primaryColor
      secondaryColor
      podcast {
        name
        id
        description
        image
        title
        rssFeed
        podcastEpisode {
          id
          publishedAt
          title
          description
          image
          audioUri
        }
      }
    }
  }
`;

const ALL_RADIO = gql`
  query {
    radios {
      id
      name
      station
      streamUrl
      image
      published
      description
    }
  }
`;

const RADIO_BY_ID = gql`
  query radioById($id: String!) {
    radioById(id: $id) {
      id
      name
      station
      published
      streamUrl
      image
      description
    }
  }
`;

const RADIO_WIDGET_BY_ID = gql`
  query radioWidgetById($id: String!, $published: Boolean) {
    radioWidgetById(id: $id, published: $published) {
      id
      name
      primaryColor
      secondaryColor
      radio {
        id
        name
        streamUrl
        station
        description
        image
      }
    }
  }
`;

const SCHEDULE_WIDGET_BY_ID = gql`
  query scheduleWidgetById($id: String!, $published: Boolean) {
    scheduleWidgetById(id: $id, published: $published) {
      id
      name
      primaryColor
      search
      secondaryColor
      liveVideos {
        id
        image
        stations
        streamUrl
        playerType
        schedule {
          id
          liveVideoEpisodes {
            id
            title
            description
            endDatetime
            duration
            program
            releasedDate
            image
          }
        }
        nowPlaying {
          id
        }
      }
    }
  }
`;

const ALL_LIVE_VIDEOS = gql`
  query {
    liveVideos {
      id
      name
      stations
      streamUrl
      playerType
      image
      description
      scheduleRss
      provider
      channelId
      published
    }
  }
`;

const LIVE_VIDEO_BY_ID = gql`
  query liveVideoById($id: String!) {
    liveVideoById(id: $id) {
      id
      description
      image
      name
      streamUrl
      published
      stations
      scheduleRss
      playerType
      provider
      channelId
    }
  }
`;

const ALL_INVITES = gql`
  query {
    invites {
      id
      firstname
      lastname
      expiry
      email
      status
      organization {
        id
      }
      userType
    }
  }
`;

const EPISODE_SETTINGS = gql`
  query {
    episodeSetting {
      id
      askEpisodeSponsor
      masterControlIntegration
      cableCastLocationId
      topics {
        id
        title
      }
      episodeOriginOptions {
        title
        id
      }
      organization {
        cablecastBaseUrl
      }
      episodeCustomFields {
        allowMultiple
        charLimit
        id
        label
        options
        required
        staff
        time
        type
      }
    }
  }
`;

const PRODUCER_SETTINGS = gql`
  query {
    producerSetting {
      id
      redirectUrl
      customFields {
        allowMultiple
        charLimit
        id
        label
        options
        required
        staff
        time
        type
      }
      organization {
        title
      }
      user {
        id
        name
        userType
      }
    }
  }
`;

const PROGRAM_SETTINGS = gql`
  query {
    programSetting {
      id
      programCustomFields {
        id
        required
        type
        charLimit
        staff
        label
        options
        allowMultiple
        time
      }

      topics {
        id
        title
      }
      programTypes {
        id
        text
      }
    }
  }
`;

const INVITE_BY_TOKEN = gql`
  query inviteByToken($token: String!) {
    inviteByToken(token: $token) {
      id
      lastname
      firstname
      email
      token
      expiry
      invitee {
        id
      }
      inviter {
        id
      }
      organization {
        id
      }
      userType
    }
  }
`;

const ALL_EPISODES = gql`
  query {
    episodes {
      program {
        title
        id
      }
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }

      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
      notesToProducer
      cablecastId
      cablecastProgramPelantionMedia
      telvueFileName
      telvueIngestDateTime
    }
  }
`;

const EPISODES_PAGINATION = gql`
  query EPISODES_PAGINATION(
    $page: Int
    $per: Int
    $query: String
    $publish: Boolean
    $published: Boolean
    $status: [EpisodeStatus!]
    $dateOfProductionStart: String
    $dateOfProductionEnd: String
    $programId: String
    $producerId: String
    $sortBy: String
    $sortOrder: String
  ) {
    episodes(
      page: $page
      per: $per
      published: $published
      query: $query
      status: $status
      publish: $publish
      dateOfProductionStart: $dateOfProductionStart
      dateOfProductionEnd: $dateOfProductionEnd
      programId: $programId
      producerId: $producerId
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      nodes {
        program {
          title
          id
        }
        createdAt
        assistantProducers {
          id
          name
          userType
        }
        customFieldValue {
          id
          value
        }
        updatedAt
        dateOfProduction
        episodeNumber
        id
        episodeOriginOption {
          title
          id
        }
        topics {
          id
          title
        }
        fullDescription
        tags {
          id
          name
        }
        producer {
          id
          name
        }
        linkExpiry
        published
        shortDescription
        sponsored
        status
        streamUrl
        thumbnailUrl
        title
        videoUrl
        notesToProducer
        cablecastProgramPelantionMedia
        telvueFileName
        cablecastId
        telvueIngestDateTime
        telvueDeleteAt
        telvueId
        telvueConnectId
        telvueProgramCode
      }
    }
  }
`;

const LIVE_VIDEO_WIDGET_BY_ID = gql`
  query liveVideoWidgetById($id: String!, $published: Boolean) {
    liveVideoWidgetById(id: $id, published: $published) {
      id
      name
      layout
      primaryColor
      secondaryColor
      liveVideo {
        id
        description
        image
        name
        playerType
        scheduleRss
        stations
        streamUrl
        nowPlaying {
          id
          eventId
          description
          title
          image
          duration
          program
        }
      }
    }
  }
`;

const ALL_PROGRAMS = gql`
  query {
    programs {
      id
      episodes {
        title
      }
      title
      programType {
        text
        id
      }
      status
      producer {
        id
        name
      }
      updatedAt
      published
      assistantProducers {
        name
        id
      }
      topics {
        id
        title
      }
      customFieldValue {
        id
        value
      }
      published
      image
      notesToProducer
    }
  }
`;

const PROGRAMS_PAGINATION = gql`
  query PROGRAMS_PAGINATION(
    $page: Int
    $per: Int
    $query: String
    $publish: Boolean
    $published: Boolean
    $sortBy: String,
    $sortOrder: String,
    $updatedAtEnd: String
    $updatedAtStart: String
    $programType: [String!]
    $status: [ProgramStatus!]
  ) {
    programs(
      page: $page
      per: $per
      query: $query
      status: $status
      publish: $publish
      published: $published
      sortBy: $sortBy
      sortOrder: $sortOrder
      updatedAtStart: $updatedAtStart
      updatedAtEnd: $updatedAtEnd
      programType: $programType
    ) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      nodes {
        id
        updatedAt
        episodes {
          title
        }
        title
        programType {
          text
          id
        }
        status
        producer {
          id
          name
        }
        updatedAt
        published
        createdAt
        assistantProducers {
          name
          id
        }
        topics {
          id
          title
        }
        customFieldValue {
          id
          value
        }
        published
        image
        notesToProducer
      }
    }
  }
`;

const PROGRAMS_FOR_DROPDOWN = gql`
  query PROGRAMS_FOR_DROPDOWN($programQuery: String) {
    programs(page: 1, per: 100, query: $programQuery, sort: true) {
      nodes {
        id
        title
      }
    }
  }
`;

const EPISODES_FOR_DROPDOWN = gql`
  query EPISODES_FOR_DROPDOWN($episodeQuery: String) {
    episodes(page: 1, per: 100, query: $episodeQuery) {
      nodes {
        id
        title
      }
    }
  }
`;

const PRODUCERS_FOR_DROPDOWN = gql`
  query PRODUCERS_FOR_DROPDOWN($producerQuery: String) {
    producers(page: 1, per: 100, query: $producerQuery, sort: true) {
      nodes {
        id
        name
        email
        username
      }
    }
  }
`;

const NEW_EPISODE_QUERIES = gql`
  query {
    episodeSetting {
      id
      episodeCustomFields {
        allowMultiple
        charLimit
        id
        label
        options
        required
        staff
        time
        type
      }
      askEpisodeSponsor
      masterControlIntegration
      cableCastLocationId
      topics {
        id
        title
      }
      episodeOriginOptions {
        id
        title
      }
    }
  }
`;

const EPISODE = gql`
  query($id: ID!) {
    episode(id: $id) {
      assistantProducers {
        id
        name
      }
      cablecastId
      cablecastProgramPelantionMedia
      customFieldValue {
        id
        value
      }
      dateOfProduction
      documents {
        id
        url
      }
      duration
      episodeNumber
      episodeOriginOption {
        id
        title
      }
      externalLinks {
        id
        title
        url
      }
      fullDescription
      id
      organization {
        id
        title
      }
      tags {
        id
        name
      }
      producer {
        id
        name
      }
      program {
        id
        title
      }
      streamUrl
      published
      shortDescription
      sponsored
      status
      topics {
        id
        title
      }
      telvueDeleteAt
      telvueFileName
      telvueId
      telvueConnectId
      telvueIngestDateTime
      telvueProgramCode
      thumbnailUrl
      title
      videoUrl
      notesToProducer
    }
  }
`;

const FOOTER_WIDGET_BY_ID = gql`
  query footerWidgetById($id: String!) {
    footerWidgetById(id: $id) {
      id
      name
      additionalInfo
      bgColor
      mailchimpListId
      mailchimpSegmentId
      hasMailchimpSubscription
      mailchimpSuccessMessage
      primaryColor
      organization {
        id
      }
      socialMediaLinks {
        url
        mediaType
      }
      images {
        id
        url
      }
      footerColumns {
        id
        header
        footerColumnsLinks {
          id
          title
          url
        }
      }
    }
  }
`;

const PRODUCER_PORTAL_WIDGET_BY_ID = gql`
  query PRODUCER_PORTAL_WIDGET_BY_ID($id: String!) {
    producerPortalWidgetById(id: $id) {
      id
      imageUrl
      newProducerMessage
      name
      primaryColor
      secondaryColor
      user {
        id
      }
      organization {
        id
      }
    }
  }
`;

const NEW_PROGRAM_FIELDS_DATA = gql`
  query {
    programSetting {
      id
      organization {
        id
        title
      }
      topics {
        id
        title
      }
      programCustomFields {
        allowMultiple
        charLimit
        id
        label
        options
        required
        staff
        time
        type
      }
      programTypes {
        id
        text
      }
    }
  }
`;
const PROGRAM_BY_ID = gql`
  query($id: ID!) {
    program(id: $id) {
      assistantProducers {
        id
        name
      }
      customFieldValue {
        id
        id
        value
      }
      episodes {
        id
        title
        episodeNumber
      }
      externalLinks {
        title
        url
      }
      fullDescription
      id
      image
      documents {
        id
        url
      }
      producer {
        id
        name
      }
      programType {
        id
        text
      }
      published
      status
      topics {
        id
        title
      }
      title
      notesToProducer
    }
  }
`;

const VOD_WIDGET_BY_ID = gql`
  query vodWidgetById($id: String!) {
    vodWidgetById(id: $id) {
      id
      name
      bgColor
      textColor
      primaryColor
      secondaryColor
      sidebarStream
      widgetType
      organization {
        id
      }
    }
  }
`;

const VOD_WIDGET_BY_ORGANIZATION = gql`
  query vodWidgetByOrganization($organizationId: String!) {
    vodWidgetByOrganization(organizationId: $organizationId) {
      id
      name
      bgColor
      textColor
      primaryColor
      secondaryColor
      sidebarStream
      widgetType
      organization {
        id
      }
    }
  }
`;
const GET_USER_BY_ID = gql`
  query userById($id: String!) {
    userById(id: $id) {
      id
      email
      name
      bio
      state
      city
      phone
      avatar
      zipcode
      userType
      address
      username
      neonCrmId
      socialMediaLinks {
        id
        url
        mediaType
      }
      customFieldValue {
        id
        value
      }
      organization {
        title
        id
      }
    }
  }
`;

const VERIFY_EMAIL_CHANGE = gql`
  query verifyEmailRequestByToken($token: String!) {
    verifyEmailRequestByToken(token: $token) {
      message
    }
  }
`;

const PRODUCER_SETTING_BY_ORG = gql`
  query producerSettingByOrganization($organizationId: String!) {
    producerSettingByOrganization(organizationId: $organizationId) {
      id
      customFields {
        id
        label
        type
        staff
        time
        options
        required
        charLimit
        allowMultiple
      }
      organization {
        id
        title
      }
      redirectUrl
    }
  }
`;

const GROUPS_PAGINATION = gql`
  query GROUPS_PAGINATION($query: String, $page: Int, $per: Int) {
    groups(page: $page, per: $per, query: $query) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      nodes {
        name
        id
        image
        updatedAt
        description
        groupTags {
          title
        }
        members {
          name
        }
      }
    }
  }
`;

const ALL_PRODUCERS_SIMPLE = gql`
  query {
    producers {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      nodes {
        id
        name
        username
      }
    }
  }
`;

const GROUP_BY_ID = gql`
  query groupById($id: String!) {
    groupById(id: $id) {
      name
      description
      id
      image
      members {
        id
        name
        username
      }
      groupTags {
        id
        title
      }
    }
  }
`;

const INTEGRATION_DATA_CALL = gql`
  query {
    organization {
      id
      neonApiKey
      neonOrgId
      lastSyncNeonCrm
      autoSyncNeonCrm
      youtubeAccessToken
      youtubeRefreshToken
      youtubeMetadata {
        id
        value
      }
    }
    cablecastIntegration {
      baseUrl
      id
      autoSync
      lastSync
      password
      username
      organization {
        id
        title
      }
    }
    telvueIntegration {
      apiKey
      apiSecret
      hasConnect
      autoSyncHypercast
      autoSyncConnect
      lastSyncHypercast
      lastSyncConnect
      id
      organization {
        id
        title
      }
      telvueHypercastApiKey
      telvueHypercastBaseUrl
    }
    mailChimpIntegration {
      apiKey
      id
      organization {
        id
        title
      }
    }
    episodeSetting {
      id
      masterControlIntegration
    }
    stripeIntegration {
      id
      devApiKey
      liveApiKey
      isDevMode
      webHooksSecret
      devPublishableKey
      livePublishableKey
    }
  }
`;

const GET_ALL_PLAYLISTS = gql`
  query playlists {
    playlists {
      id
      title
      description
      playlistType
      publishOnApp
    }
  }
`;
const GET_PLAYLIST_BY_ID = gql`
  query playlistById($id: String!) {
    playlistById(id: $id) {
      id
      title
      description
      playlistType
      publishOnApp
      filterType
      episodes {
        id
        title
      }
      programs {
        id
        title
      }
      tags {
        id
        title: name
      }
      topics {
        id
        title
      }
    }
  }
`;

const PLAYLIST_WIDGET_BY_ID = gql`
  query PLAYLIST_WIDGET_BY_ID($id: String!) {
    playlistWidgetById(id: $id) {
      id
      bgColor
      cardSize
      description
      layout
      primaryColor
      secondaryColor
      textColor
      name
      playlists {
        id
        title
      }
    }
  }
`;

const PLAYLISTS = gql`
  query PLAYLISTS {
    playlists {
      id
      title
      description
      playlistType
      publishOnApp
      tags {
        id
        name
      }
      topics {
        id
        title
      }
    }
  }
`;

const GET_ALL_USER_TAGS = gql`
  query tags {
    tags {
      id
      title: name
    }
  }
`;

const GET_ALL_TOPICS = gql`
  query topics {
    topics {
      id
      title
    }
  }
`;

const DONATION_WIDGET_BY_ID = gql`
  query donationWidgetById($id: String!) {
    donationWidgetById(id: $id) {
      id
      bgColor
      donationWidgetType
      footerHasMailchimpSubscription
      footerMailchimpSegmentId
      footerMailchimpListId
      footerText
      formMessage
      formSuccessEmailMessage
      formSuccessMessage
      formTitle
      id
      name
      organization {
        id
      }
      popupDelayHours
      popupType
      primaryColor
      secondaryColor
      textColor
      user {
        id
      }
      images {
        id
        url
      }
    }
  }
`;

const DONATION_PAGINATION = gql`
  query getAllDonations(
    $per: Int
    $page: Int
    $query: String
    $endDate: String
    $startDate: String
    $status: [DonationStatus!]
    $donationType: DonationTypes
  ) {
    donations(
      per: $per
      page: $page
      query: $query
      startDate: $startDate
      endDate: $endDate
      donationType: $donationType
      status: $status
    ) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      nodes {
        id
        amount
        firstName
        lastName
        email
        donationType
        donationStatus
        subscribeToMailList
        customFieldValue {
          id
          value
        }
        phone
        state
        city
        country
        createdAt
        zipCode
        address
        paymentIntentId
      }
    }
  }
`;

const GET_DONATION_SETTING = gql`
  query donationSetting {
    donationSetting {
      id
      donationCustomFields {
        id
        required
        type
        charLimit
        staff
        label
        options
        allowMultiple
        time
      }
    }
    me {
      id
      userType
    }
  }
`;

const DONATION_BY_ID = gql`
  query donationById($id: String!) {
    donationById(id: $id) {
      id
      amount
      firstName
      lastName
      email
      donationType
      donationStatus
      subscribeToMailList
      phone
      state
      city
      country
      createdAt
      zipCode
      address
      paymentIntentId
      customFieldValue {
        id
        value
      }
    }
  }
`;

const MAILCHIMP_LIST = gql`
  query {
    mailChimpLists {
      id
      name
    }
  }
`;

const MAILCHIMP_SEGMENTS_BY_ID = gql`
  query mailchimpData($listId: String!) {
    mailChimpListSegments(listId: $listId) {
      id
      name
    }
  }
`;

const SUBSCRIPTION_WIDGET_BY_ID = gql`
  query SUBSCRIPTION_WIDGET_BY_ID($id: String!) {
    subscriptionWidgetById(id: $id) {
      id
      name
      bgColor
      formTitle
      createdAt
      showPhone
      showEmail
      popupType
      textColor
      updatedAt
      formMessage
      showAddress
      primaryColor
      showLastName
      showFirstName
      secondaryColor
      mailchimpListId
      popupDelayHours
      mailchimpSegmentId
      formSuccessMessage
      subscriptionWidgetType
      formSuccessEmailMessage
      organization {
        id
      }
      user {
        id
        email
      }
    }
  }
`;

const ALL_WIDGETS = gql`
  query ALL_WIDGETS {
    allWidgets {
      id
      name
      sourceId
      updatedAt
      widgetType
      createdAt
    }
  }
`;

const SEARCH_WIDGET_BY_ID = gql`
  query searchWidgetById($id: String!) {
    searchWidgetById(id: $id) {
      bgColor
      createdAt
      id
      name
      organization {
        id
      }
      primaryColor
      secondaryColor
      sidebarStream
      textColor
      updatedAt
      user {
        id
      }
    }
  }
`;

const PODCASTS_PUBLISH_STATUS = gql`
  query {
    podcasts {
      published
    }
  }
`;

const EPISODE_STREAM_URL = gql`
  query {
    episodes {
      nodes {
        streamUrl
      }
    }
  }
`;

const EPISODE_STATUS = gql`
  query {
    episodes {
      nodes {
        status
      }
    }
  }
`;


const EPISODE_PUBLISHED = gql`
  query {
    episodes {
      nodes {
        published
      }
    }
  }
`;

// Local queries

const GET_PAGE_NUMBER = gql`
  {
    route @client
    pageNumber @client
  }
`;


const ORGANIZATION_STATS = gql`
  query {
    organizationStats {
      episodes {
        totalCount
        statusCounts {
          published
          unpublished
          accepted
          declined
          inReview
        }
      }
      podcasts {
        totalCount
        statusCounts {
          published
          unpublished
        }
      }
    }
  }
`;

export default {
    ALL_USERS_QUERY,
    ME_QUERY,
    ALL_PODCASTS,
    ORGANIZATION,
    PODCAST_WIDGET_BY_ID,
    PODCAST_BY_ID,
    ALL_RADIO,
    RADIO_BY_ID,
    RADIO_WIDGET_BY_ID,
    ALL_LIVE_VIDEOS,
    LIVE_VIDEO_BY_ID,
    LIVE_VIDEO_WIDGET_BY_ID,
    SCHEDULE_WIDGET_BY_ID,
    ALL_INVITES,
    INVITE_BY_TOKEN,
    EPISODE_SETTINGS,
    ALL_EPISODES,
    PROGRAM_SETTINGS,
    ALL_PROGRAMS,
    NEW_EPISODE_QUERIES,
    EPISODE,
    NEW_PROGRAM_FIELDS_DATA,
    PROGRAM_BY_ID,
    FOOTER_WIDGET_BY_ID,
    PRODUCER_PORTAL_WIDGET_BY_ID,
    VOD_WIDGET_BY_ID,
    VOD_WIDGET_BY_ORGANIZATION,
    ALL_PRODUCERS_SIMPLE,
    GROUP_BY_ID,
    GET_USER_BY_ID,
    PRODUCER_SETTINGS,
    VERIFY_EMAIL_CHANGE,
    PRODUCER_SETTING_BY_ORG,
    GROUPS_PAGINATION,
    EPISODES_PAGINATION,
    PROGRAMS_PAGINATION,
    PRODUCERS_PAGINATION,
    INTEGRATION_DATA_CALL,
    GET_ALL_PLAYLISTS,
    GET_ALL_USER_TAGS,
    GET_ALL_TOPICS,
    GET_PLAYLIST_BY_ID,
    PLAYLIST_WIDGET_BY_ID,
    PLAYLISTS,
    MAILCHIMP_LIST,
    DONATION_WIDGET_BY_ID,
    DONATION_PAGINATION,
    GET_DONATION_SETTING,
    DONATION_BY_ID,
    MAILCHIMP_SEGMENTS_BY_ID,
    SUBSCRIPTION_WIDGET_BY_ID,
    PROGRAMS_FOR_DROPDOWN,
    PRODUCERS_FOR_DROPDOWN,
    EPISODES_FOR_DROPDOWN,
    SEARCH_WIDGET_BY_ID,
    ALL_WIDGETS,
    PODCASTS_PUBLISH_STATUS,
    EPISODE_STREAM_URL,
    EPISODE_STATUS,
    EPISODE_PUBLISHED,
    ORGANIZATION_STATS,
    //Local queries
    GET_PAGE_NUMBER,
};
