import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  Input,
  Spin,
  Form,
  Descriptions,
  Layout,
  Radio,
  Checkbox,
  InputNumber,
  message,
  Typography,
} from "antd";
import CustomFields from "../../../components/CustomField";
import {
  SaveOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { COUNTRIES, SALSA_LABS_GET_STATES_API_URL } from "../../../constants";
import {
  DONATION_STATUS,
  DONATION_TYPE,
  UserRole,
} from "../../../utils/contants";
import { ThemeColors } from "../../../theme";
import HelpText from "../../../components/HelpText";
import { useMutation, useQuery } from "@apollo/client";
import { Mutations, Queries } from "../../../api";
import { fixAutocomplete, trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const { Content } = Layout;
const { Option } = Select;

const Container = styled(Layout)`
  padding: 2rem;
  background: white;
`;

const HorizontalContainerSB = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  align-self: "flex-end"
  flex-direction: column;
  .ant-typography code {
    padding: 8px;
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SubscribeContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;

  .donation-subscribe-radio {
    margin: 2rem 1rem 10px 0;
  }
`;

const msg =
  "Only use this form if donation is via check or non credit/debit card. For credit card donations, use the donate form on your website.";

const defaultDonationFields = {
  amount: null,
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: null,
  country: "US",
  zipCode: null,
  email: "",
  phone: "",
  donationStatus: DONATION_STATUS.Succeeded.value,
  customFieldValue: [],
  subscribeToMailchimp: true,
  paymentIntentId: "",
  donationType: DONATION_TYPE.OFFLINE.value,
};

const DonationStatusRadioOptions = Object.values(DONATION_STATUS).map(
  ({ text, value }) => ({
    value: value,
    label: text,
  })
);

const filterStaffAndAdminCustomFields = (fields) => {
  let staffFields = [];
  let adminFields = [];
  if (Array.isArray(fields)) {
    fields.forEach((field) => {
      if (!!field?.staff) {
        staffFields.push(field);
      } else {
        adminFields.push(field);
      }
    });
  }
  return { staffFields, adminFields };
};

const parseCustomFields = (fields) => {
  let customFields = [];
  if (Array.isArray(fields)) {
    fields.forEach(
      (obj) => (customFields[obj.id] = obj?.value ? JSON.parse(obj.value) : "")
    );
  }
  return customFields;
};

const DonationForm = (props) => {
  const [form] = Form.useForm();
  const passedDonationId = props.match.params.id;

  const [statesData, setStatesData] = useState([]);
  const [statesLoading, setStatesLoading] = useState(false);
  const [staffCustomFields, setStaffCustomFields] = useState([]);
  const [adminCustomFields, setAdminCustomFields] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState({
    id: "",
    userType: UserRole.STAFF,
  });

  const navigateToHome = () => props.history.push("/donations");

  const { loading: fetchingCustomFields } = useQuery(
    Queries.GET_DONATION_SETTING,
    {
      onError: (err) => message.error(trimErrorMessage(err.message)),
      onCompleted: (data) => {
        const customFields = data?.donationSetting?.donationCustomFields;
        const { staffFields, adminFields } =
          filterStaffAndAdminCustomFields(customFields);
        setStaffCustomFields(staffFields);
        setAdminCustomFields(adminFields);

        if (data?.me?.userType === UserRole.ADMIN) {
          setLoggedInUser(data?.me);
        }
      },
      fetchPolicy: "network-only",
    }
  );

  const [refundDonation, { loading: refundLoding }] = useMutation(
    Mutations.REFUND_DONATION,
    {
      onCompleted: () => {
        message.success("Donation refunded successfully");
        navigateToHome();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const { loading: fetchingDonationDetail } = useQuery(Queries.DONATION_BY_ID, {
    variables: {
      id: passedDonationId,
    },
    skip: !!!passedDonationId,
    onCompleted: (data) => {
      const donationToUpdate = data?.donationById;
      if (!!donationToUpdate?.id) {
        !!donationToUpdate.country &&
          handleCountrySelected(donationToUpdate.country, false);
        let customFields = parseCustomFields(
          donationToUpdate?.customFieldValue
        );

        form.setFieldsValue({
          ...customFields,
          amount: donationToUpdate?.amount,
          firstName: donationToUpdate?.firstName,
          lastName: donationToUpdate?.lastName,
          address: donationToUpdate?.address,
          city: donationToUpdate?.city,
          state: donationToUpdate?.state,
          country: donationToUpdate?.country,
          zipCode: donationToUpdate?.zipCode,
          email: donationToUpdate?.email,
          phone: donationToUpdate?.phone,
          donationStatus: donationToUpdate?.donationStatus,
          subscribeToMailchimp: donationToUpdate?.subscribeToMailchimp || false,
          paymentIntentId: donationToUpdate?.paymentIntentId || "",
          donationType:
            donationToUpdate?.donationType || DONATION_TYPE.OFFLINE.value,
        });
      }
    },
    fetchPolicy: "network-only",
    onError: (err) => {
      message.error(trimErrorMessage(err.message));
    },
  });

  const [createDonation, { loading: creatingDonation }] = useMutation(
    Mutations.CREATE_OFFLINE_DONATION,
    {
      onCompleted: (data) => {
        if (!!data?.createOfflineDonation?.id) {
          message.success("Donation added successfully.");
          navigateToHome();
        } else {
          message.error("Something wrong happened adding this donation.");
        }
      },
    }
  );

  const [updateDonation, { loading: updatingDonation }] = useMutation(
    Mutations.UPDATE_DONATION,
    {
      onCompleted: (data) => {
        if (!!data?.updateDonation?.id) {
          message.success("Donation updated successfully.");
          navigateToHome();
        } else {
          message.error("Something wrong happened updating this donation.");
        }
      },
    }
  );

  useEffect(() => {
    if (!!!passedDonationId)
      handleCountrySelected(defaultDonationFields.country);
    fixAutocomplete();
    // eslint-disable-next-line
  }, []);

  const handleCountrySelected = (countrySelected, clearState = true) => {
    if (!!countrySelected) {
      setStatesLoading(true);
      fetch(SALSA_LABS_GET_STATES_API_URL + countrySelected)
        .then((res) => res.json())
        .then((data) => {
          if (clearState) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              state: null,
            });
          }
          setStatesData(data);
        })
        .catch(console.log)
        .finally((e) => setStatesLoading(false));
    }
  };

  const handleSubmit = async () => {
    const fields = form.getFieldsValue();
    let customFieldValue = [];
    staffCustomFields.concat(adminCustomFields).forEach((customField) => {
      customFieldValue.push({
        id: customField.id,
        value:
          fields[customField.id] === undefined
            ? ""
            : JSON.stringify(fields[customField.id]),
      });
    });
    const payload = {
      address: fields.address || "",
      amount: fields.amount,
      city: fields.city,
      country: fields.country,
      customFieldValue: customFieldValue,
      donationStatus: fields.donationStatus,
      donationType: fields.donationType,
      email: fields.email,
      firstName: fields.firstName,
      lastName: fields.lastName,
      phone: fields.phone,
      state: fields.state || "NONE",
      zipCode: fields.zipCode,
      subscribeToMailchimp: fields.subscribeToMailchimp || false,
    };

    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();

    if (!!!passedDonationId) {
      createDonation({
        variables: {
          ...payload,
        },
      }).catch((err) => message.error(trimErrorMessage(err.message)));
    } else {
      updateDonation({
        variables: {
          id: passedDonationId,
          ...payload,
        },
      }).catch((err) => message.error(trimErrorMessage(err.message)));
    }
  };

  const handleRefund = () => {
    if (passedDonationId) {
      refundDonation({
        variables: {
          donationId: passedDonationId,
        },
      }).catch((error) => message.error(error.message));
    }
  };

  const isEditable = () =>
    !(
      form.getFieldValue("donationStatus") ===
        DONATION_STATUS.Succeeded.value && !!passedDonationId
    );

  const isShowRefund = () =>
    !!passedDonationId &&
    form.getFieldValue("donationType") === DONATION_TYPE.ONLINE.value &&
    form.getFieldValue("donationStatus") === DONATION_STATUS.Succeeded.value;

  const FormTitle = (props) => (
    <h1
      style={{
        fontSize: props.size,
        fontWeight: 600,
        textAlign: "start",
      }}
    >
      {props.title}
    </h1>
  );

  const StaffContainer = () => (
    <>
      <FormTitle title={"Staff fields"} size={"20px"} />
      <HorizontalContainer>
        <LeftContainer>
          <Form.Item
            label={<HelpText title={"Donation type"} />}
            required
            name="donationType"
            style={{
              paddingLeft: 2,
              width: "50%",
              margin: 0,
            }}
          >
            <Select placeholder="Select origin option">
              {Object.values(DONATION_TYPE).map((type) => (
                <Option
                  disabled={form.getFieldValue("donationType") !== type.value}
                  key={type.value}
                >
                  {type.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </LeftContainer>

        <RightContainer>
          {form.getFieldValue("donationType") === DONATION_TYPE.ONLINE.value &&
          !!form.getFieldValue("paymentIntentId") ? (
            <Form.Item
              label={<HelpText title={"Stripe ID"} />}
              name="paymentIntentId"
              style={{
                paddingLeft: 2,
                width: "50%",
                margin: 0,
              }}
            >
              <Typography.Text
                ellipsis={true}
                copyable
                code
                style={{ display: "flex", alignItems: "center" }}
              >
                {form.getFieldValue("paymentIntentId")}
              </Typography.Text>
            </Form.Item>
          ) : (
            <Form.Item style={{ alignSelf: "flex-end" }}>
              <Descriptions.Item
                label="Note!"
                style={{ fontSize: 12, marginTop: 8 }}
              >
                {msg}
              </Descriptions.Item>
            </Form.Item>
          )}
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  const CustomFieldContainer = (fields, name) => (
    <LeftContainer>
      <CustomFields
        form={form}
        key={name || ""}
        customFields={fields}
        customFieldLabel={(name, isRequired) => {
          return <HelpText title={name} required={isRequired} />;
        }}
      />
    </LeftContainer>
  );

  const DonationFormFields = () => (
    <>
      <FormTitle title={"Donation form fields"} size={"20px"} />
      <VerticalContainer>
        <HorizontalContainer>
          <Form.Item
            name={"amount"}
            label={<HelpText title={"Donation amount"} required={true} />}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <InputNumber
              min={1}
              precision={2}
              style={{ width: "100%" }}
              disabled={!!passedDonationId}
              placeholder={"Donation amount"}
            />
          </Form.Item>
          <Form.Item
            label={<HelpText title={"Email address"} required={true} />}
            name={"email"}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
              {
                type: "email",
                message: "Not a valid email",
              },
            ]}
          >
            <Input
              disabled={!!passedDonationId}
              placeholder={"Donor's email"}
            />
          </Form.Item>
          <Form.Item
            label={<HelpText title={"Phone number"} required={true} />}
            name={"phone"}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <Input placeholder={"Donor's phone number"} />
          </Form.Item>
        </HorizontalContainer>
        <HorizontalContainer>
          <Form.Item
            label={<HelpText title={"First name"} required={true} />}
            name={"firstName"}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <Input placeholder={"Donor's firstname"} />
          </Form.Item>
          <Form.Item
            label={<HelpText title={"Last name"} required={true} />}
            name={"lastName"}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <Input placeholder={"Donor's lastname"} />
          </Form.Item>
        </HorizontalContainer>
        <HorizontalContainer>
          <Form.Item
            name={"address"}
            label={<HelpText title={"Address"} />}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
          >
            <Input placeholder={"Donor's address"} />
          </Form.Item>
          <Form.Item
            label={<HelpText title={"City"} required={true} />}
            name={"city"}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <Input placeholder={"Donor's city"} />
          </Form.Item>
        </HorizontalContainer>
        <HorizontalContainer>
          <Form.Item
            label={<HelpText title={"Country"} required={true} />}
            name={"country"}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <Select
              filterOption={(input, { label }) =>
                label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              onChange={handleCountrySelected}
              placeholder="Select"
              internalProps={{ autocomplete: "stopDamnAutocomplete" }}
              options={COUNTRIES}
            />
          </Form.Item>
          {statesData?.length > 0 && (
            <Form.Item
              label={<HelpText title={"State"} required={true} />}
              name={"state"}
              style={{
                paddingLeft: 2,
                margin: 0,
                marginRight: "2rem",
                width: "25%",
              }}
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select
                filterOption={(input, { label }) =>
                  label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                placeholder="Select"
                options={statesData || []}
              />
            </Form.Item>
          )}
          <Form.Item
            label={<HelpText title={"Postal code"} required={true} />}
            name={"zipCode"}
            style={{
              paddingLeft: 2,
              margin: 0,
              marginRight: "2rem",
              width: "25%",
            }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <Input placeholder={"Postal code"} />
          </Form.Item>
        </HorizontalContainer>
        <SubscribeContainer>
          <Form.Item
            valuePropName="checked"
            name="subscribeToMailchimp"
            className="donation-subscribe-radio"
          >
            <Checkbox defaultChecked />
          </Form.Item>
          <HelpText title={"Subscribed to mailing list"} />
        </SubscribeContainer>
      </VerticalContainer>
    </>
  );

  const StatusFields = () => (
    <>
      <FormTitle title={"Status"} size={"20px"} />
      <Form.Item
        name={"donationStatus"}
        style={{
          display: "flex",
          marginTop: "1rem",
          marginBottom: "2rem",
          flexDirection: "row",
        }}
      >
        <Radio.Group
          disabled={
            form.getFieldValue("donationType") === DONATION_TYPE.ONLINE.value
          }
          options={DonationStatusRadioOptions}
        />
      </Form.Item>
    </>
  );

  const formFooter = () => (
    <HorizontalContainerSB>
      <Button shape="round" size={"large"} onClick={navigateToHome}>
        Cancel
      </Button>
      <HorizontalContainer>
        {isShowRefund() && (
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              marginRight: 8,
              background: ThemeColors.colors.orange,
              borderColor: ThemeColors.colors.orange,
            }}
            icon={<ReloadOutlined />}
            onClick={handleRefund}
          >
            Refund
          </Button>
        )}

        <Button
          type="primary"
          shape="round"
          icon={false ? <LoadingOutlined /> : <SaveOutlined />}
          size={"large"}
          onClick={form.submit}
          disabled={!!passedDonationId ? isEditable() : false}
        >
          {!!!passedDonationId ? "Create donation" : "Update donation"}
        </Button>
      </HorizontalContainer>
    </HorizontalContainerSB>
  );

  return (
    <Container>
      <Spin
        size="large"
        spinning={
          refundLoding ||
          statesLoading ||
          creatingDonation ||
          fetchingCustomFields ||
          fetchingDonationDetail ||
          updatingDonation
        }
      >
        <Content style={{ overflow: "auto", marginBottom: "1.5rem" }}>
          <Form.Provider onFormFinish={handleSubmit}>
            <Form
              requiredMark={false}
              layout={"vertical"}
              form={form}
              scrollToFirstError
              initialValues={defaultDonationFields}
            >
              {StaffContainer()}
              <div style={{ marginBottom: "4rem" }}>
                {CustomFieldContainer(staffCustomFields)}
              </div>
              {DonationFormFields()}
              <div style={{ marginBottom: "6rem" }}>
                {loggedInUser.userType === UserRole.ADMIN &&
                  CustomFieldContainer(adminCustomFields)}
              </div>

              {StatusFields()}
            </Form>
          </Form.Provider>
        </Content>
        {formFooter()}
      </Spin>
    </Container>
  );
};

export default DonationForm;
