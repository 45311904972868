import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Spin, message, Drawer, Button } from "antd";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Notifications from "../components/Notifications";
import { Queries } from "./../api";
import Logo from "../logo.svg";
import LogoSm from "../logo_sm.svg";
import Logout from "./Logout";
import {
  DashboardOutlined,
  DesktopOutlined,
  VideoCameraOutlined,
  MenuOutlined,
  AudioOutlined,
  SoundOutlined,
  UserOutlined,
  PlaySquareOutlined,
  ApiOutlined,
  SettingOutlined,
  RiseOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";
import "./layout.css";
import { isFeatureAvailable } from "../FeatureConfig";
import { UserRole, MENU } from "../utils/contants";

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

function PrimaryLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    loading: orgLoading,
    error: orgError,
    data: orgData,
  } = useQuery(Queries.ORGANIZATION, {});

  const {
    loading: meLoading,
    error: meError,
    data: meData,
  } = useQuery(Queries.ME_QUERY, {});

  useEffect(() => {
    if (meError) {
      message.error("server error");
    }
  }, [meError]);

  useEffect(() => {
    if (orgError) {
      message.error("server error");
    }
  }, [orgError]);

  const paths = props.location.pathname.split("/").slice(1);
  const active = paths[0] === "" ? "dashboard" : paths[0];

  const isUserAdmin = () => meData?.me?.userType === UserRole.ADMIN;

  const handleMobileMenu = () => {
    setVisible(true);
  };

  const renderMenu = () => {
    return (
      <Spin
        spinning={meLoading || orgLoading}
        wrapperClassName="menu-wrap-fixed"
      >
        <Menu
          onClick={() => setVisible(false)}
          theme="dark"
          defaultSelectedKeys={[active]}
          mode="inline"
        >
          <div className={"logo-container-desktop"}>
            {collapsed ? (
              <img className="logo-sm" src={LogoSm} alt={"localeyz"} />
            ) : (
              <img className="logo" src={Logo} alt={"localeyz"} />
            )}
          </div>
          {isFeatureAvailable(MENU.DASHBOARD) && (
            <Menu.Item key="dashboard" onClick={() => props.history.push("/")}>
              <DashboardOutlined />
              <span>{MENU.DASHBOARD}</span>
            </Menu.Item>
          )}
          {isFeatureAvailable(MENU.WIDGETS) && (
            <Menu.Item
              key="widgets"
              onClick={() => props.history.push("/widgets")}
            >
              <DesktopOutlined />
              <span>{MENU.WIDGETS}</span>
            </Menu.Item>
          )}
          {isFeatureAvailable(MENU.LIVE_VIDEO) && (
            <Menu.Item
              key="live-video"
              onClick={() => props.history.push("/live-video")}
            >
              <VideoCameraOutlined />
              <span>{MENU.LIVE_VIDEO}</span>
            </Menu.Item>
          )}
          {isFeatureAvailable(MENU.PODCASTS) && (
            <Menu.Item
              key="podcasts"
              onClick={() => props.history.push("/podcasts")}
            >
              <AudioOutlined />
              <span>{MENU.PODCASTS}</span>
            </Menu.Item>
          )}

          {isFeatureAvailable(MENU.RADIO) && (
            <Menu.Item key="radio" onClick={() => props.history.push("/radio")}>
              <SoundOutlined />
              <span>{MENU.RADIO}</span>
            </Menu.Item>
          )}

          {isFeatureAvailable(MENU.PROGRAMS) && (
            <Menu.Item
              key="programs"
              onClick={() => props.history.push("/programs")}
            >
              <VideoCameraOutlined />
              <span>{MENU.PROGRAMS}</span>
            </Menu.Item>
          )}

          {isFeatureAvailable(MENU.EPISODES) && (
            <Menu.Item
              key="episodes"
              onClick={() => props.history.push("/episodes")}
            >
              <PlaySquareOutlined />
              <span>{MENU.EPISODES}</span>
            </Menu.Item>
          )}

          {isFeatureAvailable(MENU.PLAYLISTS) && (
            <Menu.Item
              key="playlist"
              onClick={() => props.history.push("/playlist")}
            >
              <PlaySquareOutlined />
              <span>{MENU.PLAYLISTS}</span>
            </Menu.Item>
          )}

          {isFeatureAvailable(MENU.API_DOCS) && (
            <Menu.Item
              key="api-docs"
              onClick={() => props.history.push("/api-docs")}
            >
              <ApiOutlined />
              <span>{MENU.API_DOCS}</span>
            </Menu.Item>
          )}
          {isFeatureAvailable(MENU.ROADMAP) && (
            <Menu.Item
              key="roadmap"
              onClick={() => props.history.push("/roadmap")}
            >
              <RiseOutlined />
              <span>{MENU.ROADMAP}</span>
            </Menu.Item>
          )}
          {isFeatureAvailable(MENU.DONATIONS) && isUserAdmin() && (
            <Menu.Item
              key="donation"
              onClick={() => props.history.push("/donations")}
            >
              <EuroCircleOutlined />
              <span>{MENU.DONATIONS}</span>
            </Menu.Item>
          )}
          <SubMenu
            key="users"
            title={
              <span>
                <UserOutlined />
                <span>{MENU.USERS}</span>
              </span>
            }
          >
            {isFeatureAvailable(MENU.PRODUCERS) && (
              <Menu.Item
                key={"producer"}
                onClick={() => props.history.push("/users/producer")}
              >
                {MENU.PRODUCERS}
              </Menu.Item>
            )}
            {isFeatureAvailable(MENU.STAFF) && (
              <Menu.Item
                key={"staff"}
                onClick={() => props.history.push("/users/staff")}
              >
                {MENU.STAFF}
              </Menu.Item>
            )}
            {isFeatureAvailable(MENU.GROUPS) && (
              <Menu.Item
                key={"groups"}
                onClick={() => props.history.push("/users/groups")}
              >
                {MENU.GROUPS}
              </Menu.Item>
            )}
            {isUserAdmin() && isFeatureAvailable(MENU.INVITES) && (
              <Menu.Item
                key={"invites"}
                onClick={() => props.history.push("/users/invites")}
              >
                {MENU.INVITES}
              </Menu.Item>
            )}
          </SubMenu>
          <SubMenu
            key="settings"
            title={
              <span>
                <SettingOutlined />
                <span>{MENU.SETTINGS}</span>
              </span>
            }
          >
            {orgData && (
              <Menu.Item
                key="organization"
                onClick={() => props.history.push("/settings/organization")}
              >
                {orgData.organization.title}
              </Menu.Item>
            )}
            {isFeatureAvailable(MENU.INTEGRATIONS) && (
              <Menu.Item
                key="integrations"
                onClick={() => props.history.push("/settings/integrations")}
              >
                {MENU.INTEGRATIONS}
              </Menu.Item>
            )}
            {/*<Menu.Item key="billing">Billing</Menu.Item>*/}
          </SubMenu>
          <Menu.Item>
            <Logout setAuth={props.setAuth} />
          </Menu.Item>
        </Menu>
      </Spin>
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        className={"mobileHeader"}
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className={"logo-container"}>
          <img className="logo" src={Logo} alt={"localeyz"} />
        </div>
        <Button
          className="toggleButton"
          type="primary"
          onClick={handleMobileMenu}
        >
          {React.createElement(MenuOutlined)}
        </Button>
      </Header>
      <Layout>
        <Sider
          className="sideBar"
          collapsible
          breakpoint={"lg"}
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
        >
          {renderMenu()}
        </Sider>
        <Drawer
          title="Menu"
          height="100vh"
          placement={"top"}
          closable={true}
          maskClosable={true}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          {renderMenu()}
        </Drawer>
        <Layout>
          <Notifications />
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              {paths.map((p) => (
                <Breadcrumb.Item key={p}>
                  {p.replace(/\b\w/, (v) => v.toUpperCase())}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            {/*style={{ padding: 24, background: "#fff" }}*/}
            <div style={{ height: "100%" }}>{props.children}</div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Localeyz.io ©2020 Created by First Turn Media
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default withRouter(PrimaryLayout);
