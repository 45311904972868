import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Mutations, Queries } from "../../../api";
import ColorsPicker from "../../../components/ColorsPicker";
import { useMutation, useQuery } from "@apollo/client";
import { message, Button, Input, Spin, Form, Select } from "antd";
import { SaveOutlined, LoadingOutlined } from "@ant-design/icons";
import { trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const Option = Select;

const Container = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  height: 100%;
  .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`;

const HorizontalContainerSB = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const DEFAULT_COLOR = "#5229F4";

const SubscriptionWidget = (props) => {
  const [form] = Form.useForm();
  const [colors, setColors] = useState({
    bgColor: DEFAULT_COLOR,
    textColor: DEFAULT_COLOR,
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });
  const id = props.match.params.id !== "new" ? props.match.params.id : false;

  const [createSearchWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_SEARCH_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createSearchWidget.name} created.`);
        goToWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updateSearchWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_SEARCH_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updateSearchWidget.name} updated.`);
        goToWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const {
    loading: searchWidgetLoading,
    error: searchWidgetError,
    data: searchWidgetData,
  } = useQuery(Queries.SEARCH_WIDGET_BY_ID, {
    skip: !id,
    variables: { id: id },
  });

  useEffect(() => {
    if (id === "new") initForm();
    // eslint-disable-next-line
  }, []);

  const initForm = (data = {}) => {
    form.setFieldsValue({
      name: data?.name || "",
      sidebarStream: data?.sidebarStream || undefined,
    });
  };

  useEffect(() => {
    if (id && searchWidgetData) {
      const searchWidget = searchWidgetData.searchWidgetById;
      initForm(searchWidget);
      setColors({
        bgColor: searchWidget.bgColor,
        textColor: searchWidget.textColor,
        primaryColor: searchWidget.primaryColor,
        secondaryColor: searchWidget.secondaryColor,
      });
    }
    if (searchWidgetError) return message.error(searchWidgetError.message);
    // eslint-disable-next-line
  }, [searchWidgetData, searchWidgetError]);

  const goToWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=search");
  }

  const handleSubmit = () => {
    const formFields = form.getFieldsValue();

    const variables = {
      name: formFields.name,
      bgColor: colors.bgColor,
      textColor: colors.textColor,
      primaryColor: colors.primaryColor,
      secondaryColor: colors.secondaryColor,
      sidebarStream: formFields.sidebarStream,
    };
    if (id) {
      updateSearchWidget({ variables: { id: id, ...variables } });
    } else {
      createSearchWidget({ variables: variables });
    }
  };

  return (
    <Container>
      <Spin
        spinning={
          (id && (searchWidgetLoading || updateLoading)) ||
          (!id && createLoading)
        }
        style={{ width: "100%", alignSelf: "center" }}
      >
        <Form.Provider onFormFinish={() => handleSubmit()} scrollToFirstError>
          <Form form={form} layout={"vertical"}>
            <Form.Item
              label={"Name"}
              name="name"
              style={{ width: "50%", fontWeight: 400, marginBottom: 24 }}
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Input type={"text"} placeholder="Name of search widget" />
            </Form.Item>
          </Form>
          <ColorsPicker
            colors={colors}
            isTextColor={true}
            setColors={setColors}
            isPrimaryColor={true}
            isSecondaryColor={true}
            isBackgroundColor={true}
          />
          <Form form={form} layout={"vertical"}>
            <Form.Item
              name={"sidebarStream"}
              label={"Sidebar stream"}
              rules={[{ required: true, message: "This field is required." }]}
              style={{
                width: "50%",
                marginTop: 0,
                marginBottom: 24,
              }}
            >
              <Select placeholder="Select sidebar stream">
                <Option key={"related_episodes"} value={"related_episodes"}>
                  Related episodes
                </Option>
                <Option key={"recent_episodes"} value={"recent_episodes"}>
                  Most recent episodes
                </Option>
              </Select>
            </Form.Item>
          </Form>

          <HorizontalContainerSB>
            <Button
              shape="round"
              style={{ marginTop: "2rem" }}
              size={"large"}
              onClick={goToWidgets}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              shape="round"
              icon={false ? <LoadingOutlined /> : <SaveOutlined />}
              size={"large"}
              onClick={form.submit}
            >
              {id ? "Update widget" : "Create widget"}
            </Button>
          </HorizontalContainerSB>
        </Form.Provider>
      </Spin>
    </Container>
  );
};

export default SubscriptionWidget;
