import React, { useState, useEffect } from "react";
import {
  DONATION_WIDGET_POPUP_LOGIC,
  DONATION_WIDGET_TYPES,
} from "../../../utils/contants";
import styled from "styled-components";
import TextArea from "antd/lib/input/TextArea";
import { Mutations, Queries } from "../../../api";
import HelpText from "../../../components/HelpText";
import { useMutation, useQuery } from "@apollo/client";
import ColorsPicker from "../../../components/ColorsPicker";
import { Button, Input, message, Spin, Form, Divider } from "antd";
import { SaveOutlined, LoadingOutlined } from "@ant-design/icons";
import UploadMultiple from "./../../../components/UploadMultiple";
import MailchimpFields from "../../../components/MailchimpFields";
import FormIntroWidgets from "../../../components/FormIntroWidgets";
import WidgetTypeSelect from "../../../components/WidgetTypeSelect";
import { trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const Container = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  height: 100%;
  h3 {
    margin: 0px 12px 0px 0px;
  }
`;

const HorizontalContainerSB = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const HorizontalContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
`;

const DEFAULT_COLOR = "#5229F4";

const DonationWidget = (props) => {
  const [form] = Form.useForm();
  const [colors, setColors] = useState({
    bgColor: DEFAULT_COLOR,
    textColor: DEFAULT_COLOR,
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });
  const id = props.match.params.id ? props.match.params.id : "new";

  const { data: donationWidgetData, loading: fetchingWidgetDetail } = useQuery(
    Queries.DONATION_WIDGET_BY_ID,
    {
      variables: {
        id: id,
      },
      skip: id === "new",
      onError: (err) => {
        message.error(trimErrorMessage(err.message));
        goToListWidgets();
      },
    }
  );

  const [createWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_DONATION_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createDonationWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updateWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_DONATION_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updateDonationWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  useEffect(() => {
    if (id === "new") {
      setFormData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (donationWidgetData?.donationWidgetById) {
      const { donationWidgetById } = donationWidgetData;
      setFormData(donationWidgetById);

      setColors({
        primaryColor: donationWidgetById.primaryColor,
        secondaryColor: donationWidgetById.secondaryColor,
        textColor: donationWidgetById.textColor,
        bgColor: donationWidgetById.bgColor,
      });
    }
    // eslint-disable-next-line
  }, [donationWidgetData]);

  const setFormData = (data = {}) => {
    form.setFieldsValue({
      name: data?.name || "",
      title: data?.formTitle || "",
      copy: data?.footerText || "",
      message: data?.formMessage || "",
      type: data?.donationWidgetType || undefined,
      popupDelayHours: data?.popupDelayHours || 1,
      successMessage: data?.formSuccessMessage || "",
      images: data?.images?.map((item) => item.url) || [],
      successEmailMessage: data?.formSuccessEmailMessage || "",
      mailchimpListId: data?.footerMailchimpListId || undefined,
      mailchimpSub: data?.footerHasMailchimpSubscription || false,
      mailchimpSegmentId: data?.footerMailchimpSegmentId || undefined,
      popUpLogic: data?.popupType || DONATION_WIDGET_POPUP_LOGIC.ONE_TIME.value,
    });
  };

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=donation");
  }

  const handleSubmit = () => {
    const fields = form.getFieldsValue();
    const variables = {
      name: fields.name,
      donationWidgetType: fields.type,
      primaryColor: colors.primaryColor,
      secondaryColor: colors.secondaryColor,
      textColor: colors.textColor,
      bgColor: colors.bgColor,
      formTitle: fields.title,
      formMessage: fields.message,
      formSuccessMessage: fields.successMessage,
      footerText: fields.copy || "",
      footerMailchimpListId: fields.mailchimpListId || "",
      footerMailchimpSegmentId: fields.mailchimpSegmentId || "",
      footerHasMailchimpSubscription: fields.mailchimpSub || false,
      popupType: fields.popUpLogic,
      popupDelayHours: parseInt(fields.popupDelayHours),
      formSuccessEmailMessage: fields.successEmailMessage,
      images: fields.images || [],
    };

    if (id === "new") {
      createWidget({
        variables: variables,
      });
    } else {
      updateWidget({
        variables: {
          id: id,
          ...variables,
        },
      });
    }
  };

  return (
    <Container>
      <Spin
        spinning={createLoading || fetchingWidgetDetail || updateLoading}
        style={{ width: "100%", alignSelf: "center" }}
      >
        <Form.Provider onFormFinish={() => handleSubmit()} scrollToFirstError>
          <Form form={form} layout={"vertical"}>
            <Form.Item
              label={<HelpText title={"Name"} />}
              name="name"
              style={{ width: "40%", fontWeight: 600, marginBottom: 18 }}
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Input type={"text"} placeholder="Name of donation widget" />
            </Form.Item>
          </Form>

          <WidgetTypeSelect
            form={form}
            TYPES={DONATION_WIDGET_TYPES}
            POPUP_TYPES={DONATION_WIDGET_POPUP_LOGIC}
          />

          <ColorsPicker
            colors={colors}
            isTextColor={true}
            setColors={setColors}
            isPrimaryColor={true}
            isSecondaryColor={true}
            isBackgroundColor={true}
          />

          <h2>Donation form intro</h2>

          <FormIntroWidgets form={form} widgetType={"donation"} />

          <h2>Form footer</h2>

          <Form form={form} layout={"vertical"}>
            <HorizontalContainer>
              <Divider
                plain
                type={"vertical"}
                style={{ height: "auto", marginRight: "1rem" }}
              />

              <Form.Item
                label={<HelpText title={"Images"} />}
                name={"images"}
                style={{ marginRight: "4rem", marginTop: 0, marginBottom: 0 }}
              >
                <UploadMultiple
                  dir={"staff/avatar"}
                  images={form.getFieldValue("images")}
                  setImages={(img) =>
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      images: img,
                    })
                  }
                  allowImages={3}
                  squareCrop={true}
                />
              </Form.Item>

              <Form.Item
                label={<HelpText title={"Message"} />}
                name={"copy"}
                style={{ width: "60%", margin: 0 }}
              >
                <TextArea
                  placeholder={"Form footer message"}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </HorizontalContainer>
          </Form>

          <MailchimpFields form={form} />

          <HorizontalContainerSB>
            <Button
              shape="round"
              size={"large"}
              onClick={goToListWidgets}
              style={{ marginTop: "2rem" }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              shape="round"
              icon={false ? <LoadingOutlined /> : <SaveOutlined />}
              size={"large"}
              onClick={form.submit}
            >
              {id === "new" ? "Create widget" : "Update widget"}
            </Button>
          </HorizontalContainerSB>
        </Form.Provider>
      </Spin>
    </Container>
  );
};

export default DonationWidget;
