import React from 'react';
import YouTubeProvider from "./youtube";
import DefaultProvider from "./default";

const providers = {
    'youtube': YouTubeProvider
};

const NotificationProvider = ({notification}) => {
    console.log(notification);
    switch (notification?.type) {
        case 'websocket':
            const subject = notification?.subject ? notification.subject.split(':') : null;
            const topic = subject?.[0];
            const CustomComponent = providers?.[topic] || DefaultProvider;

            return <CustomComponent notification={notification}/>;

        default:
            return <DefaultProvider notification={notification}/>;
    }
};

export default NotificationProvider;
