import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Queries, Mutations } from "../../../api";
import { Button, message, Spin, Form } from "antd";
import WidgetForm from "../../../components/WidgetForm";
import { useMutation, useQuery } from "@apollo/client";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { getSession } from "../../../helpers/SessionManagement";
import { trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const HorizontalContainerSB = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const DEFAULT_COLOR = "#5229F4";

const FORM_FIELDS = {
  name: {
    key: "name",
    label: "Name",
    placeHolder: "Live video widget name",
  },
  select: {
    key: "liveVideos",
    label: "Live Videos",
    placeHolder: "Select live videos to include",
  },
  dropdown: {
    key: "layout",
    label: "Layout",
    placeHolder: "Select Layout",
    options: [
      { id: "HORIZONTAL", value: "Horizontal" },
      { id: "STACKED", value: "Stacked" },
    ],
  },
};

const CreateLiveVideoList = (p) => {
  let videos = [];
  if (p) {
    p.forEach((p) => {
      videos.push(p.id);
    });
  }
  return videos;
};

const LiveVideoWidget = (props) => {
  const id = props.match.params.id !== "new" ? props.match.params.id : "new";
  const [form] = Form.useForm();
  const [colors, setColors] = useState({
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });

  const [createLiveVideoWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_LIVE_VIDEO_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createLiveVideoWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updateLiveVideoWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_LIVE_VIDEO_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updateLiveVideoWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const { loading: liveVideoWidgetLoading, data: liveVideoWidgetData } =
    useQuery(Queries.LIVE_VIDEO_WIDGET_BY_ID, {
      skip: id === "new",
      variables: { id: id },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    });

  const { data: liveVideosData, loading: liveVideosLoading } = useQuery(
    Queries.ALL_LIVE_VIDEOS,
    {
      onError: () => message.error("server error"),
    }
  );

  useEffect(() => {
    if (id === "new") {
      initForm();
      loadColors(
        getSession().organization?.primaryColor || DEFAULT_COLOR,
        getSession().organization?.secondaryColor || DEFAULT_COLOR
      );
    }

    if (id !== "new" && liveVideoWidgetData) {
      initForm(liveVideoWidgetData);
      loadColors(
        liveVideoWidgetData.liveVideoWidgetById.primaryColor,
        liveVideoWidgetData.liveVideoWidgetById.secondaryColor
      );
    }
    // eslint-disable-next-line
  }, [liveVideoWidgetData, id]);

  const loadColors = (primaryColor, secondaryColor) => {
    setColors({
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
    });
  };

  const initForm = (data = {}) => {
    form.setFieldsValue({
      name: data?.liveVideoWidgetById?.name || "",
      liveVideos: CreateLiveVideoList(data?.liveVideoWidgetById?.liveVideo),
      layout: data?.liveVideoWidgetById?.layout || "",
    });
  };

  const handleSubmit = () => {
    const formFields = form.getFieldsValue();
    const variables = {
      name: formFields.name,
      liveVideos: formFields.liveVideos,
      layout: formFields.layout,
      primaryColor: colors.primaryColor,
      secondaryColor: colors.secondaryColor,
    };
    if (id !== "new") {
      updateLiveVideoWidget({ variables: { id: id, ...variables } });
    } else {
      createLiveVideoWidget({ variables });
    }
  };

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=live-video");
  };

  return (
    <div style={{ padding: 24, background: "#ffffff" }}>
      <Spin
        spinning={
          (id !== "new" && liveVideoWidgetLoading) ||
          createLoading ||
          updateLoading
        }
      >
        <WidgetForm
          form={form}
          colors={colors}
          setColors={setColors}
          isPrimaryColor={true}
          isSecondaryColor={true}
          FORM_FIELDS={FORM_FIELDS}
          handleSubmit={handleSubmit}
          selectLoading={liveVideosLoading}
          selectOptions={liveVideosData?.liveVideos}
        />

        <HorizontalContainerSB>
          <Button shape="round" size={"large"} onClick={goToListWidgets}>
            Cancel
          </Button>

          <Button
            type="primary"
            shape="round"
            size={"large"}
            onClick={form.submit}
            style={{ marginTop: 16 }}
            icon={false ? <LoadingOutlined /> : <SaveOutlined />}
          >
            {id !== "new" ? "Update widget" : "Create widget"}
          </Button>
        </HorizontalContainerSB>
      </Spin>
    </div>
  );
};

export default LiveVideoWidget;
