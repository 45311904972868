import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomFields from "../CustomField";
import ChangeEmailModal from "../ChangeEmailModal";
import { SocialLinksComponent } from "../LinksComponent";
import PasswordAlert from "../PasswordAlert";
import states from "../../screens/settings/organization/state";
import { SaveOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Collapse, Form, Input, Select, Row, Col, Button, Spin } from "antd";
import moment from "moment";
import { UserRole } from "../../utils/contants";
import UploadMultiple from "../UploadMultiple";
import { useWorker } from "../../hooks/useWorker";

const { Option } = Select;
const { TextArea } = Input;

const Container = styled.div`
  background: white;
  padding: 2.543rem;
  margin-top: 3rem;
  .save {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    border-radius: 50%;
  }
`;

const { Panel } = Collapse;

const UserInfoForm = ({
  form,
  onSubmit,
  userData,
  isLoaderDisabled,
  loading,
  squareImage,
  setSquareImage,
  socialLinksList,
  setSocialLinksList,
  disableUsername,
  disabledFields,
  isUpdateMode,
  onCancel,
  customFields,
  handleEmailChange,
}) => {
  const [neonInfo, setNeonInfo] = useState(null);
  const [password, setPassword] = useState("");
  const isLoading = isLoaderDisabled ? false : loading;
  const isDisabled = (fieldname) => {
    return (
      disabledFields && fieldname in disabledFields && disabledFields[fieldname]
    );
  };

  const { workerMessage, sendWorkerMessage } = useWorker("neon:user");

  useEffect(() => {
    if (userData?.neonCrmId) {
      sendWorkerMessage({
        type: "websocket",
        subject: "neon:user",
        message: {
          debug: false,
          neonCrmId: userData.neonCrmId,
        },
      });
    } else {
      setNeonInfo(false);
    }
  }, [sendWorkerMessage, userData]);

  useEffect(() => {
    setNeonInfo(workerMessage?.info?.user);
  }, [workerMessage]);

  return (
    <Spin spinning={isLoading}>
      <Container>
        <Form.Provider onFormFinish={onSubmit}>
          <Form layout={"vertical"} form={form} scrollToFirstError>
            <Form.Item
              style={isUpdateMode && { marginBottom: 0 }}
              label="Email"
              name={"email"}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Input
                disabled={isDisabled("email")}
                type={"text"}
                size={"large"}
                placeholder="Email address"
              />
            </Form.Item>
            {isUpdateMode && (
              <Form.Item style={{ marginBottom: 10 }}>
                <ChangeEmailModal
                  handleEmailChange={(email) => handleEmailChange(email)}
                />
              </Form.Item>
            )}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Form.Item
                label="First name"
                name={"firstname"}
                style={{ width: "50%", marginRight: "2rem" }}
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input
                  disabled={isDisabled("firstname")}
                  size={"large"}
                  placeholder="First name"
                />
              </Form.Item>
              <Form.Item
                label="Last name"
                name={"lastname"}
                style={{ width: "50%" }}
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input
                  disabled={isDisabled("lastname")}
                  size={"large"}
                  placeholder="Last name"
                />
              </Form.Item>
            </div>

            {!disableUsername && (
              <Form.Item
                label="Username"
                name={"username"}
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input size={"large"} placeholder={"i.e John"} />
              </Form.Item>
            )}

            {!isUpdateMode && (
              <PasswordAlert isShow={true} password={password} />
            )}
            {!isUpdateMode && (
              <Form.Item
                label="Password"
                name={"password"}
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input.Password
                  size={"large"}
                  placeholder={"password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
            )}
            <Form.Item label="Street address" name={"address"}>
              <Input size={"large"} placeholder="Your street address" />
            </Form.Item>
            <Row>
              <Col span={6}>
                <Form.Item label="State" name={"state"}>
                  <Select name={"state"} style={{ width: 200 }} size={"large"}>
                    {states.map((state, i) => {
                      return (
                        <Option key={state + i} value={state}>
                          {state}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="City" name={"city"}>
                  <Input
                    style={{ width: 200 }}
                    size={"large"}
                    placeholder="City"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Zip code" name={"zipcode"}>
                  <Input style={{ width: 200 }} size={"large"} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Phone number" name={"phone"}>
                  <Input type={"tel"} size={"large"} style={{ width: 200 }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Profile picture" name={"avatar"}>
                  <UploadMultiple
                    dir={"staff/avatar"}
                    type="avatar"
                    images={squareImage}
                    setImages={(img) => setSquareImage(img?.toString() || "")}
                    allowImages={1}
                    squareCrop={true}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <h4>Membership</h4>
                {neonInfo === null && (
                  <div
                    style={{ backgroundColor: "#DADADA", padding: "15px 10px" }}
                  >
                    Loading...
                  </div>
                )}

                {neonInfo === false && (
                  <div
                    style={{ backgroundColor: "#DADADA", padding: "15px 10px" }}
                  >
                    No membership info to display.
                  </div>
                )}

                {neonInfo?.id && (
                  <>
                    <div
                      style={{
                        backgroundColor: "#DADADA",
                        padding: "15px 10px",
                      }}
                    >
                      <p>
                        <strong>Neon Contact ID:</strong> {neonInfo?.id}
                      </p>

                      <Collapse onChange={() => {}}>
                        {neonInfo.memberships.map((mem, i) => (
                          <Panel
                            key={i}
                            header={<strong>{mem?.level}</strong>}
                            style={{
                              ...(mem?.active
                                ? { backgroundColor: "#cfefbf" }
                                : { backgroundColor: "#efbfbf" }),
                            }}
                            extra={
                              mem?.active ? (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Active</span>{" "}
                                  <CheckOutlined
                                    style={{ marginLeft: "10px" }}
                                  />
                                </span>
                              ) : (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Inactive</span>{" "}
                                  <CloseOutlined
                                    style={{ marginLeft: "10px" }}
                                  />
                                </span>
                              )
                            }
                          >
                            <ul style={{ marginBottom: 0 }}>
                              <li>
                                <strong>Status:</strong>{" "}
                                {mem?.active ? "Active" : "Inactive"}
                              </li>
                              <li>
                                <strong>Membership Type:</strong> {mem?.level}
                              </li>
                              <li>
                                <strong>Join Date:</strong>{" "}
                                {moment
                                  .unix(mem?.joinDate)
                                  .format("MM/DD/YYYY")}
                              </li>
                              <li>
                                <strong>Renewal Date:</strong>{" "}
                                {moment
                                  .unix(mem?.renewalDate)
                                  .format("MM/DD/YYYY")}
                              </li>
                              <li>
                                <strong>Link:</strong>{" "}
                                <a
                                  style={{ textDecoration: "underline" }}
                                  href={mem?.linkUrl}
                                  target="_blank"
                                >
                                  Open in Neon
                                </a>
                              </li>
                            </ul>
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={25}>
                <Form.Item label="Bio" name={"bio"}>
                  <TextArea
                    size={"large"}
                    rows={6}
                    style={{ width: "800px" }}
                    placeholder="Tell us something about you!"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Social links" name={"socialMediaLinks"}>
              <SocialLinksComponent
                data={socialLinksList}
                links={(list) => setSocialLinksList(list)}
              />
            </Form.Item>
          </Form>
          {customFields?.length > 0 &&
            form.getFieldValue("userType") === UserRole.PRODUCER && (
              <CustomFields
                form={form}
                key={customFields}
                customFields={customFields}
              />
            )}
        </Form.Provider>
        <div className={"save"}>
          <Button type="default" shape="round" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            icon={<SaveOutlined />}
            size={"large"}
            onClick={() => {
              form.submit();
            }}
          >
            {!isUpdateMode ? "Sign Up" : "Save"}
          </Button>
        </div>
      </Container>
    </Spin>
  );
};

export default UserInfoForm;
