import COUNTRIES from "./countries.json";

const URLS = {
    API_BASE_URL: process.env.REACT_APP_API_URL,
    FRONT_END_BASE_URL: process.env.REACT_APP_FRONT_END_BASE_URL,
    API_ANALYTICS: process.env.REACT_APP_SERVICES_API,
};
const PROXY_URL = "https://app.localeyz.io/proxy";
const GOOGLE_API_KEY = "AIzaSyAE_MsuJRaEcdMt9eaFvleQA2ocxrrzBTA";
const SALSA_LABS_GET_STATES_API_URL =
    "https://default.salsalabs.org/api/widget/stateProvinces/";
const USER_TYPES = {
    ADMIN: "admin",
    STAFF: "staff",
    PRODUCER: "producer"
}

export default URLS;
export { GOOGLE_API_KEY, PROXY_URL, COUNTRIES, SALSA_LABS_GET_STATES_API_URL, USER_TYPES };
