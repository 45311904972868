import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  Layout,
  Form,
  Input,
  Select,
  Radio,
  Switch,
  message,
  Spin,
} from "antd";
import moment from "moment";
import { Queries, Mutations } from "../../api";
import CustomFields from "../../components/CustomField";
import UploadMultiple from "./../../components/UploadMultiple";
import DocFilesComponent from "../../components/DocFileComponent";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FormTitle, FormFooter } from "./../../components/HeaderFooter";
import { ExternalLinksComponent } from "./../../components/LinksComponent";
import {
  debounce,
  trimErrorMessage,
  initialExternalLink,
  checkForDeleteProducers,
} from "../../utils";
import { defaultStatus, FIELD_TYPE, SEARCH_DELAY } from "../../utils/contants";
import { clearDirectusCache } from "../../helpers/ClearDirectusCache";

const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1142px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1142px) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  .upload {
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      width: 10rem;
      height: 10rem;
    }
  }
  @media screen and (max-width: 1142px) {
    width: 100%;
  }
  .ant-select-selector {
    height: auto !important;
    align-items: center !important;
  }
  .ant-select-selection-item {
    line-height: 22px !important;
    white-space: normal !important;
    text-overflow: unset !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
`;

const NewProgram = (props) => {
  const [form] = Form.useForm();
  const [docList, setDocList] = useState([]);
  const [producers, setProducers] = useState([]);
  const [thumbnail, setThumbnail] = useState("");
  const [docLoading, setDocLoading] = useState(false);
  const [queryProducer, setQueryProducer] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [externalLinks, setExternalLinks] = useState(initialExternalLink);
  const programId =
    props.match.params.id === "new" ? false : props.match.params.id;

  const {
    data: programFieldsData,
    error: programFieldsDataError,
    loading: programFieldsDataLoading,
  } = useQuery(Queries.NEW_PROGRAM_FIELDS_DATA);

  const [
    getProgramData,
    {
      data: programDataResponse,
      loading: programDataLoading,
      error: programDataError,
    },
  ] = useLazyQuery(Queries.PROGRAM_BY_ID, {
    variables: { id: programId },
    fetchPolicy: "no-cache",
  });

  const [
    createProgram,
    { data: createProgramData, loading: createProgramLoading },
  ] = useMutation(Mutations.CREATE_PROGRAM, {
    onError: (e) => message.error(trimErrorMessage(e.message)),
  });

  const [
    updateProgram,
    { data: updateProgramData, loading: updateProgramLoading },
  ] = useMutation(Mutations.UPDATE_PROGRAM, {
    onError: (e) => message.error(trimErrorMessage(e.message)),
  });

  const [
    deletePrograms,
    { loading: deleteLoading, data: deleteData },
  ] = useMutation(Mutations.DELETE_PROGRAMS);

  const {
    data: producerForDropdownData,
    loading: producerForDropdownLoading,
  } = useQuery(Queries.PRODUCERS_FOR_DROPDOWN, {
    variables: {
      producerQuery: queryProducer,
    },
    onError: (err) => message.error(trimErrorMessage(err.message)),
  });

  const getUpdatedTopics = () => {
    const updatedTopics = programFieldsData?.programSetting?.topics.filter(
      (topic) =>
        programDataResponse?.program?.topics.some(
          (item) => topic.id === item.id
        )
    );
    return updatedTopics || [];
  };

  const getUpdatedType = () => {
    const updatedTypes = programFieldsData?.programSetting?.programTypes?.filter(
      (type) => programDataResponse?.program?.programType?.id === type.id
    );
    return updatedTypes?.[0] || null;
  };

  const gotoPrograms = () => {
    props.history.push("/programs");
  };

  const setFormFields = (data = {}, topics = [], type = null) => {
    let customField = [];
    if (data?.customFieldValue?.length > 0) {
      data.customFieldValue.forEach((obj) => {
        customField[obj.id] = obj.value
          ? moment(JSON.parse(obj.value), moment.ISO_8601).isValid()
            ? moment(JSON.parse(obj.value), moment.ISO_8601)
            : JSON.parse(obj.value)
          : undefined;
      });
    }

    form.setFieldsValue({
      image: data?.image || "",
      title: data?.title || "",
      documents: data?.documents || [],
      published: data?.published || false,
      programType: type || undefined,
      externalLinks: data?.externalLinks || [],
      notesToProducer: data?.producerNote || "",
      fullDescription: data?.fullDescription || "",
      topics: topics,
      ...customField,
      executiveProducerId: data?.producer?.id || undefined,
      assistantProducers: data?.assistantProducers?.map((i) => i.id) || [],
      status:
        data?.status === "DRAFT"
          ? defaultStatus.inReview
          : data?.status || defaultStatus.inReview,
    });
  };

  useEffect(() => {
    if (programDataError) {
      message.error(trimErrorMessage(programDataError.message));
    }
    if (programFieldsDataError) {
      message.error(trimErrorMessage(programFieldsDataError.message));
    }
    // eslint-disable-next-line
  }, [programDataError, programFieldsDataError]);

  useEffect(() => {
    if (programDataResponse && producerForDropdownData) {
      const _producers = producerForDropdownData?.producers?.nodes;
      let missingProducers = checkForDeleteProducers(_producers, [
        programDataResponse?.program?.producer,
      ]);
      let missingAssistantProducers = checkForDeleteProducers(
        _producers,
        programDataResponse?.program?.assistantProducers
      );
      setProducers([
        ..._producers,
        ...missingProducers,
        ...missingAssistantProducers,
      ]);
    } else if (producerForDropdownData) {
      setProducers(producerForDropdownData?.producers?.nodes);
    }

    // eslint-disable-next-line
  }, [programDataResponse, producerForDropdownData]);

  useEffect(() => {
    if (updateProgramData) {
      message.success(`Program updated successfully.`);
      gotoPrograms();
    }
    if (createProgramData) {
      message.success(`Program created successfully.`);
      gotoPrograms();
    }
    // eslint-disable-next-line
  }, [updateProgramData, createProgramData]);

  useEffect(() => {
    if (programId) {
      getProgramData();
    } else {
      setFormFields();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (programDataResponse) {
      const _topics = getUpdatedTopics();
      const _type = getUpdatedType();
      setFormFields(
        programDataResponse?.program,
        _topics?.map((topic) => topic.id),
        _type?.id
      );
      setThumbnail(programDataResponse?.program?.image);
      setDocList(
        programDataResponse?.program?.documents?.map((item) => item.url)
      );
      setExternalLinks(
        programDataResponse?.program?.externalLinks.map((item, id) => ({
          id,
          url: item.url,
          title: item.title,
        }))
      );
    }
    // eslint-disable-next-line
  }, [programDataResponse]);

  const onProducerSearch = (str) => setQueryProducer(str);

  const debounceOnProducerQuery = useCallback(
    debounce(onProducerSearch, SEARCH_DELAY),
    []
  );

  const onCancel = () => {
    gotoPrograms();
  };

  const handleDelete = (programId) => {
    deletePrograms({
      variables: {
        ids: [programId],
      },
    }).catch((error) => message.error(error.message));
    props.history.goBack();
  };

  const isStaffFields = () =>
    programFieldsData?.programSetting?.programCustomFields?.filter(
      (customField) => customField.staff
    ).length > 0;

  const onSubmit = async () => {
    if (thumbnail) {
      const fields = form.getFieldsValue();
      if (
        fields[FIELD_TYPE.ASSISTANT_PRODUCERS].includes(
          fields[FIELD_TYPE.EXECUTIVE_PRODUCER]
        )
      ) {
        message.error(
          "Exective producer and Assistant producer can't be same."
        );
        return;
      }
      fields[FIELD_TYPE.DOCUMENTS] = docList;
      fields[FIELD_TYPE.IMAGE] = thumbnail;
      fields[FIELD_TYPE.EXTERNAL_LINKS] = externalLinks.map((item) => ({
        title: item.title,
        url: item.url,
      }));
      let customFieldValue = [];
      programFieldsData.programSetting.programCustomFields.forEach(
        (customField) => {
          customFieldValue.push({
            id: customField.id,
            value:
              fields[customField.id] === undefined
                ? ""
                : JSON.stringify(fields[customField.id]),
          });
        }
      );
      const variables = {
        assistantProducers: fields[FIELD_TYPE.ASSISTANT_PRODUCERS],
        customFieldValue,
        documents: docList,
        executiveProducerId: fields[FIELD_TYPE.EXECUTIVE_PRODUCER],
        externalLinks: externalLinks.filter(
          (item) => item.url && delete item.id
        ),
        fullDescription: fields[FIELD_TYPE.FULL_DESCRIPTION],
        image: thumbnail,
        notesToProducer: fields[FIELD_TYPE.NOTES_TO_PRODUCER],
        programType: fields[FIELD_TYPE.PROGRAM_TYPE],
        published: fields[FIELD_TYPE.PUBLISHED],
        status: fields[FIELD_TYPE.STATUS],
        topics: fields[FIELD_TYPE.TOPICS],
        title: fields[FIELD_TYPE.TITLE],
      };

      // Clearing directus cache for showing table view with updated records
      await clearDirectusCache();

      if (programId) {
        updateProgram({
          variables: {
            id: programId,
            ...variables,
          },
        });
      } else {
        createProgram({
          variables,
        });
      }
    } else {
      message.error("Program thumbnail is required.");
    }
  };

  const ProducerCustomFields = () => (
    <LeftContainer>
      {programFieldsData &&
        programFieldsData?.programSetting?.programCustomFields.length > 0 && (
          <CustomFields
            form={form}
            key={programFieldsData}
            customFields={programFieldsData?.programSetting?.programCustomFields.filter(
              (customField) => customField.staff === false
            )}
          />
        )}
    </LeftContainer>
  );

  const ProducerFormOne = () => (
    <>
      <FormTitle title={"Program fields"} size={"24px"} />
      <FormTitle title={"Program details"} size={"16px"} />

      <HorizontalContainer>
        <LeftContainer>
          <Form.Item
            label="Program title"
            name={FIELD_TYPE.TITLE}
            style={{ marginBottom: 38, width: "50%" }}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input type={"text"} placeholder="Program title" />
          </Form.Item>
          <Form.Item
            label="Program type"
            name={FIELD_TYPE.PROGRAM_TYPE}
            style={{ marginBottom: 38, width: "50%" }}
          >
            <Select placeholder="Select">
              {programFieldsData?.programSetting?.programTypes?.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </LeftContainer>

        <RightContainer>
          <Form.Item
            label="Exective producer"
            name={FIELD_TYPE.EXECUTIVE_PRODUCER}
            style={{ marginBottom: 38, width: "70%" }}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select
              showSearch
              showArrow={true}
              filterOption={true}
              notFoundContent={null}
              optionFilterProp={"filter"}
              defaultActiveFirstOption={false}
              placeholder="Executive producer"
              loading={producerForDropdownLoading}
              onSearch={(query) => {
                if (query.trim() === "" && query.length === 0) return;
                debounceOnProducerQuery(query);
              }}
            >
              {producers?.map((item) => (
                <Option key={item.id} filter={item.name}>
                  {item.name && item.email
                    ? `${item.name} (${item?.email})`
                    : `${item.name}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Assistant Producer"
            name={FIELD_TYPE.ASSISTANT_PRODUCERS}
            style={{ marginBottom: 38, width: "70%" }}
          >
            <Select
              mode="multiple"
              optionFilterProp={"filter"}
              placeholder="Search producers"
              loading={producerForDropdownLoading}
              onSearch={(query) => {
                if (query.trim() === "" && query.length === 0) return;
                debounceOnProducerQuery(query);
              }}
            >
              {producers?.map((item) => (
                <Option key={item.id} filter={item.name}>
                  {item.name && item.email
                    ? `${item.name} (${item?.email})`
                    : `${item.name}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  const topics = [...(programFieldsData?.programSetting?.topics || [])];
  topics.sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }

    if (a.title > b.title) {
      return 1;
    }

    return 0;
  });

  const ProducerFormTwo = () => (
    <>
      <FormTitle title={"Program information"} size={"16px"} />

      <HorizontalContainer>
        <LeftContainer>
          <Form.Item
            label="Full description"
            name={FIELD_TYPE.FULL_DESCRIPTION}
            style={{ marginBottom: 38, width: "70%" }}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <TextArea
              autoSize={{ minRows: 7, maxRows: 10 }}
              placeholder="Full description of your program"
            />
          </Form.Item>
          <Form.Item
            label="Main Topics"
            name={FIELD_TYPE.TOPICS}
            style={{ marginBottom: 38, width: "70%" }}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Select
              mode="multiple"
              placeholder="Add topics"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {topics.map((topic) => (
                <Option key={topic.id} value={topic.id}>
                  {topic.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </LeftContainer>
        <RightContainer>
          <div style={{ width: "70%" }}>
            <ExternalLinksComponent
              data={externalLinks}
              title={"External links"}
              links={(list) => setExternalLinks(list)}
            />
          </div>
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  const ProducerFormThree = () => (
    <>
      <FormTitle title={"Program media"} size={"16px"} />

      <HorizontalContainer>
        <LeftContainer>
          <DocFilesComponent
            key={docList}
            data={docList}
            dir={"vod/program/doc"}
            onLoadingChanges={setDocLoading}
            docFiles={(list) => setDocList(list)}
          />
        </LeftContainer>
        <RightContainer>
          <Form.Item
            label="Program image"
            style={{ width: "40%" }}
            required={true}
          >
            <UploadMultiple
              allowImages={1}
              images={thumbnail}
              squareCrop={false}
              dir={"vod/program/thumbnail"}
              onLoadingChanges={setImageLoading}
              setImages={(img) => setThumbnail(img.toString())}
            />
          </Form.Item>
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  const ModerationForm = () => (
    <>
      <FormTitle title={"Moderation states"} size={"24px"} />
      <HorizontalContainer>
        <LeftContainer>
          <Form.Item
            label="Status"
            name={FIELD_TYPE.STATUS}
            style={{ paddingLeft: 2 }}
          >
            <Radio.Group>
              <Radio value={defaultStatus.declined}>Declined</Radio>
              <Radio value={defaultStatus.inReview}>In review</Radio>
              <Radio value={defaultStatus.accept}>Accepted</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Note to producer"
            name={FIELD_TYPE.NOTES_TO_PRODUCER}
            style={{ marginBottom: 38, width: "80%" }}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 8 }}
              placeholder="Optional note to producer"
            />
          </Form.Item>
        </LeftContainer>
        <RightContainer>
          <Form.Item
            label="Published"
            name={FIELD_TYPE.PUBLISHED}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  const StaffCustomFields = () => (
    <>
      <FormTitle title={"Staff fields"} size={"24px"} />
      <LeftContainer>
        {isStaffFields() ? (
          <CustomFields
            form={form}
            key={programFieldsData}
            customFields={programFieldsData?.programSetting?.programCustomFields?.filter(
              (customField) => customField.staff
            )}
          />
        ) : (
          <p style={{ color: "#C4C4C4" }}>No staff field</p>
        )}
      </LeftContainer>
    </>
  );

  return (
    <Layout style={{ padding: 44, background: "white" }}>
      <Spin
        spinning={
          programFieldsDataLoading ||
          programDataLoading ||
          updateProgramLoading ||
          createProgramLoading
        }
      >
        <Content style={{ overflow: "auto", marginBottom: "1.5rem" }}>
          <Form.Provider onFormFinish={() => onSubmit()}>
            {StaffCustomFields()}
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {ProducerFormOne()}
            </Form>
            {ProducerCustomFields()}
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {ProducerFormTwo()}
            </Form>
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {ProducerFormThree()}
            </Form>
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {ModerationForm()}
            </Form>
          </Form.Provider>
        </Content>
        <FormFooter
          showSwitch={false}
          showDelete={programDataResponse ? true : false}
          name={programDataResponse?.program?.title}
          handleDelete={() => handleDelete(programDataResponse?.program?.id)}
          disablePositive={false}
          docLoading={docLoading}
          negativeBtnTitle="Cancel"
          imageLoading={imageLoading}
          onCancel={() => onCancel()}
          onSubmit={() => form.submit()}
          positiveBtnTitle={programId ? "Save program" : "Create program"}
        />
      </Spin>
    </Layout>
  );
};

export default NewProgram;
