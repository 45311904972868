import Landing from "../screens/dashboard";
import Organzation from "../screens/settings/organization";
import Integrations from "../screens/settings/integrations";
import Podcasts from "./../screens/podcasts";
import PodcastForm from "./../screens/podcasts/form";
import Radio from "./../screens/radio";
import RadioForm from "./../screens/radio/form";
import LiveVideo from "./../screens/liveVideo";
import LiveVideoForm from "./../screens/liveVideo/form";

import Playlist from "../screens/playlist";
import PlaylistForm from "../screens/playlist/form";

import Widgets from "./../screens/widgets";
import RadioWidget from "../screens/widgets/radioWidget";
import WidgetsPreview from "./../screens/widgets/Preview";
import PodcastWidget from "../screens/widgets/podcastWidget";
import PlaylistWidget from "../screens/widgets/playlistWidget";
import ProducerPortalWidget from "../screens/widgets/producerPortalWidget";
import ScheduleWidget from "../screens/widgets/scheduleWidget";
import LiveVideoWidget from "../screens/widgets/liveVideoWidget";
import FooterWidget from "../screens/widgets/footerWidget";
import Episode from "../screens/episode";
import Roadmap from "../screens/roadmap";
import ApiDocs from "../screens/apiDocs";
import Programs from "../screens/programs";
import StaffUsers from "../screens/users/staff";
import NewEpisode from "../screens/episode/new";
import NewProgram from "../screens/programs/new";
import StaffInvites from "../screens/users/invites";
import VideoOnDemandWidget from "../screens/widgets/videoOnDemandWidget";
import Groups from "../screens/users/groups";
import NewGroup from "../screens/users/groups/new";
import CreateUserThroughAdmin from "../screens/createUserThroughAdmin";
import UpdateUser from "../components/UpdateUser";
import Producers from "../screens/users/producers";
import { UserRole } from "../utils/contants";
import Donation from "../screens/donation";
import DonationForm from "../screens/donation/form";
import DonationWidget from "./../screens/widgets/donationWidget";
import SubscriptionWidget from "./../screens/widgets/subscriptionWidget";
import SearchWidget from "./../screens/widgets/searchWidget";

const Routes = [
  {
    path: "/",
    name: "landing",
    exact: true,
    component: Landing,
  },
  {
    path: "/live-video",
    name: "live video",
    exact: true,
    component: LiveVideo,
  },
  {
    path: "/live-video/:id",
    name: "live video form",
    exact: true,
    component: LiveVideoForm,
  },
  {
    path: "/playlist",
    name: "playlist",
    exact: true,
    component: Playlist,
  },
  {
    path: "/playlist/new",
    name: "playlist form",
    exact: true,
    component: PlaylistForm,
  },
  {
    path: "/playlist/:id",
    name: "playlist form",
    exact: true,
    component: PlaylistForm,
  },
  {
    path: "/donations",
    name: "Donations",
    exact: true,
    component: Donation,
  },
  {
    path: "/donations/new",
    name: "Donation form new",
    exact: true,
    component: DonationForm,
  },
  {
    path: "/donation/edit/:id",
    name: "Donation form update",
    exact: true,
    component: DonationForm,
  },
  {
    path: "/podcasts",
    name: "podcasts",
    exact: true,
    component: Podcasts,
  },
  {
    path: "/podcasts/:id",
    name: "podcasts form",
    exact: true,
    component: PodcastForm,
  },
  {
    path: "/radio",
    name: "radio",
    exact: true,
    component: Radio,
  },
  {
    path: "/radio/:id",
    name: "radio form",
    exact: true,
    component: RadioForm,
  },
  {
    path: "/widgets",
    name: "widgets",
    exact: true,
    component: Widgets,
  },
  {
    path: "/programs",
    name: "program",
    exact: true,
    component: Programs,
  },
  {
    path: "/programs/:id",
    name: "new program",
    exact: true,
    component: NewProgram,
  },
  {
    path: "/programs/edit/:id",
    name: "edit program",
    exact: true,
    component: NewProgram,
  },
  {
    path: "/episodes",
    name: "episode",
    exact: true,
    component: Episode,
  },
  {
    path: "/episodes/new",
    name: "new episode",
    exact: true,
    component: NewEpisode,
  },
  {
    path: "/episodes/edit/:id",
    name: "new episode",
    exact: true,
    component: NewEpisode,
  },
  {
    path: "/widgets/podcasts/:id",
    name: "podcasts widget",
    exact: true,
    component: PodcastWidget,
  },
  {
    path: "/widgets/podcasts/:id/preview",
    name: "podcasts widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/radio/:id",
    name: "podcasts widget",
    exact: true,
    component: RadioWidget,
  },
  {
    path: "/widgets/footer/:id",
    name: "footer widget",
    exact: true,
    component: FooterWidget,
  },
  {
    path: "/widgets/footer/:id/preview",
    name: "footer widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/radio/:id/preview",
    name: "radios widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/live-video/:id/preview",
    name: "live video widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/schedule/:id/preview",
    name: "schedule widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/live-video/:id",
    name: "live video widget",
    exact: true,
    component: LiveVideoWidget,
  },
  {
    path: "/widgets/schedule/:id",
    name: "schedule widget",
    exact: true,
    component: ScheduleWidget,
  },
  {
    path: "/widgets/producer-portal/:id",
    name: "producer portal widget",
    exact: true,
    component: ProducerPortalWidget,
  },
  {
    path: "/widgets/producer-portal/:id/preview",
    name: "producer portal widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/video-on-demand/:id/preview",
    name: "video on demand widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/playlist/:id",
    name: "playlist widget",
    exact: true,
    component: PlaylistWidget,
  },
  {
    path: "/widgets/playlist/:id/preview",
    name: "playlist widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/donation/new",
    name: "Create Donation widget",
    exact: true,
    component: DonationWidget,
  },
  {
    path: "/widgets/donation/:id",
    name: "Donation widget",
    exact: true,
    component: DonationWidget,
  },
  {
    path: "/widgets/donation/:id/preview",
    name: "Donation widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/subscription/new",
    name: "Create Donation widget",
    exact: true,
    component: SubscriptionWidget,
  },
  {
    path: "/widgets/subscription/:id",
    name: "Donation widget",
    exact: true,
    component: SubscriptionWidget,
  },
  {
    path: "/widgets/subscription/:id/preview",
    name: "Subscription widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/widgets/search/new",
    name: "Create Search widget",
    exact: true,
    component: SearchWidget,
  },
  {
    path: "/widgets/search/:id",
    name: "Search widget",
    exact: true,
    component: SearchWidget,
  },
  {
    path: "/widgets/search/:id/preview",
    name: "Search widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/settings/organization",
    name: "organization settings",
    exact: true,
    component: Organzation,
  },
  {
    path: "/settings/integrations",
    name: "integration settings",
    exact: true,
    component: Integrations,
  },
  {
    path: "/api-docs",
    name: "api documentation",
    exact: true,
    component: ApiDocs,
  },
  {
    path: "/users/staff",
    name: "staff",
    exact: true,
    component: StaffUsers,
  },
  {
    path: "/users/invites",
    name: "invites",
    exact: true,
    component: StaffInvites,
    roles: [UserRole.ADMIN],
  },
  {
    path: "/users/groups",
    name: "groups",
    exact: true,
    component: Groups,
  },
  {
    path: "/users/groups/:id",
    name: "new group",
    exact: true,
    component: NewGroup,
  },
  {
    path: "/users/producer",
    name: "producer",
    exact: true,
    component: Producers,
  },
  {
    path: "/users/edit/:id",
    name: "edit user",
    exact: true,
    component: UpdateUser,
  },
  {
    path: "/users/producer/edit/:id",
    name: "edit producer",
    exact: true,
    component: UpdateUser,
  },
  {
    path: "/widgets/video-on-demand/:id",
    name: "video-on-demand widget",
    exact: true,
    component: VideoOnDemandWidget,
  },
  {
    path: "/widgets/video-on-demand/:id/preview",
    name: "video-on-demand widget preview",
    exact: true,
    component: WidgetsPreview,
  },
  {
    path: "/roadmap",
    name: "Roadmap",
    exact: true,
    component: Roadmap,
  },
  {
    path: "/users/staff/create",
    name: "create-user-through-admin",
    exact: true,
    component: CreateUserThroughAdmin,
  },
  {
    path: "/users/producer/create",
    name: "create-producer-through-admin",
    exact: true,
    component: CreateUserThroughAdmin,
  },
];

export default Routes;
