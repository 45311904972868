export const formatStatusData = (data) => {
  return [
    { name: "Accepted", value: data.statusCounts.accepted, fill: "#52c41a" },
    { name: "Declined", value: data.statusCounts.declined, fill: "#f5222d" },
    { name: "In review", value: data.statusCounts.inReview, fill: "#fa8c16" },
  ];
};

export const formatProviderData = (data) => {
  let regexYoutube = /youtu/;
  let regexVimeo = /vimeo/;
  let regexTelvue = /telvue/;
  let regexCablecast = /cablecast/;
  let regexArchive = /archive/;
  let y = 0;
  let v = 0;
  let t = 0;
  let c = 0;
  let a = 0;
  let o = 0;
  for (let i = 0; i < data.length; i++) {
    let d = data[i].stream_url;
    if (d) {
      if (d.match(regexYoutube)) {
        y++;
      } else if (d.match(regexVimeo)) {
        v++;
      } else if (d.match(regexTelvue)) {
        t++;
      } else if (d.match(regexCablecast)) {
        c++;
      } else if (d.match(regexArchive)) {
        a++;
      } else {
        o++;
      }
    } else {
      o++;
    }
  }
  return [
    { name: "Youtube", value: y, fill: "#722ed1" },
    { name: "Vimeo", value: v, fill: "#2f54eb" },
    { name: "Telvue", value: t, fill: "#52c41a" },
    { name: "Cablecast", value: c, fill: "#eb2f96" },
    { name: "Archive", value: a, fill: "#1890ff" },
    { name: "Other", value: o, fill: "#fa8c16" },
  ];
};

// Turn publish data into array for chart
export const formatPublishData = (data) => {
  return [
    { name: "Published", value: data.statusCounts.published, fill: "#52c41a" },
    {
      name: "Unpublished",
      value: data.statusCounts.unpublished,
      fill: "#f5222d",
    },
  ];
};

export const weekday = new Array(7);
weekday[0] = "Sun";
weekday[1] = "Mon";
weekday[2] = "Tues";
weekday[3] = "Wed";
weekday[4] = "Thurs";
weekday[5] = "Fri";
weekday[6] = "Sat";

let allWidgets = {
  producerPortal: 0,
  producer: 0,
  liveVideo: 0,
  donation: 0,
  subsciption: 0,
  radio: 0,
  schedule: 0,
  playlist: 0,
  vod: 0,
  podcast: 0,
};

// This function returns the beginning of the first day and the end of the second day (in milliseconds).
// this is because the date range picker returns the day you set, but the time it returns for that day
// is the current time (for example if its 8pm and you select december 10th, the date returned will be 8pm on
// december 10th). This then captures all data that the user expected
export const formatDates = (firstDate, secondDate) => {
  let date1 = new Date(firstDate);
  let date2 = new Date(secondDate);
  let beginOfDate1 =
    date1 -
    (date1.getHours() * 1000 * 3600 +
      date1.getMinutes() * 1000 * 60 +
      date1.getSeconds() * 1000 +
      date1.getMilliseconds());
  let endOfDate2 =
    firstDate === secondDate
      ? beginOfDate1 + 86399999
      : date2 +
        ((24 - date2.getHours()) * 1000 * 3600 +
          (60 - date2.getMinutes()) * 1000 * 60 +
          (60 - date2.getSeconds()) * 1000 +
          (1000 - date2.getMilliseconds()));
  return [beginOfDate1, endOfDate2];
};

const formatCounter = (multiple, firstDate, secondDate, oneDay) => {
  let counter = [];

  if (oneDay) {
    // if we format the data hourly
    for (let i = 0; i < 24; i++) {
      // set up the counter with 23 hours
      if (multiple) {
        // if we are splitting the result by widget, we must have this template
        counter[i] = {
          Hour: i,
          ...allWidgets,
        };
      } else {
        counter[i] = { Hour: i, Count: 0 };
      }
    }
  } else {
    let numDaysDiff = (secondDate - firstDate) / (1000 * 3600 * 24);
    // set up the month counter accordingly
    for (let i = 0; i < numDaysDiff + 1; i++) {
      let startDay = firstDate + 86400000 * i;
      let date = new Date(startDay).toString().substring(4, 10);
      if (multiple) {
        counter[i] = {
          Date: date,
          ...allWidgets,
        };
      } else {
        counter[i] = { Date: date, Count: 0 };
      }
    }
  }
  return counter;
};

export const formatData = (data, multiple, donation, firstDate, secondDate) => {
  // a boolean to determine if we need to format the data hourly
  let oneDay =
    new Date(firstDate).toDateString() === new Date(secondDate).toDateString();
  let counter = formatCounter(multiple, firstDate, secondDate, oneDay);

  let widgetsTemplate = { ...allWidgets };
  // loop through each event in the array of data to populate the counter correctly
  data &&
    data.forEach((event) => {
      // check dateTime exists on the object
      if (!isNaN(event.params.dateTime)) {
        // format the data in hours or date
        if (oneDay) {
          // HOURLY
          event.params.dateTime = new Date(event.params.dateTime).getHours();
        } else {
          // DATE STAMP
          event.params.dateTime = new Date(event.params.dateTime)
            .toString()
            .substring(4, 10);
        }

        // if the data isn't just for one day then search through the month array to find the right day
        if (!oneDay) {
          for (let i = 0; i < counter.length; i++) {
            if (counter[i].Date === event.params.dateTime) {
              if (multiple) {
                // if splitting by widget, increment correct widget counter
                let widget = event.params.widget;
                counter[i][widget]++;
                widgetsTemplate[widget]++;
              } else if (donation) {
                // if donation increment amount
                counter[i].Count += event.params.amount;
              } else {
                counter[i].Count++;
              }
            }
          }
          // otherwise just use the hour index
        } else {
          // but first check if we want multiple lines on the graph
          if (multiple) {
            let widget = event.params.widget;
            counter[event.params.dateTime][widget]++;
            widgetsTemplate[widget]++;
            // simple- use the hour index
          } else {
            // check if counting donation amounts
            if (donation) {
              counter[event.params.dateTime].Count += event.params.amount;
            } else {
              counter[event.params.dateTime].Count++;
            }
          }
        }
      }
    });

  // NOTE: this commented out code rearranges a counter so that the most recent date or time appears first.
  // this was used when we had just a dropdown menu of past 24 hours, past week, or past month. If we want to
  // add that menu again then we can use this code, otherwise it can be deleted.

  // rearange data so that the most recent time is first
  // let current =
  //   dateRange === "hourly" ? new Date().getHours() : new Date().getDay();
  // let sortedCounter = [];
  // let removed = "";
  // if (dateRange !== "monthly") {
  //   sortedCounter = counter.slice(current).concat(counter.slice(0, current));
  //   removed = sortedCounter.splice(0, 1)[0];
  //   sortedCounter.splice(sortedCounter.length, 0, removed);
  // } else {
  //   sortedCounter = counter.reverse();
  // }

  let arrayToReturn = [counter, widgetsTemplate, oneDay];
  return arrayToReturn;
};

export const formatVODdata = (data, id) => {
  let counterArr = [];
  let counterObj = {};
  data.forEach((item) => {
    if (item?.params && !isNaN(item.params[id])) {
      if (counterObj[parseInt(item.params[id])] !== undefined) {
        counterObj[parseInt(item.params[id])].count++;
      } else {
        counterObj[parseInt(item.params[id])] = { count: 1 };
      }
      (item.params.hasOwnProperty("title") ||
        item.params.hasOwnProperty("name")) &&
        (counterObj[parseInt(item.params[id])].title =
          item.params.title || item.params.name || "[No title]");
    }
  });
  let i = 0;
  for (const [key, value] of Object.entries(counterObj)) {
    counterArr[i] = { id: key, count: value?.count };
    value.hasOwnProperty("title") && (counterArr[i].title = value?.title);
    i++;
  }
  counterArr.sort((a, b) => b.count - a.count);
  counterArr = counterArr.slice(0, 10);
  return counterArr;
};
