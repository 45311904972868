import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import { withRouter } from "react-router";
import helpTextJson from "./../../content/helpText";
import { InfoCircleOutlined } from "@ant-design/icons";

const Title = styled.h3`
  margin-top: 0.4rem;
  margin-right: 0.5rem;
`;

const HelpText = (props) => {
    let toolTipText = null;
    const {required = false} = props;

    const page = props.history.location.pathname.split("/");
    if (page[1] && helpTextJson[page[1]]) {
        if (page[1] === "widgets" && page[2]) {
            toolTipText = helpTextJson[page[1]][page[2]][props.title];
        } else if (page[1] === "settings" && page[2]) {
            toolTipText = helpTextJson[page[1]][page[2]][props.title];
        } else {
            toolTipText = helpTextJson[page[1]][props.title];
        }
    }

    return (
        <div>
            <Title style={{display: toolTipText ? "inline-flex" : "flex"}}>
                {required && (
                    <span style={{color: "red", fontSize: 16, marginRight: 6}}>*</span>
                )}
                {props.title}
            </Title>

            {toolTipText && (
                <Tooltip title={toolTipText}>
                    <InfoCircleOutlined/>
                </Tooltip>
            )}
        </div>
    );
};

export default withRouter(HelpText);
