import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Queries } from "../../api";
import { Input, Form, Divider, Switch, Select, Spin } from "antd";
import { useQuery, useLazyQuery } from '@apollo/client';

const {Option} = Select;
const {TextArea} = Input;

const HorizontalContainer = styled.div`
  display: flex;
  margin: 1rem 0px;
  flex-direction: row;
`;

const VerticalContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const reqMsg = "This field is required.";
const pattern = new RegExp("^[a-zA-Z0-9_]*$");
const patternError = "Id shouldn't contain any special characters.";

const MailchimpFields = (props) => {
    const {form, successMessage = false, skipSwitchField = false} = props;
    const {data: mailchimpList, loading: mailchimpListLoading} = useQuery(
        Queries.MAILCHIMP_LIST
    );

    const [
        getMailchimpSegment,
        {data: mailchimpSegmentData, loading: mailchimpSegmentLoading},
    ] = useLazyQuery(Queries.MAILCHIMP_SEGMENTS_BY_ID);

    useEffect(() => {
        if (form.getFieldValue("mailchimpListId")) {
            getMailchimpSegment({
                variables: {listId: form.getFieldValue("mailchimpListId")},
            });
        } // eslint-disable-next-line
    }, [form.getFieldValue("mailchimpListId")]);

    const [, setFormChanged] = useState(false);
    return (
        <Form layout={"vertical"} form={form}>
            {!skipSwitchField && (
                <Form.Item
                    label="Include Mailchimp subscription"
                    name={"mailchimpSub"}
                    style={{
                        fontWeight: 600,
                        display: "flex",
                        marginTop: "2rem",
                        width: "fit-content",
                        alignItems: "baseline",
                        flexDirection: "row-reverse",
                    }}
                    valuePropName="checked"
                >
                    <Switch
                        onChange={() => setFormChanged((prev) => !prev)}
                        style={{marginRight: 20}}
                    />
                </Form.Item>
            )}
            {(form.getFieldValue("mailchimpSub") || skipSwitchField) && (
                <HorizontalContainer>
                    <Divider
                        plain
                        type={"vertical"}
                        style={{height: "auto", marginRight: "1rem"}}
                    />

                    <VerticalContainer>
                        <Spin size={"default"} spinning={mailchimpListLoading}>
                            <Form.Item
                                style={{marginTop: 0}}
                                name={"mailchimpListId"}
                                label={"Mailchimp list ID"}
                                rules={[
                                    {required: true, message: reqMsg},
                                    {
                                        pattern: pattern,
                                        message: patternError,
                                    },
                                ]}
                            >
                                <Select
                                    showArrow={true}
                                    placeholder="Select mailchimp list ID"
                                    defaultActiveFirstOption={false}
                                    onChange={() => setFormChanged((prev) => !prev)}
                                >
                                    {mailchimpList?.mailChimpLists?.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Spin>
                        <Spin size={"default"} spinning={mailchimpSegmentLoading}>
                            <Form.Item
                                style={{marginTop: 0}}
                                name={"mailchimpSegmentId"}
                                label={"Mailchimp tag ID"}
                                rules={[
                                    {required: true, message: reqMsg},
                                    {
                                        pattern: pattern,
                                        message: patternError,
                                    },
                                ]}
                            >
                                <Select
                                    showArrow={true}
                                    defaultActiveFirstOption={false}
                                    placeholder="Select mailchimp tag ID"
                                    disabled={!form.getFieldValue("mailchimpListId")}
                                >
                                    {mailchimpSegmentData?.mailChimpListSegments?.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Spin>
                        {successMessage && (
                            <Form.Item
                                style={{marginTop: 0}}
                                name={"mailchimpSuccessMessage"}
                                label={"Success message"}
                                rules={[{required: true, message: reqMsg}]}
                            >
                                <TextArea
                                    placeholder="Your subscription joining success message"
                                    rows={3}
                                />
                            </Form.Item>
                        )}
                    </VerticalContainer>
                </HorizontalContainer>
            )}
        </Form>
    );
};

export default MailchimpFields;
