import React, { useState, useEffect } from "react";
import states from "./state";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import { Mutations, Queries } from "../../../api";
import HelpText from "../../../components/HelpText";
import { GOOGLE_API_KEY } from "../../../constants";
import MapPicker from "../../../components/MapPicker";
import { useMutation, useQuery } from '@apollo/client';
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Select, Input, message, Spin, Row, Col } from "antd";
import UploadMultiple from "../../../components/UploadMultiple";

const {Option} = Select;
const {TextArea} = Input;

const StyledForm = styled.div`
  .save {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }
`;

export default function Organization(props) {
    const [lat, setlat] = useState("");
    const [lng, setlng] = useState("");
    const [city, setCity] = useState("");
    const [title, setTitle] = useState("");
    const [state, setState] = useState("");
    const [image, setImage] = useState("");
    const [squareImage, setSquareImage] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [phone, setPhone] = useState("");
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [description, setDescription] = useState("");
    const [primaryColor, setPrimaryColor] = useState("");
    const [protocol, setProtocol] = useState("https://");
    const [streetAddress, setStreetAdress] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");

    const {
        loading: organizationLoading,
        error: organizationError,
        data: organizationData,
    } = useQuery(Queries.ORGANIZATION);

    const [
        updateOrganizatoin,
        {
            loading: updateOrganizationLoading,
            error: updateOrganizationError,
            data: updateOrganizationData,
        },
    ] = useMutation(Mutations.UPDATE_ORGANIZATION, {
        update(cache, {data: {updateOrganizatoin}}) {
            cache.writeQuery({
                query: Queries.ORGANIZATION,
                data: {organization: {...updateOrganizatoin}},
            });
        },
    });
    useEffect(() => {
        if (updateOrganizationError || organizationError) {
            message.error("server error");
        }
    }, [
        updateOrganizationError,
        organizationError
    ]);

    useEffect(() => {
        if (organizationData && organizationData.organization) {
            const image = organizationData.organization.image;
            const squareImage = organizationData.organization.squareImage;
            const address = organizationData.organization.address;
            const website = organizationData.organization.website;
            const maplat = organizationData.organization.latitude;
            const maplng = organizationData.organization.longitude;
            const phone = organizationData.organization.phone;
            // const timezone = organizationData.organization.timezone;
            const description = organizationData.organization.description;
            const organization = organizationData.organization.title;
            const organizationCity = organizationData.organization.city;
            const organizationState = organizationData.organization.state;
            const organizationZipCode = organizationData.organization.zip;
            const primaryColor = organizationData.organization.primaryColor;
            const secondaryColor = organizationData.organization.secondaryColor;

            setImage(image ? image : "");
            setSquareImage(squareImage ? squareImage : "");
            setlat(maplat ? maplat : "");
            setlng(maplng ? maplng : "");
            setPhone(phone ? phone : "");
            setWebsiteUrl(website ? website : "");
            setStreetAdress(address ? address : "");
            // setTimezone(timezone ? timezone : "Eastern");
            setTitle(organization ? organization : "");
            setDescription(description ? description : "");
            setCity(organizationCity ? organizationCity : "");
            setPrimaryColor(primaryColor ? primaryColor : "");
            setState(organizationState ? organizationState : "");
            setZipCode(organizationZipCode ? organizationZipCode : "");
            setSecondaryColor(secondaryColor ? secondaryColor : "");
        }
    }, [organizationData]);

    useEffect(() => {
        if (updateOrganizationData) {
            return message.success("Organization data saved.");
        }
    }, [updateOrganizationData]);

    const selectBefore = (
        <Select
            value={protocol}
            style={{width: 90}}
            onChange={(e) => setProtocol(e)}
        >
            <Option value="https://">https://</Option>
            <Option value="http://">http://</Option>
        </Select>
    );

    const geoSearch = (a, s, c) => {
        const query = `https://maps.googleapis.com/maps/api/geocode/json?address=${a},+${c},+${s}&key=${GOOGLE_API_KEY}`;
        fetch(query)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (
                    data.results &&
                    data.results[0] &&
                    data.results[0].geometry &&
                    data.results[0].geometry.location
                ) {
                    setlat(data.results[0].geometry.location.lat.toString());
                    setlng(data.results[0].geometry.location.lng.toString());
                }
            });
    };

    return (
        <div style={{padding: 24, background: "#fff"}}>
            <Spin spinning={organizationLoading}>
                <StyledForm>
                    <HelpText title={"Organization title"} required={true}/>
                    <Input
                        value={title}
                        size={"large"}
                        placeholder={"Organization title"}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <HelpText title={"Website URL"}/>
                    <Input
                        size={"large"}
                        addonBefore={selectBefore}
                        placeholder={"Drupal website URL"}
                        value={websiteUrl}
                        onChange={(e) =>
                            setWebsiteUrl(
                                e.target.value.replace("https://", "").replace("http://", "")
                            )
                        }
                    />
                    <Row>
                        <Col span={6}>
                            <HelpText title={"Logo"}/>

                            <UploadMultiple
                                dir={"organization"}
                                images={image}
                                setImages={(img) => setImage(img?.toString() || "")}
                                allowImages={1}
                                squareCrop={false}
                            />
                        </Col>
                        <Col span={6}>
                            <HelpText title={"Square logo"}/>
                            <UploadMultiple
                                dir="organization/square"
                                images={squareImage}
                                setImages={img => setSquareImage(img?.toString() || "")}
                                allowImages={1}
                                squareCrop={true}
                            />
                        </Col>
                        <Col span={6}>
                            <HelpText title={"Primary color"}/>
                            <ChromePicker
                                disableAlpha
                                color={primaryColor}
                                onChangeComplete={(e) => setPrimaryColor(e.hex)}
                            />
                        </Col>
                        <Col span={6}>
                            <HelpText title={"Secondary color"}/>
                            <ChromePicker
                                disableAlpha
                                color={secondaryColor}
                                onChangeComplete={(e) => setSecondaryColor(e.hex)}
                            />
                        </Col>
                    </Row>
                    <HelpText title={"Street address"}/>
                    <Input
                        value={streetAddress}
                        size={"large"}
                        placeholder={"Organization street address"}
                        onChange={(e) => {
                            setStreetAdress(e.target.value);
                        }}
                        onBlur={() => {
                            geoSearch(streetAddress, state, city);
                        }}
                    />
                    <Row>
                        <Col span={6}>
                            <HelpText title={"State"}/>
                            <Select
                                defaultValue="New York"
                                value={state}
                                style={{width: 200}}
                                onChange={(value) => {
                                    setState(value);
                                    geoSearch(streetAddress, value, city);
                                }}
                                size={"large"}
                            >
                                {states.map((state, i) => {
                                    return (
                                        <Option key={state + i} value={state}>
                                            {state}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col span={6}>
                            <HelpText title={"City"}/>
                            <Input
                                value={city}
                                style={{width: 200}}
                                size={"large"}
                                defaultValue={""}
                                onChange={(e) => {
                                    setCity(e.target.value);
                                }}
                                onBlur={() => {
                                    geoSearch(streetAddress, state, city);
                                }}
                            />
                        </Col>
                        <Col span={6}>
                            <HelpText title={"Zip code"}/>
                            <Input
                                value={zipCode}
                                style={{width: 200}}
                                size={"large"}
                                defaultValue={"02130"}
                                onChange={(e) => setZipCode(e.target.value)}
                            />
                        </Col>
                        <Col span={6}>
                            <HelpText title={"Phone number"}/>
                            <Input
                                style={{width: 200}}
                                size={"large"}
                                value={phone}
                                maxLength="10"
                                type="tel"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <HelpText title={"GPS coordinates"}/>
                    <MapPicker
                        lat={lat}
                        lng={lng}
                        onLatLngPick={(lat, lng) => {
                            setlat(lat.toString());
                            setlng(lng.toString());
                        }}
                        height={"300px"}
                        zoom={4}
                    />
                    {/* <HelpText title={"Time zone"} />
          <Select
            value={timezone}
            style={{ width: 200 }}
            onChange={(option) => setTimezone(option)}
            size={"large"}
          >
            <Option value="Eastern">Eastern</Option>
            <Option value="Mountain">Mountain</Option>
          </Select> */}
                    <HelpText title={"Description"}/>
                    <TextArea
                        value={description}
                        size={"large"}
                        placeholder={"Organization description"}
                        rows={4}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className={"save"}>
                        <Button
                            type="primary"
                            shape="round"
                            icon={
                                updateOrganizationLoading ? (
                                    <LoadingOutlined/>
                                ) : (
                                    <SaveOutlined/>
                                )
                            }
                            size={"large"}
                            disabled={false}
                            onClick={() =>
                                !updateOrganizationLoading &&
                                updateOrganizatoin({
                                    variables: {
                                        primaryColor: primaryColor,
                                        secondaryColor: secondaryColor,
                                        title: title,
                                        latitude: lat,
                                        longitude: lng,
                                        website: websiteUrl,
                                        image: image,
                                        squareImage: squareImage,
                                        description: description,
                                        zip: zipCode,
                                        city: city,
                                        address: streetAddress,
                                        state: state,
                                        phone: phone,
                                        timezone: "",
                                    },
                                })
                            }
                        >
                            Save organization settings
                        </Button>
                    </div>
                </StyledForm>
            </Spin>
        </div>
    );
}
