import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { defaultStatus } from "../../utils/contants";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Select,
  Spin,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import DeletePopUp from "../DeletePopUp";
import AddTopics from "../../screens/episode/TableView/addTopics";
import ChangeProgram from "../../screens/episode/TableView/changeProgram";
import AddTags from "../../screens/episode/TableView/addTags";

const { Option } = Select;

export const CheckboxWrapper = styled.div`
  .ant-checkbox-inner {
    border-color: #999999;
    border-width: 2px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 16px;

  .TextInput {
    width: 256px;
    height: 32px;
    min-width: 100px;
  }

  .Actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .horizontalDivider {
    display: none;
  }

  @media (max-width: 1300px) {
    .verticalDivider {
      display: none;
    }

    .horizontalDivider {
      display: block;
    }

    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-select {
      width: 100% !important;
    }
  }
`;
const Filters = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1300px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const MediaTableHeader = (props) => {
  const {
    tableRef,
    onDelete,
    setStatus,
    isLoading,
    isDisableExport,
    exportLoading,
    onClickAddNew,
    addNewBtnTitle,
    onChangeSearch,
    handlePushVideo,
    areRowsSelected,
    onClickExportCSV,
    searchPlaceholder,
    handlePublishStatus,
    integrationType,
    isShowPushAction = true,
    hasFilterDropdown = false,
    actionsMenu,
    setProgram,
    setProducer,
    debounceOnProgramQuery,
    debounceOnProducerQuery,
    episodeFiltersList,
    programsForDropdownLoading,
    programs,
    topics,
    producersForDropdownLoading,
    producers,
    handleChangeProgram = null,
    handleAddTopics = null,
    handleAddTags = null,
    episodeSettings,
  } = props;

  const [rowsSelected, setRowsSelected] = useState(areRowsSelected);
  const [showSecondaryFilter, setShowSecondaryFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [currentValue, setCurrentValue] = useState(null);
  const [changeProgramVisible, setChangeProgramVisible] = useState(false);
  const [addTopicsVisible, setAddTopicsVisible] = useState(false);
  const [addTagsVisible, setAddTagsVisible] = useState(false);

  const onSelectPrimaryFilter = (value, optionObj) => {
    setCurrentValue(null);
    setShowSecondaryFilter(true);
    setActiveFilter(value);
  };

  const onClearPrimaryFilter = () => {
    setShowSecondaryFilter(false);
    clearSecondaryFilter();
  };

  const isSecondaryFilterLoading = () => {
    switch (activeFilter) {
      case "Programs":
        return programsForDropdownLoading;
      case "Producers":
        return producersForDropdownLoading;
      default:
        return;
    }
  };

  const onSecondaryFilterSearch = (query) => {
    switch (activeFilter) {
      case "Programs":
        if (query.trim() === "" && query.length === 0) return;
        debounceOnProgramQuery(query);
        break;
      case "Producers":
        if (query.trim() === "" && query.length === 0) return;
        debounceOnProducerQuery(query);
        break;
      default:
        return;
    }
  };

  const setSecondaryFilter = (labeledValue, option) => {
    setCurrentValue(option.filter);
    switch (activeFilter) {
      case "Producers":
        setProgram(null);
        setProducer(labeledValue);
        break;
      case "Programs":
        setProducer(null);
        setProgram(labeledValue);
        break;
      default:
        return;
    }
  };

  const clearSecondaryFilter = () => {
    switch (activeFilter) {
      case "Programs":
        debounceOnProgramQuery("");
        setCurrentValue(null);
        setProgram(null);
        break;
      case "Producers":
        debounceOnProducerQuery("");
        setCurrentValue(null);
        setProducer(null);
        break;
      default:
        return;
    }
  };

  const renderSecondaryFilterOptions = () => {
    switch (activeFilter) {
      case "Programs":
        return programs?.map((item) => (
          <Option key={item.id} filter={item.title}>
            {item.title} ({item?.id})
          </Option>
        ));
      case "Producers":
        return producers?.map((item) => (
          <Option key={item.id} filter={item.name}>
            {item.name}
          </Option>
        ));
      default:
        return;
    }
  };

  useEffect(() => {
    setRowsSelected(areRowsSelected);
  }, [areRowsSelected]);

  return (
    <>
      <Container>
        <ChangeProgram
          changeProgramVisible={changeProgramVisible}
          setChangeProgramVisible={setChangeProgramVisible}
          programs={programs}
          programsForDropdownLoading={programsForDropdownLoading}
          debounceOnProgramQuery={debounceOnProgramQuery}
          handleChangeProgram={handleChangeProgram}
        />
        <AddTopics
          addTopicsVisible={addTopicsVisible}
          setAddTopicsVisible={setAddTopicsVisible}
          handleAddTopics={handleAddTopics}
          episodeSettings={episodeSettings}
          topics={topics}
          collection={props?.isPrograms ? "programs" : "episodes"}
        />
        <AddTags
          addTagsVisible={addTagsVisible}
          setAddTagsVisible={setAddTagsVisible}
          handleAddTags={handleAddTags}
        />
        <Filters>
          <Input
            allowClear
            style={{
              width: 256,
              height: 32,
              marginBottom: 12,
              marginRight: 12,
            }}
            placeholder={searchPlaceholder}
            prefix={<SearchOutlined />}
            onChange={(e) => onChangeSearch(e.target.value)}
          />

          {!!hasFilterDropdown && (
            <div style={{ marginBottom: "20px" }}>
              <Divider
                className="verticalDivider"
                type="vertical"
                style={{
                  height: "30px",
                  marginRight: "20px",
                  backgroundColor: "#d9d9d9",
                }}
              />
              <Divider
                className="horizontalDivider"
                style={{ margin: "8px 0 12px 0", backgroundColor: "#d9d9d9" }}
              />
              <Select
                placeholder="Search By"
                style={{
                  width: 256,
                  height: 32,
                  marginBottom: 12,
                  marginRight: 12,
                }}
                allowClear={true}
                onSelect={(value, optionObj) =>
                  onSelectPrimaryFilter(value, optionObj)
                }
                onClear={() => onClearPrimaryFilter()}
              >
                {episodeFiltersList.map((item, ind) => (
                  <Option key={item} filter={item}>
                    {"Search By " + item}
                  </Option>
                ))}
              </Select>
              {!!showSecondaryFilter && (
                <>
                  <Select
                    showSearch
                    allowClear
                    filterOption={true}
                    optionFilterProp={"filter"}
                    placeholder={"Select " + activeFilter}
                    defaultActiveFirstOption={false}
                    style={{
                      width: 356,
                      height: 32,
                      marginBottom: 12,
                      marginRight: 12,
                    }}
                    value={currentValue}
                    loading={isSecondaryFilterLoading()}
                    onClear={() => clearSecondaryFilter()}
                    onSearch={(query) => onSecondaryFilterSearch(query)}
                    onSelect={(labeledValue, option) =>
                      setSecondaryFilter(labeledValue, option)
                    }
                  >
                    {renderSecondaryFilterOptions()}
                  </Select>
                </>
              )}
            </div>
          )}
        </Filters>
        <div className="Actions">
          {props?.tableRef?.current && (
            <Dropdown
              trigger="click"
              overlay={
                <Menu
                  onClick={(val) => {
                    if (val?.key === "fit") {
                      props.tableRef.current.api.sizeColumnsToFit();
                    } else {
                      props.tableRef.current.columnApi.autoSizeAllColumns();
                    }
                  }}
                >
                  <Menu.Item key="fit">Size Columns To Fit View</Menu.Item>
                  <Menu.Item key="content">
                    Size Columns To Fit Content
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                style={{ marginRight: 12, marginBottom: 12 }}
                type="primary"
                shape="round"
                icon={<DownOutlined />}
              >
                Size Columns
              </Button>
            </Dropdown>
          )}

          <Button
            style={{ marginRight: 12, marginBottom: 12 }}
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={() => onClickAddNew()}
          >
            {addNewBtnTitle}
          </Button>

          <Button
            disabled={isDisableExport}
            icon={exportLoading ? <LoadingOutlined /> : <SaveOutlined />}
            style={{ marginRight: 12, marginBottom: 12 }}
            onClick={() => onClickExportCSV()}
          >
            Export CSV
          </Button>
          <ActionsMenu
            isPrograms={props.isPrograms}
            actionsMenu={actionsMenu}
            onDelete={onDelete}
            isLoading={isLoading}
            setStatus={setStatus}
            areRowsSelected={!!rowsSelected}
            handlePushVideo={handlePushVideo}
            handlePublishStatus={handlePublishStatus}
            integrationType={integrationType}
            isShowPushAction={isShowPushAction}
            handleChangeProgram={() => setChangeProgramVisible(true)}
            handleAddTopics={() => setAddTopicsVisible(true)}
            handleAddTags={() => setAddTagsVisible(true)}
          />
        </div>
      </Container>
      <CheckboxWrapper
        style={{
          padding: "0 10px 10px 1px",
        }}
      >
        <Checkbox
          onClick={(e) => {
            const checked = e.target.checked;
            const rows = tableRef.current.api.getRenderedNodes();
            rows.forEach((row) => {
              row.setSelected(checked);
            });

            setRowsSelected(checked);
          }}
        >
          Select Page
        </Checkbox>
      </CheckboxWrapper>
    </>
  );
};

export const ActionsMenu = (props) => {
  const {
    handlePublishStatus,
    setStatus,
    handlePushVideo,
    onDelete,
    isLoading,
    areRowsSelected,
    integrationType,
    isShowPushAction,
    actionsMenu,
    handleChangeProgram,
    handleAddTopics,
    handleAddTags,
    isPrograms = false,
  } = props;
  const canShowTags = () => handleAddTags && !isPrograms;

  const defaultMenu = (
    <Menu>
      <Menu.Item key="0" style={{ height: 30 }}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>STATUS</p>
      </Menu.Item>
      <Menu.Item key="1">
        <Button
          icon={<CheckCircleOutlined />}
          style={{
            width: 174,
            height: 32,
            textAlign: "start",
            alignItems: "left",
            fontSize: 14,
            color: "#73D13D",
            background: "#F6FFED",
            border: "1px solid #73D13D",
          }}
          onClick={() => {
            setStatus(defaultStatus.accept);
          }}
        >
          Mark as accepted
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button
          icon={<ExclamationCircleOutlined />}
          color="orange"
          style={{
            width: 174,
            height: 32,
            fontSize: 14,
            textAlign: "start",
            justifyContent: "flex-end",
            background: "#FFFBE6",
            border: "1px solid #FFC53D",
            color: "#FFC53D",
            marginBottom: 12,
          }}
          onClick={() => {
            setStatus(defaultStatus.inReview);
          }}
        >
          Mark as in review
        </Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button
          icon={<CloseCircleOutlined />}
          color="red"
          style={{
            width: 174,
            height: 32,
            fontSize: 14,
            textAlign: "start",
            justifyContent: "flex-end",
            background: "#FFF1F0",
            border: "1px solid #FF4D4F",
            color: "#FF4D4F",
          }}
          onClick={() => {
            setStatus(defaultStatus.declined);
          }}
        >
          Mark as declined
        </Button>
      </Menu.Item>

      {handleChangeProgram && (
        <>
          <Menu.Item key="3.5" style={{ height: 30 }}>
            <p style={{ fontSize: 14, fontWeight: 600 }}>Other</p>
          </Menu.Item>
          {!isPrograms && (
            <Menu.Item key="3.6">
              <Button
                icon={<CheckCircleOutlined />}
                color="green"
                style={{
                  width: 174,
                  height: 32,
                  fontSize: 14,
                  textAlign: "start",
                  color: "#73D13D",
                  background: "#F6FFED",
                  border: "1px solid #73D13D",
                }}
                onClick={() => {
                  handleChangeProgram();
                }}
              >
                Change program
              </Button>
            </Menu.Item>
          )}

          {handleAddTopics && (
            <Menu.Item key="3.7">
              <Button
                icon={<CheckCircleOutlined />}
                color="green"
                style={{
                  width: 174,
                  height: 32,
                  fontSize: 14,
                  textAlign: "start",
                  color: "#73D13D",
                  background: "#F6FFED",
                  border: "1px solid #73D13D",
                }}
                onClick={() => {
                  handleAddTopics();
                }}
              >
                Add topics
              </Button>
            </Menu.Item>
          )}
          {canShowTags() && (
            <Menu.Item key="3.8">
              <Button
                icon={<CheckCircleOutlined />}
                color="green"
                style={{
                  width: 174,
                  height: 32,
                  fontSize: 14,
                  textAlign: "start",
                  color: "#73D13D",
                  background: "#F6FFED",
                  border: "1px solid #73D13D",
                }}
                onClick={() => {
                  handleAddTags();
                }}
              >
                Add tags
              </Button>
            </Menu.Item>
          )}
        </>
      )}

      <Menu.Item key="4" style={{ height: 30 }}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>Published</p>
      </Menu.Item>
      <Menu.Item key="5">
        <Button
          icon={<CheckCircleOutlined />}
          color="green"
          style={{
            width: 174,
            height: 32,
            fontSize: 14,
            textAlign: "start",
            color: "#73D13D",
            background: "#F6FFED",
            border: "1px solid #73D13D",
          }}
          onClick={() => {
            handlePublishStatus(true);
          }}
        >
          Published
        </Button>
      </Menu.Item>
      <Menu.Item key="6">
        <Button
          icon={<CloseCircleOutlined />}
          color="red"
          style={{
            width: 174,
            height: 32,
            fontSize: 14,
            textAlign: "start",
            background: "#FFF1F0",
            border: "1px solid #FF4D4F",
            color: "#FF4D4F",
          }}
          onClick={() => {
            handlePublishStatus(false);
          }}
        >
          Unpublished
        </Button>
      </Menu.Item>
      {isShowPushAction && (
        <Menu.Item key="7" style={{ marginTop: 20 }}>
          <Button
            type="primary"
            icon={<ShareAltOutlined />}
            style={{
              width: 174,
              height: 32,
              fontSize: 14,
              textAlign: "center",
            }}
            onClick={handlePushVideo}
          >
            {integrationType === "CABLECAST"
              ? "Push on Cablecast"
              : "Push to Telvue"}
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="8">
        <DeletePopUp
          integrationType={integrationType}
          action={(toDelete) => {
            onDelete(toDelete);
          }}
          isDeleteEpisode={isShowPushAction}
        >
          Delete
        </DeletePopUp>
      </Menu.Item>
    </Menu>
  );
  return (
    <Spin spinning={isLoading}>
      <Dropdown
        overlay={actionsMenu || defaultMenu}
        disabled={!areRowsSelected}
      >
        <Button disabled={!areRowsSelected}>
          Action <DownOutlined />
        </Button>
      </Dropdown>
    </Spin>
  );
};

export default MediaTableHeader;
