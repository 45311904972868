import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Button, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { dateRangeUI } from "../../components/MediaTable/columns";
import { emptyIfNull } from "../../utils";
import { DONATION_STATUS, DONATION_TYPE } from "../../utils/contants";

const getTypeItemColor = (type) =>
  type === DONATION_TYPE.ONLINE.value ? "purple" : "geekblue";

const getDate = (date) =>
  dayjs(date).isValid()
    ? dayjs(date, "YYYY-MM-DDThh:mm:ssZ").format("MM-DD-YYYY")
    : DASH;

const getStatusFilters = Object.values(DONATION_STATUS);

const getStatusItemColor = (status) =>
  status === DONATION_STATUS.Succeeded.value
    ? "green"
    : status === DONATION_STATUS.Refund_Pending.value
    ? "orange"
    : status === DONATION_STATUS.Pending.value
    ? "orange"
    : "red";

const DASH = (
  <span style={{ justifyContent: "center", display: "flex" }}>&#8212;</span>
);
const columns = (
  dateRange,
  onDateChange,
  onDateRangeReset,
  onApplyDateFilter,
  history
) =>
  new Map([
    [
      "Donation",
      {
        title: "Donation",
        dataIndex: "amount",
        key: "donation",
        align: "center",
        render: (amount, record) => (
          <Button
            style={{ padding: 0 }}
            onClick={() => history.push("/donation/edit/" + record.id)}
            type="link"
          >
            {amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Button>
        ),
      },
    ],
    [
      "Name",
      {
        title: "Name",
        dataIndex: "firstName",
        key: "firstName",
        align: "center",
        sorter: (a, b) => {
          const aStr = emptyIfNull(a.firstName) + " " + emptyIfNull(a.lastName);
          const bStr = emptyIfNull(b.firstName) + " " + emptyIfNull(b.lastName);

          return aStr.localeCompare(bStr);
        },
        sortDirections: ["descend", "ascend"],
        render: (name, record) => {
          return (
            <Typography.Text>
              {emptyIfNull(record.firstName) +
                " " +
                emptyIfNull(record.lastName)}
            </Typography.Text>
          );
        },
      },
    ],
    [
      "Email",
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        align: "center",
        sorter: (a, b) =>
          a.email && b.email && a?.email.localeCompare(b?.email),
        sortDirections: ["descend", "ascend"],
        render: (email, record) => <Typography.Text>{email}</Typography.Text>,
      },
    ],
    [
      "Type",
      {
        title: "Type",
        key: "type",
        dataIndex: "donationType",
        filterMultiple: false,
        filters: Object.values(DONATION_TYPE),
        align: "center",
        render: (type, record) => (
          <Tag color={getTypeItemColor(type)}>{type}</Tag>
        ),
      },
    ],
    [
      "Date",
      {
        title: "Date",
        key: "date",
        dataIndex: "createdAt",
        filterDropdown: () =>
          dateRangeUI(
            {
              start: dateRange.startDateLocal,
              end: dateRange.endDateLocal,
            },
            onDateChange,
            onDateRangeReset,
            onApplyDateFilter
          ),
        render: (date, record) => (
          <Typography.Text>{getDate(date)}</Typography.Text>
        ),
      },
    ],
    [
      "Status",
      {
        title: "Status",
        key: "status",
        dataIndex: "donationStatus",
        align: "center",
        filters: getStatusFilters,
        render: (status, record) => (
          <Tag color={getStatusItemColor(status)}>{status}</Tag>
        ),
      },
    ],
    [
      "City",
      {
        title: "City",
        key: "city",
        dataIndex: "city",
        align: "center",
        sorter: (a, b) => a.city && b.city && a?.city.localeCompare(b?.city),
        sortDirections: ["descend", "ascend"],
        render: (city, record) => <Typography.Text>{city}</Typography.Text>,
      },
    ],
    [
      "Country",
      {
        title: "Country",
        key: "country",
        dataIndex: "country",
        align: "center",
        sorter: (a, b) =>
          a.country && b.country && a?.country.localeCompare(b?.country),
        sortDirections: ["descend", "ascend"],
        render: (country, record) => (
          <Typography.Text>{country}</Typography.Text>
        ),
      },
    ],
    [
      "Phone",
      {
        title: "Phone",
        key: "phone",
        dataIndex: "phone",
        align: "center",
        sorter: (a, b) => a.city && b.phone && a?.phone.localeCompare(b?.phone),
        sortDirections: ["descend", "ascend"],
        render: (phone, record) => <Typography.Text>{phone}</Typography.Text>,
      },
    ],
    [
      "State",
      {
        title: "State",
        key: "state",
        dataIndex: "state",
        align: "center",
        sorter: (a, b) =>
          a.state && b.state && a?.state.localeCompare(b?.state),
        sortDirections: ["descend", "ascend"],
        render: (state, record) => <Typography.Text>{state}</Typography.Text>,
      },
    ],
    [
      "Zip",
      {
        title: "Zip",
        key: "zip",
        dataIndex: "zipCode",
        align: "center",
        sorter: (a, b) =>
          a.zipCode && b.zipCode && a?.zipCode.localeCompare(b?.zipCode),
        sortDirections: ["descend", "ascend"],
        render: (zip, record) => <Typography.Text>{zip}</Typography.Text>,
      },
    ],
    [
      "Subscribed to mail list",
      {
        title: "Subscribed to mail list?",
        key: "subscribedToMailList",
        dataIndex: "subscribeToMailList",
        align: "center",
        sorter: (a, b) => a.subscribeToMailList > b.subscribeToMailList,
        sortDirections: ["descend", "ascend"],
        render: (subscribeToMailList, record) => {
          if (subscribeToMailList) {
            return <CheckCircleTwoTone twoToneColor="#52c41a" />;
          } else {
            return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
          }
        },
      },
    ],
  ]);

const initialVisibleColumns = [
  "Donation",
  "Name",
  "Email",
  "Type",
  "Date",
  "Status",
];

export { columns, initialVisibleColumns };
