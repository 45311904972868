import axios from "axios";
import { ITransport, TransportError } from "@directus/sdk";

/**
 * Transport implementation
 */
export class Transport extends ITransport {
  axios;
  config;
  handleLogout;

  constructor(config) {
    super();

    this.config = config;
    this.handleLogout = config.handleLogout;

    this.axios = axios.create({
      baseURL: this.config.url,
      params: this.config.params,
      headers: this.config.headers,
      onUploadProgress: this.config.onUploadProgress,
      withCredentials: true,
    });

    if (this.config?.beforeRequest)
      this.beforeRequest = this.config.beforeRequest;
  }

  beforeRequest(config) {
    const token = localStorage.getItem("auth_token");
    const bearer = token
      ? token.startsWith(`Bearer `)
        ? String(token)
        : `Bearer ${token}`
      : "";

    return {
      ...config,
      headers: {
        Authorization: bearer,
        ...config.headers,
      },
    };
  }

  get url() {
    return this.config.url;
  }

  async request(method, path, data, options) {
    try {
      let config = {
        method,
        url: path,
        data: data,
        params: options?.params,
        headers: options?.headers,
        onUploadProgress: options?.onUploadProgress,
      };

      config = this.beforeRequest(config);

      const response = await this.axios.request(config);

      const content = {
        raw: response.data,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        data: response.data.data,
        meta: response.data.meta,
        errors: response.data.errors,
      };

      if (response.data.errors) {
        throw new TransportError(null, content);
      }

      return content;
    } catch (err) {
      if (!err || !(err instanceof Error)) {
        throw err;
      }

      if (axios.isAxiosError(err)) {
        if ([401].includes(err.response?.status)) {
          await this.handleLogout();
          return;
        }
      }

      throw new TransportError(err);
    }
  }

  async get(path, options) {
    return await this.request("get", path, undefined, options);
  }

  async head(path, options) {
    return await this.request("head", path, undefined, options);
  }

  async options(path, options) {
    return await this.request("options", path, undefined, options);
  }

  async delete(path, data, options) {
    return await this.request("delete", path, data, options);
  }

  async put(path, data, options) {
    return await this.request("put", path, data, options);
  }

  async post(path, data, options) {
    return await this.request("post", path, data, options);
  }

  async patch(path, data, options) {
    return await this.request("patch", path, data, options);
  }
}
