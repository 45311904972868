import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import styled from "styled-components";
import InviteModel from "../inviteModel";
import { MailOutlined } from "@ant-design/icons";
import { Queries, Mutations } from "../../../api";
import { trimErrorMessage } from "../../../utils";
import { InviteStatus } from "../../../utils/contants";
import DeletePopUp from "../../../components/DeletePopUp";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Spin, Input, message, Menu, Dropdown } from "antd";
import { PlusOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import SimpleTableView from "../../../components/simpleTableView";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 16px;

  .TextInput {
    width: 256px;
    height: 32px;
    min-width: 100px;
  }
  .Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const TableContainer = styled.div`
  margin-top: 1rem;
  .ant-table-content {
    overflow-x: auto;
  }
`;

export default function StaffInvites(props) {
  const [invites, setInvites] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedInviteKeys, setSelectedInviteKeys] = useState([]);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);

  const { loading: usersLoading, data: inviteData } = useQuery(
    Queries.ALL_INVITES
  );

  const [inviteStaff, { data, loading }] = useMutation(
    Mutations.SEND_INVITE_FOR_STAFF_MEMBER,
    {
      update(cache, { data: { sendInviteForStaffMember } }) {
        const { invites } = cache.readQuery({
          query: Queries.ALL_INVITES,
        });

        cache.writeQuery({
          query: Queries.ALL_INVITES,
          data: {
            invites: [sendInviteForStaffMember, ...invites],
          },
        });
      },
    }
  );

  const [deleteInvites, { loading: deleteLoading }] = useMutation(
    Mutations.DELETE_INVITES,
    {
      update(cache, { data: { deleteInvites } }) {
        let { invites: allInvites } = cache.readQuery({
          query: Queries.ALL_INVITES,
        });
        allInvites = allInvites.filter((invite) => {
          return selectedInviteKeys.findIndex((item) => item === invite.id) < 0;
        });
        setInvites(allInvites);
        message.success(
          `${selectedInviteKeys.length} invite(s) deleted successfully`
        );
        setSelectedInviteKeys([]);
        cache.writeQuery({
          query: Queries.ALL_INVITES,
          data: { invites: allInvites },
        });
      },
    }
  );

  const [callResendInvites, { loading: resendLoading }] = useMutation(
    Mutations.RESEND_INVITES,
    {
      update(cache, { data: { resendInvites } }) {
        const { invites } = cache.readQuery({
          query: Queries.ALL_INVITES,
        });
        const tempInvites = [...invites];
        tempInvites.forEach((invite, i) => {
          var obj = resendInvites.find((item) => item.id === invite.id);
          if (obj) {
            tempInvites[i] = obj;
          }
        });
        setInvites(tempInvites);
        message.success(
          `${selectedInviteKeys.length} invite(s) sent successfully`
        );
        setSelectedInviteKeys([]);
        cache.writeQuery({
          query: Queries.ALL_INVITES,
          data: { invites: tempInvites },
        });
      },
    }
  );

  useEffect(() => {
    if (data) {
      message.success(`Invite sent to ${data.sendInviteForStaffMember.email}`);
    }
  }, [data]);

  useEffect(() => {
    if (inviteData) {
      setInvites(inviteData.invites);
    }
  }, [inviteData]);

  const handleSendInvite = (
    firstName,
    lastName,
    email,
    redirectUrl,
    userType
  ) => {
    setInviteModalVisible(false);
    try {
      inviteStaff({
        variables: {
          firstname: firstName,
          lastname: lastName,
          email: email,
          redirectUrl: redirectUrl,
          userType: userType,
        },
        refetchQueries: [{ query: Queries.ALL_INVITES }],
      }).catch((e) => {
        if (e.message.includes("Network error")) {
          message.error("Please check your network connection!");
        } else message.error(trimErrorMessage(e.message));
        setInviteModalVisible(false);
      });
    } catch (e) {}
  };

  const onSearchChange = (val) => {
    setSearchText(val.trim());
    if (inviteData?.invites) {
      if (!searchText || searchText === "") {
        setInvites(inviteData.invites);
      } else {
        const filtered = inviteData.invites.filter((invite) => {
          const name = (invite.firstname + invite.lastname).toLowerCase();
          return name.includes(val.trim().toLowerCase());
        });
        setInvites(filtered);
      }
    }
  };

  const onDeleteSelected = () => {
    const selectedAndAcceptedInvites = selectedInviteKeys.filter(
      (key) =>
        inviteData.invites.findIndex(
          (invite) =>
            invite.id === key && invite.status === InviteStatus.ACCEPTED
        ) > 0
    );

    if (selectedAndAcceptedInvites?.length) {
      message.error(
        "Accepted invites can't be deleted. Please deselect them first."
      );
    } else if (inviteData?.invites) {
      deleteInvites({
        variables: {
          ids: selectedInviteKeys,
        },
      }).catch((err) => message.error(trimErrorMessage(err.message)));
    }
  };
  const onResendInviteSelected = () => {
    const selectedAndAcceptedInvites = selectedInviteKeys.filter(
      (key) =>
        inviteData.invites.findIndex(
          (invite) =>
            invite.id === key && invite.status === InviteStatus.ACCEPTED
        ) > 0
    );

    if (selectedAndAcceptedInvites?.length) {
      message.error(
        "Accepted invites can't be resent. Please deselect them first."
      );
    } else if (inviteData?.invites) {
      callResendInvites({
        variables: {
          inviteIds: selectedInviteKeys,
        },
      }).catch((err) => message.error(trimErrorMessage(err.message)));
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedInviteKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedInviteKeys,
  };

  const actionsMenu = () => (
    <Menu>
      <Menu.Item>
        <Button
          icon={<MailOutlined />}
          type={"primary"}
          onClick={onResendInviteSelected}
          style={{ width: "100%" }}
        >
          Resend invite
        </Button>
      </Menu.Item>
      <Menu.Item>
        <DeletePopUp
          action={(toDelete) => {
            if (toDelete) onDeleteSelected();
          }}
          title="Confirm delete"
          message="Are you sure you want to delete these record(s)?"
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Spin
        spinning={usersLoading || loading || deleteLoading || resendLoading}
      >
        <TableHeader>
          <Input
            value={searchText}
            prefix={<SearchOutlined />}
            placeholder={"Search invites"}
            style={{ width: 256, height: 40 }}
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <div className="Actions">
            <Button
              shape="round"
              type="primary"
              size={"medium"}
              className={"send-invite"}
              icon={<PlusOutlined />}
              style={{ marginRight: 12 }}
              onClick={() => setInviteModalVisible(true)}
            >
              Send an invite
            </Button>
            <Dropdown
              trigger="click"
              overlay={actionsMenu}
              disabled={!selectedInviteKeys.length > 0}
            >
              <Button style={{ width: 135, height: 32 }}>
                Action <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </TableHeader>
        <TableContainer>
          <SimpleTableView
            columns={columns}
            data={invites || []}
            rowSelection={rowSelection}
            selectedRowKeys={selectedInviteKeys}
            scroll={{ y: "calc(100vh - 300px)" }}
          />
        </TableContainer>
        <InviteModel
          inviteModalVisible={inviteModalVisible}
          setInviteModalVisible={setInviteModalVisible}
          invites={invites ? invites : []}
          handleInviteSend={handleSendInvite}
        />
      </Spin>
    </div>
  );
}
