import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Select, Input, Switch, message, Spin, Form } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { PODCAST_FORM_FIELDS, PROTOCOLS } from "../../../utils/contants";
import HelpText from "../../../components/HelpText";
import { useDatabase } from "../../../hooks/database";
import { getSession } from "../../../helpers/SessionManagement";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const { Option } = Select;

const StyledForm = styled.div`
  .switch {
    margin-top: 1.5rem;
    margin-right: 0.5rem;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const HorizontalContainerSB = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
`;

const rules = [{ required: true, message: "This field is required." }];

export default function PodcastForm(props) {
  const { database, loading: databaseLoading } = useDatabase();
  const session = getSession();
  const [podcastLoading, setPodcastLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();
  const id = props.match.params.id;

  const handleSubmit = async () => {
    setSaveLoading(true);
    const formFields = form.getFieldsValue();
    const variables = {
      name: formFields.name,
      rss_feed: formFields.protocol + formFields.rss_feed,
      published: formFields.published,
    };

    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();

    if (id !== "new") {
      await database.items("podcasts").updateOne(id, variables);
      message.success(variables.name + " updated");
      setSaveLoading(false);
      props.history.push("/podcasts");
    } else {
      await database.items("podcasts").createOne({
        ...variables,
        title: "",
        organization_id: session?.organization?.id,
        user_id: session?.id,
      });

      message.success(variables.name + " created");
      setSaveLoading(false);
      props.history.push("/podcasts");
    }
  };

  const initForm = useCallback(
    (data = {}) => {
      const { name, published = true } = data || "";
      const pro = data?.rss_feed?.includes(PROTOCOLS.http)
        ? PROTOCOLS.http
        : PROTOCOLS.https || PROTOCOLS.https;

      const rssFeed =
        (data?.rss_feed || "")
          .replace(PROTOCOLS.https, "")
          .replace(PROTOCOLS.http, "") || "";

      form.setFieldsValue({
        name: name,
        rss_feed: rssFeed,
        protocol: pro,
        published: published,
      });
    },
    [form]
  );

  useEffect(() => {
    async function init() {
      if (id === "new") {
        initForm();
        return;
      }

      setPodcastLoading(true);
      const res = await database.items("podcasts").readOne(id);
      initForm(res);
      setPodcastLoading(false);
    }

    if (!databaseLoading) {
      init();
    }
  }, [id, databaseLoading, database, initForm]);

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Spin spinning={id && (podcastLoading || saveLoading)}>
        <StyledForm>
          <Form.Provider onFormFinish={() => handleSubmit()} scrollToFirstError>
            <Form form={form} layout={"vertical"}>
              <Form.Item
                label={<HelpText title="Name" />}
                name={PODCAST_FORM_FIELDS.name.key}
                style={{ fontWeight: 600, margin: "18px 0px" }}
                rules={rules}
              >
                <Input
                  type={"text"}
                  placeholder={PODCAST_FORM_FIELDS.name.placeHolder}
                  size={"large"}
                />
              </Form.Item>
              <HelpText title="RSS feed" required={true} />
              <HorizontalContainer>
                <Form.Item
                  name={PODCAST_FORM_FIELDS.protocol.key}
                  style={{ fontWeight: 600, width: 100 }}
                  rules={[{ required: true }]}
                >
                  <Select className="select-before" size={"large"}>
                    <Option value={PROTOCOLS.https}>{PROTOCOLS.https}</Option>
                    <Option value={PROTOCOLS.http}>{PROTOCOLS.http}</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={PODCAST_FORM_FIELDS.rssFeed.key}
                  style={{ fontWeight: 600, width: "100%" }}
                  rules={rules}
                >
                  <Input
                    type={"text"}
                    placeholder={PODCAST_FORM_FIELDS.rssFeed.placeHolder}
                    size={"large"}
                  />
                </Form.Item>
              </HorizontalContainer>
              <SwitchWrapper>
                <Form.Item
                  name={PODCAST_FORM_FIELDS.published.key}
                  valuePropName="checked"
                >
                  <Switch className="switch" />
                </Form.Item>
                <HelpText title="Published" />
              </SwitchWrapper>
            </Form>
            <HorizontalContainerSB>
              <Button
                shape="round"
                size={"large"}
                onClick={() => {
                  props.history.push("/podcasts");
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                shape="round"
                size={"large"}
                onClick={form.submit}
                style={{ marginTop: 16 }}
                icon={<SaveOutlined />}
              >
                {id !== "new" ? "Save podcast" : "Create podcast"}
              </Button>
            </HorizontalContainerSB>
          </Form.Provider>
        </StyledForm>
      </Spin>
    </div>
  );
}
