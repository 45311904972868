import { useCallback, useEffect, useState, useRef } from "react";
import worker from "../utils/workerize!../main.worker"; // eslint-disable-line import/no-webpack-loader-syntax
import { USER_SESSION_KEY } from "../helpers/SessionManagement";

let workerEnabled = typeof SharedWorker !== "undefined";
let workerInstance = null;
let observers = [];
const SOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

export const useWorker = (subscribeTo = null) => {
  const [workerMessage, setWorkerMessage] = useState(null);
  const subscribedToItems = useRef([]);

  const getWorker = useCallback(() => {
    if (!workerInstance) {
      if (!workerEnabled) return console.log("Shared workers not supported");
      workerInstance = worker();
      workerInstance.port.postMessage({
        type: "init",
        websocketUrl: SOCKET_URL,
        credentials: JSON.parse(localStorage.getItem(USER_SESSION_KEY)),
      });

      workerInstance.port.onmessage = (e) => {
        observers.forEach((o) => {
          if (o.subscribedToItems.current?.[0]) {
            const type = e?.data?.type;
            const subject = e?.data?.subject;

            if (
              (type && o.subscribedToItems.current.includes(type)) ||
              (subject && o.subscribedToItems.current.includes(subject))
            ) {
              o.func(e?.data);
            }
          } else {
            o.func(e?.data);
          }
        });
      };
    }

    return workerInstance;
  }, []);

  const sendWorkerMessage = useCallback(
    (message) => {
      const w = getWorker();
      if (!message?.type && subscribedToItems.current?.length === 1) {
        message.type = subscribedToItems.current[0];
      }

      w.port.postMessage(message);
    },
    [getWorker]
  );

  useEffect(() => {
    if (
      subscribeTo &&
      !Array.isArray(subscribeTo) &&
      subscribeTo !== subscribedToItems.current[0]
    ) {
      subscribedToItems.current = [subscribeTo];
    } else if (subscribeTo && Array.isArray(subscribeTo)) {
      if (
        JSON.stringify(subscribeTo) !==
        JSON.stringify(subscribedToItems.current)
      ) {
        subscribedToItems.current = subscribeTo;
      }
    }
  }, [subscribeTo]);

  useEffect(() => {
    observers.push({ func: setWorkerMessage, subscribedToItems });
    getWorker();

    return () => {
      observers = observers.filter(
        (update) => update?.func !== setWorkerMessage
      );
    };
  }, [getWorker]);

  return {
    workerMessage,
    sendWorkerMessage,
    getWorker,
  };
};
