import React, { useState, useEffect } from "react";
import Queries from "../../../api/Queries";
import Mutations from "../../../api/Mutations";
import styled from "styled-components";
import { UserRole } from "../../../utils/contants";
import HelpText from "../../../components/HelpText";
import { useQuery, useMutation } from "@apollo/client";
import { Collapse, Button, message, Spin, Input } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { getSession } from "./../../../helpers/SessionManagement";
import CustomFieldsComponent from "../../../components/CustomFieldsComponent";
import { getValidCustomFields, getValidCustomFieldsRuby, isValidCustomFields } from "../../../utils";
import ProducersTable from "./tableView";

const SaveWrapper = styled.div`
  display: flex;
  margin: 1rem;
`;

const { Panel } = Collapse;

const Producers = (props) => {
  const [customFieldData, setCustomFieldData] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState("");

  const {
    loading: settingsLoading,
    error: settingsError,
    data: settingsData,
  } = useQuery(Queries.PRODUCER_SETTINGS, {});

  const [
    createProducerSettings,
    { loading: createLoading, errro: createError, data: createData },
  ] = useMutation(Mutations.CREATE_PRODUCER_SETTINGS, {});

  const [
    updateProducerSettings,
    {
      loading: updateSettingsLoading,
      error: updateSettingsError,
      data: updateSettingsData,
    },
  ] = useMutation(Mutations.UPDATE_PRODUCER_SETTINGS, {});

  useEffect(() => {
    if (settingsData && settingsData.producerSetting) {
      const { producerSetting } = settingsData;
      setRedirectUrl(producerSetting?.redirectUrl);
      setCustomFieldData(getValidCustomFieldsRuby(producerSetting?.customFields));
    }
  }, [settingsData, settingsError, settingsLoading]);

  useEffect(() => {
    if (createData) {
      message.success("Settings for producer created successfully");
    }
    if (updateSettingsData) {
      message.success("Settings for producer updated successfully");
    }
  }, [createData, updateSettingsData]);

  useEffect(() => {
    if (createError || updateSettingsError) {
      message.error(createError || updateSettingsError);
    }
  }, [createError, updateSettingsError]);

  const deleteCustomField = (id) => {
    setCustomFieldData(customFieldData.filter((item, index) => index !== id));
  };

  const getUpdateAndCreateVariable = () => {
    return {
      redirectUrl: redirectUrl,
      customFields: getValidCustomFieldsRuby(customFieldData),
    };
  };

  const handleCreateAndUpdate = () => {
    if (settingsData && settingsData.producerSetting) {
      if (isValidCustomFields(customFieldData)) {
        updateProducerSettings({
          variables: {
            ...getUpdateAndCreateVariable(),
            id: settingsData?.producerSetting.id,
          },
          refetchQueries: [{ query: Queries.PRODUCER_SETTINGS }],
        });
      } else {
        message.error("Label field requierd!");
      }
    } else {
      if (isValidCustomFields(customFieldData)) {
        createProducerSettings({
          variables: getUpdateAndCreateVariable(),
          refetchQueries: [{ query: Queries.PRODUCER_SETTINGS }],
        });
      } else {
        message.error("Label field requierd!");
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ padding: 24, background: "#fff" }}>
        <Collapse onChange={() => {}} style={{ width: "100%" }}>
          <Panel header="Producer settings" key="2" style={{ width: "100%" }}>
            <HelpText title={"Redirect Url"} />
            <Input
              type="text"
              style={{ width: "100%" }}
              size={"large"}
              placeholder="Redirect url"
              value={redirectUrl}
              onChange={(e) => setRedirectUrl(e.target.value)}
            ></Input>
            <HelpText title={"Custom fields"} />
            <CustomFieldsComponent
              data={customFieldData}
              setData={setCustomFieldData}
              deleteCustomField={deleteCustomField}
              isProducer={true}
            />

            <SaveWrapper>
              {settingsLoading || createLoading || updateSettingsLoading ? (
                <Spin size="large" />
              ) : (
                <Button
                  style={{ marginLeft: 'auto' }}
                  type="primary"
                  shape="round"
                  icon={createLoading ? <LoadingOutlined /> : <PlusOutlined />}
                  onClick={() => {
                    handleCreateAndUpdate();
                  }}
                >
                  {settingsData?.producerSetting
                    ? "Update Producer Settings"
                    : "Create Producer Setings"}
                </Button>
              )}
            </SaveWrapper>
          </Panel>
        </Collapse>
      </div>

      <div style={{ padding: 24, background: "#fff", marginTop: 26 }}>
        <ProducersTable
          history={props.history}
          settingsData={settingsData}
          isShowRowSelection={getSession().userType !== UserRole.STAFF}
        />
      </div>
    </div>
  );
};

export default Producers;
