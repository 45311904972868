import React from "react";
import { Link } from "react-router-dom";
import { Tag, Avatar, Button, Divider } from "antd";
import DeleteConfirm from "../../components/DeleteConfirm/DeleteConfirm";
import { PlusOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";

const columns = (
    onEdit,
    onDelete,
    onRefresh,
    refreshLoading,
    refreshingVideo
) => [
    {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (image) => <Avatar src={image}/>,
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
            <Link
                to={`/live-video/${record.id}`}
                style={{width: 100, background: "yellow"}}
            >
                <p style={{width: 210}}>{text}</p>
            </Link>
        ),
    },
    {
        title: "Stations",
        dataIndex: "stations",
        key: "stations",
        render: (stations, record) => (
            <span
                style={{
                    maxWidth: "100px",
                    overflowX: "scroll",
                    display: "block",
                    whiteSpace: "nowrap",
                }}
            >
        {stations}
      </span>
        ),
    },
    {
        title: "Stream URL",
        key: "streamUrl",
        dataIndex: "streamUrl",
        render: (url) => (
            <span>
        <Tag
            color={"orange"}
            style={{maxWidth: "100px", overflowX: "scroll"}}
        >
          {url}
        </Tag>
      </span>
        ),
    },
    {
        title: "Provider",
        key: "provider",
        dataIndex: "provider",
        render: (provider) => (
            <span>
        <Tag
            color={"magenta"}
            style={{maxWidth: "200px", overflowX: "scroll"}}
        >
          {provider}
        </Tag>
      </span>
        ),
    },
    {
        title: "Status",
        key: "published",
        dataIndex: "published",
        render: (published) => (
            <span>
        {!published && (
            <Tag
                color="red"
                size="small"
                style={{maxWidth: "70px", overflowX: "scroll"}}
            >
                Not published
            </Tag>
        )}
                {published && (
                    <Tag color="green" style={{maxWidth: "150px", overflowX: "scroll"}}>
                        published
                    </Tag>
                )}
      </span>
        ),
    },
    {
        title: "Action",
        key: "action",
        render: (text, record) => (
            <span style={{display: "flex"}}>
        <Button
            type={"primary"}
            icon={<EditOutlined/>}
            onClick={() => onEdit(record.id)}
        >
          Edit
        </Button>
        <Divider type="vertical" style={{alignSelf: "center"}}/>
        <Button
            icon={
                refreshLoading && refreshingVideo.id === record.id ? (
                    <LoadingOutlined/>
                ) : (
                    <PlusOutlined/>
                )
            }
            type={"secondary"}
            disabled={refreshLoading}
            onClick={() => onRefresh(record.id, record.name)}
        >
          {refreshLoading && refreshingVideo.id === record.id
              ? "Refresh"
              : "Refresh"}
        </Button>
        <Divider type="vertical" style={{alignSelf: "center"}}/>
        <DeleteConfirm name={record.name} action={() => onDelete(record.id)}/>
      </span>
        ),
    },
];

export default columns;
