import React, { useState, useEffect, useRef } from "react";
import { Queries } from "../../api";
import styled from "styled-components";
import HelpText from "../../components/HelpText";
import { format, parseISO } from "date-fns";
import { PROXY_URL } from "../../constants";
import { useQuery } from "@apollo/client";
import {
  PlusOutlined,
  LoadingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { defaultStatus, MEDIA_TYPE } from "../../utils/contants";
import {
  Collapse,
  Select,
  Switch,
  Button,
  message,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import CustomFieldsComponent from "../../components/CustomFieldsComponent";
import { getValidCustomFields, getValidCustomFieldsDirectus, isValidCustomFields } from "../../utils";
import TableHeader from "../../components/Table/TableHeader";
import Table from "../../components/Table";
import { useDatabase } from "../../hooks/database";
import { getSession } from "../../helpers/SessionManagement";
import { Link } from "react-router-dom";
import { useEntities, useEntityTable } from "../../hooks/entity";
import {
  ScrollableCell,
  TagsContainer,
} from "../../components/MediaTable/columns";
import { v4 as uuidv4 } from "uuid";

const AllowEpisodeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const SaveWrapper = styled.div`
  padding: 1em 0;
  display: flex;
  justify-content: center;
`;

const { Panel } = Collapse;
const { Option } = Select;

const masterControlIntegrationOptions = [
  <Option key={MEDIA_TYPE.Cabelcast}>{MEDIA_TYPE.Cabelcast}</Option>,
  <Option key={MEDIA_TYPE.Telvue}>{MEDIA_TYPE.Telvue}</Option>,
];

const Episode = (props) => {
  const { database } = useDatabase();
  const tableRef = useRef(null);
  const [allColumns, setAllColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const session = getSession();

  const staticFilters = {
    organization_id: {
      _eq: session?.organization?.id,
    },
  };

  const { fetchData, setQuickSearch } = useEntities({
    baseEntity: "episodes",
    staticFilters,
    fields: [
      "*",
      "program_id.title",
      "producer_id.name",
      "topics.topic_id.title",
      "tags.tag_id.name",
      "assistant_producers.user_id.name",
    ],
  });

  const { getColumnAttributes, loading: loadingDatabase } = useEntityTable({
    baseEntity: "episodes",
    staticFilters,
  });

  // states for UI
  const [cablecastLocation, setCablecastLocation] = useState();
  const [selectedEpisodeTopics, setSelectedEpisodeTopics] = useState();
  const [selectedEpisodeOptions, setSelectedEpisodeOptions] = useState();
  const [episodeSettings, setEpisodeSettings] = useState(null);
  const [allowEpisodeSponsored, setAllowEpisodeSponsored] = useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  const [selectedCableCastLocation, setSelectedCableCastLocation] = useState();
  const [episodeCustomFieldData, setEpisodeCustomFieldData] = useState([]);
  const [selectedMasterControl, setSelectedMasterControl] = useState(
    MEDIA_TYPE.Telvue
  );

  const isTelvue = selectedMasterControl === MEDIA_TYPE.Telvue

  const [programOptions, setProgramOptions] = useState({
    programType: [],
    programTags: [],
  });

  const {
    loading: settingsLoading,
    error: settingsError,
    data: settingsData,
  } = useQuery(Queries.EPISODE_SETTINGS, {fetchPolicy: 'network-only'});

  const getLocations = (url) => {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "json";
    request.onload = function () {
      let locations = [];
      if (request.response?.locations) {
        request.response.locations.map((item) =>
          locations.push({ id: item.id, name: item.name })
        );
        setCablecastLocation(locations);
      }
    };
    request.send();
  };


  useEffect(() => {
    if (episodeSettings) {
      if (episodeSettings.organization_id?.cablecast_integration?.base_url) {
        getLocations(
          `${PROXY_URL}/${episodeSettings.organization_id.cablecast_integration.base_url}/locations`
        );
      }

      setSelectedMasterControl(
        episodeSettings.master_control_integration === 1
          ? "TELVUE"
          : "CABLECAST"
      );

      setSelectedEpisodeOptions(
        episodeSettings.origin_options.map((item) => {
          return item.id;
        })
      );

      setSelectedCableCastLocation(episodeSettings?.cable_cast_location_id);
      setSelectedEpisodeTopics(
        episodeSettings.topics.map((topic) => topic.topic_id?.id)
      );

      setAllowEpisodeSponsored(episodeSettings.ask_episode_sponsor);
      setEpisodeCustomFieldData(
        getValidCustomFields(episodeSettings?.episode_custom_fields)
      );

      setProgramOptions({
        programTags: episodeSettings.topics.map((topic, i) => {
          return (
            <Option key={topic?.topic_id?.id}>
              {topic?.topic_id?.title}
            </Option>
          );
        }),
        programType: episodeSettings.origin_options.map((item) => {
          return <Option key={item.id}>{item.title}</Option>;
        }),
      });
    }
  }, [episodeSettings, settingsError, settingsLoading]);

  const getStatusItemColor = (status) =>
    status === defaultStatus.accept
      ? "green"
      : status === defaultStatus.declined
      ? "red"
      : status === defaultStatus.draft
      ? "#e9e9e9"
      : "orange";

  useEffect(() => {
    async function initCols() {
      const fields = await database.fields.readMany("programs");
      const statusFieldOptions =
        fields.find((f) => f?.field === "status")?.meta?.options?.choices || [];

      const episodeSettings = await database
        .items("episode_settings")
        .readMany({
          fields: [
            "*",
            "organization_id.cablecast_relation.base_url",
            "origin_options.title",
            "origin_options.id",
            "topics.topic_id.*",
          ],
          filter: {
            organization_id: session?.organization?.id,
          },
        });

      setEpisodeSettings(episodeSettings?.data?.[0]);
      let customFieldCols = [];
      if (episodeSettings?.data?.[0]?.episode_custom_fields?.[0]) {
        customFieldCols = episodeSettings.data[0].episode_custom_fields.map(
          (field) => {
            return {
              field: field?.id,
              colId: field?.id,
              headerName: field?.label,
              flex: 1,
              filter: false,
              sort: false,
              initialHide: true,
              cellRendererFramework: (params) => {
                if (!params?.value) {
                  if (field?.type === "Checkbox") {
                    return "No";
                  }

                  return " - ";
                }

                switch (field?.type) {
                  case "Checkbox":
                    return params?.value === "true" ? "Yes" : "No";

                  case "Date":
                  case "DateTime":
                    const val = params.value.replaceAll('"', "");
                    return format(parseISO(val), "MM/dd/yyyy");
                  default:
                    return "";
                }
              },
              valueGetter: (params) => {
                if (params?.data?.custom_field_value?.[0]) {
                  const val = params.data.custom_field_value.find(
                    (f) => f?.id === field?.id
                  );

                  return val?.value || null;
                }

                return null;
              },
            };
          }
        );
      }

      const columns = [
        {
          field: "id",
          colId: "id",
          headerName: "ID",
          sort: "desc",
          checkboxSelection: (params) => !params?.node?.footer,
          flex: 1,
          ...(await getColumnAttributes({
            field: "id",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/episodes/edit/${params?.data?.id}`}>
                {params?.value}
              </Link>
            );
          },
        },
        {
          field: "title",
          colId: "title",
          headerName: "Episode",
          flex: 1,
          ...(await getColumnAttributes({
            field: "title",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/episodes/edit/${params?.data?.id}`}>
                {params?.value}
              </Link>
            );
          },
        },
        {
          field: "program_id.title",
          colId: "program_id.title",
          headerName: "Program",
          flex: 1,
          ...(await getColumnAttributes({
            field: "title",
            entity: "programs",
          })),
          sortable: false,
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return params?.value || " - ";
          },
        },
        {
          field: "producer_id.name",
          colId: "producer_id.name",
          headerName: "Producer",
          flex: 1,
          ...(await getColumnAttributes({
            field: "name",
            entity: "users",
          })),
          sortable: false,
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return params?.value || " - ";
          },
        },
        {
          field: "date_of_production",
          colId: "date_of_production",
          headerName: "Production date",
          flex: 1,
          ...(await getColumnAttributes({
            field: "date_of_production",
          })),
        },
        {
          field: "updated_at",
          colId: "updated_at",
          headerName: "Updated date",
          flex: 1,
          ...(await getColumnAttributes({
            field: "updated_at",
          })),
        },
        {
          field: "status",
          colId: "status",
          headerName: "Status",
          flex: 1,
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params) => {
              const val = parseInt(params?.value);
              switch (val) {
                case 0:
                  return "DRAFT";

                case 1:
                  return "IN_REVIEW";

                case 2:
                  return "ACCEPTED";

                case 3:
                  return "DECLINED";

                default:
                  return " - ";
              }
            },
            values: [0, 1, 2, 3],
          },
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            const status =
              statusFieldOptions.find((s) => s?.value === params?.value)
                ?.text || " - ";

            return (
              <span>
                <Tag
                  color={getStatusItemColor(status)}
                  style={{
                    maxWidth: "150px",
                    overflowX: "scroll",
                    display: "inline-flex",
                  }}
                >
                  {status}
                </Tag>
              </span>
            );
          },
        },
        {
          field: "published",
          colId: "published",
          headerName: "Published",
          flex: 1,
          ...(await getColumnAttributes({
            field: "published",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span>
                {!params?.value && (
                  <Tag color="red" size="small">
                    Not published
                  </Tag>
                )}

                {params?.value && <Tag color="green">Published</Tag>}
              </span>
            );
          },
        },
        {
          field: "created_at",
          colId: "created_at",
          headerName: "Created At",
          flex: 1,
          minWidth: 100,
          ...(await getColumnAttributes({
            field: "created_at",
          })),
        },
        {
          field: "tags",
          colId: "tags",
          headerName: "Tags",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          sortable: false,
          filter: false,
          cellRendererFramework: (params) => {
            if (params?.data?.tags?.[0]) {
              return (
                <ScrollableCell>
                  <TagsContainer>
                    {params.data.tags.map((item) => (
                      <Tag
                        key={item?.tag_id?.name}
                        style={{
                          color: "#000",
                          backgroundColor: "#E5E5E5",
                          borderColor: "#000",
                          maxWidth: "150px",
                          overflowX: "scroll",
                          display: "inline-flex",
                        }}
                      >
                        {item?.tag_id?.name}
                      </Tag>
                    ))}
                  </TagsContainer>
                </ScrollableCell>
              );
            }

            return " - ";
          },
        },
        {
          field: "topics",
          colId: "topics",
          headerName: "Topics",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          sortable: false,
          filter: false,
          cellRendererFramework: (params) => {
            if (params?.data?.topics?.[0]) {
              return (
                <ScrollableCell>
                  <TagsContainer>
                    {params.data.topics.map((item) => (
                      <Tag
                        key={item?.topic_id?.title}
                        color={"red"}
                        style={{
                          maxWidth: "150px",
                          overflowX: "scroll",
                          display: "inline-flex",
                        }}
                      >
                        {item?.topic_id?.title}
                      </Tag>
                    ))}
                  </TagsContainer>
                </ScrollableCell>
              );
            }

            return " - ";
          },
        },
        {
          field: "assistant_producers",
          colId: "assistant_producers",
          headerName: "Assistant Producers",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          sortable: false,
          filter: false,
          cellRendererFramework: (params) => {
            if (params?.data?.assistant_producers?.[0]) {
              return (
                <ScrollableCell>
                  <TagsContainer>
                    {params.data.assistant_producers.map((item) => (
                      <Tag
                        key={item?.user_id?.name}
                        color={"green"}
                        style={{ maxWidth: "150px", overflowX: "scroll" }}
                      >
                        {item?.user_id?.name}
                      </Tag>
                    ))}
                  </TagsContainer>
                </ScrollableCell>
              );
            }

            return " - ";
          },
        },
        {
          field: "thumbnail_url",
          colId: "thumbnail_url",
          headerName: "Thumbnail",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "thumbnail_url",
          })),
          cellRendererFramework: (params) => {
            if (params?.value) {
              return (
                <Tooltip
                  title={<img style={{ width: "100%" }} src={params.value} />}
                >
                  <a>View Image</a>
                </Tooltip>
              );
            }

            return " - ";
          },
        },
        {
          field: "episode_origin_option_id",
          colId: "episode_origin_option_id",
          headerName: "Episode Origin",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "episode_origin_option_id",
          })),
        },
        {
          field: "stream_url",
          colId: "stream_url",
          headerName: "Episode Stream URL",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "stream_url",
          })),
        },
        {
          field: "video_url",
          colId: "video_url",
          headerName: "Episode Video",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          cellRendererFramework: (params) => {
            if (params?.value) {
              return (
                <div>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    style={{ width: 128 }}
                    onClick={() => {
                      window.open(params.value, "_blank");
                    }}
                  >
                    Download
                  </Button>
                </div>
              );
            }

            return " - ";
          },
        },
        {
          field: "link_expiry",
          colId: "link_expiry",
          headerName: "Expiry Link",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "link_expiry",
          })),
        },
        {
          field: "telvue_id",
          colId: "telvue_id",
          headerName: "Telvue ID",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "telvue_id",
          })),
        },
        {
          field: "telvue_connect_id",
          colId: "telvue_connect_id",
          headerName: "Telvue Connect ID",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "telvue_connect_id",
          })),
        },
        {
          field: "telvue_file_name",
          colId: "telvue_file_name",
          headerName: "Expected Filename",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "telvue_file_name",
          })),
        },
        {
          field: "telvue_ingest_date_time",
          colId: "telvue_ingest_date_time",
          headerName: "Ingest Date & Time",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "telvue_ingest_date_time",
          })),
        },
        {
          field: "telvue_delete_at",
          colId: "telvue_delete_at",
          headerName: "Telvue Episode Delete At",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "telvue_delete_at",
          })),
        },
        {
          field: "telvue_program_code",
          colId: "telvue_program_code",
          headerName: "Program Code",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "telvue_program_code",
          })),
        },
        {
          field: "cablecast_id",
          colId: "cablecast_id",
          headerName: "Cablecast ID",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "cablecast_id",
          })),
        },
        {
          field: "cablecast_program_pelantion_media",
          colId: "cablecast_program_pelantion_media",
          headerName: "Cablecast relation media",
          flex: 1,
          minWidth: 100,
          initialHide: true,
          ...(await getColumnAttributes({
            field: "cablecast_program_pelantion_media",
          })),
        },
        ...customFieldCols,
      ];

      setAllColumns(columns);
    }

    if (!loadingDatabase) {
      initCols();
    }
    // eslint-disable-next-line
  }, [database, getColumnAttributes, loadingDatabase]);

  useEffect(() => {
    const filteredColumns = allColumns.filter(c => isTelvue ? !c.field.includes('cablecast') : !c.field.includes('telvue'))
    setColumns(filteredColumns)
  }, [isTelvue, allColumns])

  const deleteCustomField = (id) => {
    setEpisodeCustomFieldData(
      episodeCustomFieldData.filter((item, index) => index !== id)
    );
  };


  const handleCreateAndUpdate = async () => {
    setSavingSettings(true);
    // Handle topics.
    const newTopics = (selectedEpisodeTopics || []).filter(isNaN);
    const topicVals = (selectedEpisodeTopics || []).filter((t) => !isNaN(t));

    if (newTopics?.[0]) {
      const newTopicIDs = await database.items("topics").createMany(
        newTopics.map((pt) => ({
          title: pt,
        }))
      );

      if (newTopicIDs?.data?.[0]) {
        newTopicIDs.data.forEach((pt) => {
          topicVals.push(pt?.id);
        });
      }
    }

    // Handle origin options.
    const newEpisodeOrigins = (selectedEpisodeOptions || []).filter(isNaN);
    const episodeOriginVals = (selectedEpisodeOptions || []).filter(
      (pt) => !isNaN(pt)
    );

    if (isValidCustomFields(episodeCustomFieldData)) {
      const customFields = getValidCustomFieldsDirectus(episodeCustomFieldData);

      if (episodeSettings?.id) {
        await database
          .items("episode_settings")
          .updateOne(episodeSettings?.id, {
            master_control_integration: isTelvue ? 1 : 0,
            ask_episode_sponsor: allowEpisodeSponsored,
            origin_options: [...episodeOriginVals.map(o => ({id: o})), ...newEpisodeOrigins.map(o => ({title: o}))],
            topics: (topicVals || []).map((t) => ({
              topic_id: t,
            })),
            episode_custom_fields: (customFields || []).map((cf) => ({
              ...cf,
              id: cf?.id || uuidv4(),
            })),
          });

        message.success("Settings for episode updated successfully");
      } else {
        await database.items("episode_settings").createOne({
          ask_episode_sponsor: allowEpisodeSponsored,
          origin_options: (episodeOriginVals || []).map((o) => ({
            title: o,
          })),
          topics: (topicVals || []).map((t) => ({
            topic_id: t,
          })),
          master_control_integration: isTelvue ? 1 : 0,
          organization_id: session?.organization?.id,
          ...(customFields?.[0]
            ? {
                episode_custom_fields: customFields.map((cf) => ({
                  ...cf,
                  id: cf?.id || uuidv4(),
                })),
              }
            : {}),
        });

        message.success("Settings for episode created successfully");
      }
    } else {
      message.error("Label field requierd!");
    }

    setSavingSettings(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ padding: 24, background: "#fff" }}>
        <Spin spinning={savingSettings}>
          <Collapse onChange={() => {}} style={{ width: "100%" }}>
            <Panel header="Episode settings" key="2" style={{ width: "100%" }}>
              <HelpText title={"Master control integration"} required={true} />
              <Select
                size={"large"}
                defaultValue={selectedMasterControl}
                style={{ width: 204 }}
                onChange={(value) => {
                  setSelectedMasterControl(value);
                }}
              >
                {masterControlIntegrationOptions}
              </Select>
              {selectedMasterControl === "CABLECAST" &&
                episodeSettings?.organization_id?.cablecast_integration
                  ?.base_url &&
                cablecastLocation?.length > 0 && (
                  <div style={{ borderLeft: "1px solid #d9d9d9" }}>
                    <div style={{ marginLeft: "20px" }}>
                      <HelpText title={"Cablecast Location"} />
                      <Select
                        size={"large"}
                        placeholder={"Select"}
                        value={selectedCableCastLocation}
                        style={{ width: 204 }}
                        onChange={(value) => {
                          setSelectedCableCastLocation(value);
                        }}
                      >
                        {cablecastLocation?.map((item) => (
                          <Option key={item.id}>{item.name}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}
              <HelpText title={"Episode origin options"} />
              <Select
                mode="tags"
                style={{ width: "100%" }}
                size={"large"}
                placeholder="Episode options"
                value={selectedEpisodeOptions}
                onChange={setSelectedEpisodeOptions}
              >
                {programOptions.programType}
              </Select>

              <HelpText title={"Episode topics"} />
              <Select
                mode="tags"
                style={{ width: "100%" }}
                size={"large"}
                placeholder="Episode topics"
                value={selectedEpisodeTopics}
                onChange={setSelectedEpisodeTopics}
              >
                {programOptions.programTags}
              </Select>
              <AllowEpisodeWrapper>
                <Switch
                  size={"small"}
                  style={{ width: 20, marginRight: 10 }}
                  defaultChecked={allowEpisodeSponsored}
                  onClick={(value) => {
                    setAllowEpisodeSponsored(value);
                  }}
                />
                <HelpText title={"Allow episodes to be sponsored"} />
              </AllowEpisodeWrapper>

              <HelpText title={"Episode custom fields"} />
              <CustomFieldsComponent
                data={episodeCustomFieldData}
                setData={setEpisodeCustomFieldData}
                deleteCustomField={deleteCustomField}
                isEpisode={true}
              />

              <SaveWrapper>
                {settingsLoading ? (
                  <Spin size="large" />
                ) : (
                  <Button
                    style={{ marginRight: 10 }}
                    type="primary"
                    shape="round"
                    icon={
                      settingsLoading ? <LoadingOutlined /> : <PlusOutlined />
                    }
                    onClick={() => {
                      handleCreateAndUpdate();
                    }}
                  >
                    {episodeSettings?.id
                      ? "Update Episode Settings"
                      : "Create Episode Setings"}
                  </Button>
                )}
              </SaveWrapper>
            </Panel>
          </Collapse>
        </Spin>
      </div>

      <div style={{ padding: 24, background: "#fff", marginTop: 26, flex: 1 }}>
        <TableHeader
          showPush
          collection="episodes"
          label="episode"
          link="/episodes/new"
          history={props.history}
          tableRef={tableRef}
          selectedRows={selectedRows}
          setQuickSearch={setQuickSearch}
          isCableCast={
            settingsData?.episodeSetting?.masterControlIntegration ===
            "CABLECAST"
          }
        />

        <Table
          hideGroupBy
          tableRef={tableRef}
          fetchData={fetchData}
          wrapperHeight={false}
          columns={columns}
          gridOptions={{
            onSelectionChanged: (event) => {
              setSelectedRows(event.api.getSelectedNodes());
            },
            groupIncludeTotalFooter: false,
            domLayout: "autoHeight",
            paginationAutoPageSize: false,
          }}
        />
      </div>
    </div>
  );
};

export default Episode;
