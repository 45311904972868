import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Spin, Tag } from "antd";
import { useDatabase } from "../../hooks/database";
import { getSession } from "../../helpers/SessionManagement";
import { useEntities, useEntityTable } from "../../hooks/entity";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import DeleteConfirm from "../../components/DeleteConfirm/DeleteConfirm";

export default function Playlist(props) {
  const { database } = useDatabase();
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const session = getSession();

  const staticFilters = {
    organization_id: {
      _eq: session?.organization?.id,
    },
  };

  const { fetchData, fetchingData } = useEntities({
    baseEntity: "playlists",
    staticFilters,
    fields: [
      "id",
      "title",
      "description",
      "publish_on_app",
      "playlist_type",
      "created_at",
      "updated_at",
      "filter_type",
    ],
  });

  const { getColumnAttributes, loading: loadingDatabase } = useEntityTable({
    baseEntity: "playlists",
    staticFilters,
  });

  const onDelete = async (id) => {
    await database.items("playlists").deleteOne(id);
    tableRef.current.api.refreshServerSideStore();
  };

  const onPublish = async (id, status) => {
    await database.items("playlists").updateOne(id, {
      publish_on_app: status,
    });

    tableRef.current.api.refreshServerSideStore();
  };

  const goToPlaylistForm = () => props.history.push("/playlist/new");

  useEffect(() => {
    async function initCols() {
      const columns = [
        {
          field: "title",
          colId: "title",
          headerName: "Title",
          sort: "asc",
          flex: 1,
          ...(await getColumnAttributes({
            field: "title",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/playlist/${params?.data?.id}`}>{params?.value}</Link>
            );
          },
        },
        {
          field: "description",
          colId: "description",
          headerName: "Description",
          flex: 1,
          ...(await getColumnAttributes({
            field: "description",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return params?.value || "";
          },
        },
        {
          field: "publish_on_app",
          colId: "publish_on_app",
          headerName: "On mobile app",
          flex: 1,
          ...(await getColumnAttributes({
            field: "published",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span>
                {!params?.value && (
                  <Tag color="red" size="small">
                    Not published
                  </Tag>
                )}

                {params?.value && <Tag color="green">published</Tag>}
              </span>
            );
          },
        },
        {
          field: "action",
          colId: "action",
          headerName: "Action",
          flex: 1,
          minWidth: 350, // @todo figure out how to override width
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span style={{ height: "100%" }}>
                <DeleteConfirm
                  name={params?.data?.title}
                  action={() => onDelete(params?.data?.id)}
                />
                <Divider type="vertical" style={{ alignSelf: "center" }} />
                <Button
                  onClick={() =>
                    onPublish(params?.data?.id, !params?.data?.publish_on_app)
                  }
                  type="primary"
                >
                  {!params?.data?.publish_on_app
                    ? "Publish to mobile"
                    : "Unpublish from mobile"}
                </Button>
              </span>
            );
          },
        },
      ];

      setColumns(columns);
    }

    if (!loadingDatabase) {
      initCols();
    }
    // eslint-disable-next-line
  }, [database, getColumnAttributes, loadingDatabase]);

  return (
    <Spin size="large" spinning={fetchingData}>
      <div style={{ padding: 24, background: "#fff" }}>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          style={{ marginBottom: "1.5rem" }}
          onClick={goToPlaylistForm}
        >
          Create a new playlist
        </Button>

        <Table
          hideGroupBy
          tableRef={tableRef}
          fetchData={fetchData}
          wrapperHeight={750}
          columns={columns}
          gridOptions={{
            groupIncludeTotalFooter: false,
          }}
        />
      </div>
    </Spin>
  );
}
