import React, { useState, useEffect } from "react";
import { Upload, Form, message } from "antd";
import { ThemeColors } from "../../theme";
import { InboxOutlined } from "@ant-design/icons";
import {
    trimS3FilePathFromURL,
    trimS3FilenameFromURL,
    getFilenameWithExtension,
    getFileId,
} from "./../../utils";
import {
    createImageUploadProps,
    deleteFile,
} from "./../../helpers/ImageUploadHelpers";

const {Dragger} = Upload;
const accept = ".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf,.csv";
const acceptArr = [
    "xlsx",
    "xls",
    "image",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "txt",
    "pdf",
    "csv",
    "srt",
    "vtt",
    "mcc",
    "scc"
];
const DocFilesComponent = (props) => {
    const setFilesList = (data) => {
        let temp = [];
        if (data?.length > 0) {
            data.map((item) =>
                temp.push({
                    uid: getFileId(trimS3FilenameFromURL(item)),
                    name: getFilenameWithExtension(trimS3FilenameFromURL(item)),
                    status: "done",
                    url: item,
                })
            );
        }
        return temp;
    };

    const [docFile, setDocFile] = useState("");
    const [docsURL, setDocsURL] = useState(props.data);
    const [docList, setDocList] = useState(setFilesList(props.data));
    const [docUploadLoading, setDocUploadLoading] = useState(false);
    const docUploadProps = createImageUploadProps(
        props.dir,
        setDocFile,
        docUploadLoading,
        setDocUploadLoading
    );

    useEffect(() => {
        if (docsURL.length === docList.length) {
            props.docFiles(docsURL);
        }
        // eslint-disable-next-line
    }, [docsURL]);

    useEffect(() => {
        if (docFile) setDocsURL([
            ...docsURL,
            docFile
        ]);
        // eslint-disable-next-line
    }, [docFile]);

    useEffect(() => {
        props.onLoadingChanges && props.onLoadingChanges(docUploadLoading);
        // eslint-disable-next-line
    }, [docUploadLoading]);

    const onRemove = (data) => {
        const filterURL = docsURL.filter((item) =>
            item.includes(`${data.uid}-${data.name}`)
        )[0];
        setDocList(docList.filter((item) => item.name !== data.name));
        deleteFile(trimS3FilePathFromURL(filterURL));
        setDocsURL(
            docsURL.filter((item) => !item.includes(`${data.uid}-${data.name}`))
        );
    };

    const validateFileType = (type) =>
        type
            ? type.split("/")[0] === "image"
            ? true
            : acceptArr.includes(type.split("/")[1])
            : message.error(
            "You cannot upload XX type of files. You can upload doc, docx, xls, xlsx, png, jpg, txt, csv files.",
            10
            );

    return (
        <div onDrop={(e) => validateFileType(e.dataTransfer?.items[0]?.type)}>
            <Form.Item label="Attach documents" style={{width: "26rem"}}>
                <Dragger
                    accept={accept}
                    multiple={true}
                    height={"11rem"}
                    {...docUploadProps}
                    fileList={docList}
                    onChange={(info) => setDocList(info.fileList)}
                    onRemove={(info) => onRemove(info)}
                    disabled={docUploadLoading}
                >
                    <InboxOutlined
                        style={{
                            fontSize: 48,
                            marginBottom: "0.6rem",
                            color: ThemeColors.colors.brand,
                        }}
                    />
                    <h2 style={{fontSize: "1rem"}}>
                        Click or drag file to this area to upload
                    </h2>
                    <p style={{fontSize: "0.9rem"}}>
                        Support for a single or bulk upload
                    </p>
                </Dragger>
            </Form.Item>
        </div>
    );
};

export default DocFilesComponent;
