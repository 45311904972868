import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, message, Spin, Form } from "antd";
import WidgetForm from "../../../components/WidgetForm";
import { getSession } from "../../../helpers/SessionManagement";
import { SaveOutlined } from "@ant-design/icons";
import { useDatabase } from "../../../hooks/database";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const HorizontalContainerSB = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const DEFAULT_COLOR = "#5229F4";
const FORM_FIELDS = {
  name: {
    key: "name",
    label: "Name",
    placeHolder: "Podcast widget name",
  },
  select: {
    key: "podcasts",
    label: "Podcasts",
    placeHolder: "Select podcasts to include",
  },
};

const CreatePodcastsList = (p) => {
  let podcasts = [];
  if (p) {
    p.forEach((p) => {
      podcasts.push(`${p?.podcast_id}`);
    });
  }

  return podcasts;
};

const PodcastWidget = (props) => {
  const { database, loading: databaseLoading } = useDatabase();
  const session = getSession();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [podcasts, setPodcasts] = useState([]);
  const [colors, setColors] = useState({
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });

  const id = props.match.params.id !== "new" ? props.match.params.id : "new";

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=podcasts");
  };

  const handleSubmit = async () => {
    const formFields = form.getFieldsValue();
    const variables = {
      name: formFields.name,
      podcasts: (formFields.podcasts || []).map((p) => ({
        podcast_id: p,
      })),
      primary_color: colors.primaryColor,
      secondary_color: colors.secondaryColor,
      organization_id: session?.organization?.id,
      user_id: session?.id,
    };

    if (id !== "new") {
      await database.items("podcast_widgets").updateOne(id, variables);
      message.success(`${variables.name} updated.`);
      goToListWidgets();
    } else {
      await database.items("podcast_widgets").createOne(variables);
      message.success(`${variables.name} created.`);
      goToListWidgets();
    }
  };

  const loadColors = useCallback(
    (primaryColor, secondaryColor) => {
      setColors({
        ...colors,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
      });
    },
    [colors]
  );

  const initForm = useCallback(
    (data = {}) => {
      form.setFieldsValue({
        name: data?.name || "",
        podcasts: CreatePodcastsList(data?.podcasts),
      });
    },
    [form]
  );

  useEffect(() => {
    async function init() {
      setLoading(true);
      if (id === "new") {
        initForm();
        loadColors(
          session.organization?.primaryColor || DEFAULT_COLOR,
          session.organization?.secondaryColor || DEFAULT_COLOR
        );

        const podcasts = await database.items("podcasts").readMany({
          filter: { organization_id: session?.organization?.id },
          sort: ["name"],
          fields: ["name", "id", "image"],
        });

        setPodcasts(podcasts?.data || []);
        setLoading(false);
        return;
      }

      const podcastWidget = await database
        .items("podcast_widgets")
        .readOne(id, {
          fields: ["*", "podcasts.*"],
        });

      const podcasts = await database.items("podcasts").readMany({
        filter: { organization_id: session?.organization?.id },
        sort: ["name"],
        fields: ["name", "id", "image"],
      });

      initForm(podcastWidget);
      loadColors(podcastWidget?.primary_color, podcastWidget?.secondary_color);

      setPodcasts(podcasts?.data || []);
      setLoading(false);
    }

    if (!databaseLoading) {
      init();
    }
    // eslint-disable-next-line
  }, [
    id,
    databaseLoading,
    database,
    initForm,
    session.organization?.primaryColor,
    session.organization?.secondaryColor,
  ]);

  return (
    <div style={{ padding: 24, background: "#ffffff" }}>
      <Spin spinning={loading} style={{ width: "100%", alignSelf: "center" }}>
        <WidgetForm
          form={form}
          colors={colors}
          setColors={setColors}
          isPrimaryColor={true}
          isSecondaryColor={true}
          listImageType={"image"}
          FORM_FIELDS={FORM_FIELDS}
          handleSubmit={handleSubmit}
          selectLoading={loading}
          selectOptions={podcasts}
        />

        <HorizontalContainerSB>
          <Button shape="round" size={"large"} onClick={goToListWidgets}>
            Cancel
          </Button>

          <Button
            type="primary"
            shape="round"
            size={"large"}
            onClick={form.submit}
            style={{ marginTop: 16 }}
            icon={<SaveOutlined />}
          >
            {id !== "new" ? "Update widget" : "Create widget"}
          </Button>
        </HorizontalContainerSB>
      </Spin>
    </div>
  );
};

export default PodcastWidget;
