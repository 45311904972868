import React, { useCallback, useRef } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import _ from "lodash";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { useDatabase } from "../../hooks/database";

const Table = ({
  columns,
  gridOptions,
  tableRef,
  fetchData,
  additionalFilters,
  rawFilters,
  rowData,
  allowNoFilters = false,
  preUpdate = null,
  batchLoadCount = 100,
  hideGroupBy = false,
  wrapperHeight = "100%",
}) => {
  const { database, loading: databaseLoading } = useDatabase();
  const firstDataRenderedRef = useRef(false);

  const handleSize = useCallback((event) => {
    event.columnApi.autoSizeAllColumns();
    const containerWidth = document.querySelectorAll(
      `.ag-theme-alpine .ag-body-viewport`
      // @ts-ignore
    )?.[0]?.offsetWidth;

    // @ts-ignore
    const totalColsWidth = document.querySelectorAll(
      `.ag-theme-alpine .ag-center-cols-container`
      // @ts-ignore
    )?.[0]?.offsetWidth;

    if (totalColsWidth <= containerWidth) {
      event.api.sizeColumnsToFit();
    } else {
      // event.api.sizeColumnsToFit();
      event.columnApi.autoSizeAllColumns();
    }
  }, []);

  const createServerSideDatasource = useCallback(
    (
      database,
      fetchData,
      allowNoFilters,
      additionalFilters = null,
      rawFilters = null,
      preUpdate = null
    ) => {
      return {
        getRows: (params) => {
          fetchData(
            database,
            params,
            additionalFilters,
            rawFilters,
            preUpdate,
            allowNoFilters
          );
        },
      };
    },
    []
  );

  const fetchServerData = useCallback(
    async (
      database,
      params,
      additionalFilters,
      rawFilters,
      preUpdate,
      allowNoFilters
    ) => {
      let sort;
      let filter = {};
      if (params?.request?.sortModel?.[0]) {
        sort = params.request.sortModel.map((s) => {
          if (s?.sort === "asc") {
            return s?.colId;
          } else {
            return `-${s?.colId}`;
          }
        });
      }

      console.log(params?.request?.filterModel);
      const parsedFilterModel = {};
      Object.entries(params?.request?.filterModel || {}).forEach(
        ([key, val]) => {
          let newKey = key.split(".");
          if (newKey.length > 1) {
            // newKey[newKey.length - 1] = 'id'
            // newKey = newKey.join('.')
            newKey = key;
          } else {
            newKey = key;
          }

          parsedFilterModel[newKey] = val;
        }
      );

      const combinedFilters = {
        ...parsedFilterModel,
        ...(additionalFilters?.current || {}),
      };

      Object.entries(combinedFilters).forEach(([filterKey, filterInfo]) => {
        let value;
        let operator;

        console.log(filterInfo);
        switch (filterInfo?.filterType) {
          case "greaterThan":
            operator = "_gt";
            value = filterInfo?.filter;
            break;

          case "greaterThanOrEqual":
            operator = "_gte";
            value = filterInfo?.filter;
            break;

          case "lessThan":
            operator = "_lt";
            value = filterInfo?.filter;
            break;

          case "lessThanOrEqual":
            operator = "_lte";
            value = filterInfo?.filter;
            break;

          case "equals":
            operator = "_eq";
            value = filterInfo?.filter;
            break;

          case "notEqual":
            operator = "_neq";
            value = filterInfo?.filter;
            break;

          case "date":
            switch (filterInfo?.type) {
              case "greaterThan":
                operator = "_gt";
                value = filterInfo?.dateFrom;
                break;

              case "greaterThanOrEqual":
                operator = "_gte";
                value = filterInfo?.dateFrom;
                break;

              case "lessThan":
                operator = "_lt";
                value = filterInfo?.dateFrom;
                break;

              case "lessThanOrEqual":
                operator = "_lte";
                value = filterInfo?.dateFrom;
                break;

              case "notEqual":
                operator = "_neq";
                value = filterInfo?.dateFrom;
                break;

              case "inRange":
                operator = "_between";
                value = [filterInfo?.dateFrom, filterInfo?.dateTo];
                break;

              default:
                operator = "_eq";
                value = filterInfo?.dateFrom;
                break;
            }

            break;

          case "number":
            if (filterInfo?.type === "inRange") {
              operator = "_between";
              value = [filterInfo?.filter, filterInfo?.filterTo];
            }

            break;

          case "set":
            operator = "_in";
            value = filterInfo?.values;
            break;

          default:
            operator = "_in";
            value = filterInfo?.values;
            break;
        }

        _.set(filter, filterKey, {
          [operator]: value,
        });
      });

      if (rawFilters?.current) {
        filter = {
          ...filter,
          ...rawFilters.current,
        };
      }

      const func = fetchData?.current ? fetchData?.current : fetchData;
      let res = await func({
        database,
        allowNoFilters,
        offset: params.request.startRow,
        limit: params.request.endRow - params.request.startRow,
        rawFilters: filter,
        sort,
      });

      if (preUpdate) {
        res = await preUpdate(res);
      }

      if (res?.setEntityResult) {
        res.setEntityResult({
          rows: res?.rows || [],
          meta: res?.meta,
        });
      }

      params.success({
        rowData: res?.rows,
        rowCount: res?.meta?.filter_count,
      });
    },
    // eslint-disable-next-line
    []
  );

  if (databaseLoading) {
    return <span>Loading...</span>;
  }

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: wrapperHeight,
        width: "100%",
      }}
    >
      <AgGridReact
        modules={AllModules}
        columnDefs={columns}
        getRowNodeId={(data) => data.id}
        gridOptions={{
          ...(rowData
            ? {
                rowData,
              }
            : {}),
          groupIncludeFooter: false,
          groupIncludeTotalFooter: true,
          groupSuppressBlankHeader: true,
          enableRangeSelection: true,
          valueCache: true,
          valueCacheNeverExpires: true,
          floatingFilter: false,
          animateRows: true,
          pagination: true,
          paginationAutoPageSize: true,
          cacheBlockSize: batchLoadCount,
          rowModelType: rowData || !fetchData ? "clientSide" : "serverSide",
          serverSideStoreType: "partial",
          groupMultiAutoColumn: true,
          suppressAggFuncInHeader: true,
          masterDetail: true,
          rowSelection: "multiple",
          suppressRowClickSelection: true,
          suppressColumnVirtualisation: true,
          groupSelectsChildren: true,
          groupSelectsFiltered: true,
          enableCharts: true,
          detailRowHeight: 600,
          rowHeight: 42,
          headerHeight: 42,
          floatingFiltersHeight: 42,
          rowGroupPanelShow: hideGroupBy ? "never" : "always",
          sideBar: {
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                },
              },
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
              },
            ],
          },
          // @ts-ignore
          defaultColGroupDef: {
            headerClass: (params) => {
              if (params?.colDef?.colId) {
                return `headerGroup${params?.columnGroup?.groupId}`;
              }
            },
          },
          defaultColDef: {
            enableRowGroup: false,
            enablePivot: false,
            sortable: true,
            resizable: true,
            filter: "agSetColumnFilter",
            filterParams: {
              excelMode: "mac",
              newRowsAction: "keep",
              buttons: ["clear"],
            },
            flex: 1,
            headerClass: (params) => {
              const colGroupValid =
                params?.column?.originalParent?.colGroupDef?.colId;

              const colGroupId = params?.column?.parent?.groupId;
              if (colGroupId && colGroupValid) {
                return `headerGroup${colGroupId}`;
              }
            },
            cellStyle: (params) => {
              const indent = 28;
              const isGroupCol = (params?.column?.colId || "").includes(
                "ag-Grid-AutoColumn"
              );

              if (params?.node?.level >= 1 && isGroupCol) {
                return {
                  paddingLeft: params?.node?.level * indent + "px",
                };
              }
            },
            cellClass: (params) => {
              const colDef = params.columnApi.getColumn(params?.colDef?.colId);

              // @ts-ignore
              const colGroupValid = colDef?.originalParent?.colGroupDef?.colId;

              // @ts-ignore
              const colGroupId = colDef?.parent?.groupId;
              if (colGroupId && colGroupValid) {
                return `headerGroup${colGroupId}`;
              }

              return [];
            },
          },
          autoGroupColumnDef: {
            resizable: true,
            flex: 1,
            minWidth: 200,
          },
          statusBar: {
            statusPanels: [
              {
                statusPanel: "agTotalRowCountComponent",
                align: "center",
              },
              { statusPanel: "agFilteredRowCountComponent" },
              { statusPanel: "agSelectedRowCountComponent" },
              { statusPanel: "agAggregationComponent" },
            ],
          },
          onToolPanelVisibleChanged(event) {
            handleSize(event);
          },
          onColumnVisible(event) {},
          onNewColumnsLoaded(event) {
            if (firstDataRenderedRef.current) {
              handleSize(event);
            }
          },
          onFirstDataRendered(event) {
            handleSize(event);
            firstDataRenderedRef.current = true;
          },
          onColumnGroupOpened(event) {
            handleSize(event);
          },
          onGridReady(event) {
            const datasource = createServerSideDatasource(
              database,
              fetchServerData,
              allowNoFilters,
              additionalFilters,
              rawFilters,
              preUpdate
            );

            event.api.setServerSideDatasource(datasource);
            if (tableRef) {
              tableRef.current = event;
            }
          },
          ...gridOptions,
        }}
      />
    </div>
  );
};

export default Table;
