import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  Layout,
  Form,
  Empty,
  Spin,
  Input,
  Button,
  Upload,
  Select,
  Checkbox,
  Alert,
  InputNumber,
  DatePicker,
  Radio,
  Switch,
  message,
  Divider,
  notification,
  Tooltip,
} from "antd";
import moment from "moment";
import { Queries, Mutations } from "./../../api";
import CustomFields from "../../components/CustomField";
import { InviteProducer } from "../../components/modals";
import TimeComponent from "../../components/TimeComponent";
import { FormTitle } from "./../../components/HeaderFooter";
import UploadMultiple from "./../../components/UploadMultiple";
import DocFilesComponent from "../../components/DocFileComponent";
import { ExternalLinksComponent } from "../../components/LinksComponent";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import {
  defaultStatus,
  FIELD_TYPE,
  MEDIA_TYPE,
  SEARCH_DELAY,
} from "../../utils/contants";
import {
  createImageUploadProps,
  deleteFile,
} from "./../../helpers/ImageUploadHelpers";
import {
  debounce,
  trimErrorMessage,
  initialExternalLink,
  trimS3FilenameFromURL,
  trimS3FilePathFromURL,
  checkForDeleteProducers,
} from "../../utils";
import {
  InboxOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useWorker } from "../../hooks/useWorker";
import DeleteConfirm from "../../components/DeleteConfirm/DeleteConfirm";
import { clearDirectusCache } from "../../helpers/ClearDirectusCache";

const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
const { Content, Footer } = Layout;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  .ant-alert-message {
    overflow: hidden;
  }
  .ant-divider-vertical {
    height: auto;
  }
  @media screen and (max-width: 1142px) {
    flex-direction: column;
  }
  .ant-select-selector {
    height: auto !important;
    align-items: center !important;
  }
  .ant-select-selection-item {
    line-height: 22px !important;
    white-space: normal !important;
    text-overflow: unset !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1142px) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  .upload {
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      width: 10rem;
      height: 10rem;
    }
  }
  @media screen and (max-width: 1142px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;
  .ant-divider,
  .ant-divider-vertical {
    height: auto;
  }
`;

const ButtonsContainer = styled.div`
  margin-bottom: 1rem;
`;

const NewEpisode = (props) => {
  const [form] = Form.useForm();
  const { sendWorkerMessage } = useWorker("websocket");
  const [includeTimeInDOP, setIncludeTimeInDOP] = useState(false);
  const [docList, setDocList] = useState([]);
  const [duration, setDuration] = useState({
    hours: "",
    minutes: "",
    seconds: "",
  });
  const [programs, setPrograms] = useState([]);
  const [producers, setProducers] = useState([]);
  const [thumbnail, setThumbnail] = useState("");
  const [executiveProducer, setExecutiveProducer] = useState(null);
  const [docLoading, setDocLoading] = useState(false);
  const [queryProgram, setQueryProgram] = useState("");
  const [queryProducer, setQueryProducer] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [episodeVideoList, setEpisodeVideoList] = useState();
  const [programChecked, setProgramChecked] = useState(false);
  const [episodeVideoFile, setEpisodeVideoFile] = useState("");
  const [pushToCableCast, setPushToCableCast] = useState(false);
  const [pushToYouTube, setPushToYouTube] = useState(false);
  const [inviteProducerModal, setInviteProducerModal] = useState(false);
  const [episodeUploadLoading, setEpisodeUploadLoading] = useState(false);
  const [externalLinks, setExternalLinks] = useState(initialExternalLink);
  const [
    getEpisodeData,
    {
      data: episodeDataResponse,
      loading: episodeDataLoading,
      error: episodeDataError,
      called: episodeDataCalled,
    },
  ] = useLazyQuery(Queries.EPISODE, {
    variables: { id: props.match.params.id },
    fetchPolicy: "no-cache",
  });

  const setFormattedFields = useCallback(
    (data = {}, topics = [], originOption = null) => {
      let customField = {};
      if (data?.customFieldValue?.length > 0) {
        data.customFieldValue.forEach((obj) => {
          customField[obj.id] = obj.value
            ? moment(JSON.parse(obj.value), moment.ISO_8601).isValid()
              ? moment(JSON.parse(obj.value), moment.ISO_8601)
              : JSON.parse(obj.value)
            : undefined;
        });
      }

      const foramttedData = {
        ...customField,
        title: data?.title || "",
        status:
          data?.status === "DRAFT"
            ? defaultStatus.inReview
            : data?.status || defaultStatus.inReview,
        videoUrl: data?.videoUrl || "",
        pushToCablecastOrTelvue: false,
        duration: changeToHHMMSS(data?.duration) || 0,
        published: data?.published || false,
        sponsored: data?.sponsored || false,
        documents: data?.documents || [],
        programId: data?.program?.id || "",
        programTitle: data?.program?.title || "",
        thumbnailUrl: data?.thumbnailUrl || "",
        externalLinks: data?.externalLinks || [],
        episodeNumber: data?.episodeNumber || "",
        topics: topics,
        telvueId: data?.telvueId,
        telvueConnectId: data?.telvueConnectId,
        streamUrl: data?.streamUrl,
        telvueFileName: data?.telvueFileName || "",
        notesToProducer: data?.notesToProducer || "",
        fullDescription: data?.fullDescription || "",
        executiveProducerId: data?.producer?.id || undefined,
        shortDescription: data?.shortDescription || "",
        customFieldValue: data?.customFieldValue || [],
        telvueProgramCode: data?.telvueProgramCode || "",
        assistantProducers: data?.assistantProducers?.map((i) => i.id) || [],
        episodeOriginOptionId: originOption || undefined,
        tags: data?.tags?.map((item) => item.name) || [],
        cablecastProgramPelantionMedia:
          data?.cablecastProgramPelantionMedia || "",
        telvueDeleteAt: moment(data?.telvueDeleteAt, moment.ISO_8601).isValid()
          ? moment(data?.telvueDeleteAt)
          : undefined,
        telvueIngestDateTime: moment(
          data?.telvueIngestDateTime,
          moment.ISO_8601
        ).isValid()
          ? moment(data?.telvueIngestDateTime)
          : undefined,
        dateOfProduction: moment(
          data?.dateOfProduction,
          moment.ISO_8601
        ).isValid()
          ? moment(data?.dateOfProduction)
          : undefined,
      };
      form.setFieldsValue(foramttedData);

      const programExists = programs.find((p) => p?.id === data?.program?.id);
      if (data?.program?.id && !programExists) {
        const newPrograms = [
          ...programs,
          {
            id: data.program.id,
            title: data.program.title,
          },
        ];

        setPrograms(newPrograms);
      }
    },
    // eslint-disable-next-line
    [form, episodeDataResponse]
  );

  const [
    sendInviteToProducer,
    { data: sendInvitationData, loading: sendInvitationLoading },
  ] = useMutation(Mutations.SEND_INVITE_FOR_STAFF_MEMBER, {
    errorPolicy: "all",
    onError: (e) => message.error(trimErrorMessage(e.message)),
  });

  const {
    data: episodeFieldsData,
    loading: episodeFieldsDataLoading,
    error: episodeFieldsDataError,
  } = useQuery(Queries.NEW_EPISODE_QUERIES, {
    onError: (err) => message.error(trimErrorMessage(err.message)),
  });

  const { loading: programsForDropdownLoading } = useQuery(
    Queries.PROGRAMS_FOR_DROPDOWN,
    {
      variables: {
        programQuery: queryProgram,
      },
      onCompleted: (data) => setPrograms(data?.programs?.nodes),
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const {
    data: producerForDropdownData,
    loading: producerForDropdownLoading,
  } = useQuery(Queries.PRODUCERS_FOR_DROPDOWN, {
    variables: {
      producerQuery: queryProducer,
    },
    onError: (err) => message.error(trimErrorMessage(err.message)),
  });

  const [
    createEpisode,
    { data: createEpisodeData, loading: createEpisodeLoading },
  ] = useMutation(Mutations.CREATE_EPISODE, {
    errorPolicy: "all",
    onError: (e) => message.error(trimErrorMessage(e.message)),
  });

  const [
    updateEpisode,
    { data: updateEpisodeData, loading: updateEpisodeLoading },
  ] = useMutation(Mutations.UPDATE_EPISODE, {
    errorPolicy: "all",
    onError: (e) => message.error(trimErrorMessage(e.message)),
  });

  const [
    deleteEpisodes,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useMutation(Mutations.DELETE_EPISODES);

  const videoUploadProps = createImageUploadProps(
    "vod/episode/video",
    setEpisodeVideoFile,
    episodeUploadLoading,
    setEpisodeUploadLoading
  );
  const getUpdatedTopics = () => {
    const updatedTopics = episodeFieldsData?.episodeSetting?.topics?.filter(
      (topic) =>
        episodeDataResponse?.episode?.topics?.some(
          (item) => topic.id === item.id
        )
    );
    return updatedTopics || [];
  };

  const getUpdatedOriginOption = () => {
    const updatedOptions = episodeFieldsData?.episodeSetting?.episodeOriginOptions?.filter(
      (origin) =>
        episodeDataResponse?.episode?.episodeOriginOption?.id === origin.id
    );
    return updatedOptions?.[0] || null;
  };

  const goToEpisodes = () => props.history.push("/episodes");

  useEffect(() => {
    if (props.match.params?.id && !episodeDataCalled) {
      getEpisodeData();
    } else {
      setFormattedFields();
    }
  }, [
    episodeDataCalled,
    getEpisodeData,
    props.match.params,
    setFormattedFields,
  ]);

  useEffect(() => {
    if (episodeDataResponse && producerForDropdownData) {
      const _producers = producerForDropdownData?.producers?.nodes;

      let missingProducers = checkForDeleteProducers(_producers, [
        episodeDataResponse?.episode?.producer,
      ]);

      let missingAssistantProducers = checkForDeleteProducers(
        _producers,
        episodeDataResponse?.episode?.assistantProducers
      );

      setProducers([
        ..._producers,
        ...missingProducers,
        ...missingAssistantProducers,
      ]);
    } else if (producerForDropdownData) {
      setProducers(producerForDropdownData?.producers?.nodes);
    }
    // eslint-disable-next-line
  }, [episodeDataResponse, producerForDropdownData]);

  useEffect(() => {
    if (episodeDataResponse) {
      if (episodeDataResponse?.episode?.producer?.id) {
        setExecutiveProducer(episodeDataResponse.episode.producer.id);
      }

      setThumbnail(episodeDataResponse.episode.thumbnailUrl);
      setDocList(episodeDataResponse.episode.documents.map((item) => item.url));
      if (episodeDataResponse.episode.videoUrl) {
        setEpisodeVideoFile(episodeDataResponse.episode.videoUrl);
        setEpisodeVideoList({
          id: 1,
          status: defaultStatus.done,
          name: episodeDataResponse.episode.videoUrl,
        });
      }
      setExternalLinks(
        episodeDataResponse?.episode?.externalLinks?.map((item, id) => ({
          id,
          url: item.url,
          title: item.title,
        }))
      );

      setProgramChecked(!!episodeDataResponse.episode.program?.id);
      setDuration(changeToHHMMSS(episodeDataResponse.episode.duration));
      const _topics = getUpdatedTopics();
      const _originOption = getUpdatedOriginOption();
      setFormattedFields(
        episodeDataResponse.episode,
        _topics?.map((topic) => topic.id),
        _originOption?.id
      );
    }
    if (episodeDataError) {
      message.error(
        `Error getting Data of Episode with id: ${props.match.params.id}. ${episodeDataError}`,
        () => props.history.push("/episodes")
      );
    }
    // eslint-disable-next-line
  }, [episodeDataResponse, episodeDataError]);

  useEffect(() => {
    if (sendInvitationData) {
      message.success(
        `Invitation sent successfully to ${sendInvitationData.sendInviteForStaffMember.firstname} ${sendInvitationData.sendInviteForStaffMember.lastname} for role of Assistant Producer.`
      );
    }
  }, [sendInvitationData]);

  useEffect(() => {
    if (sendInvitationLoading) {
      message.loading("Sending Invite.", 1);
    }
  }, [sendInvitationLoading]);

  useEffect(() => {
    if (episodeFieldsDataError) {
      message.error(
        `Error getting episode settings. ${episodeFieldsDataError}`
      );
    }
  }, [episodeFieldsDataError]);

  useEffect(() => {
    if (createEpisodeData) {
      message.success(`Episode created successfully`);
      goToEpisodes();
    }
    // eslint-disable-next-line
  }, [createEpisodeData]);

  useEffect(() => {
    if (updateEpisodeData) {
      message.success(`Episode updated successfully`);
      goToEpisodes();
    }
    // eslint-disable-next-line
  }, [updateEpisodeData]);

  useEffect(() => {
    if (episodeVideoFile) {
      setEpisodeVideoList({
        id: 1,
        status: defaultStatus.done,
        name: trimS3FilenameFromURL(episodeVideoFile),
      });
    }
    // eslint-disable-next-line
  }, [episodeVideoFile]);

  useEffect(() => {
    if (episodeUploadLoading) {
      setEpisodeVideoList({
        id: 1,
        status: defaultStatus.uploading,
        name: "Uploading file...",
      });
    }
  }, [episodeUploadLoading]);

  const onProducerSearch = (str) => setQueryProducer(str);

  const debounceOnProducerQuery = useCallback(
    debounce(onProducerSearch, SEARCH_DELAY),
    []
  );

  const onProgramSearch = (str) => setQueryProgram(str);

  const debounceOnProgramQuery = useCallback(
    debounce(onProgramSearch, SEARCH_DELAY),
    []
  );

  const handleSendInvite = (firstName, lastName, email) => {
    sendInviteToProducer({
      variables: {
        firstname: firstName,
        lastname: lastName,
        email,
        redirectUrl: window.location.origin,
        userType: "producer",
      },
    });
    setInviteProducerModal(false);
  };

  const handleFormChange = async (_, { changedFields }) => {
    if (changedFields?.[0]?.name?.[0] === "executiveProducerId") {
      if (!changedFields?.[0]?.value) {
        setPushToYouTube(false);
      }

      setExecutiveProducer(changedFields?.[0]?.value);
    }
  };

  const handleSubmitClick = async () => {
    const episodeData = form.getFieldsValue();
    if (
      episodeData[FIELD_TYPE.ASSISTANT_PRODUCERS].includes(
        episodeData[FIELD_TYPE.EXECUTIVE_PRODUCER]
      )
    ) {
      message.error("Exective producer and Assistant producer can't be same.");
      return;
    }

    let customFieldValue = [];
    episodeFieldsData.episodeSetting.episodeCustomFields.forEach(
      (customField) => {
        customFieldValue.push({
          id: customField.id,
          value:
            episodeData[customField.id] === undefined
              ? ""
              : JSON.stringify(episodeData[customField.id]),
        });
      }
    );
    const h = (duration?.hours || 0) * 3600;
    const m = (duration?.minutes || 0) * 60;
    const s = duration?.seconds || 0;
    const d = parseInt(h + m + s);

    let programVal = episodeData[FIELD_TYPE.PROGRAM_ID];
    let programTitle = episodeData[FIELD_TYPE.PROGRAM_TITLE];
    if (!programChecked) {
      programVal = null;
      programTitle = null;
    }

    const variables = {
      customFieldValue,
      title: episodeData[FIELD_TYPE.TITLE],
      status: episodeData[FIELD_TYPE.STATUS],
      videoUrl: episodeVideoFile,
      pushToCablecastOrTelvue: pushToCableCast,
      pushToYoutube: pushToYouTube,
      duration: d,
      published: episodeData[FIELD_TYPE.PUBLISHED],
      sponsored: episodeData[FIELD_TYPE.SPONSORED],
      documents: docList,
      streamUrl: episodeData[FIELD_TYPE.STREAM_URL],
      programId: programVal,
      programTitle: programTitle,
      thumbnailUrl: thumbnail,
      externalLinks: externalLinks.filter((item) => item.url && delete item.id),
      episodeNumber:
        episodeData[FIELD_TYPE.EPISODE_NUMBER]?.toString() ||
        episodeData[FIELD_TYPE.EPISODE_NUMBER],
      topics: episodeData[FIELD_TYPE.TOPICS],
      telvueFileName: episodeData[FIELD_TYPE.TELVUE_FILENAME],
      notesToProducer: episodeData[FIELD_TYPE.NOTES_TO_PRODUCER],
      fullDescription: episodeData[FIELD_TYPE.FULL_DESCRIPTION],
      executiveProducerId: episodeData[FIELD_TYPE.EXECUTIVE_PRODUCER],
      shortDescription: episodeData[FIELD_TYPE.SHORT_DESCRIPTION],
      telvueProgramCode: episodeData[FIELD_TYPE.TELVUE_PROGRAM_CODE],
      assistantProducers: episodeData[FIELD_TYPE.ASSISTANT_PRODUCERS],
      episodeOriginOptionId: episodeData[FIELD_TYPE.EPISODE_ORIGIN_OPTION],
      tags: episodeData[FIELD_TYPE.TAGS],
      cablecastProgramPelantionMedia: episodeData[FIELD_TYPE.CABLE_CAST_MEDIA],
      telvueDeleteAt: episodeData.telvueDeleteAt
        ? episodeData.telvueDeleteAt.toISOString()
        : null,
      telvueIngestDateTime: episodeData.telvueIngestDateTime
        ? episodeData.telvueIngestDateTime.toISOString()
        : null,
      dateOfProduction: episodeData.dateOfProduction
        ? episodeData.dateOfProduction.toISOString()
        : null,
    };

    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();

    let epId = props?.match?.params?.id;
    if (props.match.params?.id) {
      await updateEpisode({
        variables: {
          id: props.match.params.id,
          ...variables,
        },
      });
    } else {
      const createResponse = await createEpisode({
        variables,
      });

      epId = createResponse?.data?.createEpisode?.id;
    }

    if (
      pushToYouTube &&
      episodeVideoFile &&
      episodeData[FIELD_TYPE.EXECUTIVE_PRODUCER]
    ) {
      notification.info({
        placement: "bottomRight",
        message:
          "We are uploading your episode to YouTube, you'll receive a notification once it's done",
      });

      sendWorkerMessage({
        type: "websocket",
        subject: "youtube:upload",
        message: {
          debug: !!process?.env?.REACT_APP_DEBUG_YOUTUBE,
          episodeId: epId,
        },
      });
    }
  };

  const handleCancelVideoClick = () => {
    deleteFile(
      `vod/episode/video/${trimS3FilePathFromURL(episodeVideoList?.name)}`
    );
    setEpisodeVideoList({});
    setEpisodeVideoFile("");
  };

  const handleDelete = (episodeId) => {
    deleteEpisodes({
      variables: {
        ids: [episodeId],
      },
    }).catch((error) => message.error(error.message));
    props.history.goBack();
  };

  const changeToHHMMSS = (value) => {
    let totalSeconds = value;
    if (totalSeconds) {
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;
      return {
        hours,
        minutes,
        seconds,
      };
    }
  };

  const FormFooter = () => {
    return (
      <Footer
        style={{
          padding: 0,
          height: "30%",
          display: "flex",
          flexDirection: "column",
          background: "white",
        }}
      >
        <ButtonsContainer>
          <Button
            style={{
              height: "34px",
              float: "left",
              marginRight: "16px",
            }}
            onClick={() => props.history.goBack()}
          >
            Cancel
          </Button>
          {episodeDataResponse?.episode?.id && (
            <DeleteConfirm
              name={episodeDataResponse?.episode?.title}
              action={() => handleDelete(episodeDataResponse?.episode?.id)}
            />
          )}
          <div
            style={{
              marginBottom: 38,
              height: "34px",
              float: "right",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Form.Item
              label={
                episodeFieldsData?.episodeSetting?.masterControlIntegration ===
                MEDIA_TYPE.Cabelcast
                  ? "Push to Cablecast"
                  : "Push to Telvue"
              }
              style={{ marginRight: "1rem" }}
            >
              <Switch
                checked={pushToCableCast}
                onChange={() => setPushToCableCast(!pushToCableCast)}
              />
            </Form.Item>

            <Form.Item label="Push to YouTube" style={{ marginRight: "1rem" }}>
              <Tooltip
                title={
                  !executiveProducer ||
                  !episodeVideoFile ||
                  episodeVideoFile === ""
                    ? "You must have a valid producer and have uploaded a video file to push to YouTube!"
                    : null
                }
              >
                <Switch
                  checked={pushToYouTube}
                  disabled={
                    !executiveProducer ||
                    !episodeVideoFile ||
                    episodeVideoFile === ""
                  }
                  onChange={() => setPushToYouTube(!pushToYouTube)}
                />
              </Tooltip>
            </Form.Item>

            <Button
              type={"primary"}
              onClick={() => form.submit()}
              disabled={episodeUploadLoading || imageLoading || docLoading}
            >
              Save episode
            </Button>
          </div>
        </ButtonsContainer>
      </Footer>
    );
  };

  const ProducerFormOne = () => (
    <>
      <FormTitle title={"Producer fields"} size={"24px"} />
      <FormTitle title={"Episode details"} size={"16px"} />

      <HorizontalContainer>
        <LeftContainer>
          <Form.Item
            name={FIELD_TYPE.TITLE}
            label="Episode title"
            style={{ marginBottom: 38, width: "60%" }}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input type={"text"} placeholder="Episode title" />
          </Form.Item>

          <Form.Item>
            <Checkbox
              style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                color: "#343434",
                fontWeight: 200,
              }}
              onChange={(e) => {
                setProgramChecked(e.target.checked);
              }}
              checked={programChecked}
            >
              This episode is part of an ongoing program
            </Checkbox>
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "16px",
              width: "70%",
            }}
          >
            <HorizontalContainer>
              <Divider
                orientation="left"
                type="vertical"
                style={{
                  background: "#8C8C8C",
                  marginRight: "1.4rem",
                  marginLeft: "0.1rem",
                }}
              />
              <div style={{ width: "60%", marginTop: 8, marginBottom: 8 }}>
                <Form.Item
                  label="Program"
                  style={{ marginBottom: 0 }}
                  initialValue={FIELD_TYPE.PROGRAM_TITLE}
                  name={FIELD_TYPE.PROGRAM_ID}
                >
                  <Select
                    showSearch
                    allowClear
                    showArrow={true}
                    filterOption={true}
                    disabled={!programChecked}
                    optionFilterProp={"filter"}
                    placeholder="Search program"
                    defaultActiveFirstOption={false}
                    loading={programsForDropdownLoading}
                    onClear={() => debounceOnProgramQuery("")}
                    notFoundContent={
                      programs?.length === 0 && (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="No program found"
                        />
                      )
                    }
                    onSearch={(query) => {
                      if (query.trim() === "" && query.length === 0) return;
                      debounceOnProgramQuery(query);
                    }}
                  >
                    {programs?.map((item) => (
                      <Option key={item.id} filter={item.title}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button
                  type="link"
                  style={{
                    float: "left",
                    fontSize: "12px",
                    color: "#1890FF",
                  }}
                  icon={<PlusCircleOutlined />}
                  size={"small"}
                  onClick={() =>
                    window.open(
                      window.location.origin + "/programs/new",
                      "_blank"
                    )
                  }
                >
                  Create a program
                </Button>
              </div>
            </HorizontalContainer>
          </div>

          <Form.Item name={FIELD_TYPE.SPONSORED} valuePropName="checked">
            <Checkbox
              style={{
                fontWeight: 200,
                fontSize: "14px",
                alignSelf: "flex-start",
                color: "#343434",
              }}
            >
              This is a sponsored episode?
            </Checkbox>
          </Form.Item>

          <Form.Item
            name={FIELD_TYPE.EPISODE_NUMBER}
            label="Episode number"
            style={{ marginBottom: 8, width: "60%" }}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              placeholder={"Your episode number"}
            />
          </Form.Item>
        </LeftContainer>

        <RightContainer>
          <Form.Item
            name={FIELD_TYPE.EXECUTIVE_PRODUCER}
            label="Executive Producer"
            style={{ marginBottom: 38, width: "70%" }}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select
              showSearch
              allowClear
              showArrow={true}
              filterOption={true}
              optionFilterProp={"filter"}
              placeholder="Search producers"
              defaultActiveFirstOption={false}
              loading={producerForDropdownLoading}
              onSearch={(query) => {
                if (query.trim() === "" && query.length === 0) return;
                debounceOnProducerQuery(query);
              }}
            >
              {producers?.map((item) => (
                <Option key={item.id} filter={item.name}>
                  {item.name && item.email
                    ? `${item.name} (${item?.email})`
                    : `${item.name}`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div
            style={{
              display: "flex",
              marginBottom: 80,
              flexDirection: "column",
              alignSelf: "flex-start",
              width: "100%",
            }}
          >
            <Form.Item
              label="Assistant Producer"
              name={FIELD_TYPE.ASSISTANT_PRODUCERS}
              style={{ width: "70%", marginBottom: 0 }}
            >
              <Select
                mode="multiple"
                optionFilterProp={"filter"}
                placeholder="Search producers"
                onSearch={(query) => {
                  if (query.trim() === "" && query.length === 0) return;
                  debounceOnProducerQuery(query);
                }}
              >
                {producers?.map((item) => (
                  <Option key={item.id} filter={item.name}>
                    {item.name && item.email
                      ? `${item.name} (${item?.email})`
                      : `${item.name}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Button
              type="link"
              style={{
                float: "left",
                fontSize: "12px",
                color: "#1890FF",
                marginTop: "0.4rem",
                alignSelf: "flex-start",
              }}
              icon={<PlusCircleOutlined />}
              size={"small"}
              onClick={() => setInviteProducerModal(true)}
            >
              Invite Producers
            </Button>
            <InviteProducer
              visible={inviteProducerModal}
              onCancel={() => setInviteProducerModal(false)}
              onOk={(firstName, lastName, email) =>
                handleSendInvite(firstName, lastName, email)
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Form.Item
              name={FIELD_TYPE.DATE_OF_PRODUCTION}
              label="Date of production"
              style={{ width: "60%" }}
              rules={[{ required: true, message: "This field is required." }]}
            >
              <DatePicker
                style={{ height: 40, display: "flex" }}
                showTime={includeTimeInDOP}
              />
            </Form.Item>
            <Form.Item
              label="Include time"
              labelAlign="right"
              style={{ marginLeft: 10 }}
            >
              <Switch
                size={"small"}
                onClick={(value) => {
                  setIncludeTimeInDOP(value);
                }}
              />
            </Form.Item>
          </div>
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  const ProducerCustomFields = () => (
    <LeftContainer>
      {episodeFieldsData?.episodeSetting?.episodeCustomFields?.filter(
        (customField) => !customField.staff
      )?.length > 0 && (
        <CustomFields
          form={form}
          key={episodeFieldsData}
          customFields={episodeFieldsData.episodeSetting?.episodeCustomFields.filter(
            (customField) => !customField.staff
          )}
        />
      )}
    </LeftContainer>
  );

  const topics = [...(episodeFieldsData?.episodeSetting?.topics || [])];
  topics.sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }

    if (a.title > b.title) {
      return 1;
    }

    return 0;
  });

  const ProducerFormTwo = () => (
    <>
      <FormTitle title={"Episode information"} size={"16px"} />

      <HorizontalContainer>
        <LeftContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "1rem",
            }}
          >
            <Form.Item
              label="Short description"
              name={FIELD_TYPE.SHORT_DESCRIPTION}
              style={{ marginBottom: "0.2rem", width: "80%" }}
              rules={[
                { required: true, message: "This field is required." },
                () => ({
                  validator(rule, value) {
                    if (value) {
                      if (value.length < 251) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Characters limit exceed!");
                    } else {
                      return Promise.reject();
                    }
                  },
                }),
              ]}
            >
              <TextArea
                autoSize={{ minRows: 5, maxRows: 6 }}
                placeholder="Short description of your episode"
              />
            </Form.Item>
            <span
              style={{
                float: "left",
                fontSize: "14px",
                alignSelf: "flex-start",
                color: "#8C8C8C",
              }}
            >
              250 character limit
            </span>
          </div>
          <Form.Item
            label="Full description"
            name={FIELD_TYPE.FULL_DESCRIPTION}
            style={{ width: "80%" }}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <TextArea
              autoSize={{ minRows: 7, maxRows: 10 }}
              placeholder="Full description of your episode"
            />
          </Form.Item>
          <Form.Item
            label="Main Topics"
            name={FIELD_TYPE.TOPICS}
            style={{ width: "60%" }}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Select
              mode="multiple"
              placeholder="Add topics"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {topics.map((item) => (
                <Option key={item.id}>{item.title}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Tags"
            name={FIELD_TYPE.TAGS}
            style={{ width: "60%" }}
          >
            <Select tokenSeparators={[","]} mode="tags" placeholder="Add tags">
              {episodeDataResponse?.episode?.tags.map((item) => {
                return <Option key={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
        </LeftContainer>
        <RightContainer>
          <div style={{ width: "70%" }}>
            <ExternalLinksComponent
              title={"External links"}
              data={externalLinks}
              links={(list) => setExternalLinks(list)}
            />
          </div>
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  const ProducerFormThree = () => (
    <>
      <FormTitle title={" Episode media"} size={"16px"} />
      <HorizontalContainer>
        <LeftContainer>
          <Form.Item
            label="Episode video file upload"
            style={{ width: "26rem" }}
          >
            {episodeVideoList?.id && (
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  flexDirection: "column",
                }}
              >
                <Alert
                  showIcon
                  style={{
                    fontSize: 14,
                    display: "flex",
                    marginBottom: 6,
                    alignItems: "center",
                    background: props.background,
                    borderColor: props.background,
                  }}
                  message={
                    episodeUploadLoading
                      ? "Uploading video file..."
                      : trimS3FilenameFromURL(episodeVideoList?.name)
                  }
                  icon={
                    episodeUploadLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <PaperClipOutlined style={{ color: "#343434" }} />
                    )
                  }
                  closable={episodeUploadLoading ? false : true}
                  onClose={() => handleCancelVideoClick()}
                />

                {episodeVideoFile && (
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    style={{ width: 128 }}
                    onClick={() => {
                      window.open(episodeVideoFile, "_blank");
                    }}
                  >
                    Download
                  </Button>
                )}
              </div>
            )}
            <Dragger
              accept="video/*"
              multiple={false}
              height={"11rem"}
              {...videoUploadProps}
              showUploadList={false}
              style={{
                display:
                  episodeVideoList?.id || episodeUploadLoading
                    ? "none"
                    : "block",
              }}
            >
              <InboxOutlined
                style={{
                  fontSize: 48,
                  marginBottom: "0.6rem",
                  color: "#1890FF",
                }}
              />
              <h2 style={{ fontSize: "1rem" }}>
                Click or drag file to this area to upload
              </h2>
            </Dragger>
          </Form.Item>
          <Form.Item
            name={FIELD_TYPE.STREAM_URL}
            label="Episode stream url"
            style={{ marginBottom: 38, width: "26rem" }}
          >
            <Input placeholder={"Stream URL"} />
          </Form.Item>
          <DocFilesComponent
            key={docList}
            data={docList}
            dir={"vod/episode/doc"}
            onLoadingChanges={setDocLoading}
            docFiles={(list) => setDocList(list)}
          />
        </LeftContainer>

        <RightContainer>
          <Form.Item label="Episode thumbnail">
            <UploadMultiple
              dir={"vod/episode/thumbnail"}
              images={thumbnail}
              allowImages={1}
              squareCrop={false}
              onLoadingChanges={setImageLoading}
              setImages={(img) => setThumbnail(img.toString())}
            />
          </Form.Item>
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  const StaffCustomFieldContainer = () => (
    <LeftContainer>
      {episodeFieldsData?.episodeSetting?.episodeCustomFields?.filter(
        (customField) => customField.staff
      )?.length > 0 && (
        <CustomFields
          form={form}
          key={episodeFieldsData}
          customFields={episodeFieldsData.episodeSetting.episodeCustomFields.filter(
            (customField) => customField.staff
          )}
        />
      )}
    </LeftContainer>
  );

  const StaffContainer = () => (
    <>
      <FormTitle title={"Staff fields"} size={"24px"} />
      <HorizontalContainer>
        <LeftContainer>
          <Form.Item
            label="Episode origin"
            name={FIELD_TYPE.EPISODE_ORIGIN_OPTION}
            style={{ paddingLeft: 2, width: "50%" }}
          >
            <Select placeholder="Select origin option">
              {episodeFieldsData?.episodeSetting?.episodeOriginOptions.map(
                (option) => (
                  <Option key={option.id}>{option.title}</Option>
                )
              )}
            </Select>
          </Form.Item>
        </LeftContainer>
        <RightContainer>
          <Form.Item
            label="Episode Duration"
            name={FIELD_TYPE.DURATION}
            style={{ paddingLeft: 2, width: "50%" }}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
              () => ({
                validator(rule, value) {
                  if (duration) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Duration Cannot be zero!");
                  }
                },
              }),
            ]}
          >
            <div>
              <TimeComponent
                duration={duration}
                setDuration={(value) => setDuration(value)}
              />
            </div>
          </Form.Item>
        </RightContainer>
      </HorizontalContainer>
      {episodeFieldsData?.episodeSetting?.masterControlIntegration ===
      MEDIA_TYPE.Cabelcast
        ? CableCastForm()
        : TelvueForm()}
    </>
  );

  const TelvueForm = () => {
    return (
      <div>
        <FormTitle title={"Telvue fields"} size={"24px"} />
        <HorizontalContainer>
          <LeftContainer>
            <Container>
              <Divider
                orientation="left"
                type="vertical"
                style={{
                  background: "#8C8C8C",
                  marginRight: "1.4rem",
                  marginLeft: "0.1rem",
                }}
              />
              <div style={{ width: "60%" }}>
                <Form.Item
                  label="Expected filename"
                  name={FIELD_TYPE.TELVUE_FILENAME}
                  rules={[
                    {
                      required: pushToCableCast,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <Input placeholder="Your expected filename" />
                </Form.Item>
                <Form.Item
                  label="Delete episode at"
                  name={FIELD_TYPE.TELVUE_DELETE_AT}
                >
                  <DatePicker showTime style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  label="Program code"
                  name={FIELD_TYPE.TELVUE_PROGRAM_CODE}
                >
                  <Input placeholder="Program code" />
                </Form.Item>
              </div>
            </Container>
          </LeftContainer>
          <RightContainer>
            <div>
              <Form.Item
                label="Episode ingest date and time"
                name={FIELD_TYPE.TELVUE_INGEST_DATE_TIME}
                style={{ paddingLeft: 2 }}
                rules={[
                  {
                    required: pushToCableCast,
                    message: "This field is required.",
                  },
                ]}
              >
                <DatePicker showTime style={{ width: "60%" }} />
              </Form.Item>
              <Form.Item
                label="Telvue ID"
                name={FIELD_TYPE.TELVUE_ID}
                style={{ paddingLeft: 2, width: "60%" }}
              >
                <Input
                  disabled
                  value={episodeDataResponse?.episode?.telvueId}
                />
              </Form.Item>
              <Form.Item
                label="Telvue connect ID"
                name={FIELD_TYPE.TELVUE_CONNECT_ID}
                style={{ paddingLeft: 2, width: "60%" }}
              >
                <Input
                  disabled
                  value={episodeDataResponse?.episode?.telvueConnectId}
                />
              </Form.Item>
            </div>
          </RightContainer>
        </HorizontalContainer>
      </div>
    );
  };

  const CableCastForm = () => {
    return (
      <div>
        <FormTitle title={"Cablecast fields"} size={"24px"} />
        <HorizontalContainer>
          <LeftContainer>
            <Container>
              <Divider
                orientation="left"
                type="vertical"
                style={{
                  background: "#8C8C8C",
                  marginRight: "1.4rem",
                  marginLeft: "0.1rem",
                }}
              />
              <Form.Item
                label="Program relation media URL"
                name={FIELD_TYPE.CABLE_CAST_MEDIA}
                style={{ paddingLeft: 2, width: "60%" }}
              >
                <Input placeholder="Media url" />
              </Form.Item>
            </Container>
          </LeftContainer>
          <RightContainer>
            <Form.Item
              label="Cablecast ID"
              style={{ paddingLeft: 2, width: "60%" }}
            >
              <Input
                disabled
                value={episodeDataResponse?.episode?.cablecastId}
              />
            </Form.Item>
          </RightContainer>
        </HorizontalContainer>
      </div>
    );
  };

  const ModerationForm = () => (
    <>
      <FormTitle title={"Moderation states"} size={"24px"} />

      <HorizontalContainer>
        <LeftContainer>
          <Form.Item
            label="Status"
            name={FIELD_TYPE.STATUS}
            style={{ paddingLeft: 2 }}
          >
            <Radio.Group>
              <Radio value={defaultStatus.declined}>Declined</Radio>
              <Radio value={defaultStatus.inReview}>In review</Radio>
              <Radio value={defaultStatus.accept}>Accepted</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Note to producer"
            name={FIELD_TYPE.NOTES_TO_PRODUCER}
            style={{ marginBottom: 38, width: "80%" }}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 8 }}
              placeholder="Optional note to producer"
            />
          </Form.Item>
        </LeftContainer>
        <RightContainer>
          <Form.Item
            label="Published"
            name={FIELD_TYPE.PUBLISHED}
            style={{ marginBottom: 38, width: "50%" }}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </RightContainer>
      </HorizontalContainer>
    </>
  );

  return (
    <Layout style={{ padding: 44, background: "white" }}>
      <Spin
        size="large"
        spinning={
          episodeDataLoading ||
          episodeFieldsDataLoading ||
          createEpisodeLoading ||
          updateEpisodeLoading
        }
      >
        <Content style={{ overflow: "auto", marginBottom: "1.5rem" }}>
          <Form.Provider
            onFormFinish={() => handleSubmitClick()}
            onFormChange={handleFormChange}
          >
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {StaffContainer()}
            </Form>
            {StaffCustomFieldContainer()}
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {ProducerFormOne()}
            </Form>
            {ProducerCustomFields()}
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {ProducerFormTwo()}
            </Form>
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {ProducerFormThree()}
            </Form>
            <Form layout={"vertical"} form={form} scrollToFirstError>
              {ModerationForm()}
            </Form>
          </Form.Provider>
        </Content>
        {FormFooter()}
      </Spin>
    </Layout>
  );
};

export default NewEpisode;
