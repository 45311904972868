import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Queries, Mutations } from "../../../api";
import WidgetForm from "../../../components/WidgetForm";
import { Button, message, Switch, Spin, Form } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { getSession } from "../../../helpers/SessionManagement";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const HorizontalContainerSB = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  h3 {
    margin: 0px 0px 0px 12px;
  }
`;

const DEFAULT_COLOR = "#5229F4";
const FORM_FIELDS = {
  name: {
    key: "name",
    label: "Name",
    placeHolder: "Schedule widget name",
  },
  select: {
    key: "liveVideos",
    label: "Schedules",
    placeHolder: "Select live videos to include",
  },
};

const CreateLiveVideoList = (p = []) => {
  let videos = [];
  p.forEach((p) => {
    videos.push(p.id);
  });
  return videos;
};

const ScheduleWidget = (props) => {
  const id = props.match.params.id !== "new" ? props.match.params.id : "new";
  const [form] = Form.useForm();
  const [colors, setColors] = useState({
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });
  const [search, setSearch] = useState(true);

  const [createScheduleWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_SCHEDULE_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createScheduleWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updateScheduleWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_SCHEDULE_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updateScheduleWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const { loading: scheduleWidgetLoading, data: scheduleWidgetData } = useQuery(
    Queries.SCHEDULE_WIDGET_BY_ID,
    {
      skip: id === "new",
      variables: { id: id },
    }
  );

  const { loading: liveVideosLoading, data: liveVideosData } = useQuery(
    Queries.ALL_LIVE_VIDEOS
  );

  useEffect(() => {
    if (id === "new") {
      initForm();
      loadColors(
        getSession().organization?.primaryColor || DEFAULT_COLOR,
        getSession().organization?.secondaryColor || DEFAULT_COLOR
      );
    }

    if (id !== "new" && scheduleWidgetData) {
      const scheduleWidget = scheduleWidgetData.scheduleWidgetById;
      initForm(scheduleWidget);
      setSearch(scheduleWidget.search);
      loadColors(scheduleWidget.primaryColor, scheduleWidget.secondaryColor);
    }
    // eslint-disable-next-line
  }, [scheduleWidgetData]);

  const initForm = (data = {}) => {
    form.setFieldsValue({
      name: data?.name || "",
      liveVideos: CreateLiveVideoList(data?.liveVideos) || [],
    });
  };

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=schedule");
  };

  const loadColors = (primaryColor, secondaryColor) => {
    setColors({
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
    });
  };

  const handleSubmit = () => {
    const formFields = form.getFieldsValue();
    const variables = {
      name: formFields.name,
      search: search,
      primaryColor: colors.primaryColor,
      secondaryColor: colors.secondaryColor,
      liveVideos: formFields?.liveVideos,
    };
    if (id !== "new") {
      updateScheduleWidget({ variables: { id: id, ...variables } });
    } else {
      createScheduleWidget({ variables });
    }
  };

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Spin
        spinning={
          (id !== "new" && scheduleWidgetLoading) ||
          createLoading ||
          updateLoading
        }
      >
        <WidgetForm
          form={form}
          colors={colors}
          setColors={setColors}
          isPrimaryColor={true}
          isSecondaryColor={true}
          listImageType={"image"}
          FORM_FIELDS={FORM_FIELDS}
          handleSubmit={handleSubmit}
          selectLoading={liveVideosLoading}
          selectOptions={liveVideosData?.liveVideos || []}
        />

        <SwitchWrapper>
          <Switch
            checked={search}
            onChange={(value) => {
              setSearch(value);
            }}
          />
          <h3>{"Enable Search"}</h3>
        </SwitchWrapper>

        <HorizontalContainerSB>
          <Button shape="round" size={"large"} onClick={goToListWidgets}>
            Cancel
          </Button>

          <Button
            type="primary"
            shape="round"
            size={"large"}
            onClick={form.submit}
            style={{ marginTop: 16 }}
            icon={false ? <LoadingOutlined /> : <SaveOutlined />}
          >
            {id !== "new" ? "Update widget" : "Create widget"}
          </Button>
        </HorizontalContainerSB>
      </Spin>
    </div>
  );
};

export default ScheduleWidget;
