import React from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import { getFormatedDate } from "../../../utils";
import { defaultFieldKeys } from "../../../utils/contants";

export const defaultGroupColumns = () =>
  new Map([
    [
      defaultFieldKeys.image,
      {
        title: "Image",
        dataIndex: "image",
        render: (image) => <Avatar src={image} />,
      },
    ],
    [
      defaultFieldKeys.name,
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/users/groups/${record.id}`}>{text}</Link>
        ),
      },
    ],
    [
      defaultFieldKeys.members,
      {
        title: "Members",
        dataIndex: "members",
        render: (list) =>
          list && (
            <span
              style={{
                maxWidth: "15rem",
                overflow: "auto",
                display: "block",
                whiteSpace: "nowrap",
              }}
            >
              {list.map((m, i) => (
                <span key={i}>
                  {m.name}
                  {i === list.length - 1 ? "" : ", "}
                </span>
              ))}
            </span>
          ),
      },
    ],
    [
      defaultFieldKeys.description,
      {
        title: "Description",
        dataIndex: "description",
        render: (text) => (
          <span
            style={{
              maxWidth: "10rem",
              overflow: "auto",
              display: "block",
              whiteSpace: "nowrap",
            }}
          >
            {text}
          </span>
        ),
      },
    ],
    [
      defaultFieldKeys.lastUpdated,
      {
        key: "lastUpdated",
        title: "Last updated",
        dataIndex: "updatedAt",
        sorter: (a, b) =>
          a.updatedAt && b.updatedAt && a.updatedAt.localeCompare(b.updatedAt),
        sortDirections: ["descend", "ascend"],
        render: (item) => getFormatedDate(item),
      },
    ],
  ]);

const columns = [
  {
    title: "Image",
    dataIndex: "image",
    render: (image) => <Avatar src={image} />,
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (text, record) => (
      <Link to={`/users/groups/${record.id}`}>{text}</Link>
    ),
  },
  {
    title: "Members",
    dataIndex: "members",
    render: (list) =>
      list && (
        <span
          style={{
            maxWidth: "15rem",
            overflow: "auto",
            display: "block",
            whiteSpace: "nowrap",
          }}
        >
          {list.map((m, i) => (
            <span key={i}>
              {m.name}
              {i === list.length - 1 ? "" : ", "}
            </span>
          ))}
        </span>
      ),
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (text) => (
      <span
        style={{
          maxWidth: "10rem",
          overflow: "auto",
          display: "block",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
    ),
  },
  {
    key: "lastUpdated",
    title: "Last updated",
    dataIndex: "updatedAt",
    sorter: (a, b) =>
      a.updatedAt && b.updatedAt && a.updatedAt.localeCompare(b.updatedAt),
    sortDirections: ["descend", "ascend"],
    render: (item) => getFormatedDate(item),
  },
];

export const defaultVisibleFields = [
  "Image",
  "Name",
  "Members",
  "Description",
  "Last updated",
];

export default columns;
