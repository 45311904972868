import React from "react";
import styled from "styled-components";
import { Form, Select, Checkbox, DatePicker, Input } from "antd";

const CUSTOM_FIELD_TYPE = {
  TEXT: "Text",
  SELECT: "Select",
  CHECKBOX: "Checkbox",
  DATE_TIME: "DateTime",
};
const { TextArea } = Input;
const { Option } = Select;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: ${(props) => (props.customFieldLabel ? "0" : "1rem")};

  .custom-field-radio {
    margin: ${(props) =>
      props.customFieldLabel ? "2rem 1rem 0 0" : "0 1rem 0 0"};
  }
`;
const CustomFields = (props) => {
  const { customFieldLabel } = props;

  const getFieldLabel = (name, isRequired) => {
    if (customFieldLabel) {
      return customFieldLabel(name, isRequired);
    } else {
      return name;
    }
  };

  const customCheckbox = (data) => (
    <CheckboxContainer customFieldLabel={customFieldLabel}>
      <Form.Item
        required={data.required}
        name={data.id}
        valuePropName="checked"
        className="custom-field-radio"
      >
        <Checkbox
          style={{
            fontWeight: 200,
            fontSize: "14px",
            color: "#343434",
          }}
        />
      </Form.Item>
      <div>
        {data.required && !customFieldLabel && (
          <span style={{ color: "red", fontSize: 12, marginRight: 6 }}>*</span>
        )}
      </div>
      {getFieldLabel(data.label, data.required)}
    </CheckboxContainer>
  );

  const customSelect = (data) => (
    <div style={customFieldLabel ? { margin: 0 } : { marginBottom: "1rem" }}>
      <Form.Item
        name={data.id}
        label={getFieldLabel(data.label, data.required)}
        required={data.required}
        style={{ width: "100%" }}
        rules={[
          { required: data.required, message: "This field is required" },
          { type: data.allowMultiple ? "array" : "string" },
        ]}
      >
        <Select
          mode={data.allowMultiple ? "multiple" : undefined}
          placeholder={`Add ${data.label}`}
        >
          {data.options.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );

  const customText = (data) => (
    <div style={customFieldLabel ? { margin: 0 } : { marginBottom: "2.5rem" }}>
      <Form.Item
        name={data.id}
        label={getFieldLabel(data.label, data.required)}
        style={{ marginBottom: "0.2rem" }}
        rules={[
          { required: data.required, message: "This field is required" },
          { type: "any", max: data.charLimit },
        ]}
      >
        {data?.charLimit < 150 ? <Input /> : <TextArea />}
      </Form.Item>
      <span
        style={{
          float: "left",
          fontSize: "12px",
          alignSelf: "flex-start",
          color: "#DADADA",
        }}
      >
        {data.charLimit} character limit
      </span>
    </div>
  );

  const customDateTime = (data) => (
    <div
      style={{
        marginBottom: "1rem",
      }}
    >
      <Form.Item
        name={data.id}
        label={data.label}
        rules={[
          {
            required: data.required,
            message: "Field is required",
          },
        ]}
      >
        <DatePicker
          style={{ height: 40, display: "flex" }}
          showTime={data.time}
        />
      </Form.Item>
    </div>
  );

  const form = props.form;
  return (
    props.customFields && (
      <Form
        form={form}
        scrollToFirstError
        layout={"vertical"}
        style={{
          width: "60%",
          paddingLeft: 2,
          display: "flex",
          marginTop: "0.8rem",
          margin: customFieldLabel && 0,
          flexDirection: "column",
        }}
      >
        {props.customFields.map((item, index) => (
          <div style={{ width: "100%" }} key={index}>
            {item.type === CUSTOM_FIELD_TYPE.TEXT && customText(item)}
            {item.type === CUSTOM_FIELD_TYPE.SELECT && customSelect(item)}
            {item.type === CUSTOM_FIELD_TYPE.CHECKBOX && customCheckbox(item)}
            {item.type === CUSTOM_FIELD_TYPE.DATE_TIME && customDateTime(item)}
          </div>
        ))}
      </Form>
    )
  );
};

export default CustomFields;
