const getParams = () => {
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get("access-token");
    const client = query.get("client");
    const client_id = query.get("client_id");
    const config = query.get("config");
    const expiry = query.get("expiry");
    const reset_password = query.get("reset_password");
    const token = query.get("token");
    const uid = query.get("uid");
    return {
        tokenType: "Bearer",
        accessToken,
        client,
        client_id,
        config,
        expiry,
        reset_password,
        token,
        uid,
    };
};
export { getParams };
