import React, { useState, useEffect } from "react";
import Logo from "../../logo.svg";
import styled from "styled-components";
import jwt from "jsonwebtoken";
import axios from "axios";
import { Input, Button, message } from "antd";
import ForgotPassword from "./forgotPassword";
import { UserRole } from "../../utils/contants";
import { Mutations, ApolloAuthClient } from "../../api";
import { ApolloProvider, useMutation } from "@apollo/client";
import { trimErrorMessage, isValidEmail } from "../../utils";
import OverlaySpinner from "./../../components/OverlaySpinner";
import { isSession, saveSession } from "../../helpers/SessionManagement";

const Wrapper = styled.div`
  background: #001529;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  max-width: 600px;
  width: 100%;
  padding: 1rem;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .forgotPassword {
    display: flex;
    cursor: pointer;
    width: 120px;
    align-self: flex-end;
    u {
      color: #fff;
    }
  }
`;

export function LogIn(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setAuth } = props;

  const [userLogin, { loading, error, data }] = useMutation(
    Mutations.LOGIN,
    {}
  );
  const [forgetPassword, setForgetPassword] = useState(false);

  useEffect(() => {
    props.history.push("/");
  }, []);

  useEffect(() => {
    const session = isSession();
    session && setAuth(true);

    async function init() {
      if (
        data.userLogin.authenticatable.userType === UserRole.SUPER_ADMIN ||
        data.userLogin.authenticatable.userType === UserRole.ADMIN ||
        data.userLogin.authenticatable.userType === UserRole.STAFF
      ) {
        // Handle directus login.
        try {
          const manage = await axios.post(
            `${process?.env?.REACT_APP_DIRECTUS_ENDPOINT}/sso/login`,
            {
              id: data?.userLogin?.authenticatable?.id,
              email: data?.userLogin?.credentials?.uid,
              name: data?.userLogin?.authenticatable?.name,
              organization: data?.userLogin?.authenticatable?.organization?.id,
              role: data?.userLogin?.authenticatable?.userType,
              token: data?.userLogin?.credentials?.accessToken,
              expires: data?.userLogin?.credentials?.expiry,
            }
          );

          const accessToken = jwt.sign(
            {
              id: manage?.data?.userId,
              role: manage?.data?.role,
              app_access: !!manage?.data?.appAccess,
              admin_access: !!manage?.data?.adminAccess,
            },
            manage?.data?.secret,
            {
              expiresIn: "1y",
              issuer: "directus",
            }
          );

          localStorage.setItem("auth_token", accessToken);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log("OAUTH2 DIRECTUS ERROR", e.message);
        }

        saveSession({
          ...data.userLogin.credentials,
          ...data.userLogin.authenticatable,
        });

        message.success(`Welcome back ${data.userLogin.authenticatable.name}`);
        setTimeout(() => {
          setAuth(true);
        }, 250);
      } else {
        message.error(
          <span>
            You are not authorized to perform this operation.
            <br />
            Please set up your account
          </span>
        );
      }
    }

    if (data) {
      init();
    }
  }, [setAuth, data, error]);

  const handleLogin = () => {
    userLogin({
      variables: { email: email.toLowerCase(), password: password },
    }).catch((e) => {
      if (e.message.includes("Network error")) {
        message.error("Please check your network connection!");
      } else message.error(trimErrorMessage(e.message));
    });
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <div style={{ background: "#001529" }}>
        <Wrapper>
          <img
            width={240}
            alt="localeyz-logo"
            src={Logo}
            style={{ alignSelf: "center" }}
          />
          <Form>
            <Input
              size={"large"}
              placeholder="Your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onPressEnter={() => handleLogin()}
            />
            <Input.Password
              size={"large"}
              placeholder="Your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onPressEnter={() => handleLogin()}
            />
            <Button
              type="primary"
              size={"large"}
              onClick={handleLogin}
              disabled={!isValidEmail(email) || password.length < 1}
            >
              Login
            </Button>
            <div
              className="forgotPassword"
              onClick={() => {
                setForgetPassword(!forgetPassword);
              }}
            >
              <u>Forgot password?</u>
            </div>
            <ForgotPassword
              modelState={forgetPassword}
              setModelState={setForgetPassword}
            />
          </Form>
        </Wrapper>
      </div>
    </>
  );
}

export default function LoginProvider(props) {
  return (
    <ApolloProvider client={ApolloAuthClient.client}>
      <LogIn {...props} />
    </ApolloProvider>
  );
}
