import React from "react";
import { Button, Checkbox } from "antd";
import styled from "styled-components";

const AddColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .default {
      display: flex;
      flex-direction: column;
      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        color: #262626;
      }
      .item {
        display: flex;
        margin: 0px;
        height: 22px;
        p {
          width: 150px;
          margin-top: -5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #e8e8e8;
    margin-top: 18px;
    padding-top: 10px;
  }
`;

const SelectFieldsModel = (props) => {
    return (
        <div style={{width: 341}}>
            <AddColumnWrapper>
                <div className="content">
                    <div className="default">
                        <p className="title">Default fields</p>
                        {props.defaultField.map((item, i) => {
                            if (item && item !== "Edit") {
                                return (
                                    <Checkbox
                                        key={i}
                                        checked={props.isChecked(item)}
                                        className="item"
                                        onChange={(event) => {
                                            props.onSelected(item, event.target.checked);
                                        }}
                                    >
                                        <p>{item}</p>
                                    </Checkbox>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                    <div className="default">
                        {props.customfields.length > 0 && (
                            <p className="title">Custom fields</p>
                        )}

                        {props.customfields.map((item, i) => {
                            return (
                                <Checkbox
                                    className="item"
                                    key={i}
                                    checked={props.isChecked(item)}
                                    onChange={(event) => {
                                        props.onSelected(item, event.target.checked);
                                    }}
                                >
                                    <p>{item}</p>
                                </Checkbox>
                            );
                        })}
                    </div>
                </div>
                <div className="bottom">
                    <Button onClick={props.onResetSelection}>Reset</Button>
                    <Button type="primary" onClick={props.closeModel}>
                        Close
                    </Button>
                </div>
            </AddColumnWrapper>
        </div>
    );
}


export default SelectFieldsModel;