const UserRole = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  PRODUCER: "producer",
  STAFF: "staff",
};

const InviteStatus = {
  ACCEPTED: "accepted",
  PENDING: "pending",
};
const defaultFieldKeys = {
  episode: "Episode",
  id: "ID",
  program: "Program",
  producer: "Producer",
  productionDate: "Date of production",
  topics: "Topics",
  streamUrl: "Episode URL",
  status: "Status",
  published: "Published",
  sponsored: "Sponsored",
  createdAt: "Created at",
  assistantProducer: "Assistant producers",
  tags: "Tags",
  thumbnail: "Thumbnail",
  type: "Type",
  updateDate: "Updated date",
  videoUrl: "Episode video",
  linkExpiry: "Expiry link",
  lastUpdated: "Last updated",
  image: "Image",
  name: "Name",
  members: "Members",
  description: "Description",
  episodeOrigin: "Episode origin",
};

const MastercontrollColKeys = {
  c_mediaURL: "Program relation media url",
  c_id: "Cablecast id",
  t_id: "Telvue id",
  t_program_code: "Program code",
  t_filename: "Expected filename",
  t_connect_id: "Telvue connect id",
  t_datetime: "Ingest date and time",
  t_delete_at: "Telvue episode delete at",
};

const defaultStatus = {
  accept: "ACCEPTED",
  declined: "DECLINED",
  inReview: "IN_REVIEW",
  draft: "DRAFT",
  all: "all",
  done: "done",
  uploading: "uploading",
};

const defaultPublishStatus = {
  publish: "Published",
  unpublished: "Unpublished",
};

const customFieldsTypes = {
  select: "Select",
  text: "Text",
  dateTime: "DateTime",
  checkbox: "Checkbox",
};

const customFieldValue = {
  TRUE: "Yes",
  FALSE: "No",
};

const FIELD_TYPE = {
  TOPICS: "topics",
  MEDIA: "media",
  IMAGE: "image",
  MEMBERS: "members",
  TITLE: "title",
  STATUS: "status",
  TELVUE_ID: "telvueId",
  TELVUE_CONNECT_ID: "telvueConnectId",
  DURATION: "duration",
  VIDEO_URL: "videoUrl",
  STREAM_URL: "streamUrl",
  PUBLISHED: "published",
  DOCUMENTS: "documents",
  SPONSORED: "sponsored",
  PROGRAM_ID: "programId",
  PROGRAM_TITLE: "programTitle",
  PROGRAM_TYPE: "programType",
  TAGS: "tags",
  THUMBNAIL_URL: "thumbnailUrl",
  PUSH: "pushToCablecastOrTelvue",
  EXTERNAL_LINKS: "externalLinks",
  EPISODE_NUMBER: "episodeNumber",
  TELVUE_FILENAME: "telvueFileName",
  TELVUE_DELETE_AT: "telvueDeleteAt",
  FULL_DESCRIPTION: "fullDescription",
  NOTES_TO_PRODUCER: "notesToProducer",
  SHORT_DESCRIPTION: "shortDescription",
  CUSTOM_FIELD_VALUE: "customFieldValue",
  DATE_OF_PRODUCTION: "dateOfProduction",
  TELVUE_PROGRAM_CODE: "telvueProgramCode",
  EXECUTIVE_PRODUCER: "executiveProducerId",
  ASSISTANT_PRODUCERS: "assistantProducers",
  EPISODE_ORIGIN_OPTION: "episodeOriginOptionId",
  TELVUE_INGEST_DATE_TIME: "telvueIngestDateTime",
  CABLE_CAST_MEDIA: "cablecastProgramPelantionMedia",
};

const MENU = {
  DASHBOARD: "Dashboard",
  WIDGETS: "Widgets",
  LIVE_VIDEO: "Live video",
  PODCASTS: "Podcasts",
  RADIO: "Radio",
  PROGRAMS: "Programs",
  EPISODES: "Episodes",
  PLAYLISTS: "Playlists",
  API_DOCS: "API docs",
  ROADMAP: "Roadmap",
  STAFF: "Staff",
  PRODUCERS: "Producers",
  USERS: "Users",
  INVITES: "Invites",
  SETTINGS: "Settings",
  INTEGRATIONS: "Integrations",
  PRODUCER: "Producer",
  GROUPS: "Groups",
  DONATIONS: "Donations",
};

const MEDIA_TYPE = {
  Cabelcast: "CABLECAST",
  Telvue: "TELVUE",
};

const CUSTOM_FIELD_ITEM = {
  type: customFieldsTypes.checkbox,
  label: "",
  staff: false,
  required: true,
  options: [],
  allowMultiple: false,
  charLimit: 250,
  time: false,
  labelRequired: false,
};

const SEARCH_DELAY = 400;

const PLAYLIST_TYPE = {
  MOST_RECENT: "MOST_RECENT",
  SPECIFIC_EPISODES: "SPECIFIC_EPISODES",
  BY_PROGRAMS: "BY_PROGRAMS",
  BY_TOPICS: "BY_TOPICS",
  BY_TAGS: "BY_TAGS",
  FILTERS: "FILTERS",
};

const PAGINATION_COUNTS = {
  default: 10,
  EPISODES: 50,
  PROGRAMS: 50,
  PODCASTS: 10,
  USERS: 10,
  WIDGETS: 10,
  GROUPS: 10,
  DONATIONS: 10,
};
const DONATION_TYPE = {
  ONLINE: { text: "Online", value: "ONLINE" },
  OFFLINE: { text: "Offline", value: "OFFLINE" },
};

const DONATION_STATUS = {
  Succeeded: {
    text: "Succeeded",
    value: "SUCCEEDED",
  },
  Refunded: {
    text: "Refunded",
    value: "REFUNDED",
  },
  Pending: {
    text: "Pending",
    value: "PENDING",
  },
  Refund_Pending: {
    text: "Refund Pending",
    value: "REFUND_PENDING",
  },
};

const DONATION_WIDGET_TYPES = {
  IN_PAGE: { label: "In-page", value: "IN_PAGE" },
  POP_UP: { label: "Pop-up", value: "POPUP" },
  CALL_TO_ACTION: { label: "Call to action", value: "CALL_TO_ACTION" },
};

const DONATION_WIDGET_POPUP_LOGIC = {
  ONE_TIME: { label: "First visit only", value: "ONE_TIME" },
  EVERY_VISIT: { label: "Every visit", value: "EVERY_VISIT" },
  HOURLY_BASIS: { label: "Once every ", value: "HOURLY_BASIS" },
};

const DEFAULT_EPISODE_COLUMNS = [
  defaultFieldKeys.id,
  defaultFieldKeys.episode,
  defaultFieldKeys.program,
  defaultFieldKeys.producer,
  defaultFieldKeys.productionDate,
  defaultFieldKeys.status,
  defaultFieldKeys.published,
  defaultFieldKeys.lastUpdated,
];

const DEFAULT_PROGRAM_COLUMNS = [
  defaultFieldKeys.id,
  defaultFieldKeys.program,
  defaultFieldKeys.episode,
  defaultFieldKeys.producer,
  defaultFieldKeys.updateDate,
  defaultFieldKeys.status,
  defaultFieldKeys.published,
  defaultFieldKeys.type,
  defaultFieldKeys.lastUpdated,
];

const WIDGET_TYPES = {
  Vod: "VOD",
  Radio: "RADIO",
  Footer: "FOOTER",
  Search: "SEARCH",
  Podcasts: "PODCAST",
  Playlist: "PLAYLIST",
  Schedule: "SCHEDULE",
  Donation: "DONATION",
  LiveVideo: "LIVE_VIDEO",
  Subscription: "SUBSCRIPTION",
  ProducerPortal: "PRODUCER_PORTAL",
};

const LIVEVIDEO_FORM_FIELDS = {
  name: {
    key: "name",
    placeHolder: "Live video name",
    label: "Name",
  },
  stations: {
    key: "stations",
    label: "Stations",
    placeHolder: "Station names",
  },
  provider: {
    key: "provider",
    label: "Live video provider",
  },
  streamProtocol: {
    key: "streamProtocol",
    label: "Stream URL",
  },
  streamUrl: {
    key: "streamUrl",
    placeHolder: "Live video stream URL",
  },
  playerType: {
    key: "playerType",
    label: "Stream type",
  },
  rssProtocol: {
    key: "rssProtocol",
    label: "Schedule RSS",
  },
  scheduleRss: {
    key: "scheduleRss",
    placeHolder: "Schedule RSS URL",
  },
  channelId: {
    key: "channelId",
    label: "Channel ID",
    placeHolder: "ID will come from stream URL",
  },
  image: {
    key: "image",
    label: "Image",
  },
  description: {
    key: "description",
    placeHolder: "Station description",
    label: "Description",
  },
  published: {
    key: "published",
  },
};

const RADIO_FORM_FIELDS = {
  name: {
    key: "name",
    label: "Name",
    placeHolder: "Radio name",
  },
  station: {
    key: "station",
    label: "Station",
    placeHolder: "Station name",
  },
  protocol: {
    key: "protocol",
    label: "Stream URL",
  },
  streamUrl: {
    key: "streamUrl",
    placeHolder: "Radio stream URL",
  },
  description: {
    key: "description",
    label: "Description",
    placeHolder: "Station description",
  },
  published: {
    key: "published",
  },
};

const PODCAST_FORM_FIELDS = {
  name: {
    key: "name",
    label: "Name",
    placeHolder: "Podcast name",
  },
  protocol: {
    key: "protocol",
    label: "RSS feed",
  },
  rssFeed: {
    key: "rss_feed",
    placeHolder: "Podcast RSS feed URL",
  },
  published: {
    key: "published",
  },
};

const PROTOCOLS = {
  https: "https://",
  http: "http://",
};

const TABS = [
  "/widgets",
  "/live-video",
  "/podcasts",
  "/radio",
  "/playlist",
  "/episodes",
  "/programs",
  "/donations",
  "/users/staff",
  "/users/groups",
  "/users/producer",
];

const ROUTES_TO_IGNORE = ["/donations", "/donations/new", "/donation/edit/:id"];

export {
  MENU,
  UserRole,
  MEDIA_TYPE,
  FIELD_TYPE,
  WIDGET_TYPES,
  InviteStatus,
  SEARCH_DELAY,
  PLAYLIST_TYPE,
  DONATION_TYPE,
  defaultStatus,
  DONATION_STATUS,
  defaultFieldKeys,
  customFieldValue,
  CUSTOM_FIELD_ITEM,
  customFieldsTypes,
  PAGINATION_COUNTS,
  defaultPublishStatus,
  DONATION_WIDGET_TYPES,
  MastercontrollColKeys,
  DEFAULT_EPISODE_COLUMNS,
  DEFAULT_PROGRAM_COLUMNS,
  DONATION_WIDGET_POPUP_LOGIC,
  LIVEVIDEO_FORM_FIELDS,
  RADIO_FORM_FIELDS,
  PODCAST_FORM_FIELDS,
  PROTOCOLS,
  TABS,
  ROUTES_TO_IGNORE,
};
