import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  message,
  Collapse,
  Spin,
  Avatar,
  Switch,
  Checkbox,
  Tooltip,
} from "antd";
import styled from "styled-components";
import HelpText from "../../../components/HelpText";
import { Mutations, Queries } from "../../../api";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import {
  LoadingOutlined,
  SaveOutlined,
  PlusOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import YouTubeIcon from "../../../assets/youtube_social_icon_red.png";
import NeonCRMIcon from "../../../assets/neoncrm.jpg";
import { MEDIA_TYPE } from "../../../utils/contants";
import { useYouTubeAuth } from "../../../hooks/useYouTubeAuth";
import { useWorker } from "../../../hooks/useWorker";
import Rentle from "./forms/rentle";

const { Panel } = Collapse;

const StyledForm = styled.div`
  .save {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  button {
    margin-top: 2rem;
  }
`;

export default function Integrations(props) {
  const [telvueConnect, setTelvueConnect] = useState(false);
  const [autoSyncTelvueHypercast, setAutoSyncTelvueHypercast] = useState(false);
  const [autoSyncTelvueConnect, setAutoSyncTelvueConnect] = useState(false);
  const [autoSyncCablecast, setAutoSyncCablecast] = useState(false);
  const [lastSyncCablecast, setLastSyncCablecast] = useState(null);
  const [autoSyncNeonCRM, setAutoSyncNeonCRM] = useState(false);
  const [lastSyncNeonCRM, setLastSyncNeonCRM] = useState(null);
  const [lastSyncTelvueHypercast, setLastSyncTelvueHypercast] = useState(null);
  const [lastSyncTelvueConnect, setLastSyncTelvueConnect] = useState(null);
  const [disabledCablecastRefresh, setDisabledCablecastRefresh] =
    useState(false);
  const [disabledNeonCRMRefresh, setDisabledNeonCRMRefresh] = useState(false);
  const [disabledTelvueHypercastRefresh, setDisabledTelvueHypercastRefresh] =
    useState(false);
  const [disabledTelvueConnectRefresh, setDisabledTelvueConnectRefresh] =
    useState(false);
  const [membershipSyncNeonLoading, setMembershipSyncNeonLoading] =
    useState(false);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [fetchingEpisodes, setFetchingEpisodes] = useState();
  const [cableCastBaseUrl, setCableCastBaseUrl] = useState("");
  const [telvueApi, setTelvueApi] = useState("");
  const [telvueSecret, setTelvueSecret] = useState("");
  const [telvueHypercastApiKey, setTelvueHypercastApiKey] = useState("");
  const [telvueHypercastBaseUrl, setTelvueHypercastBaseUrl] = useState("");
  const [youtubeIntegration, setYouTubeIntegration] = useState(null);
  const [neonCRMOrgID, setNeonCRMOrgID] = useState(null);
  const [neonCRMAPIKey, setNeonCRMAPIKey] = useState(null);
  const [mailChimpApi, setMailChimpApi] = useState("");
  const { workerMessage, sendWorkerMessage } = useWorker("neon:sync");

  const {
    authenticate: authenticateYouTube,
    authLoaded: youtubeAuthLoaded,
    mutationLoading: youtubeMutateLoading,
    removeExistingConnection: removeExistingYouTubeConnection,
  } = useYouTubeAuth(setYouTubeIntegration);

  const [integrationsResponse, setIntegrationsResponse] = useState({
    cabelCastResponse: "",
    telvueApiResponse: "",
    mailChimpApiResponse: "",
  });

  const [stripeCreds, setStripeCreds] = useState({
    liveApiKey: "",
    devApiKey: "",
    webHooksSecret: "",
    devPublishableKey: "",
    livePublishableKey: "",
    isDevMode: true,
  });

  const {
    loading: integrationLoading,
    error: integrationError,
    data: integrationData,
    refetch: refetchIntegrationData,
  } = useQuery(Queries.INTEGRATION_DATA_CALL, {
    fetchPolicy: "network-only",
    onError: (err) => {
      message.error("Error loading organization's data");
    },
  });

  const [
    fetchEpisodesOnCableCast,
    { loading: fetchLoading, error: fetchError, data: fetchData },
  ] = useMutation(Mutations.FETCH_EPISODES_ON_CABLECAST, {});

  const [
    fetchEpisodesOnTelvue,
    {
      loading: fetchTelvueLoading,
      error: fetchTelvueError,
      data: fetchTelvueData,
    },
  ] = useMutation(Mutations.FETCH_EPISODES_ON_TELVUE, {});

  const [
    pullEpisodesFromTelvueConnect,
    {
      loading: fetchTelvueConnectLoading,
      error: fetchTelvueConnectError,
      data: fetchTelvueConnectData,
    },
  ] = useMutation(Mutations.PULL_EPISODES_FROM_TELVUE_CONNECT, {});

  const [
    createCablecastIntegration,
    {
      loading: createCablecastIntegrationLoading,
      error: createCablecastIntegrationError,
      data: createCablecastIntegrationData,
    },
  ] = useMutation(Mutations.CREATE_CABLECAST_INTEGRATION);

  const [
    updateCablecastIntegration,
    {
      loading: updateCablecastIntegrationLoading,
      error: updateCablecastIntegrationError,
      data: updateCablecastIntegrationData,
    },
  ] = useMutation(Mutations.UPDATE_CABLECAST_INTEGRATION);
  const [
    createTelvueIntegration,
    {
      loading: createTelvueIntegrationLoading,
      error: createTelvueIntegrationError,
      data: createTelvueIntegrationData,
    },
  ] = useMutation(Mutations.CREATE_TELVUE_INTEGRATION);

  const [
    updateTelvueIntegration,
    {
      loading: updateTelvueIntegrationLoading,
      error: updateTelvueIntegrationError,
      data: updateTelvueIntegrationData,
    },
  ] = useMutation(Mutations.UPDATE_TELVUE_INTEGRATION);

  const [
    updateNeonIntegration,
    {
      loading: updateNeonIntegrationLoading,
      error: updateNeonIntegrationError,
      data: updateNeonIntegrationData,
    },
  ] = useMutation(Mutations.UPDATE_NEON_INTEGRATION);

  const [
    createMailChimpIntegration,
    {
      loading: createMailChimpIntegrationLoading,
      error: createMailChimpIntegrationError,
      data: createMailChimpIntegrationData,
    },
  ] = useMutation(Mutations.CREATE_MAILCHIMP_INTEGRATION);

  const [
    updateMailChimpIntegration,
    {
      loading: updateMailChimpIntegrationLoading,
      error: updateMailchimpIntegrationError,
      data: updateMailChimpIntegrationData,
    },
  ] = useMutation(Mutations.UPDATE_MAILCHIMP_INTEGRATION);

  const [createStripeIntegration, { loading: createStripeIntegrationLoading }] =
    useMutation(Mutations.CREATE_STRIPE_SETTINGS, {
      onError: (err) => message.error("Error creating stripe integration"),
      onCompleted: (err) => {
        refetchIntegrationData();
        message.success("Stripe integration created successfully");
      },
    });

  const [updateStripeIntegration, { loading: updateStripeIntegrationLoading }] =
    useMutation(Mutations.UPDATE_STRIPE_SETTINGS, {
      onError: (err) => message.error("Error updating stripe integration"),
      onCompleted: (err) => {
        refetchIntegrationData();
        message.success("Stripe integration updated successfully");
      },
    });

  //Error for all mutations and queries
  useEffect(() => {
    if (createCablecastIntegrationError)
      message.error("Error creating cable cast integration");
    if (updateCablecastIntegrationError) {
      refetchIntegrationData();
      message.error("Error updating cable cast integration");
    }
    if (createTelvueIntegrationError)
      message.error("Error creating telvue integration");
    if (updateTelvueIntegrationError) {
      refetchIntegrationData();
      message.error("Error updating telvue integration");
    }
    if (createMailChimpIntegrationError)
      message.error("Error creating mail champ integration");
    if (updateMailchimpIntegrationError) {
      refetchIntegrationData();
      message.error("Error updating mail champ integration");
    }
    if (fetchData || fetchTelvueData)
      message.success(
        "Successfully triggered refresh. This task is running in the background."
      );
    if (fetchError || fetchTelvueError) message.error(fetchError.message);
    // eslint-disable-next-line
  }, [
    integrationError,
    createCablecastIntegrationError,
    updateCablecastIntegrationError,
    createTelvueIntegrationError,
    updateTelvueIntegrationError,
    createMailChimpIntegrationError,
    updateMailchimpIntegrationError,
    fetchError,
    fetchData,
    fetchTelvueError,
    fetchTelvueData,
    fetchingEpisodes,
  ]);

  useEffect(() => {
    if (integrationData?.organization?.youtubeAccessToken) {
      setYouTubeIntegration(integrationData.organization);
    }

    if (integrationData?.organization?.neonApiKey) {
      setNeonCRMAPIKey(integrationData.organization.neonApiKey);
    }

    if (integrationData?.organization?.neonOrgId) {
      setNeonCRMOrgID(integrationData.organization.neonOrgId);
    }

    setLastSyncNeonCRM(integrationData?.organization?.lastSyncNeonCrm);
    setAutoSyncNeonCRM(!!integrationData?.organization?.autoSyncNeonCrm);

    if (integrationData?.stripeIntegration) {
      const { stripeIntegration } = integrationData;
      setStripeCreds({
        devApiKey: stripeIntegration?.devApiKey,
        liveApiKey: stripeIntegration?.liveApiKey,
        isDevMode: stripeIntegration?.isDevMode || false,
        webHooksSecret: stripeIntegration?.webHooksSecret,
        livePublishableKey: stripeIntegration?.livePublishableKey,
        devPublishableKey: stripeIntegration?.devPublishableKey,
      });
    }

    if (integrationData?.cablecastIntegration) {
      const name = integrationData.cablecastIntegration?.username;
      const password = integrationData.cablecastIntegration?.password;
      const baseUrl = integrationData.cablecastIntegration?.baseUrl;
      const autoSync = integrationData.cablecastIntegration?.autoSync;
      const lastSync = integrationData.cablecastIntegration?.lastSync;

      setUserName(name ? name : "");
      setUserPassword(password ? password : "");
      setCableCastBaseUrl(baseUrl ? baseUrl : "");
      setAutoSyncCablecast(!!autoSync);
      setLastSyncCablecast(lastSync);
    }

    if (integrationData?.telvueIntegration) {
      const apiKey = integrationData.telvueIntegration?.apiKey;
      const apiSecret = integrationData.telvueIntegration?.apiSecret;
      const autoSyncHypercast =
        integrationData.telvueIntegration?.autoSyncHypercast;
      const autoSyncConnect =
        integrationData.telvueIntegration?.autoSyncConnect;
      const lastSyncHypercast =
        integrationData.telvueIntegration?.lastSyncHypercast;
      const lastSyncConnect =
        integrationData.telvueIntegration?.lastSyncConnect;
      const telvueHypercastApiKey =
        integrationData.telvueIntegration?.telvueHypercastApiKey;
      const telvueHypercastBaseUrl =
        integrationData.telvueIntegration?.telvueHypercastBaseUrl;

      setAutoSyncTelvueHypercast(!!autoSyncHypercast);
      setAutoSyncTelvueConnect(!!autoSyncConnect);
      setLastSyncTelvueHypercast(lastSyncHypercast);
      setLastSyncTelvueConnect(lastSyncConnect);

      setTelvueApi(apiKey ? apiKey : "");
      setTelvueSecret(apiSecret ? apiSecret : "");
      setTelvueHypercastApiKey(
        telvueHypercastApiKey ? telvueHypercastApiKey : ""
      );
      setTelvueHypercastBaseUrl(
        telvueHypercastBaseUrl ? telvueHypercastBaseUrl : ""
      );
    }
    if (integrationData?.mailChimpIntegration) {
      const apiKey = integrationData.mailChimpIntegration?.apiKey;
      setMailChimpApi(apiKey ? apiKey : "");
    }
    setIntegrationsResponse({
      ...integrationsResponse,
      cabelCastResponse: integrationData?.cablecastIntegration,
      telvueApiResponse: integrationData?.telvueIntegration,
      mailChimpApiResponse: integrationData?.mailChimpIntegration,
    });
    setTelvueConnect(integrationData?.telvueIntegration?.hasConnect || false);
    // eslint-disable-next-line
  }, [integrationData]);

  useEffect(() => {
    if (workerMessage?.status === "success") {
      setMembershipSyncNeonLoading(false);
      message.success("Successfully synced Neon CRM members.");
    }
  }, [workerMessage]);

  useEffect(() => {
    if (updateCablecastIntegrationData) {
      message.success("Cablecast integration configs updated successfully.");
      setIntegrationsResponse({
        ...integrationsResponse,
        cabelCastResponse:
          updateCablecastIntegrationData.updateCablecastIntegration,
      });
    }
    if (updateTelvueIntegrationData) {
      message.success("Telvue integration configs updated successfully.");
      setIntegrationsResponse({
        ...integrationsResponse,
        telvueApiResponse: updateTelvueIntegrationData.updateTelvueIntegration,
      });
    }
    if (updateMailChimpIntegrationData) {
      message.success("MailChimp integration configs updated successfully.");
      setIntegrationsResponse({
        ...integrationsResponse,
        mailChimpApiResponse:
          updateMailChimpIntegrationData.updateMailChimpIntegration,
      });
    }
    // eslint-disable-next-line
  }, [
    updateCablecastIntegrationData,
    updateTelvueIntegrationData,
    updateMailChimpIntegrationData,
  ]);

  useEffect(() => {
    if (createCablecastIntegrationData) {
      setIntegrationsResponse({
        ...integrationsResponse,
        cabelCastResponse:
          createCablecastIntegrationData.createCablecastIntegration,
      });
    }
    if (createTelvueIntegrationData) {
      setIntegrationsResponse({
        ...integrationsResponse,
        telvueApiResponse: createTelvueIntegrationData.createTelvueIntegration,
      });
    }
    if (createMailChimpIntegrationData) {
      setIntegrationsResponse({
        ...integrationsResponse,
        mailChimpApiResponse:
          createMailChimpIntegrationData.createMailChimpIntegration,
      });
    }
    // eslint-disable-next-line
  }, [
    createCablecastIntegrationData,
    createTelvueIntegrationData,
    createMailChimpIntegrationData,
  ]);

  const isUpdateOrCreateIntegration = () => {
    if (integrationsResponse.cabelCastResponse === null) {
      createCablecastIntegration({
        variables: {
          userName: userName,
          password: userPassword,
          baseUrl: cableCastBaseUrl,
          autoSync: autoSyncCablecast,
        },
      });
    } else {
      updateCablecastIntegration({
        variables: {
          userName: userName,
          password: userPassword,
          baseUrl: cableCastBaseUrl,
          autoSync: autoSyncCablecast,
        },
      });
    }
  };

  const isUpdateOrCreateTelvueIntegration = () => {
    if (integrationsResponse.telvueApiResponse === null) {
      createTelvueIntegration({
        variables: {
          apiKey: telvueConnect ? telvueApi : "",
          apiSecret: telvueConnect ? telvueSecret : "",
          hasConnect: telvueConnect,
          autoSyncHyperCast: autoSyncTelvueHypercast,
          autoSyncConnect: autoSyncTelvueConnect,
          telvueHypercastApiKey: telvueHypercastApiKey,
          telvueHypercastBaseUrl: telvueHypercastBaseUrl,
        },
      });
    } else {
      updateTelvueIntegration({
        variables: {
          apiKey: telvueConnect ? telvueApi : "",
          apiSecret: telvueConnect ? telvueSecret : "",
          hasConnect: telvueConnect,
          autoSyncHypercast: autoSyncTelvueHypercast,
          autoSyncConnect: autoSyncTelvueConnect,
          telvueHypercastApiKey: telvueHypercastApiKey,
          telvueHypercastBaseUrl: telvueHypercastBaseUrl,
        },
      });
    }
  };

  const isUpdateOrCreateMailChimpIntegration = () => {
    if (integrationsResponse.mailChimpApiResponse === null) {
      createMailChimpIntegration({
        variables: {
          apiKey: mailChimpApi,
        },
      });
    } else {
      updateMailChimpIntegration({
        variables: {
          apiKey: mailChimpApi,
        },
      });
    }
  };

  const updateNeonCRMData = async () => {
    await updateNeonIntegration({
      variables: {
        neonApiKey: neonCRMAPIKey,
        neonOrgId: neonCRMOrgID,
        autoSyncNeonCrm: autoSyncNeonCRM,
      },
    });

    if (!autoSyncNeonCRM) {
      sendWorkerMessage({
        type: "websocket",
        subject: "neon:remove",
        message: {
          orgId: integrationData?.organization?.id,
        },
      });
    } else {
      sendWorkerMessage({
        type: "websocket",
        subject: "neon:add",
        message: {
          orgId: integrationData?.organization?.id,
        },
      });
    }

    message.success("Neon CRM integration configs updated successfully.");
  };

  const isDataRemainsSame = () => {
    if (integrationsResponse.cabelCastResponse) {
      if (
        integrationsResponse.cabelCastResponse.username === userName &&
        integrationsResponse.cabelCastResponse.password === userPassword &&
        integrationsResponse.cabelCastResponse.baseUrl === cableCastBaseUrl
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isDisabled = () => {
    if (integrationsResponse.telvueApiResponse) {
      if (telvueConnect) {
        if (telvueApi && telvueSecret) {
          if (
            integrationsResponse.telvueApiResponse.apiKey === telvueApi &&
            integrationsResponse.telvueApiResponse.apiSecret === telvueSecret &&
            integrationsResponse.telvueApiResponse.telvueHypercastApiKey ===
              telvueHypercastApiKey &&
            integrationsResponse.telvueApiResponse.telvueHypercastBaseUrl ===
              telvueHypercastBaseUrl
          ) {
            return true;
          }
        } else {
          return true;
        }
        return false;
      } else {
        if (
          integrationsResponse.telvueApiResponse.telvueHypercastApiKey ===
            telvueHypercastApiKey &&
          integrationsResponse.telvueApiResponse.telvueHypercastBaseUrl ===
            telvueHypercastBaseUrl &&
          integrationsResponse.telvueApiResponse.hasConnect === telvueConnect
        )
          return true;
      }
      return false;
    }
    return false;
  };

  const handleSubmitStripeIntegration = () => {
    if (integrationData?.stripeIntegration) {
      updateStripeIntegration({
        variables: {
          ...stripeCreds,
        },
      });
    } else {
      createStripeIntegration({
        variables: {
          ...stripeCreds,
        },
      });
    }
  };

  const hasStripeFormNotChanged = () => {
    return (
      integrationData?.stripeIntegration?.liveApiKey ===
        stripeCreds.liveApiKey &&
      integrationData?.stripeIntegration?.devApiKey === stripeCreds.devApiKey &&
      integrationData?.stripeIntegration?.devPublishableKey ===
        stripeCreds.devPublishableKey &&
      integrationData?.stripeIntegration?.livePublishableKey ===
        stripeCreds.livePublishableKey &&
      integrationData?.stripeIntegration?.isDevMode === stripeCreds.isDevMode &&
      integrationData?.stripeIntegration?.webHooksSecret ===
        stripeCreds.webHooksSecret
    );
  };

  const lastSyncWithinTime = (timestamp) => {
    if (!timestamp) {
      return false;
    }

    const currentTime = moment();
    const timestampMoment = moment(timestamp, moment.ISO_8601);
    return currentTime.diff(timestampMoment) <= 3600000;
  };

  const getYoutubeIntegrationMessage = (youtubeData) => {
    if (youtubeData?.youtubeAccessToken) {
      return (
        <div style={{ marginTop: "10px" }}>
          This account is already authenticated with YouTube.
        </div>
      );
    }

    return <span />;
  };

  return (
    <Spin
      spinning={
        integrationLoading ||
        fetchLoading ||
        fetchTelvueLoading ||
        createCablecastIntegrationLoading ||
        updateCablecastIntegrationLoading ||
        createTelvueIntegrationLoading ||
        updateTelvueIntegrationLoading ||
        createMailChimpIntegrationLoading ||
        updateMailChimpIntegrationLoading ||
        createStripeIntegrationLoading ||
        updateStripeIntegrationLoading
      }
    >
      <Collapse accordion>
        <Panel
          header={
            <>
              <Avatar
                src={require("../../../assets/rentle.png")}
                size={16}
                style={{ marginBottom: "4px", marginRight: "6px" }}
              />
              <span>Rentle</span>
            </>
          }
          key="rentle"
        >
          <Rentle />
        </Panel>
        <Panel
          collapsible={
            integrationData?.episodeSetting?.masterControlIntegration !==
            MEDIA_TYPE.Cabelcast
              ? "disabled"
              : ""
          }
          header={
            <>
              <Avatar
                src={
                  "https://assets.website-files.com/5c7a7afeee8807c8b2b803a4/5c7a82649254e98b8fc0df29_CablecastLogo.png"
                }
                size={16}
                style={{ marginBottom: "4px", marginRight: "6px" }}
              />
              <span>Cablecast</span>
            </>
          }
          key="cablecast"
        >
          <StyledForm>
            <HelpText title={"Cablecast username"} required={true} />
            <Input
              size={"large"}
              style={{ marginBottom: "10px" }}
              placeholder={"Cablecast username"}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <HelpText title={"Cablecast password"} required={true} />
            <Input.Password
              size={"large"}
              style={{ marginBottom: "10px" }}
              placeholder={"Cablecast password"}
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <HelpText title={"Cablecast baseUrl"} required={true} />
            <Input
              size={"large"}
              placeholder={"Cablecast baseUrl"}
              value={cableCastBaseUrl}
              onChange={(e) => setCableCastBaseUrl(e.target.value)}
            />

            <div
              className={"save"}
              style={{
                alignItems: "center",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <h4 style={{ flex: "1 1 100%" }}>Episode pull</h4>
              <Tooltip
                title={
                  lastSyncWithinTime(lastSyncCablecast) ||
                  disabledCablecastRefresh
                    ? "Please wait at least an hour from the last sync"
                    : null
                }
                placement="topLeft"
              >
                <Button
                  style={{ marginTop: 0, marginRight: "15px" }}
                  icon={
                    fetchLoading && fetchingEpisodes ? (
                      <LoadingOutlined />
                    ) : (
                      <PlusOutlined />
                    )
                  }
                  disabled={
                    !cableCastBaseUrl?.length > 0 ||
                    fetchLoading ||
                    !isDataRemainsSame() ||
                    lastSyncWithinTime(lastSyncCablecast) ||
                    disabledCablecastRefresh
                  }
                  onClick={() => {
                    setFetchingEpisodes();
                    fetchEpisodesOnCableCast();
                    setDisabledCablecastRefresh(true);
                  }}
                >
                  Refresh
                </Button>
              </Tooltip>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Switch
                  style={{ marginTop: 0, marginRight: "10px" }}
                  checked={autoSyncCablecast}
                  onChange={(check) => setAutoSyncCablecast(check)}
                />
                <span>Auto sync</span>
              </div>
              <div
                style={{
                  flex: "1 1 100%",
                  marginTop: "10px",
                  color: "#8C8C8C",
                  fontSize: "12px",
                }}
              >
                Last sync:{" "}
                {lastSyncCablecast
                  ? moment(lastSyncCablecast, moment.ISO_8601).format(
                      "MMMM Do, YYYY h:mmA"
                    )
                  : "never"}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  type="primary"
                  shape="round"
                  icon={
                    createCablecastIntegrationLoading ||
                    updateCablecastIntegrationLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SaveOutlined />
                    )
                  }
                  size={"default"}
                  onClick={isUpdateOrCreateIntegration}
                >
                  Save settings
                </Button>
              </div>
            </div>
          </StyledForm>
        </Panel>

        <Panel
          collapsible={
            integrationData?.episodeSetting?.masterControlIntegration !==
            MEDIA_TYPE.Telvue
              ? "disabled"
              : ""
          }
          header={
            <>
              <Avatar
                src={
                  "https://pbs.twimg.com/profile_images/823285204/V.tag.RGBa_400x400.jpg"
                }
                size={16}
                style={{ marginBottom: "4px", marginRight: "6px" }}
              />
              <span>Telvue</span>
            </>
          }
          key="telvue"
        >
          <StyledForm>
            <h2>Telvue Hypercast</h2>

            <div
              style={{
                borderLeft: "1px solid #d9d9d9",
                marginLeft: "5px",
                marginBottom: "20px",
                padding: "10px 0 10px 20px",
              }}
            >
              <HelpText title={"Telvue HyperCast API Key"} required={true} />
              <Input.Password
                size={"large"}
                style={{ marginBottom: "10px" }}
                placeholder={"Telvue HyperCast API key"}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                value={telvueHypercastApiKey}
                onChange={(e) => setTelvueHypercastApiKey(e.target.value)}
              />
              <HelpText title={"Telvue Hypercast BaseUrl"} required={true} />
              <Input
                size={"large"}
                placeholder={"Telvue Hypercast BaseUrl"}
                value={telvueHypercastBaseUrl}
                onChange={(e) => setTelvueHypercastBaseUrl(e.target.value)}
              />
              <div
                className={"save"}
                style={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingTop: "15px",
                  flexWrap: "wrap",
                }}
              >
                <h4 style={{ flex: "1 1 100%" }}>Episode pull</h4>

                <Tooltip
                  title={
                    lastSyncWithinTime(lastSyncTelvueHypercast) ||
                    disabledTelvueHypercastRefresh
                      ? "Please wait at least an hour from the last sync"
                      : null
                  }
                  placement="topLeft"
                >
                  <Button
                    style={{ marginTop: 0, marginRight: "15px" }}
                    icon={
                      fetchTelvueLoading && fetchingEpisodes ? (
                        <LoadingOutlined />
                      ) : (
                        <PlusOutlined />
                      )
                    }
                    disabled={
                      !telvueHypercastApiKey?.length > 0 ||
                      !telvueHypercastBaseUrl?.length > 0 ||
                      fetchTelvueLoading ||
                      lastSyncWithinTime(lastSyncTelvueHypercast) ||
                      disabledTelvueHypercastRefresh
                    }
                    onClick={() => {
                      setFetchingEpisodes();
                      fetchEpisodesOnTelvue();
                      setDisabledTelvueHypercastRefresh(true);
                    }}
                  >
                    Refresh
                  </Button>
                </Tooltip>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Switch
                    style={{ marginTop: 0, marginRight: "10px" }}
                    checked={autoSyncTelvueHypercast}
                    onChange={(check) => setAutoSyncTelvueHypercast(check)}
                  />
                  <span>Auto sync</span>
                </div>
                <div
                  style={{
                    flex: "1 1 100%",
                    marginTop: "10px",
                    color: "#8C8C8C",
                    fontSize: "12px",
                  }}
                >
                  Last sync:{" "}
                  {lastSyncTelvueHypercast
                    ? moment(lastSyncTelvueHypercast, moment.ISO_8601).format(
                        "MMMM Do, YYYY h:mmA"
                      )
                    : "never"}
                </div>
              </div>
            </div>

            <h2>Telvue Connect</h2>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Switch
                style={{ marginTop: 0, marginRight: "10px" }}
                checked={telvueConnect}
                onChange={(check) => setTelvueConnect(check)}
              />
              <span>Enable Telvue Connect</span>
            </div>

            {telvueConnect && (
              <div
                style={{
                  borderLeft: "1px solid #d9d9d9",
                  marginLeft: "5px",
                  padding: "10px 0 10px 20px",
                }}
              >
                <HelpText title={"Telvue Connect API key"} required={true} />
                <Input.Password
                  size={"large"}
                  style={{ marginBottom: "10px" }}
                  placeholder={"Telvue Connect API key"}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  value={telvueApi}
                  onChange={(e) => setTelvueApi(e.target.value)}
                />

                <HelpText title={"Telvue Connect API secret"} required={true} />
                <Input.Password
                  size={"large"}
                  placeholder={"Telvue Connect API secret"}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  value={telvueSecret}
                  onChange={(e) => setTelvueSecret(e.target.value)}
                />

                <div
                  className={"save"}
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingTop: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  <h4 style={{ flex: "1 1 100%" }}>Video on demand pull</h4>

                  <Tooltip
                    title={
                      lastSyncWithinTime(lastSyncTelvueConnect) ||
                      disabledTelvueConnectRefresh
                        ? "Please wait at least an hour from the last sync"
                        : null
                    }
                    placement="topLeft"
                  >
                    <Button
                      style={{ marginTop: 0, marginRight: "15px" }}
                      icon={
                        fetchTelvueLoading && fetchingEpisodes ? (
                          <LoadingOutlined />
                        ) : (
                          <PlusOutlined />
                        )
                      }
                      disabled={
                        !telvueApi?.length > 0 ||
                        !telvueSecret?.length > 0 ||
                        fetchTelvueLoading ||
                        lastSyncWithinTime(lastSyncTelvueConnect) ||
                        disabledTelvueConnectRefresh
                      }
                      onClick={() => {
                        setFetchingEpisodes();
                        pullEpisodesFromTelvueConnect();
                        setDisabledTelvueConnectRefresh(true);
                      }}
                    >
                      Refresh
                    </Button>
                  </Tooltip>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Switch
                      style={{ marginTop: 0, marginRight: "10px" }}
                      checked={autoSyncTelvueConnect}
                      onChange={(check) => setAutoSyncTelvueConnect(check)}
                    />
                    <span>Auto sync</span>
                  </div>
                  <div
                    style={{
                      flex: "1 1 100%",
                      marginTop: "10px",
                      color: "#8C8C8C",
                      fontSize: "12px",
                    }}
                  >
                    Last sync:{" "}
                    {lastSyncTelvueConnect
                      ? moment(lastSyncTelvueConnect, moment.ISO_8601).format(
                          "MMMM Do, YYYY h:mmA"
                        )
                      : "never"}
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "15px",
              }}
            >
              <Button
                type="primary"
                shape="round"
                size={"default"}
                disabled={
                  telvueConnect &&
                  (!telvueApi ||
                    !telvueSecret ||
                    telvueApi === "" ||
                    telvueSecret === "")
                }
                icon={
                  updateTelvueIntegrationLoading ||
                  createTelvueIntegrationLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
                onClick={isUpdateOrCreateTelvueIntegration}
              >
                Save settings
              </Button>
            </div>
          </StyledForm>
        </Panel>

        <Panel
          key="youtube"
          header={
            <>
              <Avatar
                size={16}
                src={YouTubeIcon}
                style={{ marginBottom: "4px", marginRight: "6px" }}
              />
              <span>YouTube</span>
            </>
          }
        >
          <Button
            type="primary"
            danger={!!youtubeIntegration?.youtubeAccessToken}
            disabled={!youtubeAuthLoaded || youtubeMutateLoading}
            loading={!youtubeAuthLoaded || youtubeMutateLoading}
            onClick={
              youtubeIntegration?.youtubeAccessToken
                ? removeExistingYouTubeConnection
                : authenticateYouTube
            }
          >
            {youtubeIntegration?.youtubeAccessToken
              ? "Remove Existing Connection"
              : "Authenticate with YouTube"}
          </Button>

          {getYoutubeIntegrationMessage(youtubeIntegration)}
        </Panel>

        <Panel
          key="neoncrm"
          header={
            <>
              <Avatar
                src={NeonCRMIcon}
                size={16}
                style={{ marginBottom: "4px", marginRight: "6px" }}
              />
              <span>NeonCRM</span>
            </>
          }
        >
          <StyledForm>
            <h2>Neon CRM</h2>

            <div
              style={{
                borderLeft: "1px solid #d9d9d9",
                marginLeft: "5px",
                marginBottom: "20px",
                padding: "10px 0 10px 20px",
              }}
            >
              <HelpText title={"Neon Organization ID"} required={true} />
              <Input
                size={"large"}
                style={{ marginBottom: "10px" }}
                placeholder={"Your Neon Organization ID"}
                value={neonCRMOrgID}
                onChange={(e) => setNeonCRMOrgID(e.target.value)}
              />

              <HelpText title={"API Key"} required={true} />
              <Input.Password
                size={"large"}
                placeholder={"Your Neon API key"}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                value={neonCRMAPIKey}
                onChange={(e) => setNeonCRMAPIKey(e.target.value)}
              />

              <div
                className={"save"}
                style={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingTop: "15px",
                  flexWrap: "wrap",
                }}
              >
                <h4 style={{ flex: "1 1 100%" }}>Membership Sync</h4>

                <Tooltip
                  title={
                    lastSyncWithinTime(lastSyncNeonCRM) ||
                    disabledNeonCRMRefresh
                      ? "Please wait at least an hour from the last sync"
                      : null
                  }
                  placement="topLeft"
                >
                  <Button
                    style={{ marginTop: 0, marginRight: "15px" }}
                    icon={
                      membershipSyncNeonLoading ? (
                        <LoadingOutlined />
                      ) : (
                        <PlusOutlined />
                      )
                    }
                    disabled={
                      !neonCRMAPIKey?.length > 0 ||
                      !neonCRMOrgID?.length > 0 ||
                      membershipSyncNeonLoading ||
                      lastSyncWithinTime(lastSyncNeonCRM) ||
                      disabledNeonCRMRefresh
                    }
                    onClick={() => {
                      setMembershipSyncNeonLoading(true);
                      setDisabledNeonCRMRefresh(true);
                      sendWorkerMessage({
                        type: "websocket",
                        subject: "neon:sync",
                        message: {
                          debug: false,
                        },
                      });
                    }}
                  >
                    Refresh
                  </Button>
                </Tooltip>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Switch
                    style={{ marginTop: 0, marginRight: "10px" }}
                    checked={autoSyncNeonCRM}
                    onChange={(check) => setAutoSyncNeonCRM(check)}
                  />
                  <span>Auto sync</span>
                </div>
                <div
                  style={{
                    flex: "1 1 100%",
                    marginTop: "10px",
                    color: "#8C8C8C",
                    fontSize: "12px",
                  }}
                >
                  Last sync:{" "}
                  {lastSyncNeonCRM
                    ? moment(lastSyncNeonCRM, moment.ISO_8601).format(
                        "MMMM Do, YYYY h:mmA"
                      )
                    : "never"}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "15px",
              }}
            >
              <Button
                type="primary"
                shape="round"
                size={"default"}
                icon={
                  updateNeonIntegrationLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
                onClick={updateNeonCRMData}
              >
                Save settings
              </Button>
            </div>
          </StyledForm>
        </Panel>

        <Panel
          header={
            <>
              <Avatar
                src={"https://i.vimeocdn.com/portrait/9934446_300x300"}
                size={16}
                style={{ marginBottom: "4px", marginRight: "6px" }}
              />
              <span>Vimeo Pro</span>
            </>
          }
          key="video"
          collapsible="disabled"
        ></Panel>
        <Panel
          header={
            <>
              <Avatar
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR0Sb0isSuf6PBJsDtWq1_VNcp21taeN14oqvZ2vnsIvG-ciYQW&usqp=CAU"
                }
                size={16}
                style={{ marginBottom: "4px", marginRight: "6px" }}
              />
              <span>MailChimp</span>
            </>
          }
          key="mailchimp"
        >
          <StyledForm>
            <HelpText title={"Mailchimp API key"} required={true} />
            <Input.Password
              size={"large"}
              placeholder={"Mailchimp API key"}
              value={mailChimpApi}
              onChange={(e) => setMailChimpApi(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />

            <div className={"save"}>
              <Button
                type="primary"
                shape="round"
                size={"large"}
                icon={
                  updateMailChimpIntegrationLoading ||
                  createMailChimpIntegrationLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
                disabled={
                  integrationsResponse.mailChimpApiResponse &&
                  integrationsResponse.mailChimpApiResponse.apiKey ===
                    mailChimpApi
                    ? true
                    : false
                }
                onClick={isUpdateOrCreateMailChimpIntegration}
              >
                Update credentials
              </Button>
            </div>
          </StyledForm>
        </Panel>
        <Panel
          header={
            <>
              <Avatar
                src={require("../../../assets/stripe.png")}
                size={16}
                style={{ marginBottom: "4px", marginRight: "6px" }}
              />
              <span>Stripe</span>
            </>
          }
          key="stripe"
        >
          <StyledForm>
            <HelpText title={"Live API key"} />
            <Input.Password
              size="large"
              placeholder={"Live secret key"}
              value={stripeCreds.liveApiKey}
              onChange={(e) =>
                setStripeCreds({ ...stripeCreds, liveApiKey: e.target.value })
              }
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <HelpText title={"Live publishable key"} />
            <Input.Password
              size="large"
              placeholder={"Live publishable key"}
              value={stripeCreds.livePublishableKey}
              onChange={(e) =>
                setStripeCreds({
                  ...stripeCreds,
                  livePublishableKey: e.target.value,
                })
              }
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <HelpText title={"Webhook signin secret"} />
            <Input.Password
              size="large"
              placeholder={"Webhook secret key"}
              value={stripeCreds.webHooksSecret}
              onChange={(e) =>
                setStripeCreds({
                  ...stripeCreds,
                  webHooksSecret: e.target.value,
                })
              }
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <HelpText title={"Dev secret key"} />
            <Input.Password
              size="large"
              placeholder={"Dev  key"}
              value={stripeCreds.devApiKey}
              onChange={(e) =>
                setStripeCreds({ ...stripeCreds, devApiKey: e.target.value })
              }
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <HelpText title={"Dev publishable key"} />
            <Input.Password
              size="large"
              placeholder={"Dev publishable key"}
              value={stripeCreds.devPublishableKey}
              onChange={(e) =>
                setStripeCreds({
                  ...stripeCreds,
                  devPublishableKey: e.target.value,
                })
              }
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <span
              style={{
                fontWeight: 500,
                fontSize: 16,
                margin: "15px 10px 0 10px",
                display: "inline-block",
              }}
            >
              Use Test Mode?
            </span>
            <Checkbox
              checked={stripeCreds.isDevMode}
              onChange={(e) =>
                setStripeCreds({ ...stripeCreds, isDevMode: e.target.checked })
              }
            />
            <div className={"save"}>
              <Button
                type="primary"
                shape="round"
                size={"large"}
                icon={
                  updateStripeIntegrationLoading ||
                  createStripeIntegrationLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
                disabled={hasStripeFormNotChanged()}
                onClick={handleSubmitStripeIntegration}
              >
                Update credentials
              </Button>
            </div>
          </StyledForm>
        </Panel>
      </Collapse>
    </Spin>
  );
}
