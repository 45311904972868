import React from "react";
import styled from "styled-components";
import { ThemeColors } from "../../theme";
import { Button, Select, Input, Form, Divider } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const {Option} = Select;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-divider-vertical {
    height: auto;
    border-left: 1px solid #dadada;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.6rem;

  .ant-form-item-label > label {
    display: flex;
    font-size: 14px;
  }
  .ant-divider-vertical {
    height: auto;
    border-left: 1px solid #dadada;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  margin-left: 0.6rem;
  flex-direction: column;
  @media screen and (max-width: 730px) {
    padding: 0px;
    margin: 0px;
  }
`;

const ExternalLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.6rem;
  .ant-form-item-label > label {
    display: flex;
  }
  .ant-divider-vertical {
    height: auto;
    border-left: 1px solid #dadada;
  }
  @media screen and (max-width: 730px) {
    flex-direction: column;
  }
`;

const TitleContainer = styled(Form.Item)`
  width: 30%;
  margin-right: 0.5rem;
  @media screen and (max-width: 730px) {
    width: 90%;
  }
`;

const UrlContainer = styled(Form.Item)`
  width: 70%;
  margin-right: 0.9rem;
  flex-direction: column;
  @media screen and (max-width: 730px) {
    width: 90%;
  }
`;

const SOCIAL_LINK_TYPES = [
    "FACEBOOK",
    "TWITTER",
    "YOUTUBE",
    "INSTAGRAM"
];

const ExternalLinkItem = (props) => {
    const {
        link,
        handleSocialLinks,
        removeSocialMedia,
        handleSocialMediaType,
    } = props;
    return (
        <ExternalLinkContainer>
            <TitleContainer label={"Title"}>
                <Input
                    type="text"
                    value={link.title}
                    placeholder={"Title"}
                    onChange={(e) => {
                        handleSocialMediaType(link.id, e.target.value);
                    }}
                />
            </TitleContainer>

            <UrlContainer label="Url">
                <Input
                    value={link.url}
                    placeholder={"https://www.facebook.com"}
                    onChange={(e) => {
                        handleSocialLinks(link.id, e.target.value);
                    }}
                />
            </UrlContainer>

            <Button
                shape={"circle"}
                icon={<MinusOutlined/>}
                style={{
                    alignSelf: "center",
                    visibility: link.id > 0 ? "visible" : "hidden",
                }}
                onClick={() => {
                    removeSocialMedia(link.id);
                }}
            />
        </ExternalLinkContainer>
    );
};

const ExternalLinksComponent = (props) => {
    const onUpdateSocialLinks = (updatedLinks) => {
        props.links([...updatedLinks]);
    };

    const handleSocialLinks = (id, value) => {
        let updatedLinks = props?.data;
        updatedLinks[id] = {
            ...updatedLinks[id],
            url: value,
        };
        onUpdateSocialLinks(updatedLinks);
    };

    const handleSocialMediaType = (id, title) => {
        let updatedLinks = props?.data;
        updatedLinks[id] = {
            ...updatedLinks[id],
            title: title,
        };
        onUpdateSocialLinks(updatedLinks);
    };

    const removeSocialMedia = (id) => {
        const updatedLinks = props?.data.filter((item) => {
            return item.id !== id;
        });
        onUpdateSocialLinks(updatedLinks);
    };

    return (
        <VerticalContainer>
            <p
                style={{
                    alignSelf: "flex-start",
                    color: ThemeColors.colors.black,
                    fontWeight: 400,
                    fontSize: 16,
                    marginBottom: "0.4rem",
                }}
            >
                {props.title}
            </p>
            <HorizontalContainer>
                <Divider
                    orientation="left"
                    type="vertical"
                    style={{
                        background: ThemeColors.colors.borderGray,
                    }}
                />
                <Wrapper>
                    {props?.data?.map((link, i) => (
                        <ExternalLinkItem
                            key={i}
                            link={link}
                            handleSocialLinks={handleSocialLinks}
                            removeSocialMedia={removeSocialMedia}
                            handleSocialMediaType={handleSocialMediaType}
                        />
                    ))}

                    <Button
                        type="dashed"
                        onClick={() => {
                            const addNewLink = {
                                id: props?.data[props.data.length - 1]?.id + 1 || 0,
                                url: "",
                                title: "",
                            };
                            onUpdateSocialLinks([
                                ...props.data,
                                addNewLink
                            ]);
                        }}
                        style={{width: "85%"}}
                        icon={<PlusOutlined/>}
                    >
                        Add links
                    </Button>
                </Wrapper>
            </HorizontalContainer>
        </VerticalContainer>
    );
};

const SocialLinkItem = (props) => {
    const {
        link,
        handleSocialLinks,
        removeSocialMedia,
        handleSocialMediaType,
    } = props;
    return (
        <HorizontalContainer>
            <Form.Item
                label={"Type"}
                style={{
                    width: "70%",
                    marginRight: "0.5rem",
                    flexDirection: "column",
                }}
            >
                <Select
                    value={link.mediaType}
                    onChange={(value) => {
                        handleSocialMediaType(link.id, value);
                    }}
                >
                    {SOCIAL_LINK_TYPES.map((item) => (
                        <Option key={item}>{item}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="Url"
                style={{
                    width: "100%",
                    flexDirection: "column",
                }}
            >
                <Input
                    value={link.url}
                    placeholder={"https://www.facebook.com"}
                    onChange={(e) => {
                        handleSocialLinks(link.id, e.target.value);
                    }}
                />
            </Form.Item>
            <Button
                shape={"circle"}
                icon={<MinusOutlined/>}
                style={{
                    marginLeft: ".9rem",
                    alignSelf: "center",
                }}
                onClick={() => {
                    removeSocialMedia(link.id);
                }}
            />
        </HorizontalContainer>
    );
};

const SocialLinksComponent = (props) => {
    const onUpdateSocialLinks = (updatedLinks) => {
        props.links([...updatedLinks]);
    };

    const handleSocialLinks = (id, value) => {
        let updatedLinks = props?.data;
        updatedLinks[id] = {
            ...updatedLinks[id],
            url: value,
        };
        onUpdateSocialLinks(updatedLinks);
    };

    const handleSocialMediaType = (id, mediaType) => {
        let updatedLinks = props?.data;
        updatedLinks[id] = {
            ...updatedLinks[id],
            mediaType: mediaType,
        };
        onUpdateSocialLinks(updatedLinks);
    };

    const removeSocialMedia = (id) => {
        const updatedLinks = props?.data.filter((item) => {
            return item.id !== id;
        });
        onUpdateSocialLinks(updatedLinks);
    };

    return (
        <HorizontalContainer>
            <Divider orientation="left" type="vertical"/>
            <Wrapper>
                {props?.data.map((link, i) => (
                    <SocialLinkItem
                        key={i}
                        link={link}
                        handleSocialLinks={handleSocialLinks}
                        removeSocialMedia={removeSocialMedia}
                        handleSocialMediaType={handleSocialMediaType}
                    />
                ))}
                <Button
                    type="dashed"
                    onClick={() => {
                        const addNewLink = {
                            id: props?.data[props?.data.length - 1]?.id + 1 || 0,
                            url: "",
                            mediaType: SOCIAL_LINK_TYPES[0],
                        };
                        onUpdateSocialLinks([
                            ...props?.data,
                            addNewLink
                        ]);
                    }}
                    style={{width: "100%"}}
                    icon={<PlusOutlined/>}
                >
                    Add links
                </Button>
            </Wrapper>
        </HorizontalContainer>
    );
};

export { SocialLinksComponent, ExternalLinksComponent };
