import React, { useEffect, useState, useCallback } from "react";
import {
  customFieldsTypes,
  DONATION_STATUS,
  PAGINATION_COUNTS,
  DONATION_TYPE,
  SEARCH_DELAY,
} from "../../utils/contants";
import moment from "moment";
import { message, Menu } from "antd";
import { Mutations } from "../../api";
import styled from "styled-components";
import Queries from "../../api/Queries";
import { ThemeColors } from "../../theme";
import { trimErrorMessage, debounce } from "../../utils";
import AlertConfirm from "../../components/AlertConfirm";
import { columns, initialVisibleColumns } from "./columns";
import { useQuery, useMutation } from "@apollo/client";
import MediaTableHeader from "../../components/MediaTableHeader";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import PaginatedTableview from "../../components/paginatedTableView";
import { useApolloClient } from "@apollo/client";

const Wrapper = styled.div`
  background: #fff;
`;

export default function DonationTableView(props) {
  const { settingsData } = props;
  const [searchString, setSearchString] = useState("");
  const [dateRange, setDateRange] = useState({
    startDateLocal: null,
    endDateLocal: moment(),
    startDateToSubmit: null,
    endDateToSubmit: null,
  });
  const [donations, setDonations] = useState([]);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDisableExport, setIsDisableExport] = useState(false);

  const localClient = useApolloClient();
  const cachedData = localClient.cache.readQuery({
    query: Queries.GET_PAGE_NUMBER,
  });
  const {
    data: donationsData,
    loading: donationsLoading,
    refetch: refetchDonationsData,
    fetchMore: fetchMoreDonations,
  } = useQuery(Queries.DONATION_PAGINATION, {
    variables: {
      page: cachedData?.pageNumber || 1,
      status: null,
      donationType: null,
      query: searchString,
      per: PAGINATION_COUNTS.DONATIONS,
      endDate: dateRange.endDateToSubmit,
      startDate: dateRange.startDateToSubmit,
    },
    onError: (err) => message.error(trimErrorMessage(err.message)),
    fetchPolicy: "network-only",
  });

  const [deleteDonations, { loading: deleteLoading }] = useMutation(
    Mutations.DELETE_DONATIONS,
    {
      onCompleted: () => {
        refetchDonationsData();
        message.success("Donation(s) deleted successfully");
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [refundDonation, { loading: refundLoding }] = useMutation(
    Mutations.REFUND_DONATION,
    {
      onCompleted: () => {
        refetchDonationsData();
        message.success("Donation refunded successfully");
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [
    exportCSVCall,
    { loading: exportLoading, error: exportError, data: exportData },
  ] = useMutation(Mutations.DONATIONS_DATA_TO_CSV, {});

  useEffect(() => {
    if (exportData) {
      message.success("Donations export in progress, CSV will be emailed");
    }

    if (exportError) {
      message.error("Error exporting data to CSV.");
    }
  }, [exportData, exportError]);

  useEffect(() => {
    if (donationsData) {
      setDonations(donationsData?.donations?.nodes || []);
    }
  }, [donationsData]);

  const handleDelete = () => {
    if (areRowsSelected) {
      deleteDonations({
        variables: {
          ids: selectedRows,
        },
      }).catch((error) => message.error(error.message));
      setSelectedRows([]);
    }
  };

  const validateBeforeRefund = () => {
    if (selectedRows.length === 1) {
      const donationToRefund = donations.find(
        (item) => item.id === selectedRows[0]
      );
      if (donationToRefund.donationStatus !== DONATION_STATUS.Succeeded.value) {
        message.error("To refund a donation, its status should be 'Succeeded'");
        return false;
      } else {
        return true;
      }
    }
  };

  const handleRefund = () => {
    if (selectedRows.length === 1) {
      refundDonation({
        variables: {
          donationId: selectedRows[0],
        },
      }).catch((error) => message.error(error.message));
      setSelectedRows([]);
    }
  };

  const onDateChange = (dateFilter = []) => {
    if (dateFilter?.length === 2) {
      setDateRange({
        ...dateRange,
        startDateLocal: dateFilter[0].toString(),
        endDateLocal: dateFilter[1].toString(),
      });
    } else {
      setDateRange({
        ...dateRange,
        startDateLocal: null,
        endDateLocal: null,
      });
    }
  };

  const onApplyDateFilter = () => {
    if (dateRange.startDateLocal && dateRange.endDateLocal) {
      setDateRange({
        ...dateRange,
        startDateToSubmit: dateRange.startDateLocal,
        endDateToSubmit: dateRange.endDateLocal,
      });
    }
  };

  const onDateRangeReset = () => {
    setDateRange({
      startDateToSubmit: null,
      endDateToSubmit: null,
      startDateLocal: null,
      endDateLocal: null,
    });
  };

  const customColumns = new Map(
    settingsData
      ? settingsData.map((field) => {
          return [
            field.label,
            { name: field.label, id: field.id, type: field.type },
          ];
        })
      : []
  );

  const onChangeSearch = (str) => setSearchString(str);

  const debounceOnChange = useCallback(
    debounce(onChangeSearch, SEARCH_DELAY),
    []
  );

  const onClickExportCSV = () => {
    setIsDisableExport(true);
    exportCSVCall().then(() => null);
  };

  const isShowRefund = (id) => {
    const item = donations.filter((item) => item.id === id[0]);
    return (
      item[0]["donationType"] === DONATION_TYPE.ONLINE.value &&
      item[0]["donationStatus"] === DONATION_STATUS.Succeeded.value
    );
  };

  const actionsMenu = () => (
    <Menu>
      {selectedRows?.length === 1 && isShowRefund(selectedRows) && (
        <Menu.Item>
          <AlertConfirm
            actionBtnText="Refund"
            actionBtnProps={{
              className: "donations-action-btn",
              style: !(selectedRows?.length === 1)
                ? {}
                : {
                    background: ThemeColors.colors.orange,
                    color: ThemeColors.colors.white,
                  },

              icon: <ReloadOutlined />,
            }}
            modalProps={{
              onOk: handleRefund,
              okText: "Confirm",
              alertMsg: "Are you sure you want to refund selected payment(s)?",
              alertTitle: "Confirm Refund",
            }}
            validate={validateBeforeRefund}
          />
        </Menu.Item>
      )}
      <Menu.Item>
        <AlertConfirm
          actionBtnText="Delete"
          actionBtnProps={{
            className: "donations-action-btn",
            icon: <DeleteOutlined />,
            type: "danger",
          }}
          modalProps={{
            onOk: handleDelete,
            okText: "Confirm",
            alertMsg: "Are you sure you want to delete selected record(s)?",
            alertTitle: "Confirm Delete",
          }}
        />
      </Menu.Item>
    </Menu>
  );

  const handleAddRecordClick = () => {
    props.history.push("/donations/new");
  };

  const areRowsSelected = selectedRows.length > 0;

  const rowSelection = {
    onChange: (selectedRows) => {
      setSelectedRows(selectedRows);
    },
    selectedRows: selectedRows,
  };

  const onTableChange = (page, filter) => {
    setFetchingMore(true);
    localClient.writeQuery({
      query: Queries.GET_PAGE_NUMBER,
      data: {
        pageNumber: page.current,
      },
    });
    fetchMoreDonations({
      variables: {
        page: page.current,
        query: searchString,
        status: filter?.status || null,
        per: PAGINATION_COUNTS.DONATIONS,
        endDate: dateRange.endDateToSubmit,
        startDate: dateRange.startDateToSubmit,
        donationType: filter?.type?.length > 0 ? filter?.type[0] : null,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    }).finally(setFetchingMore(false));
  };

  return (
    <Wrapper>
      <MediaTableHeader
        setStatus={() => {}}
        exportLoading={false}
        isDisableExport={isDisableExport}
        isShowPushAction={false}
        actionsMenu={actionsMenu}
        onClickAddNew={handleAddRecordClick}
        onChangeSearch={(value) => {
          if (value.trim() === "" && searchString.length === 0) return;
          debounceOnChange(value);
        }}
        onDelete={() => {}}
        onClickExportCSV={onClickExportCSV}
        handlePublishStatus={() => {}}
        addNewBtnTitle={"Add new donation"}
        searchPlaceholder={"Search donation"}
        areRowsSelected={areRowsSelected}
        isLoading={deleteLoading || refundLoding}
      />

      <PaginatedTableview
        withPopover={true}
        selectedRows={selectedRows}
        customColumns={customColumns}
        onTableChange={onTableChange}
        customFieldsTypes={customFieldsTypes}
        onResetColumns={initialVisibleColumns}
        loading={donationsLoading || fetchingMore}
        defaultVisibleFields={initialVisibleColumns}
        data={donationsData?.donations?.nodes || []}
        current={donationsData?.donations?.metadata?.currentPage || 1}
        pageSize={
          donationsData?.donations?.metadata?.limitValue ||
          PAGINATION_COUNTS.default
        }
        totalCount={donationsData?.donations?.metadata?.totalCount}
        columns={[
          ...columns(
            dateRange,
            onDateChange,
            onDateRangeReset,
            onApplyDateFilter,
            props.history
          ),
        ]}
        onRowSelection={props.isShowRowSelection ? rowSelection : false}
      />
    </Wrapper>
  );
}
