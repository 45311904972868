import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import URLS from "../../constants";
import { getSession } from "../../helpers/SessionManagement";
import Queries from "../Queries";

const cache = new InMemoryCache();

const graphqlLink = createHttpLink({
  uri: URLS.API_BASE_URL + "graphql",
});

const authLink = createHttpLink({
  uri: URLS.API_BASE_URL + "auth",
});

const headersLink = setContext((_, { headers }) => {
  const session = getSession();
  if (session) {
    return {
      headers: {
        ...headers,
        uid: session.uid,
        "token-type": "Bearer",
        "access-token": session.accessToken,
        client: session.client,
      },
    };
  } else return {};
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  }
};

class ApolloClientObj {
  constructor() {
    this.client = new ApolloClient({
      link: headersLink.concat(graphqlLink),
      uri: URLS.API_BASE_URL + "graphql",
      cache: cache,
      defaultOptions: defaultOptions
    });
    this.client.writeQuery({
      query: Queries.GET_PAGE_NUMBER,
      data: {
        pageNumber: 1,
        route: "/",
      },
    });
  }
}

class ApolloAuthClientObj {
  constructor() {
    this.client = new ApolloClient({
      link: headersLink.concat(authLink),
      uri: URLS.API_BASE_URL + "auth",
      cache: cache,
    });
  }
}

const ApolloAuthClient = new ApolloAuthClientObj();
const ApolloDataClient = new ApolloClientObj();

const clearClientCache = () => {
  ApolloDataClient.client.cache.reset();
  ApolloAuthClient.client.cache.reset();
};

export default ApolloDataClient;
export { clearClientCache, ApolloAuthClient };
