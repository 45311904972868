import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Select, Input, Switch, Button, Form, Row, Col } from "antd";
import { customFieldsTypes } from "../../utils/contants";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import CustomFieldTooltip from "../../components/CustomFieldTooltip";

const CustomFieldBox = styled.div`
  width: 100%;
  .textInput {
    font-size: 14px;
    line-height: 22px;
  }
  .selectOptions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const defaultFieldTypeOptions = [
  { key: customFieldsTypes.checkbox, label: customFieldsTypes.checkbox },
  { key: customFieldsTypes.select, label: customFieldsTypes.select },
  { key: customFieldsTypes.text, label: customFieldsTypes.text },
  { key: customFieldsTypes.dateTime, label: "Date time" },
];

const { Option } = Select;
export default function CustomFieldsComponent(props) {
  const [data, setData] = useState(props.data);

  const customFieldTypeOptions = () => {
    let formFieldTypes = defaultFieldTypeOptions;
    if (props.formFieldTypes && Array.isArray(props.formFieldTypes)) {
      formFieldTypes = props.formFieldTypes;
    }
    return formFieldTypes.map(({ key, label }) => (
      <Option key={key}>{label} </Option>
    ));
  };

  const placeholders = {
    Checkbox: ` ${
      props.isProducer
        ? "This producer meets Media Factory requirements?"
        : `This ${
            !props.isEpisode ? "program" : "episode"
          } meets Media Factory requirements  example custom field lorem ipsum?`
    }`,
    Select: ` ${
      props.isProducer
        ? "What is the target audience of this producer?"
        : `What is the target audiance for your ${
            !props.isEpisode ? "program" : "episode"
          }?`
    }`,
    Text: ` ${
      props.isProducer
        ? "Describe producer's interests and production purpose"
        : `Describe your ${!props.isEpisode ? "program" : "episode"} purpose.`
    }`,
    DateTime: ` ${
      props.isProducer
        ? "When producer will start production?"
        : `When are you filming your ${
            !props.isEpisode ? "program" : "episode"
          }?`
    }`,
  };
  const customFieldTypes = {
    label: "label",
    type: "type",
    staff: "staff",
    required: "required",
    options: "options",
    allowMultiple: "multiple",
    charlimit: "charlimit",
    time: "time",
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const handleChanges = (value, field, id) => {
    props.setData(
      data?.map((customField, index) => {
        if (index === id) {
          return {
            id: customField.id,
            type: field === customFieldTypes.type ? value : customField.type,
            label: field === customFieldTypes.label ? value : customField.label,
            staff: field === customFieldTypes.staff ? value : customField.staff,
            required:
              field === customFieldTypes.required
                ? value
                : customField.required,
            options:
              field === customFieldTypes.options ? value : customField.options,
            allowMultiple:
              field === customFieldTypes.allowMultiple
                ? value
                : customField.allowMultiple,
            charLimit:
              field === customFieldTypes.charlimit
                ? parseInt(value, 10)
                : customField.charLimit,
            time: field === customFieldTypes.time ? value : customField.time,
          };
        } else {
          return {
            id: customField.id,
            type: customField.type,
            label: customField.label,
            staff: customField.staff,
            required: customField.required,
            options: customField.options,
            allowMultiple: customField.allowMultiple,
            charLimit: customField.charLimit,
            time: customField.time,
          };
        }
      })
    );
  };

  const AddNewItem = () => {
    props.setData([
      ...data,
      {
        type: "Checkbox",
        label: "",
        staff: false,
        required: true,
        options: [],
        allowMultiple: false,
        charLimit: 250,
        time: false,
        labelRequired: false,
      },
    ]);
  };

  return (
    <div>
      {data?.map((customField, index) => {
        return (
          <Form layout={"vertical"} key={index}>
            <CustomFieldBox type={customField.type}>
              <Row
                style={{
                  width: "100%",
                  borderLeft: "1px solid #d9d9d9",
                  paddingLeft: "30px",
                }}
              >
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    shape="circle"
                    icon={<MinusOutlined />}
                    onClick={() => {
                      props.deleteCustomField(index);
                    }}
                  />
                </Col>
              </Row>
              <Row
                style={{
                  width: "100%",
                  borderLeft: "1px solid #d9d9d9",
                  paddingLeft: "30px",
                  marginLeft: 0,
                }}
                gutter={[16, 0]}
              >
                <Col xs={24} sm={24} md={6}>
                  <Form.Item label={"Type"}>
                    <Select
                      defaultValue="Checkbox"
                      value={customField.type}
                      onChange={(value) => {
                        handleChanges(value, "type", index);
                      }}
                    >
                      {customFieldTypeOptions()}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <CustomFieldTooltip
                        title={"Label"}
                        toolTipText={customField.id}
                        required={true}
                        preText="ID "
                        tooltips={["Copy ID", "ID Copied"]}
                      />
                    }
                  >
                    <Input
                      placeholder={placeholders[customField.type]}
                      value={customField.label}
                      onChange={(e) => {
                        handleChanges(e.target.value, "label", index);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={6} md={4}>
                  <Form.Item label={"Staff"}>
                    <Switch
                      size={"small"}
                      defaultChecked={customField.staff}
                      onClick={(value) => {
                        handleChanges(value, "staff", index);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={6} md={4}>
                  <Form.Item label={"Required"}>
                    <Switch
                      size={"small"}
                      defaultChecked={customField.required}
                      onClick={(value) => {
                        handleChanges(value, "required", index);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  width: "100%",
                  borderLeft: "1px solid #d9d9d9",
                  paddingLeft: "38px",
                }}
              >
                {customField.type === "Select" && (
                  <Row style={{width: '100%'}}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label={"Options"} style={{ paddingRight: 15 }}>
                        <Select
                          mode="tags"
                          size={"large"}
                          placeholder="Options"
                          value={customField.options}
                          onChange={(value) => {
                            handleChanges(
                              value.map((v) => v.toLowerCase()),
                              "options",
                              index
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label={"Allow Multiple"}>
                        <Switch
                          size={"small"}
                          defaultChecked={customField.allowMultiple}
                          onClick={(value) => {
                            handleChanges(value, "multiple", index);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {customField.type === "Text" && (
                  <Form.Item label={"Char Limit"}>
                    <Input
                      type="number"
                      placeholder={250}
                      value={customField.char_limit}
                      onChange={(e) => {
                        handleChanges(e.target.value, "char_limit", index);
                      }}
                    />
                  </Form.Item>
                )}

                {customField.type === "DateTime" && (
                  <Form.Item label={"Include time"}>
                    <Switch
                      size={"small"}
                      defaultChecked={customField.time}
                      onClick={(value) => {
                        handleChanges(value, "time", index);
                      }}
                    />
                  </Form.Item>
                )}
              </Row>
            </CustomFieldBox>
          </Form>
        );
      })}
      <Button
        type="dashed"
        onClick={AddNewItem}
        style={{
          width: "100%",
          marginTop: 18,
        }}
        icon={<PlusOutlined />}
      >
        Add fields
      </Button>
    </div>
  );
}
