import React, { Component } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Map, TileLayer, Marker } from "react-leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

class MapPicker extends Component {
    constructor(props) {
        super(props);
        let lat = 0;
        let lng = 0;
        if (this.props.lat && this.props.lng) {
            lat = this.props.lat;
            lng = this.props.lng;
        }
        let zoom = this.props.zoom;
        this.state = {
            zoom: zoom,
            currentPos: {lat: lat, lng: lng},
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.lat !== prevState.lat) {
            return ({currentPos: {lat: nextProps.lat, lng: nextProps.lng}});
        } else return null;
    }

    addMarker = e => {
        this.setState({currentPos: e.latlng});
        this.props.onLatLngPick(e.latlng.lat, e.latlng.lng);
    };

    render() {
        return (
            <Map
                center={
                    this.state.currentPos.lat === 0 || this.state.currentPos.lng === 0
                        ? {
                            lat: 39.8283,
                            lng: -85.5795
                        }
                        : this.state.currentPos
                }
                onClick={this.addMarker}
                zoom={this.state.zoom}
                onViewportChanged={e => this.setState({zoom: e.zoom})}
                scrollWheelZoom={true}
                zoomControl={true}
                dragging={true}
                style={{height: this.props.height, marginBottom: "1rem", zIndex: "1"}}
            >
                <TileLayer
                    attribution="Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL."
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {this.state.currentPos &&
                this.state.currentPos.lat !== 0 &&
                this.state.currentPos.lng !== 0 &&
                <Marker position={this.state.currentPos}/>
                }
            </Map>
        );
    }
}

export default MapPicker;
