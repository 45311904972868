import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Queries from "../../../api/Queries";
import Mutations from "../../../api/Mutations";
import { SEARCH_DELAY } from "../../../utils/contants";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";
import { useMutation, useQuery } from "@apollo/client";
import UploadMultiple from "./../../../components/UploadMultiple";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Layout, Form, Input, Select, Button, Spin, message } from "antd";
import {
  checkForDeleteProducers,
  trimErrorMessage,
  debounce,
} from "./../../../utils";

const { TextArea } = Input;
const { Content } = Layout;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1142px) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  .upload {
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      width: 10rem;
      height: 10rem;
    }
  }
  @media screen and (max-width: 1140px) {
    width: 100%;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem;
  .ant-divider-vertical {
    height: auto;
  }
  @media screen and (max-width: 1142px) {
    flex-direction: column;
  }
`;

const FormFooter = (props) => {
  const history = useHistory();

  return (
    <Footer>
      <Button
        onClick={() => {
          history.goBack();
        }}
        shape="round"
        size={"large"}
      >
        Cancel
      </Button>
      <Button
        type={"primary"}
        onClick={() => props.form.submit()}
        shape="round"
        icon={props.loading ? <LoadingOutlined /> : <SaveOutlined />}
        size={"large"}
        disabled={props.disabled}
      >
        {props.id !== "new" ? "Save group" : "Create group"}
      </Button>
    </Footer>
  );
};

export default function NewGroup(props) {
  const id = props.match.params.id;
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [tags, setTags] = useState([]);
  const [group, setGroup] = useState({});
  const [members, setMembers] = useState([]);
  const [thumbnail, setThumbnail] = useState("");
  const [description, setDescription] = useState("");
  const [producersList, setProducersList] = useState([]);
  const [queryProducer, setQueryProducer] = useState();
  const { Option } = Select;

  const {
    loading: groupLoading,
    error: groupError,
    data: groupData,
  } = useQuery(Queries.GROUP_BY_ID, {
    skip: id === "new",
    variables: { id: id },
  });

  const {
    loading: producersLoading,
    error: producersError,
    data: producersData,
  } = useQuery(Queries.PRODUCERS_FOR_DROPDOWN, {
    variables: {
      producerQuery: queryProducer,
    },
    onError: (err) => message.error(trimErrorMessage(err.message)),
  });

  const [
    createGroup,
    { loading: createLoading, error: createError, data: createData },
  ] = useMutation(Mutations.CREATE_GROUP);

  const [
    updateGroup,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(Mutations.UPDATE_GROUP);

  useEffect(() => {
    if (producersData && groupData) {
      const _producers = producersData?.producers?.nodes || [];
      let missingMembers = checkForDeleteProducers(
        _producers,
        groupData.groupById.members
      );
      setProducersList([...missingMembers, ..._producers]);
    } else if (producersData) {
      setProducersList(producersData?.producers?.nodes || []);
    }
  }, [producersData, groupData]);

  useEffect(() => {
    if (id !== "new" && groupData) {
      const group = groupData.groupById;

      setGroup(group);
      setName(group.name);
      setDescription(group.description);
      setThumbnail(group.image);
      setTags(group.groupTags?.map((t) => t.title));
      setMembers(group.members?.map((m) => m.id));

      form.setFieldsValue({
        name: group.name,
        description: group.description,
        members: group.members,
      });
    }

    if (producersError) message.error(producersError.message);
    if (groupError) message.error(groupError.message);

    // Handle create
    if (createData) {
      props.history.goBack();
      message.success(createData.createGroup.name + " created");
    }
    if (createError) message.error(createError.message);

    if (updateData) {
      message.success(updateData.updateGroup.name + " updated");
      props.history.goBack();
    }
    if (updateError) message.error(updateError.message);
  }, [
    id,
    producersError,
    groupData,
    groupError,
    updateData,
    updateError,
    createData,
    createError,
    form,
    props.history,
  ]);

  const handleSubmitClick = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    if (id === "new") {
      createGroup({
        variables: {
          name: name,
          description: description,
          members: members,
          groupTags: tags,
          image: thumbnail,
        },
      });
    } else {
      updateGroup({
        variables: {
          id: id,
          name: name,
          description: description,
          members: members,
          groupTags: tags,
          image: thumbnail,
        },
      });
    }
  };

  const onProducerSearch = (str) => setQueryProducer(str);

  const debounceOnProducerQuery = useCallback(
    debounce(onProducerSearch, SEARCH_DELAY),
    []
  );

  const NewGroupForm = () => (
    <HorizontalContainer>
      <LeftContainer>
        <Form.Item
          label="Group name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your group name",
            },
          ]}
          style={{ width: "80%" }}
        >
          <Input onChange={(e) => setName(e.target.value)} type={"text"} />
        </Form.Item>
        <Form.Item
          label="Group description"
          name="description"
          style={{ width: "80%" }}
        >
          <TextArea
            placeholder="Short description of the group"
            autoSize={{ minRows: 7, maxRows: 10 }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Members"
          style={{ width: "80%" }}
          rules={[
            {
              required: true,
              message: "This field is required.",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            value={members}
            mode="multiple"
            showArrow={true}
            filterOption={true}
            placeholder="Add members"
            loading={producersLoading}
            optionFilterProp={"filter"}
            defaultActiveFirstOption={false}
            onChange={(value) => setMembers(value)}
            onSearch={(query) => {
              if (query.trim() === "" && query.length === 0) return;
              debounceOnProducerQuery(query);
            }}
          >
            {producersList.map((item) => (
              <Option key={item.id} value={item.id} filter={item.name}>
                {item.name} {item.username ? `(${item.username})` : null}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Allowed tags"
          style={{ width: "80%" }}
          rules={[
            {
              required: true,
              message: "This field is required.",
            },
          ]}
        >
          <Select
            mode="tags"
            placeholder="Add tags"
            value={tags}
            onChange={(value) => setTags(value)}
          />
        </Form.Item>
      </LeftContainer>
      <RightContainer>
        <Form.Item label="Group image">
          <UploadMultiple
            dir={"users/producers/groups"}
            images={thumbnail}
            setImages={(img) => setThumbnail(img.toString())}
            allowImages={1}
            squareCrop={false}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          />
        </Form.Item>
      </RightContainer>
    </HorizontalContainer>
  );

  return (
    <Layout>
      <div style={{ padding: 24, background: "#fff" }}>
        <Spin spinning={id !== "new" && groupLoading}>
          <Content>
            <Form.Provider onFormFinish={() => handleSubmitClick()}>
              <Form form={form} layout={"vertical"} scrollToFirstError>
                {NewGroupForm()}
              </Form>
            </Form.Provider>
          </Content>
          <FormFooter
            id={id}
            form={form}
            loading={createLoading || updateLoading}
            disabled={
              name === group.name &&
              description === group.description &&
              thumbnail === group.image &&
              JSON.stringify(members) ===
                JSON.stringify(group.members?.map((m) => m.id)) &&
              JSON.stringify(tags) ===
                JSON.stringify(group.groupTags?.map((t) => t.title))
            }
          />
        </Spin>
      </div>
    </Layout>
  );
}
