import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import { isValidEmail } from "./../../utils";

function ChangeEmailModal(props) {
    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState("");

    return (
        <>
            <Button
                type="link"
                onClick={() => setVisible(true)}
                style={{padding: 0}}
            >
                Change email address
            </Button>
            <Modal
                title={"Change Email"}
                visible={visible}
                destroyOnClose={true}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setVisible(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="send"
                        type="primary"
                        disabled={!isValidEmail(email)}
                        onClick={() => {
                            props.handleEmailChange(email);
                            setVisible(false);
                            setEmail("");
                        }}
                    >
                        Send Confirmation to Email
                    </Button>,
                ]}
            >
                <p>New email address</p>
                <Input
                    type="text"
                    placeholder="Your new email address"
                    onChange={(e) => setEmail(e.target.value)}
                ></Input>
            </Modal>
        </>
    );
}

export default ChangeEmailModal;
