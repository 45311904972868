import React, { useEffect } from "react";
import { message } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { ApolloProvider, useMutation } from "@apollo/client";
import { deleteSession } from "../helpers/SessionManagement";
import { Mutations, ApolloAuthClient, clearClientCache } from "../api";
import { trimErrorMessage } from "../utils";

const LogoutProvider = (props) => {
  return (
    <ApolloProvider client={ApolloAuthClient.client}>
      <LogoutInner {...props} />
    </ApolloProvider>
  );
};

const LogoutInner = (props) => {
  const { setAuth } = props;
  const [userLogout, { error, data }] = useMutation(Mutations.LOGOUT, {});

  useEffect(() => {
    if (data) {
      message.success(
        `See you next time ${data.userLogout.authenticatable.name}`
      );
      const session = deleteSession();
      clearClientCache();
      session && setAuth(false);
    }
    if (error) {
      const session = deleteSession();
      clearClientCache();
      session && setAuth(false);
      message.error(error.message);
    }
  }, [setAuth, data, error]);

  return (
    <div
      key="logout"
      onClick={() => {
        userLogout().catch((err) => {
          setAuth(false);
          message.error(trimErrorMessage(err.message));
        });
      }}
    >
      <LogoutOutlined />
      <span>Logout</span>
    </div>
  );
};

export default LogoutProvider;
