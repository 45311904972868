import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      credentials {
        uid
        client
        expiry
        accessToken
      }
      authenticatable {
        id
        name
        email
        userType
        avatar
        organization {
          id
          title
        }
      }
    }
  }
`;
const LOGOUT = gql`
  mutation {
    userLogout {
      authenticatable {
        name
      }
    }
  }
`;

const CREATE_PODCAST = gql`
  mutation createPodcast(
    $name: String!
    $rssFeed: String!
    $published: Boolean!
  ) {
    createPodcast(name: $name, rssFeed: $rssFeed, published: $published) {
      id
      name
      title
      rssFeed
      published
      image
      podcastEpisode {
        description
        id
        audioUri
        publishedAt
        title
      }
    }
  }
`;

const DELETE_PODCAST = gql`
  mutation deletePodcast($id: String!) {
    deletePodcast(id: $id) {
      id
      name
      title
      rssFeed
      image
      podcastEpisode {
        description
        id
        audioUri
        publishedAt
        title
      }
    }
  }
`;

const UPDATE_PODCAST = gql`
  mutation updatePodcast(
    $name: String!
    $rssFeed: String!
    $id: String!
    $published: Boolean
  ) {
    updatePodcast(
      name: $name
      rssFeed: $rssFeed
      id: $id
      published: $published
    ) {
      id
      name
      title
      rssFeed
      published
      image
      published
      podcastEpisode {
        description
        id
        audioUri
        publishedAt
        title
      }
    }
  }
`;

const CREATE_PODCAST_WIDGET = gql`
  mutation createPodcastWidget(
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $podcasts: [String!]!
  ) {
    createPodcastWidget(
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      podcasts: $podcasts
    ) {
      id
      name
      primaryColor
      secondaryColor
      podcast {
        id
        name
        image
      }
    }
  }
`;

const UPDATE_PODCAST_WIDGET = gql`
  mutation updatePodcastWidget(
    $id: String!
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $podcasts: [String!]!
  ) {
    updatePodcastWidget(
      id: $id
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      podcasts: $podcasts
    ) {
      id
      name
      primaryColor
      secondaryColor
      podcast {
        id
        name
        image
      }
    }
  }
`;

const CREATE_SCHEDULE_WIDGET = gql`
  mutation createScheduleWidget(
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $liveVideos: [String!]!
    $search: Boolean!
  ) {
    createScheduleWidget(
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      liveVideos: $liveVideos
      search: $search
    ) {
      id
      name
      primaryColor
      secondaryColor
      search
      liveVideos {
        id
        name
        image
        description
        playerType
        scheduleRss
        streamUrl
        stations
        provider
        channelId
        published
      }
    }
  }
`;

const UPDATE_SCHEDULE_WIDGET = gql`
  mutation updateScheduleWidget(
    $name: String!
    $id: String!
    $primaryColor: String!
    $secondaryColor: String!
    $liveVideos: [String!]!
    $search: Boolean!
  ) {
    updateScheduleWidget(
      name: $name
      id: $id
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      liveVideos: $liveVideos
      search: $search
    ) {
      id
      name
      primaryColor
      secondaryColor
      search
      liveVideos {
        id
        name
        image
        description
        playerType
        scheduleRss
        streamUrl
        stations
        provider
        channelId
        published
      }
    }
  }
`;

const CREATE_RADIO = gql`
  mutation createRadio(
    $name: String!
    $streamUrl: String!
    $image: String!
    $description: String!
    $station: String!
    $published: Boolean!
  ) {
    createRadio(
      name: $name
      streamUrl: $streamUrl
      image: $image
      description: $description
      station: $station
      published: $published
    ) {
      name
      streamUrl
      id
      image
      description
      station
      published
    }
  }
`;

const CREATE_RADIO_WIDGET = gql`
  mutation createRadioWidget(
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $radios: [String!]!
  ) {
    createRadioWidget(
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      radios: $radios
    ) {
      id
      name
      primaryColor
      secondaryColor
      radio {
        name
        streamUrl
        id
        image
        description
        station
      }
    }
  }
`;

const DELETE_RADIO = gql`
  mutation deleteRadio($id: String!) {
    deleteRadio(id: $id) {
      id
      station
      image
      streamUrl
      name
      description
    }
  }
`;

const UPDATE_RADIO = gql`
  mutation updateRadio(
    $name: String!
    $streamUrl: String!
    $image: String!
    $description: String!
    $station: String!
    $id: String!
    $published: Boolean
  ) {
    updateRadio(
      name: $name
      streamUrl: $streamUrl
      image: $image
      description: $description
      station: $station
      id: $id
      published: $published
    ) {
      name
      streamUrl
      id
      image
      description
      station
      published
    }
  }
`;

const UPDATE_RADIO_WIDGET = gql`
  mutation updateRadioWidget(
    $id: String!
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $radios: [String!]!
  ) {
    updateRadioWidget(
      id: $id
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      radios: $radios
    ) {
      id
      name
      primaryColor
      secondaryColor
      radio {
        name
        streamUrl
        id
        image
        description
        station
      }
    }
  }
`;

const DELETE_LIVE_VIDEO = gql`
  mutation deleteLiveVideo($id: String!) {
    deleteLiveVideo(id: $id) {
      id
      description
      image
      name
      streamUrl
    }
  }
`;

const CREATE_LIVE_VIDEO = gql`
  mutation createLiveVideo(
    $name: String!
    $image: String!
    $stations: String!
    $scheduleRss: String!
    $playerType: String!
    $description: String!
    $streamUrl: String!
    $provider: String
    $channelId: String
    $published: Boolean!
  ) {
    createLiveVideo(
      name: $name
      image: $image
      stations: $stations
      scheduleRss: $scheduleRss
      streamUrl: $streamUrl
      description: $description
      playerType: $playerType
      provider: $provider
      channelId: $channelId
      published: $published
    ) {
      id
      name
      image
      description
      playerType
      scheduleRss
      streamUrl
      stations
      provider
      channelId
      published
    }
  }
`;

const UPDATE_LIVE_VIDEO = gql`
  mutation updateLiveVideo(
    $name: String!
    $image: String!
    $stations: String!
    $scheduleRss: String!
    $playerType: String!
    $description: String!
    $streamUrl: String!
    $id: String!
    $provider: String
    $channelId: String
    $published: Boolean
  ) {
    updateLiveVideo(
      name: $name
      image: $image
      stations: $stations
      scheduleRss: $scheduleRss
      streamUrl: $streamUrl
      description: $description
      playerType: $playerType
      id: $id
      provider: $provider
      channelId: $channelId
      published: $published
    ) {
      id
      name
      image
      description
      playerType
      scheduleRss
      stations
      streamUrl
      provider
      channelId
      published
    }
  }
`;

const UPDATE_LIVE_VIDEO_WIDGET = gql`
  mutation updateLiveVideoWidget(
    $id: String!
    $primaryColor: String!
    $secondaryColor: String!
    $liveVideos: [String!]!
    $name: String!
    $layout: LiveVideoWidgetLayout!
  ) {
    updateLiveVideoWidget(
      id: $id
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      name: $name
      layout: $layout
      liveVideos: $liveVideos
    ) {
      id
      name
      primaryColor
      secondaryColor
      layout
      liveVideo {
        id
        description
        image
        name
        playerType
        scheduleRss
        stations
        streamUrl
      }
    }
  }
`;
const UPDATE_ORGANIZATION = gql`
  mutation updateOrganizatoin(
    $primaryColor: String
    $secondaryColor: String
    $title: String
    $address: String
    $city: String
    $state: String
    $latitude: String
    $longitude: String
    $website: String
    $image: String
    $squareImage: String
    $description: String
    $zip: String
    $phone: String
    $timezone: String
  ) {
    updateOrganizatoin(
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      title: $title
      latitude: $latitude
      longitude: $longitude
      website: $website
      image: $image
      squareImage: $squareImage
      description: $description
      zip: $zip
      city: $city
      address: $address
      state: $state
      phone: $phone
      timezone: $timezone
    ) {
      address
      title
      id
      city
      description
      image
      squareImage
      latitude
      primaryColor
      secondaryColor
      latitude
      longitude
      state
      title
      website
      zip
      phone
      timezone
    }
  }
`;
const CREATE_CABLECAST_INTEGRATION = gql`
  mutation createCablecastIntegration(
    $userName: String!
    $password: String!
    $baseUrl: String
    $autoSync: Boolean
  ) {
    createCablecastIntegration(
      username: $userName
      password: $password
      baseUrl: $baseUrl
      autoSync: $autoSync
    ) {
      password
      username
      baseUrl
      autoSync
    }
  }
`;
const UPDATE_NEON_INTEGRATION = gql`
  mutation updateNeonIntegration(
    $neonApiKey: String!
    $neonOrgId: String!
    $autoSyncNeonCrm: Boolean
  ) {
    updateOrganizatoin(
      neonApiKey: $neonApiKey
      neonOrgId: $neonOrgId
      autoSyncNeonCrm: $autoSyncNeonCrm
    ) {
      neonApiKey
      neonOrgId
      autoSyncNeonCrm
    }
  }
`;
const UPDATE_TELVUE_INTEGRATION = gql`
  mutation updateTelvueIntegration(
    $apiKey: String!
    $apiSecret: String!
    $hasConnect: Boolean!
    $autoSyncHypercast: Boolean
    $autoSyncConnect: Boolean
    $telvueHypercastBaseUrl: String!
    $telvueHypercastApiKey: String!
  ) {
    updateTelvueIntegration(
      apiKey: $apiKey
      apiSecret: $apiSecret
      hasConnect: $hasConnect
      autoSyncHypercast: $autoSyncHypercast
      autoSyncConnect: $autoSyncConnect
      telvueHypercastBaseUrl: $telvueHypercastBaseUrl
      telvueHypercastApiKey: $telvueHypercastApiKey
    ) {
      apiKey
      apiSecret
      hasConnect
      autoSyncHypercast
      autoSyncConnect
      telvueHypercastBaseUrl
      telvueHypercastApiKey
    }
  }
`;
const CREATE_TELVUE_INTEGRATION = gql`
  mutation createTelvueIntegration(
    $apiKey: String!
    $apiSecret: String!
    $hasConnect: Boolean!
    $autoSyncHypercast: Boolean
    $autoSyncConnect: Boolean
    $telvueHypercastBaseUrl: String!
    $telvueHypercastApiKey: String!
  ) {
    createTelvueIntegration(
      apiKey: $apiKey
      apiSecret: $apiSecret
      hasConnect: $hasConnect
      autoSyncHypercast: $autoSyncHypercast
      autoSyncConnect: $autoSyncConnect
      telvueHypercastBaseUrl: $telvueHypercastBaseUrl
      telvueHypercastApiKey: $telvueHypercastApiKey
    ) {
      apiKey
      apiSecret
      hasConnect
      autoSyncHypercast
      autoSyncConnect
      telvueHypercastBaseUrl
      telvueHypercastApiKey
    }
  }
`;

const UPDATE_CABLECAST_INTEGRATION = gql`
  mutation updateCablecastIntegration(
    $userName: String!
    $password: String!
    $baseUrl: String
    $autoSync: Boolean
  ) {
    updateCablecastIntegration(
      username: $userName
      password: $password
      baseUrl: $baseUrl
      autoSync: $autoSync
    ) {
      password
      username
      baseUrl
      autoSync
    }
  }
`;

const CREATE_LIVE_VIDEO_WIDGET = gql`
  mutation createLiveVideoWidget(
    $primaryColor: String!
    $secondaryColor: String!
    $liveVideos: [String!]!
    $name: String!
    $layout: LiveVideoWidgetLayout!
  ) {
    createLiveVideoWidget(
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      name: $name
      layout: $layout
      liveVideos: $liveVideos
    ) {
      id
      name
      primaryColor
      secondaryColor
      layout
      liveVideo {
        id
        description
        image
        name
        playerType
        scheduleRss
        stations
        streamUrl
      }
    }
  }
`;

const REFRESH_LIVE_VIDEO = gql`
  mutation refreshLiveVideo($id: ID!) {
    refreshLiveVideo(id: $id) {
      jobStatus
    }
  }
`;

const REFRESH_PODCAST = gql`
  mutation refreshPodcast($id: ID!) {
    refreshPodcast(id: $id) {
      jobStatus
    }
  }
`;

const SEND_INVITE_FOR_STAFF_MEMBER = gql`
  mutation sendInviteForStaffMember(
    $firstname: String!
    $lastname: String!
    $email: String!
    $userType: String!
    $redirectUrl: String!
  ) {
    sendInviteForStaffMember(
      firstname: $firstname
      lastname: $lastname
      email: $email
      userType: $userType
      redirectUrl: $redirectUrl
    ) {
      id
      email
      firstname
      lastname
      email
      status
      expiry
      organization {
        id
      }
      userType
    }
  }
`;
const UPDATE_USER_PASSWORD = gql`
  mutation userUpdatePassword(
    $currentPassword: String
    $password: String!
    $passwordConfirmation: String!
  ) {
    userUpdatePassword(
      password: $password
      currentPassword: $currentPassword
      passwordConfirmation: $passwordConfirmation
    ) {
      username
      userType
      name
    }
  }
`;
const SEND_PASSSWORD_RESET = gql`
  mutation userSendPasswordReset($email: String!, $redirectUrl: String!) {
    userSendPasswordReset(email: $email, redirectUrl: $redirectUrl) {
      message
    }
  }
`;

const CREATE_EPISODE_SETTINGS = gql`
  mutation createEpisodeSettings(
    $askEpisodeSponsor: Boolean!
    $episodeCustomFields: [CustomFieldInput!]
    $episodeOriginOptions: [String!]
    $masterControlIntegration: MasterControlIntegration!
    $cableCastLocationId: String
    $topics: [String!]
  ) {
    createEpisodeSettings(
      askEpisodeSponsor: $askEpisodeSponsor
      episodeCustomFields: $episodeCustomFields
      episodeOriginOptions: $episodeOriginOptions
      masterControlIntegration: $masterControlIntegration
      cableCastLocationId: $cableCastLocationId
      topics: $topics
    ) {
      askEpisodeSponsor
      masterControlIntegration
      cableCastLocationId
      episodeCustomFields {
        id
        label
        type
        charLimit
        options
        staff
        time
        allowMultiple
        required
      }
      episodeOriginOptions {
        title
      }
      topics {
        id
        title
      }
      organization {
        id
        title
      }
    }
  }
`;

const CREATE_PRODUCER_SETTINGS = gql`
  mutation createProducerSettings(
    $customFields: [CustomFieldInput!]
    $redirectUrl: String
  ) {
    createProducerSettings(
      customFields: $customFields
      redirectUrl: $redirectUrl
    ) {
      redirectUrl
      customFields {
        id
        label
        type
        charLimit
        options
        staff
        time
        allowMultiple
        required
      }
    }
  }
`;

const UPDATE_PRODUCER_SETTINGS = gql`
  mutation updateProducerSettings(
    $id: ID!
    $customFields: [CustomFieldInput!]
    $redirectUrl: String
  ) {
    updateProducerSettings(
      id: $id
      customFields: $customFields
      redirectUrl: $redirectUrl
    ) {
      redirectUrl
      customFields {
        id
        label
        type
        charLimit
        options
        staff
        time
        allowMultiple
        required
      }
    }
  }
`;

const DELETE_INVITE = gql`
  mutation deleteInvite($id: ID!) {
    deleteInvite(id: $id) {
      id
      firstname
      lastname
      email
      status
      expiry
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(userId: $id) {
      id
      name
      email
      username
    }
  }
`;
const DELETE_USERS = gql`
  mutation deleteUsers($ids: [ID!]!) {
    deleteUsers(ids: $ids) {
      id
      name
      email
      username
    }
  }
`;

const RESEND_INVITE = gql`
  mutation resendInvite($inviteId: ID!) {
    resendInvite(inviteId: $inviteId) {
      id
      firstname
      lastname
      expiry
      email
      status
    }
  }
`;

const RESEND_INVITES = gql`
  mutation resendInvites($inviteIds: [ID!]!) {
    resendInvites(inviteIds: $inviteIds) {
      id
      firstname
      lastname
      expiry
      email
      status
      userType
      organization {
        id
        title
      }
    }
  }
`;
const UPDATE_USER = gql`
  mutation updateUser(
    $name: String
    $bio: String
    $state: String
    $city: String
    $avatar: String
    $phone: String
    $firstname: String
    $lastname: String
    $zipcode: String
    $userType: String
    $address: String
    $username: String
    $socialMediaLinks: [SocialMediaCreateInput!]
    $customFieldValue: [CustomFieldValueInput!]
  ) {
    updateUser(
      name: $name
      bio: $bio
      state: $state
      city: $city
      firstname: $firstname
      lastname: $lastname
      phone: $phone
      avatar: $avatar
      zipcode: $zipcode
      userType: $userType
      address: $address
      username: $username
      socialMediaLinks: $socialMediaLinks
      customFieldValue: $customFieldValue
    ) {
      id
      email
      name
      bio
      state
      city
      phone
      avatar
      zipcode
      userType
      firstname
      lastname
      address
      username
      socialMediaLinks {
        id
        url
        mediaType
      }
      customFieldValue {
        id
        value
      }
      organization {
        title
        id
      }
    }
  }
`;
const UPDATE_USER_INFORMATION = gql`
  mutation updateUserInformation(
    $userId: String!
    $name: String
    $bio: String
    $state: String
    $city: String
    $avatar: String
    $phone: String
    $firstname: String
    $lastname: String
    $zipcode: String
    $userType: String
    $address: String
    $socialMediaLinks: [SocialMediaCreateInput!]
    $customFieldValue: [CustomFieldValueInput!]
  ) {
    updateUserInformation(
      userId: $userId
      name: $name
      bio: $bio
      state: $state
      city: $city
      firstname: $firstname
      lastname: $lastname
      phone: $phone
      avatar: $avatar
      zipcode: $zipcode
      userType: $userType
      address: $address
      socialMediaLinks: $socialMediaLinks
      customFieldValue: $customFieldValue
    ) {
      id
      email
      name
      bio
      state
      city
      phone
      avatar
      zipcode
      userType
      firstname
      lastname
      address
      socialMediaLinks {
        id
        url
        mediaType
      }
      customFieldValue {
        id
        value
      }
      organization {
        title
        id
      }
    }
  }
`;
const UPDATE_EPISODE_SETTINGS = gql`
  mutation updateEpisodeSettings(
    $id: ID!
    $askEpisodeSponsor: Boolean
    $episodeCustomFields: [CustomFieldInput!]
    $episodeOriginOptions: [String!]
    $masterControlIntegration: MasterControlIntegration
    $cableCastLocationId: String
    $topics: [String!]
  ) {
    updateEpisodeSettings(
      askEpisodeSponsor: $askEpisodeSponsor
      episodeCustomFields: $episodeCustomFields
      episodeOriginOptions: $episodeOriginOptions
      masterControlIntegration: $masterControlIntegration
      cableCastLocationId: $cableCastLocationId
      topics: $topics
      id: $id
    ) {
      askEpisodeSponsor
      cableCastLocationId
      episodeCustomFields {
        allowMultiple
        charLimit
        id
        label
        options
        required
        staff
        time
        type
      }
      episodeOriginOptions {
        id
        title
      }
      id
    }
  }
`;

const UPDATE_EPISODE_STATUS = gql`
  mutation updateEpisodesStatus($status: EpisodeStatus!, $ids: [ID!]!) {
    updateEpisodesStatus(ids: $ids, status: $status) {
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }

      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
    }
  }
`;

const CHANGE_EPISODES_PROGRAM = gql`
  mutation changeEpisodesProgram($programId: ID!, $ids: [ID!]!) {
    changeEpisodesProgram(ids: $ids, programId: $programId) {
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }

      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
    }
  }
`;

const ADD_EPISODES_TOPICS = gql`
  mutation addEpisodesTopics($topics: [ID!]!, $ids: [ID!]!) {
    addEpisodesTopics(ids: $ids, topics: $topics) {
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }

      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
    }
  }
`;

const ADD_EPISODES_TAGS = gql`
  mutation addEpisodesTags($tags: [String!]!, $ids: [ID!]!) {
    addEpisodesTags(ids: $ids, tags: $tags) {
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }

      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
    }
  }
`;

const UPDATE_EPISODE_PUBLISHED_STATUS = gql`
  mutation updateEpisodesPublishedStatus($published: Boolean!, $ids: [ID!]!) {
    updateEpisodesPublishedStatus(published: $published, ids: $ids) {
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }

      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
    }
  }
`;

const CREATE_USER = gql`
  mutation addNewUser(
    $email: String!
    $name: String!
    $bio: String
    $state: String
    $city: String
    $avatar: String
    $phone: String
    $zipcode: String
    $userType: String!
    $address: String
    $password: String!
    $username: String
    $firstname: String!
    $lastname: String!
    $socialMediaLinks: [SocialMediaCreateInput!]
    $customFieldValue: [CustomFieldValueInput!]
    $confirmSuccessUrl: String
    $passwordConfirmation: String!
  ) {
    addNewUser(
      email: $email
      name: $name
      bio: $bio
      state: $state
      city: $city
      phone: $phone
      avatar: $avatar
      zipcode: $zipcode
      userType: $userType
      address: $address
      password: $password
      username: $username
      firstname: $firstname
      lastname: $lastname
      socialMediaLinks: $socialMediaLinks
      customFieldValue: $customFieldValue
      confirmSuccessUrl: $confirmSuccessUrl
      passwordConfirmation: $passwordConfirmation
    ) {
      id
      userType
      organization {
        id
        title
      }
      name
      address
      firstname
      lastname
      phone
      state
      city
      avatar
      zipcode
      username
      email
    }
  }
`;
const USER_SIGN_UP = gql`
  mutation signUp(
    $email: String!
    $name: String!
    $bio: String
    $state: String
    $city: String
    $avatar: String
    $phone: String
    $zipcode: String
    $userType: String!
    $address: String
    $password: String!
    $username: String!
    $firstname: String!
    $lastname: String!
    $socialMediaLinks: [SocialMediaCreateInput!]
    $customFieldValue: [CustomFieldValueInput!]
    $confirmSuccessUrl: String
    $passwordConfirmation: String!
    $token: String!
  ) {
    userSignUp(
      email: $email
      name: $name
      bio: $bio
      state: $state
      city: $city
      phone: $phone
      avatar: $avatar
      zipcode: $zipcode
      userType: $userType
      address: $address
      password: $password
      username: $username
      firstname: $firstname
      lastname: $lastname
      customFieldValue: $customFieldValue
      socialMediaLinks: $socialMediaLinks
      confirmSuccessUrl: $confirmSuccessUrl
      passwordConfirmation: $passwordConfirmation
      token: $token
    ) {
      id
      userType
      organization {
        id
        title
      }
      name
      address
      firstname
      lastname
      phone
      state
      city
      avatar
      zipcode
      username
      email
    }
  }
`;
const CREATE_PROGRAM_SETTINGS = gql`
  mutation createProgramSettings(
    $programCustomFields: [CustomFieldInput!]
    $programTypes: [String!]
    $topics: [String!]
  ) {
    createProgramSettings(
      programTypes: $programTypes
      programCustomFields: $programCustomFields
      topics: $topics
    ) {
      id
      programCustomFields {
        id
        required
        type
        charLimit
        staff
        label
        options
        allowMultiple
        time
      }

      topics {
        id
        title
      }
      programTypes {
        id
        text
      }
    }
  }
`;

const UPDATE_PROGRAM_PUBLISH_STATUS = gql`
  mutation updateProgramsPublishedStatus($published: Boolean!, $ids: [ID!]!) {
    updateProgramsPublishedStatus(published: $published, ids: $ids) {
      id
      episodes {
        title
      }
      title
      programType {
        text
        id
      }
      status
      producer {
        id
        name
      }
      updatedAt
      published
      assistantProducers {
        name
        id
      }
      topics {
        id
        title
      }
      customFieldValue {
        id
        value
      }
      published
      image
    }
  }
`;

const UPDATE_PROGRAM_STATUS = gql`
  mutation updateProgramsStatus($status: ProgramStatus!, $ids: [ID!]!) {
    updateProgramsStatus(status: $status, ids: $ids) {
      id
      episodes {
        title
      }
      title
      programType {
        text
        id
      }
      status
      producer {
        id
        name
      }
      updatedAt
      published
      assistantProducers {
        name
        id
      }
      topics {
        id
        title
      }
      customFieldValue {
        id
        value
      }
      published
      image
    }
  }
`;

const UPDATE_PROGRAM_SETTINGS = gql`
  mutation updateProgramSettings(
    $id: ID!
    $programCustomFields: [CustomFieldInput!]
    $programTypes: [String!]
    $topics: [String!]
  ) {
    updateProgramSettings(
      id: $id
      programCustomFields: $programCustomFields
      programTypes: $programTypes
      topics: $topics
    ) {
      id
      programCustomFields {
        id
        label
        type
        charLimit
        staff
        time
        allowMultiple
        required
        options
      }
      topics {
        id
        title
      }
      programTypes {
        id
        text
      }
    }
  }
`;

const DELETE_PROGRAMS = gql`
  mutation deletePrograms($ids: [ID!]!) {
    deletePrograms(ids: $ids) {
      id
      episodes {
        title
      }
      title
      programType {
        text
        id
      }
      status
      producer {
        id
        name
      }
      updatedAt
      published
      assistantProducers {
        name
        id
      }
      topics {
        id
        title
      }
      customFieldValue {
        id
        value
      }
      published
      image
    }
  }
`;

const CREATE_EPISODE = gql`
  mutation createEpisode(
    $title: String!
    $sponsored: Boolean!
    $episodeNumber: String
    $programId: String
    $dateOfProduction: ISO8601DateTime!
    $shortDescription: String!
    $fullDescription: String!
    $videoUrl: String
    $thumbnailUrl: String
    $customFieldValue: [CustomFieldValueInput!]
    $status: EpisodeStatus
    $documents: [String!]
    $assistantProducers: [ID!]
    $externalLinks: [ExternalLinkInput!]
    $published: Boolean!
    $topics: [ID!]
    $tags: [String!]
    $episodeOriginOptionId: ID
    $duration: Int
    $streamUrl: String
    $telvueFileName: String
    $pushToCablecastOrTelvue: Boolean
    $pushToYoutube: Boolean
    $telvueIngestDateTime: ISO8601DateTime
    $telvueDeleteAt: ISO8601DateTime
    $telvueProgramCode: String
    $cablecastProgramPelantionMedia: String
    $executiveProducerId: String!
    $notesToProducer: String
  ) {
    createEpisode(
      title: $title
      sponsored: $sponsored
      episodeNumber: $episodeNumber
      programId: $programId
      dateOfProduction: $dateOfProduction
      shortDescription: $shortDescription
      fullDescription: $fullDescription
      videoUrl: $videoUrl
      thumbnailUrl: $thumbnailUrl
      customFieldValue: $customFieldValue
      status: $status
      documents: $documents
      assistantProducers: $assistantProducers
      externalLinks: $externalLinks
      published: $published
      topics: $topics
      streamUrl: $streamUrl
      tags: $tags
      episodeOriginOptionId: $episodeOriginOptionId
      duration: $duration
      telvueFileName: $telvueFileName
      telvueIngestDateTime: $telvueIngestDateTime
      telvueDeleteAt: $telvueDeleteAt
      telvueProgramCode: $telvueProgramCode
      pushToCablecastOrTelvue: $pushToCablecastOrTelvue
      pushToYoutube: $pushToYoutube
      cablecastProgramPelantionMedia: $cablecastProgramPelantionMedia
      executiveProducerId: $executiveProducerId
      notesToProducer: $notesToProducer
    ) {
      program {
        title
        id
      }
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }
      streamUrl
      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
    }
  }
`;

const UPDATE_EPISODE = gql`
  mutation (
    $id: ID!
    $title: String!
    $sponsored: Boolean!
    $episodeNumber: String
    $programId: String
    $dateOfProduction: ISO8601DateTime!
    $shortDescription: String!
    $fullDescription: String!
    $videoUrl: String
    $thumbnailUrl: String
    $customFieldValue: [CustomFieldValueInput!]
    $status: EpisodeStatus
    $documents: [String!]
    $assistantProducers: [ID!]
    $externalLinks: [ExternalLinkInput!]
    $published: Boolean!
    $topics: [ID!]
    $streamUrl: String
    $tags: [String!]
    $episodeOriginOptionId: ID
    $duration: Int
    $telvueFileName: String
    $pushToCablecastOrTelvue: Boolean
    $pushToYoutube: Boolean
    $telvueIngestDateTime: ISO8601DateTime
    $telvueDeleteAt: ISO8601DateTime
    $telvueProgramCode: String
    $cablecastProgramPelantionMedia: String
    $executiveProducerId: String!
    $notesToProducer: String
  ) {
    updateEpisode(
      id: $id
      title: $title
      sponsored: $sponsored
      episodeNumber: $episodeNumber
      programId: $programId
      dateOfProduction: $dateOfProduction
      shortDescription: $shortDescription
      fullDescription: $fullDescription
      videoUrl: $videoUrl
      thumbnailUrl: $thumbnailUrl
      customFieldValue: $customFieldValue
      status: $status
      documents: $documents
      assistantProducers: $assistantProducers
      externalLinks: $externalLinks
      published: $published
      topics: $topics
      streamUrl: $streamUrl
      pushToCablecastOrTelvue: $pushToCablecastOrTelvue
      pushToYoutube: $pushToYoutube
      tags: $tags
      episodeOriginOptionId: $episodeOriginOptionId
      duration: $duration
      telvueFileName: $telvueFileName
      telvueIngestDateTime: $telvueIngestDateTime
      telvueDeleteAt: $telvueDeleteAt
      telvueProgramCode: $telvueProgramCode
      cablecastProgramPelantionMedia: $cablecastProgramPelantionMedia
      executiveProducerId: $executiveProducerId
      notesToProducer: $notesToProducer
    ) {
      program {
        title
        id
      }
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }
      streamUrl
      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
    }
  }
`;

const DELETE_EPISODES = gql`
  mutation deleteEpisodes($ids: [ID!]!) {
    deleteEpisodes(ids: $ids) {
      assistantProducers {
        id
        name
        userType
      }
      customFieldValue {
        id
        value
      }
      dateOfProduction
      episodeNumber
      id
      episodeOriginOption {
        title
        id
      }
      topics {
        id
        title
      }
      fullDescription
      tags {
        id
        name
      }
      producer {
        id
        name
      }
      published
      shortDescription
      sponsored
      status
      thumbnailUrl
      title
      videoUrl
    }
  }
`;

const CREATE_PRODUCER_PORTAL_WIDGET = gql`
  mutation createProducerPortalWidget(
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $imageUrl: String!
    $newProducerMessage: String!
  ) {
    createProducerPortalWidget(
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      newProducerMessage: $newProducerMessage
      imageUrl: $imageUrl
    ) {
      id
      name
      imageUrl
      primaryColor
      secondaryColor
      newProducerMessage
      user {
        id
      }
      organization {
        id
      }
    }
  }
`;

const UPDATE_PRODUCER_PORTAL_WIDGET = gql`
  mutation updateProducerPortalWidget(
    $id: String!
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $imageUrl: String!
    $newProducerMessage: String!
  ) {
    updateProducerPortalWidget(
      id: $id
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      newProducerMessage: $newProducerMessage
      imageUrl: $imageUrl
    ) {
      id
      name
      imageUrl
      primaryColor
      secondaryColor
      newProducerMessage
      user {
        id
      }
      organization {
        id
      }
    }
  }
`;

const PUSH_EPISODES_TO_CABLE_CAST = gql`
  mutation pushEpisodesToCableCast($episodeIds: [String!]!) {
    pushEpisodesToCableCast(episodeIds: $episodeIds) {
      jobStatus
    }
  }
`;

const PUSH_EPISODES_TO_TELVUE_HYPERCAST = gql`
  mutation pushEpisodesToTelvueHypercast($episodeIds: [String!]!) {
    pushEpisodesToTelvueHypercast(episodeIds: $episodeIds) {
      jobStatus
    }
  }
`;

const CREATE_PROGRAM = gql`
  mutation createProgram(
    $title: String
    $fullDescription: String
    $status: ProgramStatus
    $customFieldValue: [CustomFieldValueInput!]
    $documents: [String!]
    $image: String
    $published: Boolean!
    $programType: ID
    $assistantProducers: [ID!]
    $externalLinks: [ExternalLinkInput!]
    $topics: [ID!]
    $notesToProducer: String
    $executiveProducerId: String!
  ) {
    createProgram(
      title: $title
      fullDescription: $fullDescription
      status: $status
      customFieldValue: $customFieldValue
      documents: $documents
      image: $image
      published: $published
      programType: $programType
      assistantProducers: $assistantProducers
      externalLinks: $externalLinks
      topics: $topics
      notesToProducer: $notesToProducer
      executiveProducerId: $executiveProducerId
    ) {
      id
    }
  }
`;

const UPDATE_PROGRAM = gql`
  mutation updateProgram(
    $id: ID!
    $title: String
    $fullDescription: String
    $status: ProgramStatus
    $customFieldValue: [CustomFieldValueInput!]
    $documents: [String!]
    $image: String
    $published: Boolean!
    $programType: ID
    $assistantProducers: [ID!]
    $externalLinks: [ExternalLinkInput!]
    $topics: [ID!]
    $notesToProducer: String
    $executiveProducerId: String!
  ) {
    updateProgram(
      id: $id
      title: $title
      fullDescription: $fullDescription
      status: $status
      customFieldValue: $customFieldValue
      documents: $documents
      image: $image
      published: $published
      programType: $programType
      assistantProducers: $assistantProducers
      externalLinks: $externalLinks
      topics: $topics
      notesToProducer: $notesToProducer
      executiveProducerId: $executiveProducerId
    ) {
      id
    }
  }
`;

const CREATE_FOOTER_WIDGET = gql`
  mutation createFooterWidget(
    $name: String!
    $additionalInfo: String
    $bgColor: String!
    $images: [String!]!
    $primaryColor: String!
    $secondaryColor: String
    $mailchimpListId: String!
    $columns: [ColumnInput!]!
    $mailchimpSegmentId: String!
    $mailchimpSuccessMessage: String!
    $hasMailchimpSubscription: Boolean!
    $socialMediaLinks: [SocialMediaCreateInput!]!
  ) {
    createFooterWidget(
      name: $name
      additionalInfo: $additionalInfo
      images: $images
      bgColor: $bgColor
      columns: $columns
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      mailchimpListId: $mailchimpListId
      socialMediaLinks: $socialMediaLinks
      mailchimpSegmentId: $mailchimpSegmentId
      hasMailchimpSubscription: $hasMailchimpSubscription
      mailchimpSuccessMessage: $mailchimpSuccessMessage
    ) {
      id
      name
      additionalInfo
      bgColor
      hasMailchimpSubscription
      mailchimpListId
      mailchimpSegmentId
      mailchimpSuccessMessage
      primaryColor
      organization {
        id
      }
      socialMediaLinks {
        url
        mediaType
      }
      images {
        id
        url
      }
      footerColumns {
        id
        header
        footerColumnsLinks {
          id
          title
          url
        }
      }
    }
  }
`;

const UPDATE_FOOTER_WIDGET = gql`
  mutation updateFooterWidget(
    $id: String!
    $name: String
    $additionalInfo: String
    $bgColor: String
    $images: [String!]
    $primaryColor: String
    $secondaryColor: String
    $columns: [ColumnInput!]
    $mailchimpListId: String!
    $mailchimpSegmentId: String!
    $hasMailchimpSubscription: Boolean
    $mailchimpSuccessMessage: String
    $socialMediaLinks: [SocialMediaCreateInput!]
  ) {
    updateFooterWidget(
      id: $id
      name: $name
      additionalInfo: $additionalInfo
      images: $images
      columns: $columns
      bgColor: $bgColor
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      mailchimpListId: $mailchimpListId
      socialMediaLinks: $socialMediaLinks
      mailchimpSegmentId: $mailchimpSegmentId
      mailchimpSuccessMessage: $mailchimpSuccessMessage
      hasMailchimpSubscription: $hasMailchimpSubscription
    ) {
      id
      name
      bgColor
      hasMailchimpSubscription
      mailchimpListId
      mailchimpSegmentId
      mailchimpSuccessMessage
      primaryColor
      additionalInfo
      organization {
        id
      }
      socialMediaLinks {
        url
        mediaType
      }
      images {
        id
        url
      }
      footerColumns {
        id
        header
        footerColumnsLinks {
          id
          title
          url
        }
      }
    }
  }
`;

const CREATE_VOD_WIDGET = gql`
  mutation createVodWidget(
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $bgColor: String!
    $textColor: String!
    $widgetType: VodWidgetOptions!
    $sidebarStream: WidgetSidebar!
  ) {
    createVodWidget(
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      bgColor: $bgColor
      textColor: $textColor
      widgetType: $widgetType
      sidebarStream: $sidebarStream
    ) {
      id
      name
      bgColor
      textColor
      primaryColor
      secondaryColor
      sidebarStream
      widgetType
      organization {
        id
      }
    }
  }
`;

const UPDATE_VOD_WIDGET = gql`
  mutation updateVodWidget(
    $id: String!
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $bgColor: String!
    $textColor: String!
    $widgetType: VodWidgetOptions!
    $sidebarStream: WidgetSidebar!
  ) {
    updateVodWidget(
      id: $id
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      bgColor: $bgColor
      textColor: $textColor
      widgetType: $widgetType
      sidebarStream: $sidebarStream
    ) {
      id
      name
      bgColor
      textColor
      primaryColor
      secondaryColor
      sidebarStream
      widgetType
      organization {
        id
      }
    }
  }
`;

const CREATE_MAILCHIMP_INTEGRATION = gql`
  mutation createMailChimpIntegration($apiKey: String!) {
    createMailChimpIntegration(apiKey: $apiKey) {
      id
      apiKey
    }
  }
`;

const CREATE_GROUP = gql`
  mutation createGroup(
    $name: String!
    $description: String!
    $image: String!
    $groupTags: [String!]!
    $members: [ID!]!
  ) {
    createGroup(
      name: $name
      description: $description
      image: $image
      groupTags: $groupTags
      members: $members
    ) {
      name
      id
      description
      image
      groupTags {
        id
        title
      }
      members {
        id
        name
      }
    }
  }
`;

const UPDATE_GROUP = gql`
  mutation updateGroup(
    $id: ID!
    $name: String!
    $description: String!
    $image: String!
    $groupTags: [String!]!
    $members: [ID!]!
  ) {
    updateGroup(
      id: $id
      name: $name
      description: $description
      image: $image
      groupTags: $groupTags
      members: $members
    ) {
      name
      id
      description
      image
      groupTags {
        id
        title
      }
      members {
        id
        name
      }
    }
  }
`;

const DELETE_GROUPS = gql`
  mutation deleteGroups($ids: [ID!]!) {
    deleteGroups(ids: $ids) {
      id
      name
    }
  }
`;

const UPDATE_MAILCHIMP_INTEGRATION = gql`
  mutation updateMailChimpIntegration($apiKey: String!) {
    updateMailChimpIntegration(apiKey: $apiKey) {
      id
      apiKey
    }
  }
`;

const DELETE_MAILCHIMP_INTEGRATION = gql`
  mutation deleteMailChimpIntegration {
    id
    apiKey
    organization {
      id
    }
  }
`;

const FETCH_EPISODES_ON_CABLECAST = gql`
  mutation fetchEpisodesOnCableCast {
    fetchEpisodesOnCableCast {
      jobStatus
    }
  }
`;

const FETCH_EPISODES_ON_TELVUE = gql`
  mutation fetchEpisodesOnTelvue {
    fetchEpisodesOnTelvue {
      jobStatus
    }
  }
`;

const PULL_EPISODES_FROM_TELVUE_CONNECT = gql`
  mutation pullEpisodesFromTelvueConnect {
    pullEpisodesFromTelvueConnect {
      jobStatus
    }
  }
`;

const UPDATE_ORG_YOUTUBE_AUTH = gql`
  mutation updateOrgYouTubeAuth(
    $accessToken: String
    $refreshToken: String
    $metadata: [CustomFieldValueInput!]
  ) {
    updateOrganizatoin(
      youtubeAccessToken: $accessToken
      youtubeRefreshToken: $refreshToken
      youtubeMetadata: $metadata
    ) {
      youtubeAccessToken
      youtubeRefreshToken
      youtubeMetadata {
        id
        value
      }
    }
  }
`;

const DELETE_INVITES = gql`
  mutation deleteInvites($ids: [ID!]!) {
    deleteInvites(ids: $ids) {
      id
      firstname
      lastname
      email
      status
      expiry
    }
  }
`;

const EPISODES_DATA_TO_CSV = gql`
  mutation episodesDataToCsv {
    episodesDataToCsv {
      jobStatus
    }
  }
`;

const PROGRAMS_DATA_TO_CSV = gql`
  mutation programsDataToCsv {
    programsDataToCsv {
      jobStatus
    }
  }
`;

const DONATIONS_DATA_TO_CSV = gql`
  mutation donationsDataToCsv {
    donationsDataToCsv {
      jobStatus
    }
  }
`;

const USERS_DATA_TO_CSV = gql`
  mutation usersDataToCsv {
    usersDataToCsv {
      jobStatus
    }
  }
`;

const REQUEST_EMAIL_CHANGE = gql`
  mutation REQUEST_EMAIL_CHANGE(
    $newEmail: String!
    $userId: String!
    $redirectUrl: String!
  ) {
    generateChangeEmailRequest(
      newEmail: $newEmail
      userId: $userId
      redirectUrl: $redirectUrl
    ) {
      id
      requestBy {
        id
        email
      }
      newEmail
      token
      user {
        id
        email
      }
    }
  }
`;
const CREATE_PLAYLIST = gql`
  mutation createPlaylist(
    $title: String!
    $description: String
    $publishOnApp: Boolean!
    $playlistType: PlaylistType!
    $filterType: FilterType!
    $episodes: [ID!]
    $programs: [ID!]
    $tags: [ID!]
    $topics: [ID!]
  ) {
    createPlaylist(
      title: $title
      description: $description
      publishOnApp: $publishOnApp
      playlistType: $playlistType
      filterType: $filterType
      episodes: $episodes
      programs: $programs
      tags: $tags
      topics: $topics
    ) {
      id
      title
      description
      playlistType
      filterType
      publishOnApp
      episodes {
        id
        title
      }
      programs {
        id
        title
      }
      tags {
        id
        title: name
      }
      topics {
        id
        title
      }
    }
  }
`;

const UPDATE_PLAYLIST = gql`
  mutation updatePlaylist(
    $id: String!
    $title: String!
    $description: String
    $publishOnApp: Boolean!
    $playlistType: PlaylistType!
    $filterType: FilterType!
    $episodes: [ID!]
    $programs: [ID!]
    $tags: [ID!]
    $topics: [ID!]
  ) {
    updatePlaylist(
      id: $id
      title: $title
      description: $description
      publishOnApp: $publishOnApp
      playlistType: $playlistType
      filterType: $filterType
      episodes: $episodes
      programs: $programs
      tags: $tags
      topics: $topics
    ) {
      id
      title
      description
      playlistType
      filterType
      publishOnApp
      episodes {
        id
        title
      }
      programs {
        id
        title
      }
      tags {
        id
        title: name
      }
      topics {
        id
        title
      }
    }
  }
`;

const DELETE_PLAYLIST = gql`
  mutation deletePlaylist($id: String!) {
    deletePlaylist(id: $id) {
      id
    }
  }
`;
const UPDATE_PLAYLIST_PUBLISH_STATUS = gql`
  mutation updatePlaylistStatus($id: String!, $publishOnApp: Boolean!) {
    updatePlaylistStatus(id: $id, publishOnApp: $publishOnApp) {
      id
      title
      description
      playlistType
      filterType
      publishOnApp
      episodes {
        id
        title
      }
      programs {
        id
        title
      }
      tags {
        id
        title: name
      }
      topics {
        id
        title
      }
    }
  }
`;

const CREATE_PLAYLIST_WIDGET = gql`
  mutation CREATE_PLAYLIST_WIDGET(
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $bgColor: String!
    $textColor: String!
    $cardSize: PlaylistWidgetCardSize!
    $layout: PlaylistWidgetLayout!
    $playlists: [String!]!
  ) {
    createPlaylistWidget(
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      bgColor: $bgColor
      textColor: $textColor
      cardSize: $cardSize
      layout: $layout
      playlists: $playlists
    ) {
      id
      bgColor
      cardSize
      description
      layout
      primaryColor
      secondaryColor
      textColor
      name
      playlists {
        id
        title
      }
    }
  }
`;

const UPDATE_PLAYLIST_WIDGET = gql`
  mutation UPDATE_PLAYLIST_WIDGET(
    $id: String!
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $bgColor: String!
    $textColor: String!
    $cardSize: PlaylistWidgetCardSize!
    $layout: PlaylistWidgetLayout!
    $playlists: [String!]!
  ) {
    updatePlaylistWidget(
      id: $id
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      bgColor: $bgColor
      textColor: $textColor
      cardSize: $cardSize
      layout: $layout
      playlists: $playlists
    ) {
      id
      bgColor
      cardSize
      description
      layout
      primaryColor
      secondaryColor
      textColor
      name
      playlists {
        id
        title
      }
    }
  }
`;

const DELETE_DONATIONS = gql`
  mutation deleteDonations($ids: [ID!]!) {
    deleteDonations(ids: $ids) {
      id
      amount
      firstName
      lastName
      email
      donationType
      donationStatus
      subscribeToMailList
      phone
      state
      city
      country
      createdAt
      zipCode
      address
      paymentIntentId
    }
  }
`;
const CREATE_DONATION_WIDGET = gql`
  mutation createDonationWidget(
    $name: String!
    $donationWidgetType: DonationWidgetTypes!
    $primaryColor: String!
    $secondaryColor: String!
    $textColor: String!
    $bgColor: String!
    $formTitle: String!
    $formMessage: String!
    $formSuccessMessage: String!
    $formSuccessEmailMessage: String!
    $footerText: String!
    $footerMailchimpSegmentId: String!
    $footerHasMailchimpSubscription: Boolean!
    $popupType: WidgetPopup
    $popupDelayHours: Int
    $images: [String!]!
    $footerMailchimpListId: String
  ) {
    createDonationWidget(
      name: $name
      donationWidgetType: $donationWidgetType
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      textColor: $textColor
      bgColor: $bgColor
      formTitle: $formTitle
      formMessage: $formMessage
      formSuccessMessage: $formSuccessMessage
      formSuccessEmailMessage: $formSuccessEmailMessage
      footerText: $footerText
      footerMailchimpSegmentId: $footerMailchimpSegmentId
      footerMailchimpListId: $footerMailchimpListId
      footerHasMailchimpSubscription: $footerHasMailchimpSubscription
      popupType: $popupType
      popupDelayHours: $popupDelayHours
      images: $images
    ) {
      id
      bgColor
      donationWidgetType
      footerHasMailchimpSubscription
      footerMailchimpSegmentId
      footerText
      formMessage
      formSuccessEmailMessage
      formSuccessMessage
      formTitle
      id
      name
      organization {
        id
      }
      popupDelayHours
      popupType
      primaryColor
      secondaryColor
      textColor
      user {
        id
      }
      images {
        id
        url
      }
    }
  }
`;

const UPDATE_DONATION_WIDGET = gql`
  mutation updateDonationWidget(
    $id: String!
    $name: String!
    $donationWidgetType: DonationWidgetTypes!
    $primaryColor: String!
    $secondaryColor: String!
    $textColor: String!
    $bgColor: String!
    $formTitle: String!
    $formMessage: String!
    $formSuccessMessage: String!
    $formSuccessEmailMessage: String!
    $footerText: String!
    $footerMailchimpSegmentId: String!
    $footerHasMailchimpSubscription: Boolean!
    $popupType: WidgetPopup
    $popupDelayHours: Int
    $images: [String!]!
    $footerMailchimpListId: String
  ) {
    updateDonationWidget(
      id: $id
      name: $name
      donationWidgetType: $donationWidgetType
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      textColor: $textColor
      bgColor: $bgColor
      formTitle: $formTitle
      formMessage: $formMessage
      formSuccessMessage: $formSuccessMessage
      formSuccessEmailMessage: $formSuccessEmailMessage
      footerText: $footerText
      footerMailchimpListId: $footerMailchimpListId
      footerMailchimpSegmentId: $footerMailchimpSegmentId
      footerHasMailchimpSubscription: $footerHasMailchimpSubscription
      popupType: $popupType
      popupDelayHours: $popupDelayHours
      images: $images
    ) {
      id
      bgColor
      donationWidgetType
      footerHasMailchimpSubscription
      footerMailchimpSegmentId
      footerText
      formMessage
      formSuccessEmailMessage
      formSuccessMessage
      formTitle
      id
      name
      organization {
        id
      }
      popupDelayHours
      popupType
      primaryColor
      secondaryColor
      textColor
      user {
        id
      }
      images {
        id
        url
      }
    }
  }
`;

const CREATE_STRIPE_SETTINGS = gql`
  mutation createStripeIntegration(
    $liveApiKey: String!
    $devApiKey: String!
    $isDevMode: Boolean!
    $webHooksSecret: String
    $devPublishableKey: String
    $livePublishableKey: String
  ) {
    createStripeIntegration(
      liveApiKey: $liveApiKey
      devApiKey: $devApiKey
      isDevMode: $isDevMode
      webHooksSecret: $webHooksSecret
      devPublishableKey: $devPublishableKey
      livePublishableKey: $livePublishableKey
    ) {
      id
    }
  }
`;

const UPDATE_STRIPE_SETTINGS = gql`
  mutation updateStripeIntegration(
    $liveApiKey: String!
    $devApiKey: String!
    $isDevMode: Boolean!
    $webHooksSecret: String
    $devPublishableKey: String
    $livePublishableKey: String
  ) {
    updateStripeIntegration(
      liveApiKey: $liveApiKey
      devApiKey: $devApiKey
      isDevMode: $isDevMode
      webHooksSecret: $webHooksSecret
      devPublishableKey: $devPublishableKey
      livePublishableKey: $livePublishableKey
    ) {
      id
    }
  }
`;

const REFUND_DONATION = gql`
  mutation refundDonation($donationId: ID!) {
    refundDonation(donationId: $donationId) {
      id
    }
  }
`;

const CREATE_OFFLINE_DONATION = gql`
  mutation createOfflineDonation(
    $amount: Float!
    $firstName: String!
    $lastName: String!
    $address: String!
    $city: String!
    $state: String!
    $country: String!
    $zipCode: String!
    $email: String!
    $phone: String!
    $donationStatus: DonationStatus!
    $customFieldValue: [CustomFieldValueInput!]!
    $subscribeToMailchimp: Boolean!
  ) {
    createOfflineDonation(
      amount: $amount
      firstName: $firstName
      lastName: $lastName
      address: $address
      city: $city
      state: $state
      country: $country
      zipCode: $zipCode
      email: $email
      phone: $phone
      donationStatus: $donationStatus
      customFieldValue: $customFieldValue
      subscribeToMailchimp: $subscribeToMailchimp
    ) {
      id
    }
  }
`;

const UPDATE_DONATION = gql`
  mutation updateDonation(
    $id: String!
    $firstName: String!
    $lastName: String!
    $address: String!
    $city: String!
    $state: String!
    $country: String!
    $zipCode: String!
    $phone: String!
    $donationStatus: DonationStatus!
    $customFieldValue: [CustomFieldValueInput!]!
    $subscribeToMailchimp: Boolean!
  ) {
    updateDonation(
      id: $id
      firstName: $firstName
      lastName: $lastName
      address: $address
      city: $city
      state: $state
      country: $country
      zipCode: $zipCode
      phone: $phone
      donationStatus: $donationStatus
      customFieldValue: $customFieldValue
      subscribeToMailchimp: $subscribeToMailchimp
    ) {
      id
    }
  }
`;

const ADD_OR_UPDATE_DONATION_SETTING = gql`
  mutation addDonationSetting($donationCustomFields: [CustomFieldInput!]!) {
    addDonationSetting(donationCustomFields: $donationCustomFields) {
      id
    }
  }
`;

const CREATE_SUBSCRIPTION_WIDGET = gql`
  mutation CREATE_SUBSCRIPTION_WIDGET(
    $name: String!
    $bgColor: String!
    $textColor: String!
    $formTitle: String!
    $showEmail: Boolean!
    $showPhone: Boolean!
    $formMessage: String!
    $popupDelayHours: Int
    $showAddress: Boolean!
    $primaryColor: String!
    $showLastName: Boolean!
    $popupType: WidgetPopup
    $secondaryColor: String!
    $showFirstName: Boolean!
    $mailchimpListId: String
    $mailchimpSegmentId: String
    $formSuccessMessage: String!
    $formSuccessEmailMessage: String!
    $subscriptionWidgetType: SubscriptionWidgetTypes!
  ) {
    createSubscriptionWidget(
      name: $name
      bgColor: $bgColor
      textColor: $textColor
      formTitle: $formTitle
      showEmail: $showEmail
      showPhone: $showPhone
      popupType: $popupType
      formMessage: $formMessage
      showAddress: $showAddress
      showLastName: $showLastName
      primaryColor: $primaryColor
      showFirstName: $showFirstName
      secondaryColor: $secondaryColor
      mailchimpListId: $mailchimpListId
      popupDelayHours: $popupDelayHours
      mailchimpSegmentId: $mailchimpSegmentId
      formSuccessMessage: $formSuccessMessage
      formSuccessEmailMessage: $formSuccessEmailMessage
      subscriptionWidgetType: $subscriptionWidgetType
    ) {
      id
      name
      bgColor
      formTitle
      createdAt
      showPhone
      showEmail
      popupType
      textColor
      updatedAt
      showAddress
      primaryColor
      showLastName
      showFirstName
      secondaryColor
      mailchimpListId
      popupDelayHours
      mailchimpSegmentId
      formSuccessMessage
      subscriptionWidgetType
      formSuccessEmailMessage
      organization {
        id
      }
      user {
        id
        email
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION_WIDGET = gql`
  mutation UPDATE_SUBSCRIPTION_WIDGET(
    $id: String!
    $name: String!
    $bgColor: String!
    $formTitle: String!
    $textColor: String!
    $showEmail: Boolean!
    $showPhone: Boolean!
    $formMessage: String!
    $popupDelayHours: Int
    $showAddress: Boolean!
    $primaryColor: String!
    $showLastName: Boolean!
    $popupType: WidgetPopup
    $secondaryColor: String!
    $showFirstName: Boolean!
    $mailchimpListId: String
    $mailchimpSegmentId: String
    $formSuccessMessage: String!
    $formSuccessEmailMessage: String!
    $subscriptionWidgetType: SubscriptionWidgetTypes!
  ) {
    updateSubscriptionWidget(
      id: $id
      name: $name
      bgColor: $bgColor
      textColor: $textColor
      formTitle: $formTitle
      showEmail: $showEmail
      showPhone: $showPhone
      popupType: $popupType
      formMessage: $formMessage
      showAddress: $showAddress
      showLastName: $showLastName
      primaryColor: $primaryColor
      showFirstName: $showFirstName
      secondaryColor: $secondaryColor
      mailchimpListId: $mailchimpListId
      popupDelayHours: $popupDelayHours
      mailchimpSegmentId: $mailchimpSegmentId
      formSuccessMessage: $formSuccessMessage
      formSuccessEmailMessage: $formSuccessEmailMessage
      subscriptionWidgetType: $subscriptionWidgetType
    ) {
      id
      name
      bgColor
      formTitle
      createdAt
      showPhone
      showEmail
      popupType
      textColor
      updatedAt
      showAddress
      primaryColor
      showLastName
      showFirstName
      secondaryColor
      mailchimpListId
      popupDelayHours
      mailchimpSegmentId
      formSuccessMessage
      subscriptionWidgetType
      formSuccessEmailMessage
      organization {
        id
      }
      user {
        id
        email
      }
    }
  }
`;

const DELETE_WIDGET = gql`
  mutation deleteWidget($id: ID!, $widgetType: WidgetTypes!) {
    deleteWidget(id: $id, widgetType: $widgetType) {
      id
      name
      widgetType
      sourceId
    }
  }
`;

const CREATE_SEARCH_WIDGET = gql`
  mutation createSearchWidget(
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $bgColor: String!
    $textColor: String!
    $sidebarStream: WidgetSidebar!
  ) {
    createSearchWidget(
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      bgColor: $bgColor
      textColor: $textColor
      sidebarStream: $sidebarStream
    ) {
      id
      name
      bgColor
      textColor
      primaryColor
      secondaryColor
      sidebarStream
    }
  }
`;

const UPDATE_SEARCH_WIDGET = gql`
  mutation updateSearchWidget(
    $id: String!
    $name: String!
    $primaryColor: String!
    $secondaryColor: String!
    $bgColor: String!
    $textColor: String!
    $sidebarStream: WidgetSidebar!
  ) {
    updateSearchWidget(
      id: $id
      name: $name
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      bgColor: $bgColor
      textColor: $textColor
      sidebarStream: $sidebarStream
    ) {
      id
      name
      bgColor
      textColor
      primaryColor
      secondaryColor
      sidebarStream
      organization {
        id
      }
    }
  }
`;

export default {
  LOGIN,
  LOGOUT,
  CREATE_PODCAST,
  DELETE_PODCAST,
  UPDATE_PODCAST,
  CREATE_PODCAST_WIDGET,
  UPDATE_PODCAST_WIDGET,
  CREATE_RADIO,
  CREATE_RADIO_WIDGET,
  DELETE_RADIO,
  UPDATE_RADIO,
  UPDATE_RADIO_WIDGET,
  CREATE_LIVE_VIDEO,
  UPDATE_LIVE_VIDEO,
  DELETE_LIVE_VIDEO,
  UPDATE_LIVE_VIDEO_WIDGET,
  UPDATE_ORGANIZATION,
  CREATE_LIVE_VIDEO_WIDGET,
  CREATE_CABLECAST_INTEGRATION,
  UPDATE_CABLECAST_INTEGRATION,
  UPDATE_TELVUE_INTEGRATION,
  CREATE_TELVUE_INTEGRATION,
  UPDATE_NEON_INTEGRATION,
  REFRESH_LIVE_VIDEO,
  REFRESH_PODCAST,
  CREATE_SCHEDULE_WIDGET,
  UPDATE_SCHEDULE_WIDGET,
  SEND_INVITE_FOR_STAFF_MEMBER,
  CREATE_USER,
  UPDATE_USER_PASSWORD,
  SEND_PASSSWORD_RESET,
  DELETE_INVITE,
  DELETE_INVITES,
  CREATE_EPISODE_SETTINGS,
  UPDATE_USER,
  UPDATE_USER_INFORMATION,
  DELETE_USER,
  DELETE_USERS,
  RESEND_INVITE,
  RESEND_INVITES,
  UPDATE_EPISODE_SETTINGS,
  UPDATE_EPISODE_STATUS,
  UPDATE_EPISODE_PUBLISHED_STATUS,
  CREATE_PROGRAM_SETTINGS,
  UPDATE_PROGRAM_PUBLISH_STATUS,
  UPDATE_PROGRAM_STATUS,
  UPDATE_PROGRAM_SETTINGS,
  DELETE_PROGRAMS,
  CREATE_EPISODE,
  UPDATE_EPISODE,
  DELETE_EPISODES,
  CREATE_PRODUCER_PORTAL_WIDGET,
  UPDATE_PRODUCER_PORTAL_WIDGET,
  PUSH_EPISODES_TO_CABLE_CAST,
  PUSH_EPISODES_TO_TELVUE_HYPERCAST,
  CREATE_PROGRAM,
  UPDATE_PROGRAM,
  CREATE_FOOTER_WIDGET,
  UPDATE_FOOTER_WIDGET,
  CREATE_MAILCHIMP_INTEGRATION,
  UPDATE_MAILCHIMP_INTEGRATION,
  DELETE_MAILCHIMP_INTEGRATION,
  CREATE_VOD_WIDGET,
  UPDATE_VOD_WIDGET,
  FETCH_EPISODES_ON_CABLECAST,
  FETCH_EPISODES_ON_TELVUE,
  UPDATE_ORG_YOUTUBE_AUTH,
  PULL_EPISODES_FROM_TELVUE_CONNECT,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUPS,
  CREATE_PRODUCER_SETTINGS,
  UPDATE_PRODUCER_SETTINGS,
  EPISODES_DATA_TO_CSV,
  PROGRAMS_DATA_TO_CSV,
  DONATIONS_DATA_TO_CSV,
  USERS_DATA_TO_CSV,
  USER_SIGN_UP,
  REQUEST_EMAIL_CHANGE,
  CREATE_PLAYLIST,
  UPDATE_PLAYLIST,
  DELETE_PLAYLIST,
  UPDATE_PLAYLIST_PUBLISH_STATUS,
  CREATE_PLAYLIST_WIDGET,
  UPDATE_PLAYLIST_WIDGET,
  CREATE_DONATION_WIDGET,
  UPDATE_DONATION_WIDGET,
  UPDATE_STRIPE_SETTINGS,
  CREATE_STRIPE_SETTINGS,
  DELETE_DONATIONS,
  REFUND_DONATION,
  CREATE_OFFLINE_DONATION,
  UPDATE_DONATION,
  ADD_OR_UPDATE_DONATION_SETTING,
  CREATE_SUBSCRIPTION_WIDGET,
  UPDATE_SUBSCRIPTION_WIDGET,
  CREATE_SEARCH_WIDGET,
  UPDATE_SEARCH_WIDGET,
  DELETE_WIDGET,
  CHANGE_EPISODES_PROGRAM,
  ADD_EPISODES_TOPICS,
  ADD_EPISODES_TAGS,
};
