import React from "react";
import { Avatar, Button, Tag } from "antd";
import { UserRole } from "../../../utils/contants";
import { getFormatedDate, DASH } from "../../../utils";
import { getSession } from "../../../helpers/SessionManagement";

const initialColumns = (history) =>
  new Map([
    [
      "Avatar",
      {
        title: "Avatar",
        dataIndex: "avatar",
        key: "avatar",

        render: (image) => <Avatar src={image} />,
      },
    ],
    [
      "Name",
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name && b.name && a?.name.localeCompare(b?.name),
        sortDirections: ["descend", "ascend"],
        render: (name, record) => {
          if (
            false
            // getSession().email !== record.email &&
            // getSession().userType !== UserRole.ADMIN
          ) {
            return <span>{name}</span>;
          }
          return (
            <Button
              style={{ padding: 0 }}
              onClick={() => history.push("/users/edit/" + record.id)}
              type="link"
            >
              {name}
            </Button>
          );
        },
      },
    ],
    [
      "Email",
      {
        key: "email",
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) =>
          a.email && b.email && a?.email.localeCompare(b?.email),
        sortDirections: ["descend", "ascend"],
        render: (email, record) => <Tag color={"orange"}>{email}</Tag>,
      },
    ],
    [
      "City",
      {
        title: "City",
        key: "City",
        dataIndex: "city",
        sorter: (a, b) => a.city && b.city && a?.city.localeCompare(b?.city),
        sortDirections: ["descend", "ascend"],
        render: (city, record) => <span>{city}</span>,
      },
    ],
    [
      "Last updated",
      {
        key: "lastUpdated",
        title: "Last updated",
        dataIndex: "updatedAt",
        sorter: (a, b) =>
          a.updatedAt && b.updatedAt && a.updatedAt.localeCompare(b.updatedAt),
        sortDirections: ["descend", "ascend"],
        render: (item) => getFormatedDate(item),
      },
    ],
  ]);

const getGroups = (groups) =>
  groups?.length > 0
    ? groups?.map((group) => (
        <Tag key={group.id} style={{ maxWidth: "150px", overflowX: "auto" }}>
          {group.name}
        </Tag>
      ))
    : DASH;

const otherColumns = () =>
  new Map([
    [
      "State",
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        sorter: (a, b) =>
          a.state && b.state && a?.state.localeCompare(b?.state),
        sortDirections: ["descend", "ascend"],
        render: (state, record) => <span>{state}</span>,
      },
    ],
    [
      "Address",
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        sorter: (a, b) =>
          a.address && b.address && a?.address.localeCompare(b?.address),
        sortDirections: ["descend", "ascend"],
        render: (address, record) => <span>{address}</span>,
      },
    ],
    [
      "Groups",
      {
        title: "Groups",
        key: "groups",
        dataIndex: "groups",
        render: (groups, record) => getGroups(record?.groups),
      },
    ],
  ]);

const initialVisibleFields = [
  "Name",
  "City",
  "Email",
  "Avatar",
  "Last updated",
];

export { initialColumns, otherColumns, initialVisibleFields };
