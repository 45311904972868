import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Queries, Mutations } from "../../../api";
import { useMutation, useQuery } from "@apollo/client";
import ColorsPicker from "../../../components/ColorsPicker";
import { SaveOutlined, LoadingOutlined } from "@ant-design/icons";
import MailchimpFields from "../../../components/MailchimpFields";
import { Button, Input, Spin, Form, Checkbox, message } from "antd";
import FormIntroWidgets from "../../../components/FormIntroWidgets";
import WidgetTypeSelect from "../../../components/WidgetTypeSelect";
import { trimErrorMessage } from "../../../utils";
import {
  DONATION_WIDGET_POPUP_LOGIC,
} from "../../../utils/contants";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const Container = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  height: 100%;
  .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`;

const HorizontalContainerSB = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const DEFAULT_COLOR = "#5229F4";

const SubscriptionWidget = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState(["email"]);
  const [colors, setColors] = useState({
    bgColor: DEFAULT_COLOR,
    textColor: DEFAULT_COLOR,
    primaryColor: DEFAULT_COLOR,
    secondaryColor: DEFAULT_COLOR,
  });
  const id = props.match.params.id !== "new" ? props.match.params.id : false;

  const { data: subscriptonData, loading: subscriptionLoading } = useQuery(
    Queries.SUBSCRIPTION_WIDGET_BY_ID,
    {
      variables: {
        id: id,
      },
      skip: !id,
      onError: (e) => message.error(trimErrorMessage(e.message)),
    }
  );

  const [createSubscription, { loading: createSubscriptionLoading }] =
    useMutation(Mutations.CREATE_SUBSCRIPTION_WIDGET, {
      onCompleted: (res) => {
        message.success(`${res.createSubscriptionWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    });

  const [updateSubscription, { loading: updateSubscriptionLoading }] =
    useMutation(Mutations.UPDATE_SUBSCRIPTION_WIDGET, {
      onCompleted: (res) => {
        message.success(`${res.updateSubscriptionWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    });

  useEffect(() => {
    if (!id) initForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subscriptonData) {
      const data = subscriptonData.subscriptionWidgetById;
      initForm(data);
      setFields([
        ...fields,
        data?.showPhone && "phone",
        data?.showAddress && "address",
        data?.showLastName && "lastName",
        data?.showFirstName && "firstName",
      ]);
      setColors({
        bgColor: data?.bgColor || DEFAULT_COLOR,
        textColor: data?.textColor || DEFAULT_COLOR,
        primaryColor: data?.primaryColor || DEFAULT_COLOR,
        secondaryColor: data?.secondaryColor || DEFAULT_COLOR,
      });
    }
    // eslint-disable-next-line
  }, [subscriptonData]);

  const initForm = (data = {}) => {
    form.setFieldsValue({
      name: data?.name || "",
      title: data?.formTitle || "",
      popUpLogic: data?.popupType || "",
      message: data?.formMessage || "",
      type: data?.subscriptionWidgetType || "",
      mailchimpListId: data?.mailchimpListId || "",
      successMessage: data?.formSuccessMessage || "",
      mailchimpSegmentId: data?.mailchimpSegmentId || "",
      popupDelayHours: parseInt(data?.popupDelayHours) || 1,
      successEmailMessage: data?.formSuccessEmailMessage || "",
    });
  };

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=subscription");
  };

  const handleSubmit = () => {
    const formFields = form.getFieldsValue();
    const variables = {
      showEmail: fields.includes("email"),
      showPhone: fields.includes("phone"),
      showAddress: fields.includes("address"),
      showLastName: fields.includes("lastName"),
      showFirstName: fields.includes("firstName"),
      name: formFields.name,
      bgColor: colors.bgColor,
      formTitle: formFields.title,
      textColor: colors.textColor,
      formMessage: formFields.message,
      popupType: formFields.popUpLogic,
      primaryColor: colors.primaryColor,
      secondaryColor: colors.secondaryColor,
      subscriptionWidgetType: formFields.type,
      mailchimpListId: formFields.mailchimpListId,
      formSuccessMessage: formFields.successMessage,
      mailchimpSegmentId: formFields.mailchimpSegmentId,
      formSuccessEmailMessage: formFields.successEmailMessage,
      popupDelayHours: parseInt(formFields.popupDelayHours) || 0,
    };

    if (id) {
      updateSubscription({ variables: { id: id, ...variables } });
    } else {
      createSubscription({ variables: variables });
    }
  };

  return (
    <Container>
      <Spin
        spinning={
          subscriptionLoading ||
          createSubscriptionLoading ||
          updateSubscriptionLoading
        }
        style={{ width: "100%", alignSelf: "center" }}
      >
        <Form.Provider onFormFinish={() => handleSubmit()} scrollToFirstError>
          <Form form={form} layout={"vertical"}>
            <Form.Item
              label={"Name"}
              name="name"
              style={{ width: "40%", fontWeight: 400, marginBottom: 18 }}
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Input type={"text"} placeholder="Name of subscription widget" />
            </Form.Item>
          </Form>
          <WidgetTypeSelect
            form={form}
            TYPES={{
              IN_PAGE: { label: "In-page", value: "IN_PAGE" },
              POP_UP: { label: "Pop-up", value: "POPUP" },
            }}
            POPUP_TYPES={DONATION_WIDGET_POPUP_LOGIC}
          />

          <ColorsPicker
            colors={colors}
            isTextColor={true}
            setColors={setColors}
            isPrimaryColor={true}
            isSecondaryColor={true}
            isBackgroundColor={true}
          />

          <h3>Subscription form intro</h3>

          <FormIntroWidgets form={form} widgetType={"subscription"} />

          <h3>Subscription form fields</h3>

          <Checkbox.Group
            style={{
              width: "100%",
              display: "flex",
              marginBottom: 18,
              flexDirection: "column",
            }}
            onChange={setFields}
            value={fields}
          >
            <Checkbox value="email" disabled={true}>
              Email address
            </Checkbox>

            <Checkbox value="firstName">First name</Checkbox>

            <Checkbox value="lastName">Last name</Checkbox>

            <Checkbox value="phone">Phone number</Checkbox>

            <Checkbox value="address">Mailing address</Checkbox>
          </Checkbox.Group>

          <h3>Mailchimp</h3>

          <MailchimpFields form={form} skipSwitchField={true} />

          <HorizontalContainerSB>
            <Button
              shape="round"
              style={{ marginTop: "2rem" }}
              size={"large"}
              onClick={goToListWidgets}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              shape="round"
              icon={
                createSubscriptionLoading || updateSubscriptionLoading ? (
                  <LoadingOutlined />
                ) : (
                  <SaveOutlined />
                )
              }
              size={"large"}
              onClick={form.submit}
            >
              {id ? "Update widget" : "Create widget"}
            </Button>
          </HorizontalContainerSB>
        </Form.Provider>
      </Spin>
    </Container>
  );
};

export default SubscriptionWidget;
