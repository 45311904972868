import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  customFieldsTypes,
  DONATION_STATUS,
  DONATION_TYPE,
  PAGINATION_COUNTS,
  SEARCH_DELAY,
  UserRole,
} from "../../utils/contants";
import HelpText from "../../components/HelpText";
import numeral from "numeral";
import {
  Collapse,
  Button,
  message,
  Spin,
  Tag,
  Avatar,
  Menu,
  Input,
  Dropdown,
  Checkbox,
} from "antd";
import { getSession } from "./../../helpers/SessionManagement";
import {
  PlusOutlined,
  LoadingOutlined,
  DownloadOutlined,
  ReloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  SaveOutlined,
  DownOutlined,
} from "@ant-design/icons";
import CustomFieldsComponent from "../../components/CustomFieldsComponent";
import {
  debounce,
  getValidCustomFields, getValidCustomFieldsRuby,
  isValidCustomFields,
  trimErrorMessage,
} from "../../utils";
import DonationTableView from "./tableView";
import { Mutations, Queries } from "../../api";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import Table from "../../components/Table";
import { useDatabase } from "../../hooks/database";
import { Link } from "react-router-dom";
import {
  ScrollableCell,
  TagsContainer,
} from "../../components/MediaTable/columns";
import { useEntities, useEntityTable } from "../../hooks/entity";
import MediaTableHeader, {
  ActionsMenu,
  CheckboxWrapper,
} from "../../components/MediaTableHeader";
import AlertConfirm from "../../components/AlertConfirm";
import { ThemeColors } from "../../theme";
import moment from "moment";

const SaveWrapper = styled.div`
  display: flex;
  //float: right;
  margin: 1rem;
`;

const { Panel } = Collapse;

const Donation = (props) => {
  const { database } = useDatabase();
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const session = getSession();

  const staticFilters = {
    organization_id: {
      _eq: session?.organization?.id,
    },
  };

  const { fetchData, setQuickSearch } = useEntities({
    baseEntity: "donations",
    staticFilters,
    fields: ["*"],
  });

  const { getColumnAttributes, loading: loadingDatabase } = useEntityTable({
    baseEntity: "donations",
    staticFilters,
  });

  const [searchString, setSearchString] = useState("");
  const [dateRange, setDateRange] = useState({
    startDateLocal: null,
    endDateLocal: moment(),
    startDateToSubmit: null,
    endDateToSubmit: null,
  });
  const [donations, setDonations] = useState([]);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDisableExport, setIsDisableExport] = useState(false);

  const localClient = useApolloClient();
  const cachedData = localClient.cache.readQuery({
    query: Queries.GET_PAGE_NUMBER,
  });
  const {
    data: donationsData,
    loading: donationsLoading,
    refetch: refetchDonationsData,
    fetchMore: fetchMoreDonations,
  } = useQuery(Queries.DONATION_PAGINATION, {
    variables: {
      page: cachedData?.pageNumber || 1,
      status: null,
      donationType: null,
      query: searchString,
      per: PAGINATION_COUNTS.DONATIONS,
      endDate: dateRange.endDateToSubmit,
      startDate: dateRange.startDateToSubmit,
    },
    onError: (err) => message.error(trimErrorMessage(err.message)),
    fetchPolicy: "network-only",
  });

  const [customFieldData, setCustomFieldData] = useState([]);

  const [deleteDonations, { loading: deleteLoading }] = useMutation(
    Mutations.DELETE_DONATIONS,
    {
      onCompleted: () => {
        refetchDonationsData();
        message.success("Donation(s) deleted successfully");
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [refundDonation, { loading: refundLoding }] = useMutation(
    Mutations.REFUND_DONATION,
    {
      onCompleted: () => {
        refetchDonationsData();
        message.success("Donation refunded successfully");
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [
    exportCSVCall,
    { loading: exportLoading, error: exportError, data: exportData },
  ] = useMutation(Mutations.DONATIONS_DATA_TO_CSV, {});

  useEffect(() => {
    if (exportData) {
      message.success("Donations export in progress, CSV will be emailed");
    }

    if (exportError) {
      message.error("Error exporting data to CSV.");
    }
  }, [exportData, exportError]);

  useEffect(() => {
    if (donationsData) {
      setDonations(donationsData?.donations?.nodes || []);
    }
  }, [donationsData]);

  const handleDelete = async () => {
    const selectedRows = tableRef.current.api.getSelectedRows() || [];
    const ids = selectedRows.map((r) => r?.id);

    if (selectedRows.length > 0) {
      await database.items("donations").deleteMany(ids);
      tableRef.current.api.refreshServerSideStore();
      message.error("Successfully deleted donations'");
    }
  };

  const validateBeforeRefund = () => {
    if (selectedRows.length === 1) {
      const donationToRefund = donations.find(
        (item) => item.id === selectedRows[0]
      );

      if (donationToRefund.donationStatus !== DONATION_STATUS.Succeeded.value) {
        message.success(
          "To refund a donation, its status should be 'Succeeded'"
        );
        return false;
      } else {
        return true;
      }
    }
  };

  const handleRefund = () => {
    if (selectedRows.length === 1) {
      refundDonation({
        variables: {
          donationId: selectedRows[0],
        },
      }).catch((error) => message.error(error.message));
      setSelectedRows([]);
    }
  };

  const onDateChange = (dateFilter = []) => {
    if (dateFilter?.length === 2) {
      setDateRange({
        ...dateRange,
        startDateLocal: dateFilter[0].toString(),
        endDateLocal: dateFilter[1].toString(),
      });
    } else {
      setDateRange({
        ...dateRange,
        startDateLocal: null,
        endDateLocal: null,
      });
    }
  };

  const onApplyDateFilter = () => {
    if (dateRange.startDateLocal && dateRange.endDateLocal) {
      setDateRange({
        ...dateRange,
        startDateToSubmit: dateRange.startDateLocal,
        endDateToSubmit: dateRange.endDateLocal,
      });
    }
  };

  const onDateRangeReset = () => {
    setDateRange({
      startDateToSubmit: null,
      endDateToSubmit: null,
      startDateLocal: null,
      endDateLocal: null,
    });
  };

  const onChangeSearch = (str) => setQuickSearch(str);

  const debounceOnChange = useCallback(
    debounce(onChangeSearch, SEARCH_DELAY),
    []
  );

  const onClickExportCSV = () => {
    setIsDisableExport(true);
    exportCSVCall().then(() => null);
  };

  const isShowRefund = (id) => {
    const item = donations.filter((item) => item.id === id[0]);
    return (
      item?.[0]?.["donationType"] === DONATION_TYPE.ONLINE.value &&
      item?.[0]?.["donationStatus"] === DONATION_STATUS.Succeeded.value
    );
  };

  const actionsMenu = () => (
    <Menu>
      {selectedRows?.length === 1 && isShowRefund(selectedRows) && (
        <Menu.Item>
          <AlertConfirm
            actionBtnText="Refund"
            actionBtnProps={{
              className: "donations-action-btn",
              style: !(selectedRows?.length === 1)
                ? {}
                : {
                    background: ThemeColors.colors.orange,
                    color: ThemeColors.colors.white,
                  },

              icon: <ReloadOutlined />,
            }}
            modalProps={{
              onOk: handleRefund,
              okText: "Confirm",
              alertMsg: "Are you sure you want to refund selected payment(s)?",
              alertTitle: "Confirm Refund",
            }}
            validate={validateBeforeRefund}
          />
        </Menu.Item>
      )}
      <Menu.Item>
        <AlertConfirm
          actionBtnText="Delete"
          actionBtnProps={{
            className: "donations-action-btn",
            icon: <DeleteOutlined />,
            type: "danger",
          }}
          modalProps={{
            onOk: handleDelete,
            okText: "Confirm",
            alertMsg: "Are you sure you want to delete selected record(s)?",
            alertTitle: "Confirm Delete",
          }}
        />
      </Menu.Item>
    </Menu>
  );

  const handleAddRecordClick = () => {
    props.history.push("/donations/new");
  };

  const areRowsSelected = selectedRows.length > 0;

  const rowSelection = {
    onChange: (selectedRows) => {
      setSelectedRows(selectedRows);
    },
    selectedRows: selectedRows,
  };

  const { loading: fetchingCustomFields } = useQuery(
    Queries.GET_DONATION_SETTING,
    {
      onError: (err) => message.error(trimErrorMessage(err.message)),
      onCompleted: (data) => {
        const customFields = data?.donationSetting?.donationCustomFields;
        if (Array.isArray(customFields)) {
          setCustomFieldData(getValidCustomFieldsRuby(customFields));
        }
      },
    }
  );


  const [updateDonationSetting, { loading: submittingSettings }] = useMutation(
    Mutations.ADD_OR_UPDATE_DONATION_SETTING,
    {
      onCompleted: (data) => {
        if (data?.addDonationSetting?.id) {
          message.success("Donation settings updated successfully.");
        }
      },
    }
  );

  const deleteCustomField = (id) => {
    setCustomFieldData(customFieldData.filter((item, index) => index !== id));
  };

  const handleCreateAndUpdate = () => {
    if (isValidCustomFields(customFieldData)) {
      updateDonationSetting({
        variables: {
          donationCustomFields: customFieldData,
        },
        refetchQueries: [{ query: Queries.GET_DONATION_SETTING }],
      }).catch((err) => message.error(trimErrorMessage(err.message)));
    } else {
      message.error("Label field requierd!");
    }
  };

  const getTypeItemColor = (type) => (type === 0 ? "purple" : "geekblue");

  const getStatusItemColor = (status) =>
    status === 1
      ? "green"
      : status === 2
      ? "orange"
      : status === 0
      ? "orange"
      : "red";

  useEffect(() => {
    async function initCols() {
      const columns = [
        {
          field: "amount",
          colId: "amount",
          headerName: "Donation",
          sort: "desc",
          checkboxSelection: (params) =>
            !params?.node?.footer && getSession().userType !== UserRole.STAFF,
          flex: 1,
          ...(await getColumnAttributes({
            field: "amount",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/donation/edit/${params?.data?.id}`}>
                {numeral(params?.value || 0).format("$0,0[.]00")}
              </Link>
            );
          },
        },
        {
          field: "first_name",
          colId: "first_name",
          headerName: "First Name",
          flex: 1,
          ...(await getColumnAttributes({
            field: "first_name",
          })),
        },
        {
          field: "last_name",
          colId: "last_name",
          headerName: "Last Name",
          flex: 1,
          ...(await getColumnAttributes({
            field: "last_name",
          })),
        },
        {
          field: "email",
          colId: "email",
          headerName: "Email",
          flex: 1,
          ...(await getColumnAttributes({
            field: "email",
          })),
        },
        {
          field: "donation_type",
          colId: "donation_type",
          headerName: "Type",
          flex: 1,
          minWidth: 100,
          ...(await getColumnAttributes({
            field: "donation_type",
          })),
          cellRendererFramework: (params) => {
            let label;
            switch (params?.value) {
              case 0:
                label = "ONLINE";
                break;

              case 1:
                label = "OFFLINE";
                break;

              default:
                label = " - ";
                break;
            }

            return <Tag color={getTypeItemColor(params?.value)}>{label}</Tag>;
          },
        },
        {
          field: "created_at",
          colId: "created_at",
          headerName: "Created At",
          flex: 1,
          minWidth: 100,
          ...(await getColumnAttributes({
            field: "created_at",
          })),
        },
        {
          field: "donation_status",
          colId: "donation_status",
          headerName: "Status",
          flex: 1,
          minWidth: 100,
          ...(await getColumnAttributes({
            field: "donation_status",
          })),
          cellRendererFramework: (params) => {
            let label;
            switch (params?.value) {
              case 0:
                label = "PENDING";
                break;

              case 1:
                label = "SUCCEEDED";
                break;

              case 2:
                label = "REFUND_PENDING";
                break;

              default:
                label = "REFUNDED";
                break;
            }

            return <Tag color={getStatusItemColor(params?.value)}>{label}</Tag>;
          },
        },
        {
          field: "city",
          colId: "city",
          headerName: "City",
          initialHide: true,
          flex: 1,
          ...(await getColumnAttributes({
            field: "city",
          })),
        },
        {
          field: "country",
          colId: "country",
          headerName: "Country",
          initialHide: true,
          flex: 1,
          ...(await getColumnAttributes({
            field: "country",
          })),
        },
        {
          field: "phone",
          colId: "phone",
          headerName: "Phone",
          initialHide: true,
          flex: 1,
          ...(await getColumnAttributes({
            field: "phone",
          })),
        },
        {
          field: "state",
          colId: "state",
          headerName: "State",
          initialHide: true,
          flex: 1,
          ...(await getColumnAttributes({
            field: "state",
          })),
        },
        {
          field: "zip_code",
          colId: "zip_code",
          headerName: "Zip",
          initialHide: true,
          flex: 1,
          ...(await getColumnAttributes({
            field: "zip_code",
          })),
        },
        {
          field: "subscribe_to_mail_list",
          colId: "subscribe_to_mail_list",
          headerName: "Subscribed to Mail List?",
          initialHide: true,
          flex: 1,
          ...(await getColumnAttributes({
            field: "subscribe_to_mail_list",
          })),
        },
      ];

      setColumns(columns);
    }

    if (!loadingDatabase) {
      initCols();
    }
    // eslint-disable-next-line
  }, [database, getColumnAttributes, loadingDatabase]);

  return (
    <Spin
      style={{ width: "100%" }}
      spinning={fetchingCustomFields || submittingSettings}
    >
      <div style={{ padding: 24, background: "#fff" }}>
        <Collapse onChange={() => {}} style={{ width: "100%" }}>
          <Panel header="Donation settings" key="2" style={{ width: "100%" }}>
            <HelpText title={"Custom fields"} />

            <CustomFieldsComponent
              data={customFieldData}
              setData={setCustomFieldData}
              deleteCustomField={deleteCustomField}
              isProducer={true}
              formFieldTypes={[
                {
                  key: customFieldsTypes.checkbox,
                  label: customFieldsTypes.checkbox,
                },
                {
                  key: customFieldsTypes.select,
                  label: customFieldsTypes.select,
                },
                { key: customFieldsTypes.text, label: customFieldsTypes.text },
              ]}
            />

            <SaveWrapper>
              {false ? (
                <Spin size="large" />
              ) : (
                <Button
                  style={{ marginLeft: 'auto' }}
                  type="primary"
                  shape="round"
                  icon={false ? <LoadingOutlined /> : <PlusOutlined />}
                  onClick={handleCreateAndUpdate}
                >
                  Update Donation Settings
                </Button>
              )}
            </SaveWrapper>
          </Panel>
        </Collapse>
      </div>

      <div style={{ padding: 24, background: "#fff", marginTop: 26 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Input
              allowClear
              style={{
                width: 256,
                height: 32,
                marginBottom: 12,
                marginRight: 12,
              }}
              placeholder="Search donations"
              prefix={<SearchOutlined />}
              onChange={(e) => {
                setQuickSearch(e.target.value);
                tableRef.current.api.refreshServerSideStore();
              }}
            />
          </div>

          <div style={{ display: "inline-flex" }}>
            {tableRef?.current && (
              <Dropdown
                trigger="click"
                overlay={
                  <Menu
                    onClick={(val) => {
                      if (val?.key === "fit") {
                        tableRef.current.api.sizeColumnsToFit();
                      } else {
                        tableRef.current.columnApi.autoSizeAllColumns();
                      }
                    }}
                  >
                    <Menu.Item key="fit">Size Columns To Fit View</Menu.Item>
                    <Menu.Item key="content">
                      Size Columns To Fit Content
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  style={{ marginRight: 12, marginBottom: 12 }}
                  type="primary"
                  shape="round"
                  icon={<DownOutlined />}
                >
                  Size Columns
                </Button>
              </Dropdown>
            )}

            <Button
              style={{ marginRight: 12, marginBottom: 12 }}
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              onClick={() => handleAddRecordClick()}
            >
              Add new donation
            </Button>

            <Button
              disabled={isDisableExport}
              icon={exportLoading ? <LoadingOutlined /> : <SaveOutlined />}
              style={{ marginRight: 12, marginBottom: 12 }}
              onClick={() => onClickExportCSV()}
            >
              Export CSV
            </Button>
            <ActionsMenu
              actionsMenu={actionsMenu}
              onDelete={() => {}}
              isLoading={deleteLoading || refundLoding}
              setStatus={() => {}}
              areRowsSelected={selectedRows?.length}
            />
          </div>
        </div>

        <CheckboxWrapper
          style={{
            padding: "0 10px 10px 1px",
          }}
        >
          <Checkbox
            onClick={(e) => {
              const checked = e.target.checked;
              const rows = tableRef.current.api.getRenderedNodes();
              rows.forEach((row) => {
                row.setSelected(checked);
              });

              setSelectedRows(checked);
            }}
          >
            Select Page
          </Checkbox>
        </CheckboxWrapper>

        <Table
          hideGroupBy
          tableRef={tableRef}
          fetchData={fetchData}
          wrapperHeight={false}
          columns={columns}
          gridOptions={{
            onSelectionChanged: (event) => {
              setSelectedRows(event.api.getSelectedNodes());
            },
            groupIncludeTotalFooter: false,
            domLayout: "autoHeight",
            paginationAutoPageSize: false,
          }}
        />
      </div>
    </Spin>
  );
};

export default Donation;
