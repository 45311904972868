import React from "react";
import styled from "styled-components";
import { Input, Button, Form, Divider } from "antd";
import HeaderLinks from "../Links";
import { ThemeColors } from "../../theme";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-divider-vertical {
    height: auto;
    border-left: 1px solid #dadada;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.6rem;

  .ant-form-item-label > label {
    display: flex;
    font-size: 14px;
  }
  .ant-divider-vertical {
    height: auto;
    border-left: 1px solid #dadada;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  margin-left: 0.6rem;
  flex-direction: column;
`;

const ColumnLinkItem = (props) => {
    const {
        link,
        data,
        handleColumnLinks,
        removeColumnLinks,
        handleColumnLinkType,
    } = props;
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <HorizontalContainer>
                <Form.Item
                    label={"Link Column Header"}
                    style={{
                        width: "100%",
                        marginRight: "0.5rem",
                        flexDirection: "column",
                    }}
                >
                    <Input
                        type="text"
                        value={link.header}
                        placeholder={"About Us"}
                        onChange={(e) => {
                            handleColumnLinkType(link.id, e.target.value);
                        }}
                    />
                </Form.Item>

                <Button
                    shape={"circle"}
                    icon={<MinusOutlined/>}
                    style={{
                        alignSelf: "center",
                        visibility: link.id > 0 ? "visible" : "hidden",
                    }}
                    onClick={() => {
                        removeColumnLinks(link.id);
                    }}
                />
            </HorizontalContainer>

            <HeaderLinks
                data={data.map((item) => ({
                    id: parseInt(item.id),
                    title: item.title,
                    url: item.url,
                }))}
                setData={(links) => {
                    handleColumnLinks(link.id, links);
                }}
            />
        </div>
    );
};

const LinkColumns = (props) => {
    const getId = () => {
        return data?.length || 0;
    };

    const {
        data = [
            {
                id: getId(),
                header: "",
                footerColumnsLinks: [{id: 0, title: "", url: ""}],
            },
        ],
    } = props;

    const onUpdateColumnLinks = (updatedLinks) => {
        props.setData(updatedLinks);
    };

    const handleColumnLinks = (id, links) => {
        const updatedLinks = props?.data.map((item) => {
            if (item.id === id) {
                item.footerColumnsLinks = links;
            }
            return item;
        });
        onUpdateColumnLinks(updatedLinks);
    };

    const handleColumnLinkType = (id, header) => {
        let updatedLinks = data.map((item) => {
            if (item.id === id) {
                item.header = header;
            }
            return item;
        });
        onUpdateColumnLinks(updatedLinks);
    };

    const removeColumnLinks = (id) => {
        const updatedLinks = data.filter((item) => {
            return item.id !== id;
        });
        onUpdateColumnLinks(updatedLinks);
    };

    return (
        <VerticalContainer>
            <p
                style={{
                    alignSelf: "flex-start",
                    color: ThemeColors.colors.black,
                    fontWeight: 400,
                    fontSize: 16,
                    marginBottom: "0.4rem",
                }}
            >
                {props.header}
            </p>
            <HorizontalContainer>
                <Divider
                    orientation="left"
                    type="vertical"
                    style={{
                        background: ThemeColors.colors.borderGray,
                    }}
                />

                <Container>
                    {data?.map((link, i) => (
                        <ColumnLinkItem
                            key={i}
                            link={link}
                            data={link.footerColumnsLinks}
                            handleColumnLinks={handleColumnLinks}
                            removeColumnLinks={removeColumnLinks}
                            handleColumnLinkType={handleColumnLinkType}
                        />
                    ))}

                    <Button
                        type="dashed"
                        onClick={() => {
                            const addNewLink = {
                                id: getId(),
                                header: "",
                                footerColumnsLinks: [],
                            };
                            onUpdateColumnLinks([
                                ...data,
                                addNewLink
                            ]);
                        }}
                        style={{width: "95%"}}
                        icon={<PlusOutlined/>}
                    >
                        Add links
                    </Button>
                </Container>
            </HorizontalContainer>
        </VerticalContainer>
    );
};

export default LinkColumns;
