import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import { SaveOutlined } from "@ant-design/icons";
import { Mutations, Queries } from "../../../api";
import HelpText from "../../../components/HelpText";
import { Button, Input, Spin, message } from "antd";
import UploadMultiple from "../../../components/UploadMultiple";
import { useMutation, useLazyQuery } from "@apollo/client";
import { trimErrorMessage } from "../../../utils";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";

const { TextArea } = Input;

const StyledForm = styled.div`
  .colors {
    display: flex;
    > div {
      margin-right: 2rem;
    }
    @media screen and (max-width: 480px) {
      display: block;
      flex-direction: column;
    }
  }
  .save {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .avatar-uploader {
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      width: 14rem;
      height: 14rem;
    }
  }
`;

const ProducerPortalWidget = (props) => {
  const [widgetData, setWidgetData] = useState({
    name: "",
    newProducerMessage: "",
    primaryColor: "#1890ff",
    secondaryColor: "#DB18FF",
  });
  const [thumbnail, setThumbnail] = useState([]);
  const id = props.match.params.id;
  const [createProducerPortalWidget, { loading: createLoading }] = useMutation(
    Mutations.CREATE_PRODUCER_PORTAL_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.createProducerPortalWidget.name} created.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [updateProducerPortalWidget, { loading: updateLoading }] = useMutation(
    Mutations.UPDATE_PRODUCER_PORTAL_WIDGET,
    {
      onCompleted: (res) => {
        message.success(`${res.updateProducerPortalWidget.name} updated.`);
        goToListWidgets();
      },
      onError: (err) => message.error(trimErrorMessage(err.message)),
    }
  );

  const [
    getProducerWidgetById,
    {
      loading: producerstLoading,
      error: producerstError,
      data: producerstWidgetByIdData,
    },
  ] = useLazyQuery(Queries.PRODUCER_PORTAL_WIDGET_BY_ID, {
    variables: {
      id: id,
    },
  });

  useEffect(() => {
    if (id !== "new") {
      getProducerWidgetById();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (producerstError) {
      message.error(producerstError.message);
    }
  }, [producerstError]);

  useEffect(() => {
    if (producerstWidgetByIdData) {
      const producerData = producerstWidgetByIdData.producerPortalWidgetById;
      setThumbnail(producerData.imageUrl);
      setWidgetData({
        ...widgetData,
        name: producerData.name,
        newProducerMessage: producerData.newProducerMessage,
        primaryColor: producerData.primaryColor,
        secondaryColor: producerData.secondaryColor,
      });
    }
    // eslint-disable-next-line
  }, [producerstWidgetByIdData]);

  const goToListWidgets = async () => {
    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();
    props.history.push("/widgets?widget=producer-portal");
  };

  const handleOnSubmit = () => {
    if (widgetData.name === "") {
      message.error("Valid name required!");
    } else {
      id !== "new"
        ? updateProducerPortalWidget({
            variables: {
              id: id,
              name: widgetData.name,
              primaryColor: widgetData.primaryColor,
              secondaryColor: widgetData.secondaryColor,
              newProducerMessage: widgetData.newProducerMessage,
              imageUrl: thumbnail,
            },
          }).catch((e) => {
            if (e.message.includes("Network error")) {
              message.error("Please check your network connection!");
            } else message.error(trimErrorMessage(e.message));
          })
        : createProducerPortalWidget({
            variables: {
              name: widgetData.name,
              primaryColor: widgetData.primaryColor,
              secondaryColor: widgetData.secondaryColor,
              newProducerMessage: widgetData.newProducerMessage,
              imageUrl: thumbnail,
            },
          }).catch((e) => {
            if (e.message.includes("Network error")) {
              message.error("Please check your network connection!");
            } else message.error(trimErrorMessage(e.message));
          });
    }
  };

  const isDisable = () => {
    return !(
      widgetData.name &&
      thumbnail.length > 0 &&
      widgetData.newProducerMessage
    );
  };

  const isUpdated = () => {
    return isDisable()
      ? true
      : updateLoading
      ? true
      : widgetData.name ===
          producerstWidgetByIdData?.producerPortalWidgetById?.name &&
        widgetData.newProducerMessage ===
          producerstWidgetByIdData?.producerPortalWidgetById
            ?.newProducerMessage &&
        widgetData.primaryColor ===
          producerstWidgetByIdData?.producerPortalWidgetById?.primaryColor &&
        widgetData.secondaryColor ===
          producerstWidgetByIdData?.producerPortalWidgetById?.secondaryColor &&
        thumbnail ===
          producerstWidgetByIdData?.producerPortalWidgetById?.imageUrl;
  };

  return (
    <Spin
      spinning={
        (id !== "new" && false) ||
        (id === "new" && false) ||
        createLoading ||
        producerstLoading ||
        updateLoading
      }
    >
      <div style={{ padding: 24, background: "#fff" }}>
        <StyledForm>
          <HelpText title={"Name"} required={true} />
          <Input
            placeholder={"Name of widget"}
            size={"large"}
            value={widgetData.name}
            onChange={(e) =>
              setWidgetData({ ...widgetData, name: e.target.value })
            }
          />
          <div className={"colors"}>
            <div>
              <HelpText title={"Primary color"} />
              <ChromePicker
                disableAlpha
                color={widgetData.primaryColor}
                onChange={(e) =>
                  setWidgetData({ ...widgetData, primaryColor: e.hex })
                }
              />
            </div>
            <div>
              <HelpText title={"Secondary color"} />
              <ChromePicker
                disableAlpha
                color={widgetData.secondaryColor}
                onChange={(e) =>
                  setWidgetData({ ...widgetData, secondaryColor: e.hex })
                }
              />
            </div>
          </div>
          <div>
            <HelpText title={"Login and join image"} required={true} />
            <UploadMultiple
              dir={"ppw/thumbnail"}
              images={thumbnail}
              setImages={(img) => setThumbnail(img.toString())}
              allowImages={1}
              squareCrop={true}
            />
          </div>
          <HelpText title={"New producer Message"} required={true} />
          <TextArea
            rows={4}
            value={widgetData.newProducerMessage}
            style={{ width: "100%" }}
            onChange={(e) =>
              setWidgetData({
                ...widgetData,
                newProducerMessage: e.target.value,
              })
            }
          />
          <div className={"save"}>
            <Button shape="round" size={"large"} onClick={goToListWidgets}>
              Cancel
            </Button>

            <Button
              type="primary"
              shape="round"
              icon={<SaveOutlined />}
              size={"large"}
              disabled={id === "new" ? isDisable() : isUpdated()}
              onClick={() => {
                handleOnSubmit();
              }}
            >
              {id === "new" ? "Create widget" : "Save widget"}
            </Button>
          </div>
        </StyledForm>
      </div>
    </Spin>
  );
};

export default ProducerPortalWidget;
