import React, { useEffect, useState, useReducer } from "react";
import { Avatar, Badge, Drawer, List, Empty } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useWorker } from "../../hooks/useWorker";
import NotificationProvider from "./providers";
import "./styles.css";

const Notifications = ({ position = "bottomRight" }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [positionCSS, setPositionCSS] = useState(null);
  const { workerMessage } = useWorker("websocket");
  const [notifications, setNotifications] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return [...state, action.value];

      default:
        return action.value;
    }
  }, []);

  useEffect(() => {
    let css;
    switch (position) {
      case "bottomLeft":
        css = {
          bottom: "20px",
          left: "20px",
        };

        break;

      case "topLeft":
        css = {
          top: "20px",
          left: "20px",
        };

        break;

      case "topRight":
        css = {
          top: "20px",
          right: "20px",
        };

        break;

      default:
        css = {
          bottom: "30px",
          right: "90px",
        };

        break;
    }

    setPositionCSS(css);
  }, [position]);

  useEffect(() => {
    if (workerMessage) {
      if (workerMessage?.subject !== "neon:user") {
        setNotifications({
          type: "add",
          value: workerMessage,
        });
      }
    }
  }, [workerMessage]);

  if (!positionCSS) {
    return <span />;
  }

  return (
    <>
      <Drawer
        closable
        width={300}
        title="Notifications"
        placement="right"
        onClose={() => setDrawerOpen(false)}
        visible={drawerOpen}
      >
        <div className="notifications-list-wrap">
          <List
            size="small"
            itemLayout="vertical"
            dataSource={notifications}
            renderItem={(notification) => (
              <NotificationProvider notification={notification} />
            )}
            locale={{
              emptyText: (
                <Empty
                  description="No Notifications"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            }}
          />
        </div>
      </Drawer>

      <div
        style={{
          cursor: "pointer",
          position: "fixed",
          zIndex: 1000,
          ...positionCSS,
        }}
        onClick={() => setDrawerOpen(true)}
      >
        <Badge showZero={false} count={notifications.length}>
          <Avatar
            icon={<BellOutlined />}
            size="large"
            style={{ backgroundColor: "#001529", verticalAlign: "middle" }}
          />
        </Badge>
      </div>
    </>
  );
};

export default Notifications;
