import React from "react";
import { Spin } from "antd";
import styled from "styled-components";

const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.7;
  height: 100%;
  z-index: 1;
  position: fixed;
  transition: 0.5s;
  overflow-x: hidden;
  background-color: #001529;
  display: ${(props) => (props.visible ? "block" : "none")};
`;

const OverlaySpinner = (props) => {
    const {visible = true} = props;
    return (
        <Overlay visible={visible}>
            <Spin
                size="large"
                style={{
                    top: "50%",
                    left: "50%",
                    position: "fixed",
                }}
            />
        </Overlay>
    );
};

export default OverlaySpinner;
