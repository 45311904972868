import HelpText from "../../../../components/HelpText";
import { Button, Input, message, Switch } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDatabase } from "../../../../hooks/database";
import { getSession } from "../../../../helpers/SessionManagement";

const StyledForm = styled.div`
  .save {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  button {
    margin-top: 2rem;
  }
  .spacer {
    width: 100%;
    height: 15px;
  }
`;

const rentleDomain = 'https://rentle.store/';

const Rentle = () => {
  const { database, loading: databaseLoading } = useDatabase();
  const [loading, setLoading] = useState(true);
  const session = getSession();
  const [integration, setIntegration] = useState({
    'id': null,
    'api_key_id': null,
    'api_key_secret': null,
    'domain_path': null,
    'enabled': true,
    'organization_id': null,
  })

  const isCreate = !integration.id;
  const isDisabled = !integration.api_key_id || !integration.api_key_secret || !integration.domain_path

  const queryRentleIntegration = async () => {
    setLoading(true);
    const res = await database.items("rentle_integrations").readMany({
      filter: {organization_id: session?.organization?.id},
      fields: [
        'id',
        'api_key_id',
        'api_key_secret',
        'domain_path',
        'enabled'
      ],
    })
    return res?.data?.[0]
  }

  useEffect(() => {
    if (!databaseLoading && session?.organization?.id) queryRentleIntegration().then(data => {
      if (data) setIntegration({...data, organization_id: session?.organization?.id})
      else setIntegration({...integration, organization_id: session?.organization?.id})
      setLoading(false);
    });
  }, [databaseLoading, session?.organization?.id, database])

  const submitIntegration = async () => {
    setLoading(true);
    const variables = {...integration};
    delete variables.id;
    if (isCreate) {
      const res = await database.items("rentle_integrations").createOne({
        ...variables
      });
      setIntegration({...integration, id: res?.id})
      message.success("Rentle integration created successfully");
    } else {
      delete variables.organization_id;
      await database.items("rentle_integrations").updateOne(integration.id, {
        ...variables
      });
      message.success("Rentle integration updated successfully");
    }
    setLoading(false);
  }

  return (
    <StyledForm>
      <HelpText title={"Rentle API Key ID"} required={true} />
      <Input.Password
        size="large"
        placeholder={"Your Rentle API key ID"}
        value={integration.api_key_id}
        onChange={(e) =>
          setIntegration({ ...integration, api_key_id: e.target.value })
        }
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <div className={'spacer'} />
      <HelpText title={"Rentle API Key Secret"} required={true} />
      <Input.Password
        size="large"
        placeholder={"Your Rentle API key secret"}
        value={integration.api_key_secret}
        onChange={(e) =>
          setIntegration({ ...integration, api_key_secret: e.target.value })
        }
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <div className={'spacer'} />
      <HelpText title={"Rentle Domain Path"} required={true} />
      <Input
        addonBefore={rentleDomain}
        size="large"
        placeholder={"Your store name"}
        value={integration.domain_path?.replace(rentleDomain, '')}
        onChange={(e) =>
          setIntegration({ ...integration, domain_path: rentleDomain + e.target.value })
        }
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <div className={'spacer'} />
      <div className={'spacer'} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch
          style={{ marginTop: 0, marginRight: "10px" }}
          checked={!!integration.enabled}
          onChange={(check) => setIntegration({ ...integration, enabled: check })}
        />
        <span>Enable Rentle</span>
      </div>

      <div className={"save"}>
        <Button
          type="primary"
          shape="round"
          size={"large"}
          icon={
            loading ? (
              <LoadingOutlined />
            ) : (
              <SaveOutlined />
            )
          }
          disabled={loading || isDisabled}
          onClick={submitIntegration}
        >
          {`${isCreate ? 'Save ' : 'Update '} credentials`}
        </Button>
      </div>
    </StyledForm>
  )
}

export default Rentle