import { Logo, LogoIcon } from "./icons";

const ThemeColors = {
    colors: {
        brand: "#1890FF",
        background: "#FFFFFF",
        white: "#FFFFFF",
        lightWhite: "#F0F2F5",
        green: "#008000",
        lightGreen: "#f0fff0",
        red: " #ff0000",
        lightRed: "#ffe4e1",
        black: "#343434",
        grey: "#A0A9BA",
        lightGrey: "#8C8C8C",
        gray: "#BFBFBF",
        gray77: "#C4C4C4",
        gray98: "#FAFAFA",
        grayBorder: "#DADADA",
        gray85: "#D9D9D9",
        polarGreen: "#F6FFED",
        gray9: "#262626",
        lightBlue: "#E6F7FF",
        orange: "#FA8C16",
    },
};

export { ThemeColors, Logo, LogoIcon };
