import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, Divider, message, Tag } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getSession } from "../../helpers/SessionManagement";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import DeleteConfirm from "../../components/DeleteConfirm/DeleteConfirm";
import { useDatabase } from "../../hooks/database";
import { useEntities, useEntityTable } from "../../hooks/entity";

const Podcasts = (props) => {
  const { database } = useDatabase();
  const tableRef = useRef(null);
  const session = getSession();

  const staticFilters = {
    organization_id: {
      _eq: session?.organization?.id,
    },
  };

  const { fetchData } = useEntities({
    baseEntity: "podcasts",
    staticFilters,
    fields: [
      "id",
      "title",
      "description",
      "image",
      "rss_feed",
      "created_at",
      "updated_at",
      "name",
      "published",
    ],
  });

  const { getColumnAttributes, loading } = useEntityTable({
    baseEntity: "podcasts",
    staticFilters,
  });

  const [refreshingPodcast, setRefreshingPodcast] = useState(null);
  const refreshLoading = !!refreshingPodcast;
  const [columns, setColumns] = useState([]);

  const onRefresh = async (id, name, rss) => {
    setRefreshingPodcast({
      id: id,
      name: name,
    });
    await database.items("podcasts").updateOne(id, {rss_feed: rss});
    message.success(name + " successfully refreshed.");
    setRefreshingPodcast(null);
  };

  const onDelete = async (id, name) => {
    if (id) {
      await database.items("podcasts").deleteOne(id);
      message.success(name + " deleted");
      tableRef.current.api.refreshServerSideStore();
    }
  };

  useEffect(() => {
    async function initCols() {
      const columns = [
        {
          field: "image",
          colId: "image",
          headerName: "",
          flex: 1,
          maxWidth: 75,
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return <Avatar src={params?.value} />;
          },
        },
        {
          field: "name",
          colId: "name",
          headerName: "Name",
          flex: 1,
          ...(await getColumnAttributes({
            field: "name",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/podcasts/${params?.data?.id}`}>{params?.value}</Link>
            );
          },
        },
        {
          field: "rss_feed",
          colId: "rss_feed",
          headerName: "RSS Feed",
          flex: 1,
          ...(await getColumnAttributes({
            field: "rss_feed",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span style={{ display: "inline-flex" }}>
                <Tag color={"orange"} style={{ overflowX: "scroll" }}>
                  {params?.value}
                </Tag>
              </span>
            );
          },
        },
        {
          field: "published",
          colId: "published",
          headerName: "Status",
          flex: 1,
          ...(await getColumnAttributes({
            field: "published",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span>
                {!params?.value && (
                  <Tag color="red" size="small">
                    Not published
                  </Tag>
                )}

                {params?.value && <Tag color="green">published</Tag>}
              </span>
            );
          },
        },
        {
          field: "action",
          colId: "action",
          headerName: "Action",
          flex: 1,
          minWidth: 250, // @todo figure out how to override width
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span style={{ height: "100%" }}>
                <Button
                  icon={
                    refreshLoading &&
                    refreshingPodcast.id === params?.data?.id ? (
                      <LoadingOutlined />
                    ) : (
                      <PlusOutlined />
                    )
                  }
                  disabled={refreshLoading}
                  type={"secondary"}
                  onClick={() =>
                    onRefresh(params?.data?.id, params?.data?.name, params?.data?.rss_feed)
                  }
                >
                  {refreshLoading && refreshingPodcast.id === params?.data?.id
                    ? "Refresh"
                    : "Refresh"}
                </Button>
                <Divider type="vertical" style={{ alignSelf: "center" }} />
                <DeleteConfirm
                  name={params?.data?.name}
                  action={() => onDelete(params?.data?.id, params?.data?.name)}
                />
              </span>
            );
          },
        },
      ];

      setColumns(columns);
    }

    if (!loading) {
      initCols();
    }
  }, [database, getColumnAttributes, loading]);

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        size={"large"}
        onClick={() => props.history.push("/podcasts/new")}
      >
        Add a podcast
      </Button>
      <div style={{ marginTop: "2rem" }} />

      <Table
        hideGroupBy
        tableRef={tableRef}
        fetchData={fetchData}
        wrapperHeight={750}
        columns={columns}
        gridOptions={{
          groupIncludeTotalFooter: false,
        }}
      />
    </div>
  );
};

export default Podcasts;
