import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { DASH } from "../../utils";
import { Button, Popover, Table } from "antd";
import SelectFieldsModel from "../SelectFieldsModel";
import { PlusCircleOutlined } from "@ant-design/icons";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .table-selected-row-count {
    margin-left: 8px;
    visibility: ${(props) => (props.isSelected ? "unset" : "hidden")};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    overflow: auto;
  }
  .ant-table-wrapper {
    width: 100%;
  }
`;

const PaginatedTableview = (props) => {
    const {
        data = [],
        withPopover,
        current = 1,
        columns = [],
        selectedRows,
        pageSize = 1,
        pageSizeOptions = [],
        showSizeChanger = false,
        totalCount = 2,
        onRowSelection,
        loading = false,
        customFieldsTypes = [],
        onTableChange = () => {
        },
        defaultVisibleFields = [],
        setVisibleColumns = false,
        customColumns = new Map([]),
        onResetColumns,
        onShowSizeChange,
        scroll = {}
    } = props;
    const [showPopup, setShowPopup] = useState();
    const [visibleFields, setVisibleFields] = useState(defaultVisibleFields);
    useEffect(() => {
        if (setVisibleColumns) setVisibleColumns(visibleFields);
    }, [
        visibleFields,
        setVisibleColumns
    ]);

    const CreateColumn = (title, id, type) => {
        return {
            title: (
                <p style={{width: 100, margin: 0, textAlign: "center"}}>{title}</p>
            ),
            dataIndex: title,
            key: title.toLowerCase().replace(" ", ""),

            render: (data, record) => (
                <p style={{textAlign: "center"}}>
                    {record.customFieldValue
                        ? record.customFieldValue.map((field) => {
                            if (field?.id === id && field?.value) {
                                try {
                                    const fieldValue = JSON.parse(
                                        field?.value.replace(/'/g, '"')
                                    );
                                    return fieldValue
                                        ? type === customFieldsTypes.select
                                            ? fieldValue.toString()
                                            : type === customFieldsTypes.checkbox
                                                ? fieldValue
                                                    ? "True"
                                                    : "False"
                                                : type === customFieldsTypes.text
                                                    ? fieldValue
                                                    : type === customFieldsTypes.dateTime
                                                        ? dayjs(fieldValue, "YYYY-MM-DDThh:mm:ssZ").format(
                                                            "MM-DD-YYYY"
                                                        )
                                                        : ""
                                        : "";
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        })
                        : DASH}
                </p>
            ),
        };
    };

    const getVisibleColumns = () => {
        const visibleColumnObjects = [];
        const allFields = new Map([...columns]);
        visibleFields.map((field) => {
            const df = allFields.get(field);
            if (df) visibleColumnObjects.push(df);
            if (customColumns.size > 0) {
                const cf = customColumns.get(field);
                cf && visibleColumnObjects.push(CreateColumn(cf.name, cf.id, cf.type));
            }
            return field;
        });

        return visibleColumnObjects;
    };

    const handleOnCustomFieldSelected = (
        item,
        checked,
        visibleFields,
        setVisibleFields
    ) => {
        if (checked) {
            setVisibleFields([
                ...visibleFields,
                item
            ]);
        } else {
            const updatedFields = [...visibleFields];
            const pos = updatedFields.findIndex((i) => i === item);
            updatedFields.splice(pos, 1);
            setVisibleFields([...updatedFields]);
        }
    };

    const handleResetSelectedCustomFields = () => {
        setVisibleFields(onResetColumns);
        setShowPopup(false);
    };

    return (
        <TableWrapper isSelected={selectedRows.length > 0}>
            {withPopover && (
                <Popover
                    content={
                        <SelectFieldsModel
                            onSelected={(item, checked) =>
                                handleOnCustomFieldSelected(
                                    item,
                                    checked,
                                    visibleFields,
                                    setVisibleFields
                                )
                            }
                            closeModel={() => setShowPopup(false)}
                            customfields={[...customColumns.keys()]}
                            defaultField={[...new Map(columns).keys()]}
                            onResetSelection={handleResetSelectedCustomFields}
                            isChecked={(item) => visibleFields.includes(item)}
                        />
                    }
                    trigger="click"
                    visible={showPopup}
                    placement="bottomRight"
                >
                    <ButtonWrapper>
                        <Button
                            style={{
                                color: "#1890FF",
                                padding: 0,
                            }}
                            type={"link"}
                            icon={<PlusCircleOutlined/>}
                            onClick={() => setShowPopup(true)}
                        >
                            {"Add Column"}
                        </Button>
                    </ButtonWrapper>
                </Popover>
            )}
            <span className={"table-selected-row-count"}>
        {`Selected ${selectedRows.length} items`}
      </span>
            <TableContainer>
                <Table
                    pagination={{
                        current: current,
                        simple: false,
                        total: totalCount,
                        defaultPageSize: pageSize,
                        pageSizeOptions: pageSizeOptions,
                        showLessItems: false,
                        showQuickJumper: true,
                        showSizeChanger: showSizeChanger,
                        onShowSizeChange: onShowSizeChange,
                    }}
                    loading={loading}
                    dataSource={data || []}
                    rowKey={(row) => row.id}
                    rowSelection={onRowSelection}
                    columns={getVisibleColumns()}
                    selectedRowKeys={selectedRows}
                    onChange={(page, filters, sort, extra) => onTableChange(page, filters, sort, extra)}
                    scroll={scroll}
                />
            </TableContainer>
        </TableWrapper>
    );
};

export default PaginatedTableview;
