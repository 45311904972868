import React, { useEffect, useRef, useState } from "react";
import { Queries } from "../../api";
import RadioColumns from "./columns";
import { Avatar, Button, Divider, message, Tag } from "antd";
import Mutations from "../../api/Mutations";
import { trimErrorMessage } from "../../utils";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import SimpleTableView from "../../components/simpleTableView";
import Table from "../../components/Table";
import { useDatabase } from "../../hooks/database";
import { getSession } from "../../helpers/SessionManagement";
import { useEntities, useEntityTable } from "../../hooks/entity";
import { Link } from "react-router-dom";
import DeleteConfirm from "../../components/DeleteConfirm/DeleteConfirm";

const Radio = (props) => {
  const { database } = useDatabase();
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const session = getSession();

  const staticFilters = {
    organization_id: {
      _eq: session?.organization?.id,
    },
  };

  const { fetchData } = useEntities({
    baseEntity: "radios",
    staticFilters,
    fields: ["*"],
  });

  const { getColumnAttributes, loading: loadingDatabase } = useEntityTable({
    baseEntity: "radios",
    staticFilters,
  });

  const { loading: dataLoading, data } = useQuery(Queries.ALL_RADIO, {
    onError: (err) => message.error(trimErrorMessage(err.message)),
  });
  const localClient = useApolloClient();
  const [current, setCurrent] = useState(
    localClient.cache.readQuery({ query: Queries.GET_PAGE_NUMBER })
      ?.pageNumber || 1
  );

  const [deleteRadio, { loading: deleteLoading }] = useMutation(
    Mutations.DELETE_RADIO,
    {
      onError: () =>
        message.error(
          "This podcast is used by a widget! You most remove dependencies first."
        ),
      onCompleted: (deleteData) =>
        message.success(`${deleteData.deleteRadio.name} deleted.`),
      update(cache, { data: { deleteRadio } }) {
        let { radios } = cache.readQuery({ query: Queries.ALL_RADIO });
        radios = radios.filter((radio) => {
          return radio.id !== deleteRadio.id;
        });
        cache.writeQuery({
          query: Queries.ALL_RADIO,
          data: { radios: radios },
        });
      },
    }
  );

  const onEdit = (id) => props.history.push(`/radio/${id}`);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onDelete = (id) => deleteRadio({ variables: { id: id } });
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedRowKeys,
  };

  const onChange = (page) => {
    localClient.writeQuery({
      query: Queries.GET_PAGE_NUMBER,
      data: {
        pageNumber: page.current,
      },
    });
    setCurrent(page.current);
  };

  useEffect(() => {
    async function initCols() {
      const columns = [
        {
          field: "image",
          colId: "image",
          headerName: "Image",
          flex: 1,
          maxWidth: 100,
          ...(await getColumnAttributes({
            field: "image",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return <Avatar src={params?.value}>{params?.value}</Avatar>;
          },
        },
        {
          field: "name",
          colId: "name",
          headerName: "Name",
          flex: 1,
          ...(await getColumnAttributes({
            field: "name",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Link to={`/radio/${params?.data?.id}`}>{params?.value}</Link>
            );
          },
        },
        {
          field: "station",
          colId: "station",
          headerName: "Station",
          flex: 1,
          ...(await getColumnAttributes({
            field: "station",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return params?.value || "";
          },
        },
        {
          field: "stream_url",
          colId: "stream_url",
          headerName: "Stream URL",
          flex: 1,
          ...(await getColumnAttributes({
            field: "stream_url",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <Tag
                color="orange"
                style={{
                  maxWidth: "200px",
                  overflowX: "scroll",
                  display: "inline-flex",
                }}
              >
                {params?.value || "Not Provided"}
              </Tag>
            );
          },
        },
        {
          field: "published",
          colId: "published",
          headerName: "Status",
          flex: 1,
          ...(await getColumnAttributes({
            field: "published",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span style={{ display: "inline-flex" }}>
                {!params?.value && (
                  <Tag
                    color="red"
                    size="small"
                    style={{ maxWidth: "70px", overflowX: "scroll" }}
                  >
                    Not published
                  </Tag>
                )}

                {params?.value && (
                  <Tag
                    color="green"
                    style={{ maxWidth: "150px", overflowX: "scroll" }}
                  >
                    Published
                  </Tag>
                )}
              </span>
            );
          },
        },
        {
          field: "actions",
          colId: "actions",
          headerName: "Actions",
          flex: 1,
          minWidth: 250, // @todo figure out how to override width
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span style={{ display: "inline-flex" }}>
                {/*<Button*/}
                {/*  type={"primary"}*/}
                {/*  icon={<EditOutlined />}*/}
                {/*  onClick={() => onEdit(params?.data?.id)}*/}
                {/*>*/}
                {/*  Edit*/}
                {/*</Button>*/}
                {/*<Divider type="vertical" style={{ alignSelf: "center" }} />*/}
                <DeleteConfirm
                  name={params?.data?.name}
                  action={() => onDelete(params?.data?.id)}
                />
              </span>
            );
          },
        },
      ];

      setColumns(columns);
    }

    if (!loadingDatabase) {
      initCols();
    }
    // eslint-disable-next-line
  }, [database, getColumnAttributes, loadingDatabase]);

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        size={"large"}
        onClick={() => props.history.push("/radio/new")}
      >
        Add a radio station
      </Button>

      <div style={{ marginTop: "2rem" }} />

      <Table
        hideGroupBy
        tableRef={tableRef}
        fetchData={fetchData}
        wrapperHeight={false}
        columns={columns}
        gridOptions={{
          groupIncludeTotalFooter: false,
          domLayout: "autoHeight",
          paginationAutoPageSize: false,
        }}
      />
    </div>
  );
};

export default Radio;
