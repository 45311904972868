import React from "react";
import { Table } from "antd";
import styled from "styled-components";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .table-selected-row-count {
    margin-left: 8px;
    visibility: ${(props) => (props.isSelected ? "unset" : "hidden")};
  }
`;

const SimpleTableView = ({
                             data = [],
                             loading = false,
                             columns = [],
                             rowType = false,
                             onChange = () => {
                             },
                             rowSelection = {},
                             selectedRowKeys = [],
                             scroll = {},
                             current = 1
                         }) => {
    return (
        <TableWrapper isSelected={selectedRowKeys.length > 0}>
      <span className={"table-selected-row-count"}>
        {`Selected ${selectedRowKeys.length} items`}
      </span>
            <Table
                scroll={scroll}
                dataSource={data}
                loading={loading}
                columns={columns}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                onChange={(page) => onChange(page)}
                rowKey={(row) => (rowType ? row.id + row.type : row.id)}
                pagination={{current: current}}
            />
        </TableWrapper>
    );
};

export default SimpleTableView;
