import React, { useState } from "react";
import styled from "styled-components";
import Queries from "../../../api/Queries";
import Mutations from "../../../api/Mutations";
import ResetUserPassword from "./resetPassword";
import { ApolloAuthClient } from "../../../api";
import { trimErrorMessage } from "../../../utils";
import DeletePopUp from "../../../components/DeletePopUp";
import { columns, defaultVisibleFields } from "./columns";
import { useQuery, useMutation } from "@apollo/client";
import { getSession } from "./../../../helpers/SessionManagement";
import { Button, Spin, message, Menu, Input, Dropdown } from "antd";
import { UserRole, PAGINATION_COUNTS } from "../../../utils/contants";
import PaginatedTableview from "../../../components/paginatedTableView";
import { DownOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import ExportCsv from "../exportCsv";

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 16px;

  .TextInput {
    width: 256px;
    height: 32px;
    min-width: 100px;
  }
  .Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const TableContainer = styled.div`
  margin-top: 1rem;
  .ant-table-content {
    overflow-x: auto;
  }
`;

export default function StaffUsers(props) {
  const isAdmin = getSession()?.userType !== UserRole.ADMIN;
  const [searchText, setSearchText] = useState("");
  const [resendLoading, setResendLoading] = useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState();
  const [selectedUserKeys, setSelectedUserKeys] = useState([]);
  const [passwordModelVisible, setPasswordModelVisible] = useState(false);
  const localClient = useApolloClient();
  const cachedData = localClient.cache.readQuery({
    query: Queries.GET_PAGE_NUMBER,
  });
  const {
    data: staffData,
    loading: staffLoading,
    fetchMore: fetchMorStaff,
  } = useQuery(Queries.ALL_USERS_QUERY, {
    variables: {
      page: cachedData?.pageNumber || 1,
      staffOnly: true,
      query: searchText,
      per: PAGINATION_COUNTS.USERS,
    },
    fetchPolicy: "network-only",
    onError: (err) => message.error(trimErrorMessage(err.message)),
  });

  const [deleteUsers, { loading: deleteLoading }] = useMutation(
    Mutations.DELETE_USERS
  );

  const handleChangePassword = async (
    currentPassword,
    newPassword,
    confirmPassword
  ) => {
    setPasswordModelVisible(false);
    setResendLoading(true);
    try {
      const result = await ApolloAuthClient.client.mutate({
        mutation: Mutations.UPDATE_USER_PASSWORD,
        variables: {
          password: newPassword,
          currentPassword: currentPassword,
          passwordConfirmation: confirmPassword,
        },
      });
      setResendLoading(false);
      if (result.data) {
        message.success(
          "Your password successfully updated. Please try to login with new password"
        );
      }
    } catch (e) {
      if (e.message.includes("Network error")) {
        message.error("Please check your network connection!");
      } else message.error(trimErrorMessage(e.message));
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedUserKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedUserKeys,
  };

  const onSearchChange = (val) => {
    setSearchText(val.trim());
  };

  const onDeleteSelected = () =>
    deleteUsers({
      variables: {
        ids: selectedUserKeys,
      },
    }).catch((err) => message.error(trimErrorMessage(err.message)));

  const actionsMenu = () => (
    <Menu>
      <Menu.Item>
        <DeletePopUp
          action={() => onDeleteSelected()}
          title="Confirm delete"
          message="Are you sure you want to delete these records?"
        >
          Delete
        </DeletePopUp>
      </Menu.Item>
    </Menu>
  );

  const onResetPassword = () => {
    setPasswordModelVisible(true);
  };

  const onTableChange = (page, filter) => {
    setFetchMoreLoading(true);
    localClient.writeQuery({
      query: Queries.GET_PAGE_NUMBER,
      data: {
        pageNumber: page.current,
      },
    });
    fetchMorStaff({
      variables: {
        staffOnly: true,
        query: searchText,
        page: page.current,
        per: PAGINATION_COUNTS.USERS,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    }).finally(() => setFetchMoreLoading(false));
  };

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Spin spinning={resendLoading || deleteLoading}>
        <TableHeader>
          <Input
            value={searchText}
            prefix={<SearchOutlined />}
            placeholder={"Search staff"}
            style={{ width: 256, height: 40 }}
            size={"medium"}
            onChange={(e) => onSearchChange(e.target.value)}
          />
          {!isAdmin && (
            <div className="Actions">
              <Button
                shape="round"
                type="primary"
                size={"medium"}
                disabled={isAdmin}
                icon={<PlusOutlined />}
                style={{ marginRight: 12 }}
                onClick={() => props.history.push("/users/staff/create")}
              >
                Add staff
              </Button>
              <ExportCsv />
              <Dropdown
                trigger="click"
                overlay={actionsMenu}
                disabled={!selectedUserKeys.length > 0}
              >
                <Button style={{ width: 135, height: 32 }}>
                  Action <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}
        </TableHeader>
        <TableContainer>
          <PaginatedTableview
            withPopover={false}
            onTableChange={onTableChange}
            selectedRows={selectedUserKeys}
            data={staffData?.users?.nodes || []}
            current={staffData?.users?.metadata?.currentPage || 1}
            onResetColumns={defaultVisibleFields}
            defaultVisibleFields={defaultVisibleFields}
            loading={staffLoading || fetchMoreLoading}
            columns={columns(props.history, onResetPassword)}
            pageSize={
              staffData?.users?.metadata?.limitValue ||
              PAGINATION_COUNTS.default
            }
            totalCount={staffData?.users?.metadata?.totalCount}
            onRowSelection={
              getSession().userType !== UserRole.STAFF ? rowSelection : false
            }
          />
        </TableContainer>

        <ResetUserPassword
          setPasswordModelVisible={setPasswordModelVisible}
          handleChangePassword={handleChangePassword}
          passwordModelVisible={passwordModelVisible}
        />
      </Spin>
    </div>
  );
}
