import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { Form, message } from "antd";
import UserInfoForm from "../UserInfoForm";
import { UserRole } from "../../utils/contants";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { getSession } from "./../../helpers/SessionManagement";
import { initialSocialLink, trimErrorMessage } from "../../utils";
import { Queries, Mutations } from "../../api";

import { clearDirectusCache } from "../../helpers/ClearDirectusCache";

const UpdateUser = (props) => {
  const [form] = Form.useForm();
  const [userData, setUserData] = useState();
  const passedUserId = props.match.params.id;
  const [squareImage, setSquareImage] = useState("");
  const isLoggedInUser = getSession().id === passedUserId;
  const isAdmin = getSession().userType === UserRole.ADMIN;
  const [socialLinksList, setSocialLinksList] = useState(initialSocialLink);
  const [isProducersUpdate, setIsProducer] = useState(false);

  const [getMeData, { loading: meLoading, error: meError, data: meData }] =
    useLazyQuery(Queries.ME_QUERY);

  const [
    getUserById,
    { loading: userLoading, error: userError, data: userByIdData },
  ] = useLazyQuery(Queries.GET_USER_BY_ID, {
    variables: {
      id: passedUserId,
    },
    fetchPolicy: "network-only",
  });

  const [
    reqEmailChange,
    { loading: emailChangeLoading, data: emailChangeData },
  ] = useMutation(Mutations.REQUEST_EMAIL_CHANGE);

  const [
    updateUser,
    { loading: updateLoading, data: updateData, error: updateError },
  ] = useMutation(Mutations.UPDATE_USER);

  const [
    updateOtherUser,
    {
      loading: updateOtherUserLoading,
      data: updateOtherUserData,
      error: updateOtherUserError,
    },
  ] = useMutation(Mutations.UPDATE_USER_INFORMATION);

  const { data: producerFieldsData, loading: producerFieldsLoading } = useQuery(
    Queries.PRODUCER_SETTINGS
  );

  useEffect(() => {
    if (updateError) {
      message.error(trimErrorMessage(updateError.message));
    }
    if (updateOtherUserError) {
      message.error(trimErrorMessage(updateOtherUserError.message));
    }
  }, [updateError, updateOtherUserError]);

  useEffect(() => {
    if (false) {
      // @todo this should be enforced on the API!!!
      message.error("You aren't authorized to access this info");
      handleFinish();
    }

    if (isLoggedInUser) {
      getMeData();
    } else {
      getUserById();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (emailChangeData) {
      message.success("Change email request sent!");
    }
    // eslint-disable-next-line
  }, [emailChangeData]);

  useEffect(() => {
    if (updateData?.updateUser) {
      handleUpdateUserSuccess(updateData.updateUser);
    }
    // eslint-disable-next-line
  }, [updateData]);

  useEffect(() => {
    if (updateOtherUserData?.updateUserInformation) {
      handleUpdateUserSuccess(updateOtherUserData.updateUserInformation);
    }
    // eslint-disable-next-line
  }, [updateOtherUserData]);

  useEffect(() => {
    if (userByIdData) {
      setUserData(userByIdData.userById);
    }
    if (userError) {
      message.error(trimErrorMessage(userError.message));
      handleFinish();
    }
    // eslint-disable-next-line
  }, [userByIdData, userError]);

  useEffect(() => {
    if (userData) {
      setIsProducer(userData.userType === UserRole.PRODUCER);
      let customField = [];
      if (userData?.customFieldValue?.length > 0) {
        userData.customFieldValue.forEach((obj) => {
          customField[obj.id] = obj.value
            ? moment(JSON.parse(obj.value), moment.ISO_8601).isValid()
              ? moment(JSON.parse(obj.value), moment.ISO_8601)
              : JSON.parse(obj.value)
            : undefined;
        });
      }
      form.setFieldsValue({
        ...customField,
        email: userData.email,
        name: userData.name,
        firstname: userData.firstname || userData.name.split(" ")[0],
        lastname: userData.lastname || userData.name.split(" ")[1],
        bio: userData.bio,
        state: userData.state,
        city: userData.city,
        phone: userData.phone,
        zipcode: userData.zipcode,
        address: userData.address,
        username: userData.username,
        userType: userData.userType,
      });
      !!userData.avatar && setSquareImage(userData.avatar);
      !!userData.socialMediaLinks &&
        setSocialLinksList(
          userData.socialMediaLinks?.map((item, id) => ({
            id,
            url: item.url,
            mediaType: item.mediaType,
          }))
        );
    }
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (meData) {
      setUserData(meData.me);
    }

    if (meError) {
      message.error(meError.message);
      handleFinish();
    }
    // eslint-disable-next-line
  }, [meData, meError]);

  const handleUpdateUserSuccess = (user) => {
    message.success(`${user.name} updated successfully`);
    handleFinish();
  };

  const handleFinish = () => {
    if (isProducersUpdate) {
      props.history.push("/users/producer");
    } else {
      props.history.push("/users/staff");
    }
  };

  const isAuthorized = () => {
    return isAdmin || isLoggedInUser;
  };

  const onSubmit = async () => {
    const {
      firstname,
      lastname,
      bio,
      state,
      city,
      phone,
      zipcode,
      address,
      username,
      userType,
    } = form.getFieldsValue();
    const formData = form.getFieldsValue();
    const remoteFields =
      producerFieldsData?.producerSetting?.customFields || [];

    const customFieldValue = remoteFields.map((f) => {
      return {
        id: f.id,
        value:
          formData[f.id] === undefined ? "" : JSON.stringify(formData[f.id]),
      };
    });

    let variables = {
      variables: {
        customFieldValue,
        name: `${firstname} ${lastname}`,
        bio,
        state,
        city,
        lastname,
        firstname,
        token: "",
        phone,
        avatar: squareImage ? squareImage : userData.avatar,
        zipcode,
        userType,
        address,
        socialMediaLinks: socialLinksList.filter(
          (item) => item.url && delete item.id
        ),
      },
    };

    // Clearing directus cache for showing table view with updated records
    await clearDirectusCache();

    if (isLoggedInUser) {
      variables.variables.username = username;
      updateUser(variables);
    } else {
      variables.variables.userId = passedUserId;
      updateOtherUser(variables);
    }
  };

  const handleEmailChange = (email) => {
    reqEmailChange({
      variables: {
        newEmail: email,
        userId: isLoggedInUser ? "" : passedUserId,
        redirectUrl: `${window.location.origin}/email-change-request`,
      },
    });
  };

  return (
    <div>
      <UserInfoForm
        form={form}
        userData={userData}
        onSubmit={onSubmit}
        loading={
          updateLoading ||
          meLoading ||
          userLoading ||
          updateOtherUserLoading ||
          producerFieldsLoading ||
          emailChangeLoading
        }
        disableUsername={!isLoggedInUser}
        squareImage={squareImage}
        setSquareImage={setSquareImage}
        socialLinksList={socialLinksList}
        setSocialLinksList={setSocialLinksList}
        disabledFields={{ email: true }}
        isUpdateMode={true}
        customFields={producerFieldsData?.producerSetting?.customFields}
        onCancel={handleFinish}
        handleEmailChange={handleEmailChange}
      />
    </div>
  );
};
export default UpdateUser;
