import React from "react";
import dayjs from "dayjs";
import {
  defaultStatus,
  defaultFieldKeys,
  defaultPublishStatus,
  MastercontrollColKeys,
} from "../../utils/contants";
import { getFormatedDate, getFormatedDateAndTime, DASH } from "../../utils";
import styled from "styled-components";
import moment from "moment";
import { Tag, Avatar, Button, DatePicker, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const Cell = styled.p`
  margin: 0;
`;

export const ScrollableCell = styled.p`
  max-width: 180px;
  overflow-x: scroll;
  margin-bottom: 0;
  display: inline-flex;
`;

export const TagsContainer = styled.span`
  display: flex;
`;

const publishFilters = [
  { text: "Published", value: true },
  { text: "Unpublished", value: false },
];

const getStatusFilters = [
  { text: "In review", value: defaultStatus.inReview },
  { text: "Accepted", value: defaultStatus.accept },
  { text: "Decline", value: defaultStatus.declined },
  { text: "Draft", value: defaultStatus.draft },
];

const getStatusItemColor = (status) =>
  status === defaultStatus.accept
    ? "green"
    : status === defaultStatus.declined
    ? "red"
    : status === defaultStatus.draft
    ? "#e9e9e9"
    : "orange";

const getPublishItemColor = (isPublished) => (isPublished ? "green" : "red");

const getPublishedValue = (isPublished) =>
  isPublished ? defaultPublishStatus.publish : defaultPublishStatus.unpublished;

const getAssistantProducers = (assistantProducers) =>
  assistantProducers?.length > 0
    ? assistantProducers?.map((item) => (
        <Tag
          key={item.name}
          color={"green"}
          style={{ maxWidth: "150px", overflowX: "scroll" }}
        >
          {item.name}
        </Tag>
      ))
    : DASH;

const getTopics = (topics) =>
  topics?.length > 0
    ? topics?.map((item) => (
        <Tag
          key={item.id}
          color={"red"}
          style={{ maxWidth: "150px", overflowX: "scroll" }}
        >
          {item.title}
        </Tag>
      ))
    : DASH;

const getTags = (tags) =>
  tags?.length > 0
    ? tags?.map((item) => (
        <Tag
          key={item.id}
          color="default"
          style={{ backgroundColor: "#e5e5e5", border: "1px solid black" }}
        >
          {item.name}
        </Tag>
      ))
    : DASH;

const getDateRange = (dateRange) => {
  return [
    !dateRange.start ? null : moment(dayjs(dateRange.start).format()),
    !dateRange.end ? null : moment(dayjs(dateRange.end).format()),
  ];
};

const dateRangeUI = (
  dateRange,
  onDateChange,
  onDateRangeReset,
  onApplyDateFilter
) => (
  <div style={{ marginLeft: 10, marginRight: 10 }}>
    <p
      style={{
        fontSize: 14,
        color: "#595959",
        paddingTop: 8,
        paddingLeft: 8,
      }}
    >
      Date range
    </p>
    <RangePicker
      allowClear={true}
      allowEmpty={false}
      value={getDateRange(dateRange)}
      onChange={(e) => {
        onDateChange(e);
      }}
      defaultValue={getDateRange(dateRange)}
      style={{ paddingLeft: 8, paddingRight: 8 }}
    />
    <hr
      style={{
        marginTop: 7,
        marginBottom: 7,
        borderTop: "1px solid #e9e9e9",
      }}
    ></hr>

    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
      }}
    >
      <Button type="default" onClick={onDateRangeReset}>
        Reset
      </Button>
      <Button type="primary" onClick={onApplyDateFilter}>
        Apply
      </Button>
    </div>
  </div>
);

const getLinkExpiry = (linkExpiry) =>
  linkExpiry ? <div>{getFormatedDate(linkExpiry)}</div> : DASH;

const defaultGenericColumns = () =>
  new Map([
    [
      defaultFieldKeys.producer,
      {
        key: "Producer",
        title: "Producer",
        dataIndex: "producer",
        render: (producer) => (
          <Cell>{producer?.name ? producer?.name : DASH}</Cell>
        ),
      },
    ],
    [
      defaultFieldKeys.status,
      {
        key: "Status",
        title: "Status",
        dataIndex: "status",
        filters: getStatusFilters,
        onFilter: (value, record) => record.status === value,
        render: (status) => (
          <Tag
            color={getStatusItemColor(status)}
            style={{ maxWidth: "150px", overflowX: "scroll" }}
          >
            {status}
          </Tag>
        ),
      },
    ],
    [
      defaultFieldKeys.published,
      {
        key: "Published",
        title: "Published",
        filterMultiple: false,
        dataIndex: "published",
        filters: publishFilters,
        onFilter: (value, record) => record.published === value,
        render: (published) => (
          <Tag
            color={getPublishItemColor(published)}
            style={{ maxWidth: "150px", overflowX: "scroll" }}
          >
            {getPublishedValue(published)}
          </Tag>
        ),
      },
    ],
    [
      defaultFieldKeys.lastUpdated,
      {
        key: "lastUpdated",
        title: "Last updated",
        dataIndex: "updatedAt",
        sorter: true,
        render: (item) => <Cell>{getFormatedDateAndTime(item)}</Cell>,
      },
    ],
    [
      defaultFieldKeys.createdAt,
      {
        key: "createdAt",
        dataIndex: "createdAt",
        title: <Cell>Created at</Cell>,
        sorter: (a, b) =>
          a.createdAt && b.createdAt && a.createdAt.localeCompare(b.createdAt),
        sortDirections: ["descend", "ascend"],
        render: (item) => <Cell>{getFormatedDateAndTime(item)}</Cell>,
      },
    ],
    [
      defaultFieldKeys.assistantProducer,
      {
        key: "assistantProducers",
        dataIndex: "assistantProducers",
        title: <Cell>Assistant Producer</Cell>,
        render: (assistant, record) =>
          getAssistantProducers(record?.assistantProducers),
      },
    ],
    [
      defaultFieldKeys.tags,
      {
        key: "tags",
        title: "Tags",
        dataIndex: "tags",
        render: (item) => (
          <ScrollableCell>
            <TagsContainer>{getTags(item)}</TagsContainer>
          </ScrollableCell>
        ),
      },
    ],
    [
      defaultFieldKeys.thumbnail,
      {
        key: "Thumbnail",
        title: "Thumbnail",
        dataIndex: "thumbnailUrl",
        render: (url) => <Avatar src={url} />,
      },
    ],
    [
      defaultFieldKeys.episodeOrigin,
      {
        key: "EpisodeOrigin",
        title: "Episode Origin",
        dataIndex: "episodeOriginOption",
        render: (item) => (
          <Tag
            color={"cyan"}
            style={{ maxWidth: "150px", overflowX: "scroll" }}
          >
            {item ? item.title : DASH}
          </Tag>
        ),
      },
    ],
  ]);

const CablecastColumns = () =>
  new Map([
    [
      MastercontrollColKeys.c_id,
      {
        key: "Cablecast id",
        title: "Cablecast id",
        dataIndex: "cablecastId",
        style: { width: "44px" },
        render: (cablecastId) => (
          <Cell>{cablecastId ? cablecastId : DASH}</Cell>
        ),
      },
    ],
    [
      MastercontrollColKeys.c_mediaURL,
      {
        key: "Program relation media url",
        title: "Program relation media url",
        dataIndex: "cablecastProgramPelantionMedia",
        render: (cablecastProgramPelantionMedia) => (
          <Cell>
            {cablecastProgramPelantionMedia
              ? cablecastProgramPelantionMedia
              : DASH}
          </Cell>
        ),
      },
    ],
  ]);

const TelvueColumns = () =>
  new Map([
    [
      MastercontrollColKeys.t_id,
      {
        key: "Telvue id",
        title: "Telvue id",
        dataIndex: "telvueId",
        render: (telvueId) => <Cell>{telvueId ? telvueId : DASH}</Cell>,
      },
    ],
    [
      MastercontrollColKeys.t_connect_id,
      {
        key: "Telvue connect id",
        title: "Telvue connect id",
        dataIndex: "telvueConnectId",
        render: (telvueConnectId) => (
          <Cell>{telvueConnectId ? telvueConnectId : DASH}</Cell>
        ),
      },
    ],
    [
      MastercontrollColKeys.t_filename,
      {
        key: "Expected filename",
        title: "Expected filename",
        dataIndex: "telvueFileName",
        render: (telvueFileName) => (
          <Cell>{telvueFileName ? telvueFileName : DASH}</Cell>
        ),
      },
    ],
    [
      MastercontrollColKeys.t_datetime,
      {
        key: "Ingest date and time",
        title: "Ingest date and time",
        dataIndex: "telvueIngestDateTime",
        render: (telvueIngestDateTime) => (
          <Cell>{getFormatedDate(telvueIngestDateTime)}</Cell>
        ),
      },
    ],
    [
      MastercontrollColKeys.t_delete_at,
      {
        key: "Telvue episode delete at",
        title: "Telvue episode delete at",
        dataIndex: "telvueDeleteAt",
        render: (telvueDeleteAt) => (
          <Cell>{getFormatedDate(telvueDeleteAt)}</Cell>
        ),
      },
    ],
    [
      MastercontrollColKeys.t_program_code,
      {
        key: "Program code",
        title: "Program code",
        dataIndex: "telvueProgramCode",
        render: (telvueProgramCode) => (
          <Cell>{telvueProgramCode ? telvueProgramCode : DASH}</Cell>
        ),
      },
    ],
  ]);

const defaultEpisodeColumns = (
  dateRange,
  onDateChange,
  onDateRangeReset,
  onApplyDateFilter
) =>
  new Map([
    [
      defaultFieldKeys.id,
      {
        key: "ID",
        title: "ID",
        dataIndex: "id",
        render: (id) => <Link to={`/episodes/edit/${id}`}>{id}</Link>,
      },
    ],
    [
      defaultFieldKeys.episode,
      {
        key: "title",
        title: "Episode",
        dataIndex: "title",
        render: (episode, record) => (
          <Link to={`/episodes/edit/${record.id}`}>{episode}</Link>
        ),
        filtered: true,
      },
    ],
    [
      defaultFieldKeys.program,
      {
        key: "Program",
        title: "Program",
        dataIndex: "program",
        render: (program) => (
          <Cell>
            {program?.title} ({program?.id})
          </Cell>
        ),
      },
    ],
    [
      defaultFieldKeys.topics,
      {
        key: "topics",
        dataIndex: "topics",
        title: "Topics",
        render: (item) => (
          <Cell>
            <TagsContainer>{getTopics(item)}</TagsContainer>
          </Cell>
        ),
      },
    ],
    [
      defaultFieldKeys.productionDate,
      {
        key: "dateOfProduction",
        dataIndex: "dateOfProduction",
        filterDropdown: () =>
          dateRangeUI(
            dateRange,
            onDateChange,
            onDateRangeReset,
            onApplyDateFilter
          ),
        title: <Cell>{"Production date"}</Cell>,
        sorter: true,
        render: (date) => <Cell>{getFormatedDateAndTime(date)}</Cell>,
      },
    ],
    [
      defaultFieldKeys.streamUrl,
      {
        key: "streamUrl",
        title: "Episode Stream Url",
        dataIndex: "streamUrl",
        render: (url) => (
          <span>
            {url ? (
              <a
                style={{
                  maxWidth: "150px",
                  overflowX: "scroll",
                  whiteSpace: "nowrap",
                }}
                href={url}
                target="_blank"
              >
                <Text style={{ width: 200 }} ellipsis={{ tooltip: url }}>
                  {url}
                </Text>
              </a>
            ) : (
              DASH
            )}
          </span>
        ),
      },
    ],
    [
      defaultFieldKeys.videoUrl,
      {
        key: "videoUrl",
        dataIndex: "videoUrl",
        title: (
          <Cell style={{ textAlign: "center" }}>
            {defaultFieldKeys.videoUrl}
          </Cell>
        ),
        render: (url) => (
          <div>
            {url ? (
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                style={{ width: 128 }}
                onClick={() => {
                  window.open(url, "_blank");
                }}
              >
                Download
              </Button>
            ) : (
              DASH
            )}
          </div>
        ),
      },
    ],
    [
      defaultFieldKeys.linkExpiry,
      {
        key: "linkExpiry",
        dataIndex: "linkExpiry",
        title: (
          <Cell style={{ textAlign: "center" }}>
            {defaultFieldKeys.linkExpiry}
          </Cell>
        ),
        sorter: (a, b) =>
          a.linkExpiry &&
          b.linkExpiry &&
          a.linkExpiry.localeCompare(b.linkExpiry),
        sortDirections: ["descend", "ascend"],
        render: (linkExpiry) => getLinkExpiry(linkExpiry),
      },
    ],
  ]);

const defaultProgramColumns = (
  dateRange,
  onDateChange,
  onDateRangeReset,
  onApplyDateFilter,
  programTypes
) =>
  new Map([
    [
      defaultFieldKeys.id,
      {
        key: "ID",
        title: "ID",
        dataIndex: "id",
        render: (text, record) => (
          <Link to={`/programs/edit/${record.id}`}>{text}</Link>
        ),
      },
    ],
    [
      defaultFieldKeys.program,
      {
        key: "Program",
        title: "Program",
        dataIndex: "title",
        render: (title, record) => (
          <Link to={`/programs/edit/${record.id}`}>{title}</Link>
        ),
      },
    ],
    [
      defaultFieldKeys.episode,
      {
        key: "title",
        title: "Episodes",
        dataIndex: "episodes",
        render: (episodes, record) => <Cell>{episodes?.length}</Cell>,
        filtered: true,
      },
    ],
    [
      defaultFieldKeys.updateDate,
      {
        title: <Cell>{"Updated date"}</Cell>,
        filterDropdown: () =>
          dateRangeUI(
            dateRange,
            onDateChange,
            onDateRangeReset,
            onApplyDateFilter
          ),
        dataIndex: "updatedAt",
        key: "DateOfProduction",
        render: (date, record) => <Cell>{getFormatedDate(date)}</Cell>,
      },
    ],
    [
      defaultFieldKeys.type,
      {
        key: "Type",
        title: "Type",
        filters: programTypes,
        dataIndex: "programType",
        render: (type, record) => <Cell>{type?.text ? type?.text : DASH}</Cell>,
      },
    ],
  ]);

export {
  dateRangeUI,
  TelvueColumns,
  CablecastColumns,
  defaultGenericColumns,
  defaultEpisodeColumns,
  defaultProgramColumns,
};
