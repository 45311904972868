import React, { useState, useEffect } from "react";
import { Input, Button, message, Spin } from "antd";
import Logo from "../../logo.svg";
import URLS from "../../constants";
import styled from "styled-components";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from '@apollo/client'
import {
    trimErrorMessage,
    hasDigit,
    hasLowerCase,
    hasUpperCase,
    hasSpecialCharacter,
} from "../../utils";
import { getParams } from "../../helpers/URLParams";
import { Mutations, ApolloAuthClient } from "../../api";
import PasswordAlert from "../../components/PasswordAlert";
import { setContext } from "@apollo/client/link/context";

const Wrapper = styled.div`
  background: #001529;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  max-width: 600px;
  width: 100%;
  padding: 1rem;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: "space-evenly";
`;

class ApolloAuthClientPasswordObj {
    constructor(params) {

        this.authLink = createHttpLink({
            uri: URLS.API_BASE_URL + "auth",
        });

        const cache = new InMemoryCache();

        this.headersLink = setContext((_, {headers}) => {
            return {
                headers: {
                    ...headers,
                    uid: params.uid,
                    "token-type": "Bearer",
                    "access-token": params.accessToken,
                    client: params.client,
                },
            }
        });

        this.client = new ApolloClient({
            link: this.headersLink.concat(this.authLink),
            cache
        });
    }
}

export function ResetPassword(props) {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState();

    useEffect(() => {
        setParams(getParams());
        props.history.push("/reset-password");
        // eslint-disable-next-line
    }, []);

    const handleResetPassword = async () => {
        if (!password) {
            message.error("New password is required!");
        } else if (
            !(
                password.length > 7 &&
                hasDigit(password) &&
                hasLowerCase(password) &&
                hasUpperCase(password) &&
                hasSpecialCharacter(password)
            )
        ) {
            message.error("Your password does not satisfy the requirements.");
        } else if (!confirmPassword) {
            message.error("Confirm password is required!");
        } else if (password !== confirmPassword) {
            message.error("New password and confirm password should be same!");
        } else {
            try {
                setLoading(true);
                const ApolloAuthPasswordClient = new ApolloAuthClientPasswordObj(
                    params
                );
                const result = await ApolloAuthPasswordClient.client.mutate({
                    mutation: Mutations.UPDATE_USER_PASSWORD,
                    variables: {
                        password: password,
                        passwordConfirmation: confirmPassword,
                    },
                });
                setLoading(false);
                if (result.data) {
                    message.success(
                        "Your password successfully updated. Please try to login with new password.",
                        15
                    );
                    props.history.push("/");
                }
            } catch (err) {
                setLoading(false);
                if (err.message.includes("Network error")) {
                    message.error("Please check your network connection!");
                } else message.error(trimErrorMessage(err.message));
            }
        }
    };

    return (
        <Spin spinning={loading}>
            <div style={{background: "#001529"}}>
                <Wrapper>
                    <Form>
                        <img alt="" src={Logo} style={{marginBottom: "2rem"}}/>
                        <PasswordAlert isShow={true} password={password}/>
                        <Input.Password
                            style={{marginTop: 16}}
                            size={"large"}
                            placeholder="New password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Input.Password
                            style={{marginTop: 16}}
                            size={"large"}
                            value={confirmPassword}
                            placeholder="Confirm password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <Button
                            style={{marginTop: 16}}
                            type="primary"
                            size={"large"}
                            onClick={() => handleResetPassword()}
                        >
                            Reset Password
                        </Button>
                    </Form>
                </Wrapper>
            </div>
        </Spin>
    );
}

export default function LoginProvider(props) {
    return (
        <ApolloProvider client={ApolloAuthClient.client}>
            <ResetPassword {...props} />
        </ApolloProvider>
    );
}
