import React from "react";
import { ThemeColors } from "../../theme";
import { Input, Button, Form, Divider } from "antd";
import styled from "styled-components";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-divider-vertical {
    height: auto;
    border-left: 1px solid #dadada;
    margin-left: 40px;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.6rem;

  .ant-form-item-label > label {
    display: flex;
  }
  .ant-divider-vertical {
    height: auto;
    border-left: 1px solid #dadada;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  margin-left: 0.6rem;
  flex-direction: column;
`;

const LinkItem = (props) => {
    const {link, handleLinks, removeLinks, handleLinkType} = props;
    return (
        <HorizontalContainer>
            <Form.Item
                label={"Text"}
                style={{
                    width: "260px",
                    marginRight: "0.5rem",
                    flexDirection: "column",
                }}
            >
                <Input
                    type="text"
                    value={link.title}
                    placeholder={"Our Mission"}
                    onChange={(e) => {
                        handleLinkType(link.id, e.target.value);
                    }}
                />
            </Form.Item>

            <Form.Item
                label="Url"
                style={{
                    width: "415px",
                    marginRight: ".9rem",
                    flexDirection: "column",
                }}
            >
                <Input
                    value={link.url}
                    placeholder={"https://firstturnmedia.com/mission"}
                    onChange={(e) => {
                        handleLinks(link.id, e.target.value);
                    }}
                />
            </Form.Item>

            <Button
                shape={"circle"}
                icon={<MinusOutlined/>}
                style={{
                    alignSelf: "center",
                    visibility: link.id > 0 ? "visible" : "hidden",
                }}
                onClick={() => {
                    removeLinks(link.id);
                }}
            />
        </HorizontalContainer>
    );
};

const HeaderLinks = (props) => {
    const {data, setData} = props;

    const getId = () => {
        return data?.length || 0;
    };

    const onUpdateLinks = (updatedLinks) => {
        setData(updatedLinks);
    };

    const handleLinks = (id, value) => {
        let updatedLinks = data.map((item) => {
            if (item.id === id) {
                item.url = value;
            }
            return item;
        });
        onUpdateLinks(updatedLinks);
    };

    const handleLinkType = (id, title) => {
        let updatedLinks = data.map((item) => {
            if (item.id === id) {
                item.title = title;
            }
            return item;
        });
        onUpdateLinks(updatedLinks);
    };

    const removeLinks = (id) => {
        const updatedLinks = data.filter((item) => {
            return item.id !== id;
        });
        onUpdateLinks(updatedLinks);
    };

    return (
        <VerticalContainer>
            <HorizontalContainer>
                <Divider
                    orientation="left"
                    type="vertical"
                    style={{
                        background: ThemeColors.colors.borderGray,
                    }}
                />
                <Container>
                    {data?.map((link, i) => (
                        <LinkItem
                            key={i}
                            link={link}
                            handleLinks={handleLinks}
                            removeLinks={removeLinks}
                            handleLinkType={handleLinkType}
                        />
                    ))}

                    <Button
                        type="dashed"
                        onClick={() => {
                            const addNewLink = {
                                id: getId(),
                                url: "",
                                title: "",
                            };
                            onUpdateLinks([
                                ...data,
                                addNewLink
                            ]);
                        }}
                        style={{width: "683px"}}
                        icon={<PlusOutlined/>}
                    >
                        Add links
                    </Button>
                </Container>
            </HorizontalContainer>
        </VerticalContainer>
    );
};

export default HeaderLinks;
