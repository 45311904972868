import React from "react";
import { Queries } from "./api";
import { UserRole } from "./utils/contants";
import { Route, Redirect } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { getSession, isSession } from "./helpers/SessionManagement";
import { TABS, ROUTES_TO_IGNORE } from "./utils/contants";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const localClient = useApolloClient();
  const cachedData = localClient.cache.readQuery({
    query: Queries.GET_PAGE_NUMBER,
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = isSession() ? getSession() : null;
        if (TABS.includes(rest.path) && cachedData?.route !== rest.path) {
          localClient.writeQuery({
            query: Queries.GET_PAGE_NUMBER,
            data: {
              pageNumber: 1,
              route: rest.path,
            },
          });
        }

        if (
          !currentUser ||
          (roles && roles.indexOf(currentUser.userType) === -1)
        ) {
          // not logged in or not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />;
        }
        if (
          currentUser?.userType !== UserRole.ADMIN &&
          ROUTES_TO_IGNORE.includes(rest?.path)
        ) {
          return <Redirect to={{ pathname: "/" }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
export default PrivateRoute;
