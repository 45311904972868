import React from "react";
import { Alert } from "antd";
import {
    hasDigit,
    hasLowerCase,
    hasUpperCase,
    hasSpecialCharacter,
} from "./../../utils";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const PasswordAlert = (props) => {
    const cond1 = props.password?.length > 7;
    const cond2 = hasUpperCase(props.password);
    const cond3 = hasLowerCase(props.password);
    const cond4 = hasDigit(props.password);
    const cond5 = hasSpecialCharacter(props.password);

    return (
        <Alert
            style={{
                textAlign: "start",
                fontSize: "12px",
                display: "flex",
                marginBottom: 4,
            }}
            message={
                <div>
                    Password must be:
                    <br/>{" "}
                    {cond1 ? (
                        <CheckCircleOutlined style={{color: "#52BE80"}}/>
                    ) : (
                        <CloseCircleOutlined/>
                    )}{" "}
                    Equal or Greater then 8 characters.
                    <br/>{" "}
                    {cond2 ? (
                        <CheckCircleOutlined style={{color: "#52BE80"}}/>
                    ) : (
                        <CloseCircleOutlined/>
                    )}{" "}
                    Password must contain 1 or more uppercase characters.
                    <br/>{" "}
                    {cond3 ? (
                        <CheckCircleOutlined style={{color: "#52BE80"}}/>
                    ) : (
                        <CloseCircleOutlined/>
                    )}{" "}
                    Password must contain 1 or more lowercase characters.
                    <br/>{" "}
                    {cond4 ? (
                        <CheckCircleOutlined style={{color: "#52BE80"}}/>
                    ) : (
                        <CloseCircleOutlined/>
                    )}{" "}
                    Password must contain 1 or more digit characters.
                    <br/>{" "}
                    {cond5 ? (
                        <CheckCircleOutlined style={{color: "#52BE80"}}/>
                    ) : (
                        <CloseCircleOutlined/>
                    )}{" "}
                    Password must contain 1 or more special characters.
                </div>
            }
            type="info"
            showIcon
        />
    );
};

export default PasswordAlert;
