import { useCallback, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useGoogleLogin } from "react-google-login";
import moment from 'moment';
import { Mutations } from "../api";

export const useYouTubeAuth = (setYouTubeIntegration) => {
    const [
        updateOrgYouTubeAuth,
        {
            loading,
            error,
            data,
        },
    ] = useMutation(Mutations.UPDATE_ORG_YOUTUBE_AUTH, {});

    const removeExistingConnection = useCallback(() => {
        updateOrgYouTubeAuth({
            variables: {
                accessToken: null,
                refreshToken: null,
                metadata: null
            }
        });
    }, [updateOrgYouTubeAuth])

    const {signIn, loaded} = useGoogleLogin({
        clientId: process.env.REACT_APP_YOUTUBE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube',
        cookiePolicy: 'single_host_origin',
        accessType: 'offline',
        responseType: 'code',
        onSuccess: (res) => {
            const code = res?.code;
            fetch('https://oauth2.googleapis.com/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    'code': code,
                    'grant_type': 'authorization_code',
                    'client_id': process.env.REACT_APP_YOUTUBE_CLIENT_ID,
                    'client_secret': process.env.REACT_APP_YOUTUBE_CLIENT_SECRET,
                    'redirect_uri': process.env.REACT_APP_YOUTUBE_REDIRECT_URI
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    const accessToken = data?.access_token;
                    const refreshToken = data?.refresh_token;

                    const currentTime = moment().format('YYYY-MM-DDThh:mm:ssZ');
                    const expiry_date = moment().add(data?.expires_in, 'seconds').valueOf();

                    updateOrgYouTubeAuth({
                        variables: {
                            accessToken,
                            refreshToken,
                            metadata: [
                                {
                                    id: 'scope',
                                    value: data?.scope
                                },
                                {
                                    id: 'id_token',
                                    value: data?.id_token
                                },
                                {
                                    id: 'authenticated_at',
                                    value: currentTime
                                },
                                {
                                    id: 'expiry_date',
                                    value: `${expiry_date}`
                                }
                            ]
                        }
                    });
                });
        }
    });

    useEffect(() => {
        if (data?.updateOrganizatoin && setYouTubeIntegration) {
            setYouTubeIntegration(data?.updateOrganizatoin)
        }
    }, [
        data,
        setYouTubeIntegration
    ]);

    return {
        authenticate: signIn,
        authLoaded: loaded,
        mutationLoading: loading,
        mutationError: error,
        mutationData: data,
        removeExistingConnection
    };
}
