import React, { useState } from 'react';
import { List, Avatar } from 'antd';
import moment from 'moment';
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import YouTubeIcon from "../../../assets/youtube_social_icon_red.png";

const YouTubeProvider = ({notification}) => {
    const [collapsed, setCollapsed] = useState(true);

    const getTimestampBlock = () => (
        <>
            {notification?.timestamp && (
                <span style={{fontSize: '12px', color: '#888888', display: 'block', lineHeight: '7px'}}>
          <br/><em>{moment.unix(notification?.timestamp).format('MM-DD-YYYY @ hh:mmA')}</em>
        </span>
            )}
        </>
    );

    return (
        <List.Item
            actions={[
                <a onClick={() => setCollapsed(!collapsed)}>
                    {collapsed
                        ? (<span>Details <CaretDownOutlined/> </span>)
                        : (<span>Collapse <CaretUpOutlined/></span>)
                    }
                </a>
            ]}
        >
            <List.Item.Meta
                title={<><span>Episode Uploaded</span>{getTimestampBlock()}</>}
                avatar={<Avatar src={YouTubeIcon}/>}
                description={!collapsed ? <span dangerouslySetInnerHTML={{__html: notification?.message}}/> : null}
            />
        </List.Item>
    );
};

export default YouTubeProvider;
