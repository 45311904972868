import React, { useState } from "react";
import styled from "styled-components";
import { Button, Modal, Input, message, Spin, Radio } from "antd";
import HelpText from "../../../components/HelpText";
import { isValidEmail } from "../../../utils";
import { UserRole } from "../../../utils/contants";
import { clearDirectusCache } from "../../../helpers/ClearDirectusCache";
import { MailOutlined } from "@ant-design/icons";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";

const StyledNameInput = styled.div`
  h3 {
    margin-top: 0.5rem;
  }
  .modal-name-inputs {
    display: flex;
  }
  .modal-name-inputs > input:first-of-type {
    margin-right: 0.5rem;
  }
`;

export const InviteForm = forwardRef((props, ref) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState(UserRole.STAFF);

  useImperativeHandle(ref, () => ({
    async sendInvite() {
      if (!firstName) {
        message.error(`First name is required`, 2);
      } else if (!lastName) {
        message.error(`Last name is required`, 2);
      } else if (!email) {
        message.error(`Email is required`, 2);
      } else if (!isValidEmail(email)) {
        message.error(`${email} is not a valid email`, 2);
      } else {
        // Clearing directus cache for showing table view with updated records
        await clearDirectusCache();
        props.handleInviteSend(
          firstName,
          lastName,
          email,
          window.location.origin + "/signup",
          userType
        );
      }
    },
  }));

  return (
    <div>
      <StyledNameInput>
        <HelpText title={"Name"} required={true} />
        <div className={"modal-name-inputs"}>
          <Input
            value={firstName}
            size={"large"}
            required
            placeholder={"First name"}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            value={lastName}
            size={"large"}
            required
            placeholder={"Last name"}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </StyledNameInput>

      <HelpText title={"Email"} required={true} />
      <Input
        value={email}
        size={"large"}
        required
        placeholder={"Email address"}
        onChange={(e) => setEmail(e.target.value)}
      />
      <HelpText title={"Role"} required={true} />
      <Radio.Group
        value={userType}
        onChange={(e) => setUserType(e.target.value)}
      >
        <Radio value={UserRole.STAFF}>Staff</Radio>
        <Radio value={UserRole.PRODUCER}>Producer</Radio>
      </Radio.Group>
    </div>
  );
});

const InviteModel = (props) => {
  const formRef = useRef(null);
  return (
    <Spin spinning={false}>
      <Modal
        destroyOnClose
        visible={props.inviteModalVisible}
        title="Invite a staff member"
        onCancel={() => {
          props.setInviteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              props.setInviteModalVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            icon={<MailOutlined />}
            onClick={() => {
              formRef.current.sendInvite();
            }}
          >
            Send invite
          </Button>,
        ]}
      >
        <InviteForm ref={formRef} {...props} />
      </Modal>
    </Spin>
  );
};
export default InviteModel;
