import React from 'react';

const DefaultProvider = ({notification}) => {
    return (
        <div>
            <span dangerouslySetInnerHTML={{__html: notification?.message}}/>
        </div>
    )
};

export default DefaultProvider;
