import React, { useState } from "react";
import { Modal, Input, message } from "antd";
import Mutations from "../../../api/Mutations";
import { ApolloAuthClient } from "../../../api";
import OverlaySpinner from "./../../../components/OverlaySpinner";
import { isValidEmail, trimErrorMessage } from "../../../utils";

export default function ForgotPassword(props) {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const resetPassword = async () => {
        if (!email) {
            message.error("Email is required.");
        } else if (isValidEmail(email)) {
            try {
                props.setModelState(false);
                setLoading(true);
                const result = await ApolloAuthClient.client.mutate({
                    mutation: Mutations.SEND_PASSSWORD_RESET,
                    variables: {
                        email: email,
                        redirectUrl: window.location.origin + "/reset-password",
                    },
                });
                if (result.data) {
                    setLoading(false);
                    message.success(
                        <div>
                            Email sent for reset password to <b>{email}</b>.
                        </div>,
                        15
                    );
                    setEmail("");
                }
            } catch (e) {
                setEmail("");
                setLoading(false);
                if (e.message.includes("Network error")) {
                    message.error("Please check your network connection!", 15);
                } else message.error(trimErrorMessage(e.message));
            }
        } else {
            setEmail("");
            message.error("Invalid email. Please re-check.", 15);
        }
    };

    return (
        <>
            {loading && <OverlaySpinner/>}
            <Modal
                destroyOnClose
                title="Forgot password"
                visible={props.modelState}
                onOk={() => {
                    resetPassword();
                }}
                onCancel={() => {
                    setEmail("");
                    props.setModelState(false);
                }}
            >
                <Input
                    size={"large"}
                    placeholder="Your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <p style={{marginTop: 20}}>
                    We will email a link to your account email to allow you to reset your
                    password. Please enter the email address associated with your account.
                </p>
            </Modal>
        </>
    );
}
