import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "antd";
import PromoImg from "./../../assets/localeyz_promo.png";
import { PieChartComponent } from "./charts";
import { Row, Col } from "react-styled-flexboxgrid";
import { formatProviderData } from "./chartUtils";
import { TimeChart } from "./mongoCharts";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { VODStaffChart } from "./vodStaffChart";
import { USER_TYPES } from "../../constants";
import { useDatabase } from "../../hooks/database";
import { getSession } from "../../helpers/SessionManagement";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Header = styled.div`
  background-color: #001529;
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  margin-right: 2rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 1rem 0;
  h1 {
    margin: 0;
  }
`;

export default function Dashboard() {
  const { database } = useDatabase();
  const session = getSession();
  const [initialized, setInitialized] = useState(false);
  const [data, setData] = useState({
    podcastPublishStatus: null,
    episodePublish: null,
    providerData: null,
    statusData: null,
    podcastCount: null,
    episodeCount: null,
    providerCount: null,
  });

  // default date range is past week. Its set on mongoCharts.js by recognizing the empty screen here
  // and setting the range accordingly as a week prior
  const [firstDate, setFirstDate] = useState("");
  const [secondDate, setSecondDate] = useState("");

  useEffect(() => {
    async function init() {
      const episodeStats = await database.items("episodes").readMany({
        groupBy: ["status", "published"],
        meta: "*",
        filter: {
          organization_id: {
            _eq: session?.organization?.id,
          },
        },
        aggregate: {
          count: "id",
        },
      });

      const videoStats = await database.items("episodes").readMany({
        fields: ["stream_url"],
        meta: "*",
        sort: ["-date_of_production"],
        filter: {
          organization_id: {
            _eq: session?.organization?.id,
          },
        },
      });

      const podcastStats = await database.items("podcasts").readMany({
        groupBy: "published",
        meta: "*",
        filter: {
          organization_id: {
            _eq: session?.organization?.id,
          },
        },
        aggregate: {
          count: "id",
        },
      });

      setData({
        providerCount: videoStats?.meta?.filter_count || 0,
        episodeCount: episodeStats?.meta?.filter_count || 0,
        podcastCount: podcastStats?.meta?.filter_count || 0,
        providerData: formatProviderData(videoStats?.data),
        podcastPublishStatus: [
          {
            name: "Published",
            value: parseInt(
              (podcastStats?.data || []).find((v) => v?.published)?.count?.id ||
                0
            ),
            fill: "#52c41a",
          },
          {
            name: "Unpublished",
            value: parseInt(
              (podcastStats?.data || []).find((v) => v?.published === false)
                ?.count?.id || 0
            ),
            fill: "#f5222d",
          },
        ],
        episodePublish: [
          {
            name: "Published",
            value: parseInt(
              (episodeStats?.data || []).reduce((agg, v) => {
                if (v?.published === true) {
                  agg += parseInt(v?.count?.id || 0);
                }

                return agg;
              }, 0)
            ),
            fill: "#52c41a",
          },
          {
            name: "Unpublished",
            value: parseInt(
              (episodeStats?.data || []).reduce((agg, v) => {
                if (v?.published === false) {
                  agg += parseInt(v?.count?.id || 0);
                }

                return agg;
              }, 0)
            ),
            fill: "#f5222d",
          },
        ],
        statusData: [
          {
            name: "Accepted",
            value: (episodeStats?.data || []).reduce((agg, v) => {
              if (v?.status === 2) {
                agg += parseInt(v?.count?.id || 0);
              }

              return agg;
            }, 0),
            fill: "#52c41a",
          },
          {
            name: "Declined",
            value: (episodeStats?.data || []).reduce((agg, v) => {
              if (v?.status === 3) {
                agg += parseInt(v?.count?.id || 0);
              }

              return agg;
            }, 0),
            fill: "#f5222d",
          },
          {
            name: "In review",
            value: (episodeStats?.data || []).reduce((agg, v) => {
              if (v?.status === 1) {
                agg += parseInt(v?.count?.id || 0);
              }

              return agg;
            }, 0),
            fill: "#fa8c16",
          },
        ],
      });

      setInitialized(true);
    }

    if (database) {
      init();
    }
  }, [database, session?.organization?.id]);

  if (!initialized) {
    return <p>Loading ...</p>;
  }

  const viewsData = session?.organization && {
    type: "view",
    orgId: session?.organization?.id,
  };

  const playsData = session?.organization && {
    type: "play",
    orgId: session?.organization?.id,
  };

  const donationAmountData = session?.organization && {
    metric: "donationMade",
    orgId: session?.organization?.id,
  };

  const producerLoginData = session?.organization && {
    metric: "producerLogin",
    orgId: session?.organization?.id,
  };

  // use orgId: -1 for testing
  const vodViewsData = session?.organization && {
    widget: "vod",
    type: "view",
    orgId: session?.organization?.id,
  };

  // use orgId: -1 for testing
  const vodPlaysData = session?.organization && {
    widget: "vod",
    type: "play",
    orgId: session?.organization?.id,
  };

  return (
    <div style={{ padding: 24, background: "#fff" }}>
      <Typography>
        <Header>
          <Row>
            <Col xs={12} sm={12} md={4} lg={3}>
              <ImageWrapper>
                <img alt=" " src={PromoImg} />
              </ImageWrapper>
            </Col>
            <Col xs={12} sm={12} md={8} lg={9}>
              <HeaderWrapper>
                <Title style={{ textAlign: "left", color: "white" }}>
                  Hi {session?.name}!
                </Title>
                <Title style={{ textAlign: "left", color: "white" }} level={4}>
                  Welcome to {session?.organization?.title}'s localeyz dashboard
                </Title>
              </HeaderWrapper>
            </Col>
          </Row>
        </Header>
        <Row>
          <Col sm={12} md={6} xs={12}>
            <PieChartComponent
              data={data.podcastPublishStatus}
              label="Podcasts"
              title={`Podcasts (${data.podcastCount})`}
            />
          </Col>
          <Col sm={12} md={6} xs={12}>
            <PieChartComponent
              data={data.episodePublish}
              label="Episodes"
              title={`Episodes (${data.episodeCount})`}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} xs={12}>
            <PieChartComponent
              data={data.providerData}
              label="Videos"
              title={`Latest video providers (Top 100)`}
            />
          </Col>
          <Col sm={12} md={6} xs={12}>
            <PieChartComponent
              data={data.statusData}
              label="Episodes"
              title={`Episodes status (${data.episodeCount})`}
            />
          </Col>
        </Row>
        <Row>
          <div style={{ padding: 16 }}>
            <Space direction="vertical" size={12}>
              <RangePicker
                // Sets the default picker to past week, this doesn't actually change anything
                // besides the visual so the actual value is set using state and then handled in mongoCharts.js
                defaultValue={[moment().subtract(7, "days"), moment()]}
                onChange={(date) => {
                  if (date) {
                    // date comes as an array with the first date being the first element
                    // and second date as second element
                    setFirstDate(moment(date[0]).valueOf());
                    setSecondDate(moment(date[1]).valueOf());
                  }
                }}
              />
            </Space>
          </div>
        </Row>
        <Row>
          <Col sm={12} md={6} xs={12}>
            {/* all  time charts are the same so comments on each prop here are the same for each*/}
            <TimeChart
              input={viewsData} // data to request from the database
              name="Widget Views" // name of the chart
              multiple={true} // if true, then the input data is a type (either 'view' or 'play') and
              // the graph splits the data by widget
              donation={false} // if true, then the input data is donation and it counts the amount instead
              // of number of events
              firstDate={firstDate} // earlier date
              secondDate={secondDate} // later date
            />
          </Col>
          <Col sm={12} md={6} xs={12}>
            <TimeChart
              input={playsData}
              name="Widget Plays"
              multiple={true}
              donation={false}
              firstDate={firstDate}
              secondDate={secondDate}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} xs={12}>
            <TimeChart
              input={donationAmountData}
              name="Donation Amounts"
              multiple={false}
              donation={true}
              firstDate={firstDate}
              secondDate={secondDate}
            />
          </Col>
          <Col sm={12} md={6} xs={12}>
            <TimeChart
              input={producerLoginData}
              name="Producer Logins"
              multiple={false}
              donation={false}
              firstDate={firstDate}
              secondDate={secondDate}
            />
          </Col>
        </Row>
        {(session?.userType === USER_TYPES.ADMIN ||
          session?.userType === USER_TYPES.STAFF) && (
          <div>
            <Row>
              <VODStaffChart
                input={vodPlaysData}
                xLabel={"Video Title"}
                xDataKey={"title"}
                formatId={"id"}
                title={"Video-On-Demand Plays (Top 10)"}
              />
            </Row>
            <Row>
              <VODStaffChart
                input={vodViewsData}
                xLabel={"Widget Name"}
                xDataKey={"title"}
                formatId={"widgetId"}
                title={"Video-On-Demand Widget Views (Top 10)"}
              />
            </Row>
          </div>
        )}
      </Typography>
    </div>
  );
}
