const widgets = [
    {title: "Radio", value: "radio"},
    {title: "Schedule", value: "schedule"},
    {title: "Podcasts", value: "podcasts"},
    {title: "Live video", value: "live-video"},
    {title: "Video on demand", value: "video-on-demand"},
    {title: "Producer Portal", value: "producer-portal"},
    {title: "Footer", value: "footer"},
    {title: "Playlist", value: "playlist"},
    {title: "Donation", value: "donation"},
    {title: "Subscription", value: "subscription"},
    {title: "Search", value: "search"},
];
const features = {
    Dashboard: true,
    Widgets: true,
    "Live video": true,
    Podcasts: true,
    Radio: true,
    Programs: true,
    Episodes: true,
    Playlists: true,
    Donations: true,
    "API docs": true,
    Staff: true,
    Producers: true,
    Groups: true,
    Users: true,
    Invites: true,
    Settings: true,
    Roadmap: true,
    Integrations: true,
};

// const routes = {
//   widgets: true,
//   "live-video": true,
//   podcasts: true,
//   radio: true,
//   programs: true,
//   episodes: true,
//   schedule: true,
//   producer: true,
//   settings: true,
//   organization: true,
//   integrations: true,
//   "api-docs": true,
//   staff: true,
//   users: true,
//   invites: true,
//   ":id": true,
//   preview: true,
//   edit: true,
//   roadmap: true,
// };

const isFeatureAvailable = (type) => {
    if (features[type]) return true;
    return false;
};

// const isRouteAvailable = (route) => {
//   const pathnames = route.split("/");

//   for (let index = 0; index < pathnames.length; index++) {
//     if (pathnames[index]) {
//       if (!routes[pathnames[index]]) return false;
//     }
//   }
//   return true;
// };

export { isFeatureAvailable, widgets };
