import React from "react";
import { Avatar, Button, Tag } from "antd";
import { KeyOutlined } from "@ant-design/icons";
import { UserRole } from "../../../utils/contants";
import { getSession } from "./../../../helpers/SessionManagement";

const columns = (history, onResetPassword) =>
  new Map([
    [
      "avatar",
      {
        title: "Avatar",
        dataIndex: "avatar",
        key: "avatar",

        render: (image) => <Avatar src={image} />,
      },
    ],
    [
      "name",
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          if (
            false
            // getSession()?.email !== record.email &&
            // getSession()?.userType !== UserRole.ADMIN
          ) {
            return <span>{text}</span>;
          }
          return (
            <Button
              style={{ padding: 0 }}
              onClick={() => history.push("/users/edit/" + record.id)}
              type="link"
            >
              {text}
            </Button>
          );
        },
      },
    ],
    [
      "email",
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        render: (text) => (
          <span>
            <Tag color={"orange"}>{text}</Tag>
          </span>
        ),
      },
    ],
    [
      "role",
      {
        title: "Role",
        key: "userType",
        dataIndex: "userType",
        filters: [
          { text: "Admin", value: UserRole.ADMIN },
          { text: "Staff", value: UserRole.STAFF },
        ],
        onFilter: (value, record) => record.userType === value,
        render: (text) => (
          <span>
            <Tag color="green" size="small">
              {text}
            </Tag>
          </span>
        ),
      },
    ],
    [
      "action",
      {
        title: "Action",
        key: "action",
        dataIndex: "id",
        render: (text, record) => (
          <Button
            icon={<KeyOutlined />}
            onClick={onResetPassword}
            disabled={getSession()?.email !== record.email}
          >
            Change password
          </Button>
        ),
      },
    ],
  ]);

const defaultVisibleFields = ["avatar", "name", "email", "role", "action"];

export { columns, defaultVisibleFields };
