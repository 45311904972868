import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getFormatedDate } from "../../utils";
import { widgets } from "../../FeatureConfig";
import {
  AudioOutlined,
  BellOutlined,
  CheckOutlined,
  CodeOutlined,
  ContainerOutlined,
  EuroCircleOutlined,
  EyeOutlined,
  LinkOutlined,
  PlaySquareOutlined,
  PlusOutlined,
  SearchOutlined,
  SoundOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { WIDGET_TYPES } from "../../utils/contants";
import {
  Button,
  Select,
  message,
  Spin,
  Tag,
  Typography,
  Tooltip,
  Divider,
} from "antd";
import Table from "../../components/Table";
import { useDatabase } from "../../hooks/database";
import { getSession } from "../../helpers/SessionManagement";
import { useEntities, useEntityTable } from "../../hooks/entity";
import DeleteConfirm from "../../components/DeleteConfirm/DeleteConfirm";
import { useHistory } from "react-router-dom";

const { Option } = Select;
const { Text } = Typography;

const TableContainer = styled.div`
  margin-top: 2rem;
  .ant-table-content {
    overflow-x: auto;
  }

  .ant-table-filter-trigger-container {
    right: 0 !important;
    left: 0 !important;
    position: unset;
    margin-right: 16px;
    :hover {
      background: none;
    }
    :focus {
      background: none;
    }
  }
`;

const widgetMap = [
  { value: "radio", table: "radio_widgets", type: "RADIO" },
  { value: "schedule", table: "schedule_widgets", type: "SCHEDULE" },
  { value: "podcasts", table: "podcast_widgets", type: "PODCAST" },
  { value: "live-video", table: "live_video_widgets", type: "LIVE_VIDEO" },
  { value: "video-on-demand", table: "vod_widgets", type: "VOD" },
  {
    value: "producer-portal",
    table: "producer_portal_widgets",
    type: "PRODUCER_PORTAL",
  },
  { value: "footer", table: "footer_widgets", type: "FOOTER" },
  { value: "playlist", table: "playlist_widgets", type: "PLAYLIST" },
  { value: "donation", table: "donation_widgets", type: "DONATION" },
  {
    value: "subscription",
    table: "subscription_widgets",
    type: "SUBSCRIPTION",
  },
  { value: "search", table: "search_widgets", type: "SEARCH" },
];

const getTypeTag = (type) => (
  <span>
    {type === WIDGET_TYPES.Podcasts && (
      <Tag color={"geekblue"}>
        <AudioOutlined /> {type.toUpperCase()}
      </Tag>
    )}
    {type === WIDGET_TYPES.Schedule && (
      <Tag color={"green"}>
        <VideoCameraOutlined /> {type.toUpperCase()}
      </Tag>
    )}
    {type === WIDGET_TYPES.Radio && (
      <Tag color={"magenta"}>
        <SoundOutlined /> {type.toUpperCase()}
      </Tag>
    )}
    {type === WIDGET_TYPES.LiveVideo && (
      <Tag color={"orange"}>
        <VideoCameraOutlined /> {"LIVE-VIDEO"}
      </Tag>
    )}
    {type === WIDGET_TYPES.ProducerPortal && (
      <Tag color={"blue"}>
        <ContainerOutlined /> {"PRODUCER-PORTAL"}
      </Tag>
    )}
    {type === WIDGET_TYPES.Footer && (
      <Tag color={"blue"}>
        <LinkOutlined /> {type.toUpperCase()}
      </Tag>
    )}
    {type === WIDGET_TYPES.Vod && (
      <Tag color={"gold"}>
        <VideoCameraOutlined /> {"VIDEO-ON-DEMAND"}
      </Tag>
    )}
    {type === WIDGET_TYPES.Playlist && (
      <Tag color={"purple"}>
        <PlaySquareOutlined /> {type.toUpperCase()}
      </Tag>
    )}
    {type === WIDGET_TYPES.Donation && (
      <Tag color={"black"}>
        <EuroCircleOutlined /> {type.toUpperCase()}
      </Tag>
    )}
    {type === WIDGET_TYPES.Subscription && (
      <Tag color={"yellow"}>
        <BellOutlined /> {type.toUpperCase()}
      </Tag>
    )}
    {type === WIDGET_TYPES.Search && (
      <Tag color={"grey"}>
        <SearchOutlined /> {type.toUpperCase()}
      </Tag>
    )}
  </span>
);

const getWidgetTypeFromParams = (search) => {
  const urlParams = new URLSearchParams(search);
  for (const [key, value] of urlParams) {
    if (key === "widget") return value;
  }
  return "video-on-demand";
};

export default function Widgets(props) {
  const history = useHistory();
  const { database } = useDatabase();
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const session = getSession();
  const [widgetType, setWidgetType] = useState(
    getWidgetTypeFromParams(props.location.search)
  );
  const [embedCopy, setEmbedCopy] = useState({ id: null, type: null });

  useEffect(() => {
    setWidgetType(getWidgetTypeFromParams(props.location.search));
  }, [props.location.search]);

  const staticFilters = {
    organization_id: {
      _eq: session?.organization?.id,
    },
  };

  const { fetchData, setBaseEntity } = useEntities({
    baseEntity: "vod_widgets",
    staticFilters,
    fields: ["*"],
  });

  const {
    getColumnAttributes,
    setBaseEntity: setTableBaseEntity,
    loading: loadingDatabase,
  } = useEntityTable({
    baseEntity: "vod_widgets",
    staticFilters,
  });

  useEffect(() => {
    if (embedCopy.sourceId && embedCopy.type) {
      setTimeout(() => {
        setEmbedCopy({
          id: null,
          type: null,
        });
      }, 2000);
    }
  }, [embedCopy]);

  const onCopy = (type, id) => {
    type = parseWidgetType(type);
    const text = `<localeyz-widget style="${
      type === "donation" || type === "subscription"
        ? ""
        : "min-height: 20rem; "
    } display: block;" data-type="${parseWidgetType(
      type
    )}" data-id="${id}" data-version="0"></localeyz-widget><script type="text/javascript" src="${
      process.env.REACT_APP_FRONT_END_BASE_URL
    }${
      type === "producer-portal"
        ? "producer-portal/v1/js/main.js"
        : "widgets/v1/js/main.js"
    }"></script>`;
    navigator.clipboard.writeText(text);
    setEmbedCopy({
      id,
      type,
    });
  };

  const parseWidgetType = (type) =>
    type === WIDGET_TYPES.Vod
      ? "video-on-demand"
      : type === WIDGET_TYPES.Podcasts
      ? "podcasts"
      : type.replace("_", "-").toLowerCase();

  const onEdit = (widget) => {
    const mapItem = widgetMap.find((w) => w?.value === widgetType);
    props.history.push(
      `widgets/${parseWidgetType(mapItem?.type)}/${widget.id}`
    );
  };

  const onDelete = async (widget) => {
    const mapItem = widgetMap.find((w) => w?.value === widgetType);
    const { id, name } = widget;
    const { table } = mapItem;
    if (id && table) {
      await database.items(table).deleteOne(id);
      message.success(name + " deleted");
      tableRef.current.api.refreshServerSideStore();
    }
  };

  const onPreview = (widget) => {
    const mapItem = widgetMap.find((w) => w?.value === widgetType);
    props.history.push(
      `widgets/${parseWidgetType(mapItem?.type)}/${widget.id}/preview`
    );
  };

  useEffect(() => {
    async function getData() {
      const mapItem = widgetMap.find((w) => w?.value === widgetType);
      if (mapItem) {
        setBaseEntity(mapItem?.table);
        setTableBaseEntity(mapItem?.table);
        setInitialized(true);
      }
    }
    if (widgetType) {
      getData();
    }
  }, [widgetType, setBaseEntity, setTableBaseEntity]);

  useEffect(() => {
    async function initCols() {
      const columns = [
        {
          field: "name",
          colId: "name",
          headerName: "Name",
          flex: 1,
          ...(await getColumnAttributes({
            field: "name",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return <a onClick={() => onEdit(params?.data)}>{params?.value}</a>;
          },
        },
        {
          field: "id",
          colId: "id",
          headerName: "ID",
          flex: 1,
          ...(await getColumnAttributes({
            field: "id",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return <Text copyable>{params?.value || ""}</Text>;
          },
        },
        {
          field: "embed_code",
          colId: "embed_code",
          headerName: "Embed Code",
          flex: 1,
          filter: false,
          sortable: false,
          cellRendererFramework: (params) => {
            const mapItem = widgetMap.find((w) => w?.value === widgetType);
            return (
              <Tooltip
                title={
                  embedCopy.id === params?.data?.id &&
                  embedCopy.type === mapItem?.type
                    ? "Copied"
                    : "Copy"
                }
              >
                <Button
                  type="dashed"
                  shape="circle"
                  icon={
                    embedCopy.id === params?.data?.id &&
                    embedCopy.type === mapItem?.type ? (
                      <CheckOutlined />
                    ) : (
                      <CodeOutlined />
                    )
                  }
                  onClick={() => onCopy(mapItem?.type, params?.data?.id)}
                />
              </Tooltip>
            );
          },
        },
        {
          field: "created_at",
          colId: "created_at",
          headerName: "Created Date",
          flex: 1,
          ...(await getColumnAttributes({
            field: "created_at",
          })),
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return <p>{getFormatedDate(params?.value)}</p>;
          },
        },
        {
          field: "widget_type",
          colId: "widget_type",
          headerName: "Type",
          flex: 1,
          filter: false,
          sortable: false,
          cellRendererFramework: (params) => {
            const mapItem = widgetMap.find((w) => w?.value === widgetType);
            if (params?.node?.footer) {
              return "";
            }

            return <p>{getTypeTag(mapItem?.type)}</p>;
          },
        },
        {
          field: "action",
          colId: "action",
          headerName: "Actions",
          flex: 1,
          filter: false,
          sortable: false,
          minWidth: 250,
          cellRendererFramework: (params) => {
            if (params?.node?.footer) {
              return "";
            }

            return (
              <span style={{ display: "inline-flex" }}>
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => onPreview(params?.data)}
                >
                  Preview
                </Button>
                <Divider type="vertical" />
                <DeleteConfirm
                  name={params?.data?.name}
                  action={() => onDelete(params?.data)}
                />
              </span>
            );
          },
        },
      ];

      setColumns(columns);
    }

    if (!loadingDatabase && widgetType) {
      initCols();
    }
    // eslint-disable-next-line
  }, [database, getColumnAttributes, loadingDatabase, widgetType]);

  return (
    <>
      <div style={{ padding: 24, background: "#fff" }}>
        <Spin spinning={loadingDatabase}>
          <Select
            placeholder="Select widget type"
            style={{ width: 220, marginRight: "2rem" }}
            size="large"
            value={widgetType}
            onChange={(value) => {
              history.push(`/widgets?widget=${value}`);
            }}
          >
            {widgets.map((item) => (
              <Option key={item.title} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>

          {initialized && (
            <>
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                size={"large"}
                disabled={!widgetType}
                onClick={() => props.history.push(`widgets/${widgetType}/new`)}
              >
                Create a new widget
              </Button>
              <TableContainer>
                <Table
                  key={widgetType}
                  hideGroupBy
                  tableRef={tableRef}
                  fetchData={fetchData}
                  wrapperHeight={false}
                  columns={columns}
                  gridOptions={{
                    groupIncludeTotalFooter: false,
                    domLayout: "autoHeight",
                    paginationAutoPageSize: false,
                  }}
                />
              </TableContainer>
            </>
          )}
        </Spin>
      </div>
    </>
  );
}
