import { message } from "antd";
import AWS from "aws-sdk";
import { trimS3FilePathFromURL } from "../utils";

const config = {
    maxRetries: 5,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

export function createImageUploadProps(
    dir,
    setImage,
    uploadLoading,
    setUploadLoading
) {
    const imageUploadProps = {
        beforeUpload: beforeUpload,
        async customRequest({
                                action,
                                data,
                                file,
                                filename,
                                headers,
                                onError,
                                onProgress,
                                onSuccess,
                            }) {
            const S3 = new AWS.S3(config);
            const params = {
                Body: file,
                ContentType: file.type,
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: dir + "/" + file.uid + "-" + file.name,
            };

            const options = {
                queueSize: 5,
                partSize: 10 * 1024 * 1024,
            };

            try {
                setUploadLoading && setUploadLoading(true);
                await S3.upload(params, options)
                    .promise()
                    .then((data) => {
                        onSuccess && onSuccess(data, file);
                        message.success(`${file.name} uploaded successfully`);
                        setUploadLoading && setUploadLoading(false);
                        setImage &&
                        setImage(
                            `https://localeyz-web-platform.s3.amazonaws.com/${dir}/${file.uid}-${file.name}`
                        );
                    })
                    .catch((err) => {
                        onError();
                        message.error(err.message);
                        setUploadLoading && setUploadLoading(false);
                    });
            } catch (err) {
                message.error(err.message);
            }
        },
    };
    return imageUploadProps;
}

export function beforeUpload(file) {
    const type = file.type.split("/")[0];
    if (type === "video") {
        //TODO: ADD MAX VIDEO SIZE AND FORMATE TYPE CHECKES HERE IF REQUIRED
        return true;
    } else if (type === "application") {
        //TODO: ADD PDF FILE SIZE AND ChANGE FORMATE TYPE CHECKES HERE IF REQUIRED
        return true;
    } else if (type === "image") {
        const isLt2M = file.size / 10240 / 10240 < 10;
        if (!isLt2M) {
            message.error("Image must smaller than 10MB!");
        }
        return isLt2M;
    }
}

export async function deleteFile(key) {
    const S3 = new AWS.S3(config);
    var deleteParam = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: key,
    };
    try {
        await S3.deleteObject(deleteParam)
            .promise()
            .then(() => message.success("File deleted successfully."))
            .catch((err) => message.error(err.message));
    } catch (err) {
        message.error(err.message);
    }

    return;
}

export function deleteFiles(files, onDone) {
    AWS.config.update(config);
    const S3 = new AWS.S3();
    var deleteParam = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Delete: {
            Objects: files.map((url) => ({Key: trimS3FilePathFromURL(url)})),
        },
    };

    S3.deleteObjects(deleteParam, onDone)
        .on("httpUploadProgress", function () {
        })
        .send(function (err, data) {
            if (err) {
                message.error(err.message);
            } else {
                message.success("Deleted successfully.");
            }
        });
    return;
}
