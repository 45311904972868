import {
  DEFAULT_EPISODE_COLUMNS,
  DEFAULT_PROGRAM_COLUMNS,
} from "../utils/contants";

export const USER_SESSION_KEY = "LOCALEYZ_ADMIN_USER_SESSION_KEY_V1";
const LOCALEYZ_EP_VISIBLE_COLUMNS_KEY = "LOCALEYZ_EP_VISIBLE_COLUMNS_KEY";
const LOCALEYZ_PR_VISIBLE_COLUMNS_KEY = "LOCALEYZ_PR_VISIBLE_COLUMNS_KEY";

function saveSession(credentials) {
  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(credentials));
  return true;
}

function getSession() {
  let session = localStorage.getItem(USER_SESSION_KEY);
  return session ? JSON.parse(session) : undefined;
}

function deleteSession() {
  localStorage.removeItem(USER_SESSION_KEY);
  localStorage.removeItem(LOCALEYZ_EP_VISIBLE_COLUMNS_KEY);
  localStorage.removeItem(LOCALEYZ_PR_VISIBLE_COLUMNS_KEY);
  return true;
}

function isSession() {
  let session = localStorage.getItem(USER_SESSION_KEY);
  return !!session;
}

function saveEpisodeVisibleColumn(columns) {
  localStorage.setItem(
    LOCALEYZ_EP_VISIBLE_COLUMNS_KEY,
    JSON.stringify(columns)
  );
  return true;
}

function getEpisodeVisibleColumn() {
  let data = localStorage.getItem(LOCALEYZ_EP_VISIBLE_COLUMNS_KEY);
  return data ? JSON.parse(data) : DEFAULT_EPISODE_COLUMNS;
}

function saveProgramVisibleColumn(columns) {
  localStorage.setItem(
    LOCALEYZ_PR_VISIBLE_COLUMNS_KEY,
    JSON.stringify(columns)
  );
  return true;
}

function getProgramVisibleColumn() {
  let data = localStorage.getItem(LOCALEYZ_PR_VISIBLE_COLUMNS_KEY);
  return data ? JSON.parse(data) : DEFAULT_PROGRAM_COLUMNS;
}

export {
  saveSession,
  getSession,
  deleteSession,
  isSession,
  getEpisodeVisibleColumn,
  getProgramVisibleColumn,
  saveProgramVisibleColumn,
  saveEpisodeVisibleColumn,
};
