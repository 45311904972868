import React, { useState, useEffect } from "react";
import { message, Form } from "antd";
import { getParams } from "../../helpers/URLParams";
import { useMutation } from "@apollo/client";
import UserInfoForm from "../../components/UserInfoForm";
import { Mutations, Queries, ApolloDataClient } from "../../api";
import {
  initialSocialLink,
  trimErrorMessage,
  hasDigit,
  hasLowerCase,
  hasUpperCase,
  hasSpecialCharacter,
} from "../../utils";
import { UserRole } from "../../utils/contants";

const getUserType = () => {
  const path = window.location.pathname.toLowerCase();
  if (path.includes("producer")) {
    return UserRole.PRODUCER;
  } else if (path.includes("staff")) {
    return UserRole.STAFF;
  } else {
    return UserRole.STAFF;
  }
};

const CreateUser = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [squareImage, setSquareImage] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [organizationID, setOrganizationID] = useState();
  const [socialLinksList, setSocialLinksList] = useState(initialSocialLink);
  const isProducersCreate = window.location.pathname
    .toLowerCase()
    .includes("producer");

  const [createUser, { data, loading: createUserLoading }] = useMutation(
    Mutations.CREATE_USER
  );

  useEffect(() => {
    if (isProducersCreate) {
      const callProduceSettings = async () => {
        try {
          setLoading(true);
          const result = await ApolloDataClient.client.query({
            query: Queries.PRODUCER_SETTINGS,
          });
          if (result.data) {
            setCustomFields(result.data.producerSetting?.customFields);
            setLoading(false);
          } else {
            setLoading(false);
            message.error("Unable to get producer settings!");
          }
        } catch (e) {
          setLoading(false);
          if (e.message.includes("Network error")) {
            message.error("Please check your network connection!");
          } else message.error(trimErrorMessage(e.message));
          handleFinish();
        }
      };
      callProduceSettings();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      message.success("Account Created Successfully");
      handleFinish();
    }
    // eslint-disable-next-line
  }, [data, props.history]);

  useEffect(() => {
    if (props.inviteDetails) {
      setFormFields(props.inviteDetails);
      setOrganizationID(props.inviteDetails.organisationId);
    } else {
      setFormFields();
      setOrganizationID(null);
    }
    // eslint-disable-next-line
  }, [props]);

  const handleFinish = () => {
    if (isProducersCreate) {
      props.history.push("/users/producer");
    } else {
      props.history.push("/users/staff");
    }
  };

  const setFormFields = (data = {}) => {
    form.setFieldsValue({
      email: data?.email || "",
      firstname: data?.firstname || "",
      lastname: data?.lastname || "",
      bio: "",
      state: "New York",
      city: "",
      phone: "",
      zipcode: "02130",
      address: "",
      password: "",
      username: "",
      socialMediaLinks: [],
      customFieldValue: [],
      passwordConfirmation: "",
      avatar: "",
      userType: data?.userType || getUserType(),
    });
  };

  const onSubmit = async () => {
    const fields = form.getFieldsValue();
    if (
      fields["password"].length > 7 &&
      hasDigit(fields["password"]) &&
      hasLowerCase(fields["password"]) &&
      hasUpperCase(fields["password"]) &&
      hasSpecialCharacter(fields["password"])
    ) {
      let customFieldValue = [];
      if (customFields?.length > 0) {
        customFields.forEach((customField) => {
          customFieldValue.push({
            id: customField.id,
            value:
              fields[customField.id] === undefined
                ? ""
                : JSON.stringify(fields[customField.id]),
          });
        });
      }

      const variables = {
        address: fields["address"],
        bio: fields["bio"],
        city: fields["city"],
        customFieldValue,
        email: fields["email"],
        phone: fields["phone"],
        state: fields["state"],
        zipcode: fields["zipcode"],
        username: fields["username"],
        organizationId: organizationID,
        userType: isProducersCreate ? UserRole.PRODUCER : UserRole.STAFF,
        token: getParams().token,
        socialMediaLinks: socialLinksList.filter(
          (item) => item.url && delete item.id
        ),
        password: fields["password"],
        passwordConfirmation: fields["password"],
        name: `${fields.firstname} ${fields.lastname}`,
        lastname: fields.lastname,
        firstname: fields.firstname,
        avatar: squareImage,
      };

      createUser({
        variables: {
          ...variables,
        },
      }).catch((e) => {
        message.error(trimErrorMessage(e.message));
      });
    } else {
      message.error("Your password does not satisfy the requirements.");
    }
  };
  return (
    <UserInfoForm
      form={form}
      onSubmit={onSubmit}
      loading={loading || createUserLoading}
      squareImage={squareImage}
      setSquareImage={setSquareImage}
      socialLinksList={socialLinksList}
      setSocialLinksList={setSocialLinksList}
      disabledFields={props.disabledFields}
      customFields={customFields}
      onCancel={handleFinish}
    />
  );
};
export default CreateUser;
