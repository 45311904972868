import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

function DeleteConfirm(props) {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState("");

    const handleOk = () => {
        props.action(props.id);
        setVisible(false);
    };

    if (props.bypass) {
        return (
            <>
                <Button
                    disabled={props.disabled ? props.disabled : false}
                    icon={<DeleteOutlined/>}
                    type="danger"
                    onClick={() => setVisible(true)}
                >
                    Delete
                </Button>
                <Modal
                    title="Are you sure about that?"
                    visible={visible}
                    onOk={handleOk}
                    onCancel={() => setVisible(false)}
                    okText={"Delete"}
                    okButtonProps={{
                        type: "danger"
                    }}
                >
                    <p>
                        {props.message}
                    </p>
                </Modal>
            </>
        )
    }

    return (
        <>
            <Button
                disabled={props.disabled ? props.disabled : false}
                icon={<DeleteOutlined/>}
                type="danger"
                onClick={() => setVisible(true)}
            >
                Delete
            </Button>
            <Modal
                title="Are you sure about that?"
                visible={visible}
                onOk={handleOk}
                onCancel={() => setVisible(false)}
                okText={"Delete"}
                okButtonProps={{
                    type: "danger",
                    disabled: value !== props.name,
                }}
            >
                <p>
                    Are you sure you want to delete <b>{props.name}</b>?
                </p>
                <p>Please type the name to confirm.</p>
                <Input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    size={"large"}
                />
            </Modal>
        </>
    );
}

export default DeleteConfirm;
