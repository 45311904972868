import React from "react";
import { Tag } from "antd";
import { UserRole, InviteStatus } from "../../../utils/contants";

const columns = [
  {
    title: "Name",
    dataIndex: "firstname",
    key: "name",
    render: (text, record) => (
      <span>{(record.firstname + " " + record.lastname).trim()}</span>
    ),
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
    render: (text) => (
      <span>
        <Tag color={"orange"}>{text}</Tag>
      </span>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text, record, i) => (
      <span>
        {record.status === InviteStatus.PENDING ? (
          <Tag color="magenta" size="small">
            {text}
          </Tag>
        ) : (
          <Tag color="green">{text}</Tag>
        )}
      </span>
    ),
  },
  {
    title: "Role",
    key: "userType",
    dataIndex: "userType",
    filters: [
      { text: "Admin", value: UserRole.ADMIN },
      { text: "Staff", value: UserRole.STAFF },
    ],
    onFilter: (value, record) => record.userType === value,
    render: (text) => (
      <span>
        <Tag color="green" size="small">
          {text}
        </Tag>
      </span>
    ),
  },
];

export { columns };
